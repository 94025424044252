<template>
   <footer class="footer-section style-2">
        <div class="container">
            <div class="footer-top">
                <div class="row g-lg-4 gy-5 justify-content-center">
                    <div class="col-lg-3 col-md-6 col-sm-6">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <a href="#"><img :src="portalLogo" style="height: 70px;
                                 filter: brightness(0) invert(1);" ></a>
                            </div>
                            <h3>Want <span>to Take <br></span> Tour Packages<span>?</span></h3>
                            <!-- <a href="package-grid.html" class="primary-btn1">Book A Tour</a> -->
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-6 d-flex justify-content-lg-center justify-content-sm-start">
                        <div class="footer-widget">
                            <div class="widget-title">
                                <h5>Quick Link</h5>
                            </div>
                            <ul class="widget-list">
                                <li><a href="/aboutUs" target="_blank">About Us</a></li>
                                <li><a href="/contactPage" target="_blank">Contact Us</a></li>
                                <li><a href="/privacyPolicy" target="_blank">Privacy Policy</a></li>
                                <li><a href="/termsOfUse" target="_blank">Terms Of Use</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-6 d-flex justify-content-lg-center justify-content-md-start">
                        <div class="footer-widget">
                            <!-- <div class="single-contact mb-10">
                                <div class="widget-title">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <g clip-path="url(#clip0_1139_225)">
                                            <path
                                                d="M17.5107 13.2102L14.9988 10.6982C14.1016 9.80111 12.5765 10.16 12.2177 11.3262C11.9485 12.1337 11.0514 12.5822 10.244 12.4028C8.44974 11.9542 6.0275 9.62168 5.57894 7.73772C5.3098 6.93027 5.84808 6.03314 6.65549 5.76404C7.82176 5.40519 8.18061 3.88007 7.28348 2.98295L4.77153 0.470991C4.05382 -0.156997 2.97727 -0.156997 2.34929 0.470991L0.644745 2.17553C-1.0598 3.96978 0.82417 8.72455 5.04066 12.941C9.25716 17.1575 14.0119 19.1313 15.8062 17.337L17.5107 15.6324C18.1387 14.9147 18.1387 13.8382 17.5107 13.2102Z" />
                                        </g>
                                    </svg>
                                    <h5>More Inquiry</h5>
                                </div>
                                <a href="tel:999858624984">+999-858 624 984</a>
                            </div> -->
                            <!-- <div class="single-contact mb-35">
                                <div class="widget-title">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <g clip-path="url(#clip0_1139_218)">
                                            <path
                                                d="M6.56266 13.2091V16.6876C6.56324 16.8058 6.60099 16.9208 6.67058 17.0164C6.74017 17.112 6.83807 17.1832 6.9504 17.22C7.06274 17.2569 7.18382 17.2574 7.29648 17.2216C7.40915 17.1858 7.5077 17.1155 7.57817 17.0206L9.61292 14.2516L6.56266 13.2091ZM17.7639 0.104306C17.6794 0.044121 17.5799 0.00848417 17.4764 0.00133654C17.3729 -0.00581108 17.2694 0.015809 17.1774 0.0638058L0.302415 8.87631C0.205322 8.92762 0.125322 9.00617 0.0722333 9.1023C0.0191447 9.19844 -0.00472288 9.30798 0.00355981 9.41749C0.0118425 9.52699 0.0519151 9.6317 0.11886 9.71875C0.185804 9.80581 0.276708 9.87143 0.380415 9.90756L5.07166 11.5111L15.0624 2.96856L7.33141 12.2828L15.1937 14.9701C15.2717 14.9963 15.3545 15.0051 15.4363 14.996C15.5181 14.9868 15.5969 14.9599 15.6672 14.9171C15.7375 14.8743 15.7976 14.8167 15.8433 14.7482C15.8889 14.6798 15.9191 14.6021 15.9317 14.5208L17.9942 0.645806C18.0094 0.543093 17.996 0.438159 17.9554 0.342598C17.9147 0.247038 17.8485 0.164569 17.7639 0.104306Z" />
                                        </g>
                                    </svg>
                                    <h5>Send Mail</h5>
                                </div>
                                <a
                                    href="https://demo-egenslab.b-cdn.net/cdn-cgi/l/email-protection#8ee7e0e8e1ceebf6efe3fee2eba0ede1e3"><span
                                        class="__cf_email__"
                                        data-cfemail="11787f777e517469707c617d743f727e7c">[email&#160;protected]</span></a>
                            </div> -->
                            <div class="single-contact">
                                <div class="widget-title">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                        <g clip-path="url(#clip0_1137_183)">
                                            <path
                                                d="M14.3281 3.08241C13.2357 1.19719 11.2954 0.0454395 9.13767 0.00142383C9.04556 -0.000474609 8.95285 -0.000474609 8.86071 0.00142383C6.70303 0.0454395 4.76268 1.19719 3.67024 3.08241C2.5536 5.0094 2.52305 7.32408 3.5885 9.27424L8.05204 17.4441C8.05405 17.4477 8.05605 17.4513 8.05812 17.4549C8.25451 17.7963 8.60632 18 8.99926 18C9.39216 18 9.74397 17.7962 9.94032 17.4549C9.94239 17.4513 9.9444 17.4477 9.9464 17.4441L14.4099 9.27424C15.4753 7.32408 15.4448 5.0094 14.3281 3.08241ZM8.99919 8.15627C7.60345 8.15627 6.46794 7.02076 6.46794 5.62502C6.46794 4.22928 7.60345 3.09377 8.99919 3.09377C10.3949 3.09377 11.5304 4.22928 11.5304 5.62502C11.5304 7.02076 10.395 8.15627 8.99919 8.15627Z" />
                                        </g>
                                    </svg>
                                    <h5>Contact Us</h5>
                                </div>
                                <!-- <a
                                    href="https://www.google.com/maps/place/Egens+Lab/@23.8340712,90.3631117,17z/data=!3m1!4b1!4m6!3m5!1s0x3755c14c8682a473:0xa6c74743d52adb88!8m2!3d23.8340663!4d90.3656866!16s%2Fg%2F11rs9vlwsk?entry=ttu">House
                                    168/170, Avenue 01, Mirpur <br> DOHS, Dhaka Bangladesh</a>
                                    <p class="mt-2" style="font-size:18px;"></p> -->
                                    <p>{{portAddress1}}</p>
                                    <p>{{emailPortal}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-sm-6 d-flex justify-content-lg-end justify-content-sm-end">
                        <div class="footer-widget">
                            <div class="widget-title two">
                                <h5>Join Our <span>Newsletter & Update</span> Package.</h5>
                            </div>
                            <form>
                                <div class="form-inner d-flex flex-column">
                                    <input type="text" class="mb-2" placeholder="Email here...">
                                    <input type="text" class="mb-2" placeholder="Phone Number...">
                                    <button type="submit">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                            viewBox="0 0 18 18">
                                            <g>
                                                <path
                                                    d="M6.56462 13.2091V16.6876C6.56519 16.8058 6.60295 16.9208 6.67254 17.0164C6.74213 17.112 6.84002 17.1832 6.95236 17.22C7.06469 17.2569 7.18577 17.2574 7.29844 17.2216C7.41111 17.1858 7.50965 17.1155 7.58012 17.0206L9.61487 14.2516L6.56462 13.2091ZM17.7659 0.104306C17.6814 0.044121 17.5818 0.00848417 17.4783 0.00133654C17.3748 -0.00581108 17.2714 0.015809 17.1794 0.0638058L0.304368 8.87631C0.207275 8.92762 0.127275 9.00617 0.0741864 9.1023C0.0210978 9.19844 -0.00276976 9.30798 0.00551294 9.41749C0.0137956 9.52699 0.0538682 9.6317 0.120813 9.71875C0.187757 9.80581 0.278661 9.87143 0.382368 9.90756L5.07362 11.5111L15.0644 2.96856L7.33337 12.2828L15.1956 14.9701C15.2736 14.9963 15.3564 15.0051 15.4382 14.996C15.52 14.9868 15.5988 14.9599 15.6691 14.9171C15.7394 14.8743 15.7995 14.8167 15.8452 14.7482C15.8909 14.6798 15.9211 14.6021 15.9336 14.5208L17.9961 0.645806C18.0114 0.543093 17.998 0.438159 17.9573 0.342598C17.9167 0.247038 17.8504 0.164569 17.7659 0.104306Z" />
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </form>
                            <!-- <div class="payment-partner">
                                <div class="widget-title">
                                    <h5>Payment Partner</h5>
                                </div>
                                <div class="icons">
                                    <ul>
                                        <li><img src="../assets/img/home1/icon/visa-logo.svg" alt></li>
                                        <li><img src="../assets/img/home1/icon/stripe-logo.svg" alt></li>
                                        <li><img src="../assets/img/home1/icon/paypal-logo.svg" alt></li>
                                        <li><img src="../assets/img/home1/icon/woo-logo.svg" alt></li>
                                        <li><img src="../assets/img/home1/icon/skrill-logo.svg" alt></li>
                                    </ul>
                                </div>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom">
                <div class="row">
                    <div
                        class="col-lg-12 d-flex flex-md-row flex-column align-items-center justify-content-md-between justify-content-center flex-wrap gap-3">
                        <ul class="social-list m-0">
                            <li>
                                <a href="https://www.facebook.com/"><i class="fa-brands fa-facebook-f"></i></a>
                            </li>
                            <li>
                                <a href="https://twitter.com/"><svg xmlns="http://www.w3.org/2000/svg" width="10"
                                        height="10" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                                        <path
                                            d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865l8.875 11.633Z" />
                                    </svg></a>
                            </li>
                            <li>
                                <a href="https://www.pinterest.com/"><i class="fa-brands fa-pinterest-p"></i></a>
                            </li>
                            <li>
                                <a href="https://www.instagram.com/"><i class="bi bi-instagram"></i></a>
                            </li>
                        </ul>
                        <p>© Copyright {{ get_year() }}, {{portLegaName}}</p>
                        <!-- <div class="footer-right">
                            <ul>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Terms & Condition</a></li>
                            </ul>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
  
  import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';
  
  export default {
    data() {
      return {
  
        portalLogo: "",
        getInstagram: "",
        getFacebook: "",
  
        footerMenus: [],
        footMenu: "",
  
        phonePortal: '',
        emailPortal: '',
  
        portAddress1:"",
        portAddress2:"",
        portLegaName:"",
  
      }
    },
  
    methods: {
      get_year(){
        return new Date().getFullYear()
      },
  
      getConfig() {
        // let getConfigData = this.$store.state.data
  
        const getConfigData = getConfigDataFromLocalStorage();
  
        // let portalLocal = localStorage.getItem("portalData")
        // let getConfigData = JSON.parse(portalLocal)
        if (getConfigData) {
          this.portalLogo = getConfigData.payload.portal_configuration.logo_path
          this.getInstagram = getConfigData.payload.portal_configuration.social_media_links.instagram
          this.getFacebook = getConfigData.payload.portal_configuration.social_media_links.facebook
  
          this.portAddress1 = getConfigData.payload.portal_configuration.portal_footer_address1
          this.portAddress2 = getConfigData.payload.portal_configuration.portal_footer_address2
          this.portLegaName = getConfigData.payload.portal_configuration.portal_legal_name
  
  
          this.phonePortal = getConfigData.payload.portal_configuration.phone
          this.emailPortal = getConfigData.payload.portal_configuration.email
  
          // let footMenu=""
          this.footMenu = getConfigData.payload.portal_configuration.menus.enabled.footer
          // this.footerMenus = footMenu.split(',')
  
          // console.log(this.footMenu, 'fmfmfmfmfm...')
        }
      },
  
  
    },
  
  
    mounted() {
      // this.getConfig();
    },
  
    created() {
      this.getConfig();
      this.get_year();
    }
  }
  
</script>

<style scoped>
 @import "@/assets/style.css";
 a{
    text-decoration: none !important;
 }
</style>