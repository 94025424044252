<template>

    <div class="p-3" style="background-color:#ffffffd9">
        <section v-html="aboutContent"></section>
  </div>
</template>


<script>

import axios from "axios";

import { getConfigDataFromLocalStorage } from '@/localStorageData/localStorageUtil.js';

export default {
    data() {
        return {

            aboutContent:"",
            getAboutUrl: "",
            aboutType: "",
        }
    },

    methods: {

        getConfig() {
            // let getConfigData = this.$store.state.data

            // let portalLocal = localStorage.getItem("portalData")
            // let getConfigData = JSON.parse(portalLocal)

            const getConfigData = getConfigDataFromLocalStorage();
            if (getConfigData) {
                this.getAboutUrl = getConfigData.payload.portal_configuration.content_data.aboutus.url
                this.aboutType = getConfigData.payload.portal_configuration.content_data.aboutus.type
                // console.log(getConfigData, 'head...about..1..')
                // console.log(this.getAboutUrl, 'head...about..2..')

                this.getConfigFunc();
            }
        },

        getConfigFunc() {
            axios.get(this.getAboutUrl, {
                headers: {
                    "Content-Type": this.aboutType,
                },
            })
                .then((response) => {

                    this.aboutContent = response.data
                    console.log(response.data, "getAboutUrlrgetAboutUrlrgetAboutUrlrab.....")

                })

                .catch((error) => {
                    console.log(error, 'erroroor.....')
                })
        }
    },

    created() {
            this.getConfig();
            this.getConfigFunc();
    }
}

</script>