<template>
 <v-app>
    <v-main>
      <div v-if="datashow">
        <div id="navbar">
          <component :is="selectedHeader" />
        </div>
        <div class="bg-pages">
          <router-view />
        </div>

        <div id="food">
          <component :is="selectedFooter" />
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import headerCom1 from "@/components/headerComponent.vue";
import footerCom from "@/components/FooterComponent.vue";
import country1 from "@/coundtrycode.json";
import CryptoJS from "crypto-js";
import axios from "axios";
import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";
export default {
  name: 'App',
  components: {
    headerCom1,
    footerCom,
    dataDomain: "",
    dataDomainData: "",
    SearchDomain: "",
    searchDomaintitle: "",
  },
  data(){
    return{
      selectedHeader: "headerCom1",
      selectedFooter: "footerCom",
      navbar: true,
      food: true,
      datashow: false,
      countryname: "",
      loading: false,
      visitcountryname: "",

      configReq: {
        service_type: "GetPortalConfigRQ",
        api_ver: "1",
        payload: {
          domain_name: "zetsfly.com",
          env: "CERT",
          language: "EN",
          token: "jsdjOJDKJ7675",
        },
      },

      configUser_Id: "",
      }
  },
  methods: {
    indexHeader() {
      const getConfigData = getConfigDataFromLocalStorage();

      if (getConfigData) {
        let portalTitle = getConfigData.payload.portal_configuration.site_title;
        let portalFavicon = getConfigData.payload.portal_configuration.favicon;

        document.getElementById("siteTitle").innerText = portalTitle;
        document.getElementById("faviconPortal").href = portalFavicon;
      }
    },

    envPortal() {
      let hostname = window.location.hostname;
      // console.log(hostname, "hshshshs....")
      if (hostname == "localhost") {
        console.log(hostname, "localhost....");
      } else {
        this.configReq.payload.domain_name = hostname;
      }

      console.log(this.configReq, "configReqconfigReqconfigReq");
      axios
        .get("https://api.zetsfly.com/api/getportalconfig", {
          params: this.configReq,
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          if (response) {
            this.loading = true;
            let configResponse = response.data;
            console.log(configResponse, "configResponseconfigResponse");
            this.dataDomainData =
              configResponse.payload.portal_configuration.geoip_location.country_domain.replace(
                "https://",
                ""
              );
            this.dataDomain =
              this.dataDomainData.charAt(0).toUpperCase() +
              this.dataDomainData.slice(1);
            this.SearchDomain = this.configReq.payload.domain_name;
            this.searchDomaintitle =
              configResponse.payload.portal_configuration.site_title;
            this.countryname =
              configResponse.payload.portal_configuration.country.name;
            let $dataname =
              configResponse.payload.portal_configuration.geoip_location
                .country_iso_code;
            // console.log(this.dataDomain,this.SearchDomain, 'response.data')
            country1.forEach((v) => {
              if ($dataname == "US" || $dataname == "CA") {
                if (v.code == $dataname) {
                  this.visitcountryname = v.name;
                }
              } else {
                this.visitcountryname = "Global";
              }
            });

            // console.log(t$data,this.SearchDomain,'this.dataDomain')
            if (this.dataDomainData.includes(this.SearchDomain)) {
              console.log("pppppppaduhdh");
              this.domaintrue = false;
            } else {
              this.domaintrue = true;
            }

            // Encrypt data using CryptoJS
            const secretKey = "portal_config_datas";
            const encryptedData = CryptoJS.AES.encrypt(
              JSON.stringify(configResponse),
              secretKey
            ).toString();

            localStorage.setItem("portalData", encryptedData);

            // localStorage.setItem("portalData", JSON.stringify(configResponse))

            if (configResponse) {
              this.datashow = true;
            }
            this.indexHeader();
          }
        })
        .catch((error) => {
          console.log("Error fetching data:", error);
          alert("Something went difficulties...");
          this.loading = false;
        });
    },

  },
    created() {
    this.envPortal();
    // this.getRandomChars();

    setTimeout(() => {
      // this.indexHeader();
    }, 2000);
  },

  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        if (newValue === "/loader") {
          this.navbar = false;
          this.food = false;
        } else {
          this.navbar = true;
          this.food = true;
        }
        // console.log(newValue, '4545');
      },
    },
  },
};

</script>

<style scoped>
 @import "@/assets/style.css";

</style>
