import { createRouter, createWebHistory } from "vue-router";
import HomeComponent from "../components/HomeComponent.vue";
import SearchResult from "../components/SearchResult.vue";
import loader from "../components/loaderComponent.vue";
import FlightDetails from "../components/flightDetailsCom.vue";
import FlightInfo from "../components/flightInfo.vue";
import confirmBook from "../components/confirmBook.vue";
import bookingView from "../components/bookingView.vue";
import aboutUs from "../components/aboutUs.vue";
import contactPage from "../components/contactPage.vue";
import privacyPolicy from "../components/privacyPolicy.vue";
import termsOfUse from "../components/termsOfUse.vue";
import loginCom from "../components/loginCom.vue";
import registerCom from "../components/registerCom.vue";
import myBooking from "../components/myBooking.vue";
import pageError from "../components/pageError.vue";
import pageNot from "../components/pageNot.vue";
import viewBooking from "../components/viewBooking.vue";
import dashboard from "../components/dashboardPage.vue";

const routes = [
  {
    path: "/",
    component: HomeComponent,
  },
  {
    path: "/search",
    component: SearchResult,
  },
  {
    path: "/loader",
    component: loader,
  },
  {
    path: "/flightDetailsCom",
    component: FlightDetails,
  },
  {
    path: "/flightinfo",
    component: FlightInfo,
  },
  {
    path: "/confirmBook",
    component: confirmBook,
  },
  {
    path: "/bookingView",
    component: bookingView,
  },
  {
    path: "/aboutUs",
    component: aboutUs,
  },
  {
    path: "/contactPage",
    component: contactPage,
  },
  {
    path: "/privacyPolicy",
    component: privacyPolicy,
  },
  {
    path: "/termsOfUse",
    component: termsOfUse,
  },
  {
    path: "/login",
    component: loginCom,
  },
  {
    path: "/register",
    component: registerCom,
  },
  {
    path: "/myBooking",
    component: myBooking,
  },
  {
    path: "/pageError",
    component: pageError,
  },
  {
    path: "/pageNot",
    component: pageNot,
  },
  {
    path: "/viewbooking",
    component: viewBooking,
  },
  {
    path: "/dashboard",
    component: dashboard,
  },

  { path: "/:pathMatch(.*)*", name: "NotFound", component: pageNot },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
