<template>
  <div v-if="searchresult" class="mb-4">
    <div class="modify-search">
      <div class="flight-search">
        <div class="container">
          <!-- Nav tabs -->
          <ul class="nav nav-tabs border-0" role="tablist">
            <li class="nav-item">
              <button
                @click="act(2)"
                class="nav-link"
                :class="{ active: isActiveTab === 'oneway' }"
                id="oneway-tab-btn"
                data-bs-toggle="tab"
                data-bs-target="#oneway"
                type="button"
                role="tab"
                aria-controls="oneway"
                aria-selected="true"
              >
                <span
                  class="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"
                ></span>
                One-way
              </button>
            </li>
            <li class="nav-item">
              <button
                class="nav-link"
                @click="act(1)"
                :class="{ active: isActiveTab === 'return' }"
                id="return-tab-btn"
                data-bs-toggle="tab"
                data-bs-target="#return"
                type="button"
                role="tab"
                aria-controls="return"
                aria-selected="false"
              >
                <span
                  class="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"
                ></span>
                Return
              </button>
            </li>
            <li class="nav-item">
              <button
                @click="act(3)"
                class="nav-link"
                :class="{ active: isActiveTab === 'multiCity' }"
                id="multiCity-tab-btn"
                data-bs-toggle="tab"
                data-bs-target="#multiCity"
                type="button"
                role="tab"
                aria-controls="multiCity"
                aria-selected="false"
              >
                <span
                  class="d-inline-block icon-20 rounded-circle bg-white align-middle me-2"
                ></span>
                Multi-city
              </button>
            </li>
          </ul>

          <!-- Tab content -->

          <div class="tab-content">
            <!-- oneway search -->
            <div v-if="onewayForm">
              <div class="row">
                <div class="col-12">
                  <div
                    class="search-pan row mx-0 theme-border-radius justify-content-evenly"
                  >
                    <div class="col-12 col-md-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                      <div
                        class="form-group1"
                        @focusin="handleFocus(1)"
                        @focusout="handleBlur(1)"
                      >
                        <div class="form-groupicon">
                          <i
                            class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                          ></i>
                          <!-- <v-icon color="grey" size="40">
                            mdi-map-marker
                          </v-icon> -->
                        </div>

                        <!-- <AutoComplete
                          v-model="oneway.from"
                          optionLabel="name"
                          dropdown
                          placeholder="Origin"
                          forceSelection
                          :suggestions="filteredCountries"
                          @complete="search"
                          ref="fromAutoComplete"
                          @change="onChangeFrom()"
                          @click="fromClick(oneway), (focusedElement = 'from')"
                          class="autoComInput"
                        >
                          <template #option="slotProps">
                            <div class="flex align-items-center dropdown-box" @click="focus1()">
                              <div>{{ slotProps.option.originalName }}</div>
                            </div>
                          </template>
</AutoComplete> -->

                        <div class="vas-autocomplete">
                          <input
                            type="text"
                            placeholder="Origin"
                            v-model="inputCity"
                            @keyup="typeCity(inputCity, 1)"
                            class="inter-input"
                          />

                          <div @click="onewaySwap" class="pos-swap">
                            <v-icon
                              size="30"
                              color="white"
                              :class="{
                                rotateSwap: !swapData,
                                normalSwap: swapData,
                              }"
                              >mdi-swap-horizontal</v-icon
                            >
                          </div>

                          <div v-if="typedrop">
                            <div
                              v-if="
                                inputCity.length > 2 && this.city.length > 0
                                  ? true
                                  : ((this.city = []), false)
                              "
                              class="autocompete-menual-dropdown"
                            >
                              <div v-for="(data, index) in city" :key="index">
                                <div
                                  @click="datachange(data)"
                                  :style="
                                    index == city.length - 1
                                      ? ''
                                      : 'border-bottom:1px solid lightgray'
                                  "
                                  class="d-flex p-2"
                                >
                                  <div
                                    style="display: flex; align-items: center"
                                  >
                                    <v-icon
                                      style="transform: rotate(45deg)"
                                      size="20"
                                      >mdi-airplane</v-icon
                                    >
                                  </div>

                                  <div class="m-1">
                                    {{ data.originalName }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                      <div
                        class="form-group1"
                        @focusin="handleFocus(2)"
                        @focusout="handleBlur(2)"
                      >
                        <div class="form-groupicon">
                          <i
                            class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                          ></i>
                          <!-- <v-icon color="grey" size="40">
                            mdi-map-marker
                          </v-icon> -->
                        </div>

                        <!-- <AutoComplete
                          v-model="oneway.to"
                          optionLabel="name"
                          dropdown
                          placeholder="Destination"
                          forceSelection
                          :suggestions="filteredCountries"
                          @complete="search"
                          ref="fromAutoComplete"
                          @change="onChangeFrom()"
                          @click="toClick(oneway), (focusedElement = 'to')"
                          class="autoComInput"
                        >
                          <template #option="slotProps">
                            <div class="flex align-items-center dropdown-box" @click="focus1()">
                              <div>{{ slotProps.option.originalName }}</div>
                            </div>
                          </template>
                        </AutoComplete> -->

                        <div class="vas-autocomplete">
                          <input
                            type="text"
                            placeholder="Destination"
                            v-model="inputCityto"
                            @keyup="typeCity(inputCityto, 2)"
                            class="inter-input"
                          />

                          <div v-if="typedrop1">
                            <div
                              v-if="
                                inputCityto.length > 2 && this.city1.length > 0
                                  ? true
                                  : ((this.city1 = []), false)
                              "
                              class="autocompete-menual-dropdown"
                            >
                              <div v-for="(data, index) in city1" :key="index">
                                <div
                                  @click="datachangeTo(data)"
                                  :style="
                                    index == city1.length - 1
                                      ? ''
                                      : 'border-bottom:1px solid lightgray'
                                  "
                                  class="d-flex p-2"
                                >
                                  <div
                                    style="display: flex; align-items: center"
                                  >
                                    <v-icon
                                      style="transform: rotate(45deg)"
                                      size="20"
                                      >mdi-airplane</v-icon
                                    >
                                  </div>

                                  <div class="ms-1">
                                    {{ data.originalName }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!-- <button class="pos-swap">
                            <i class="bi bi-arrow-left-right pl-1"></i>
                          </button> -->
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2 DatecolumnSizing"
                    >
                      <div class="form-control form-group d-flex align-center">
                        <div class="d-flex align-center">
                          <i class="bi bi-calendar3 h2 icon-pos mb-0 me-2"></i>
                        </div>
                        {{
                          console.log(
                            oneway.dedate,
                            "oneway.dedateoneway.dedate33333333333333"
                          )
                        }}
                        <VueDatePicker
                          ref="dpRef1"
                          v-model="oneway.dedate"
                          :min-date="oneway.dedate"
                          :max-date="
                            new Date(
                              new Date().getFullYear() + 1,
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                          "
                          auto-apply
                          @mousedown="focusedElement = 'calendar1'"
                          :format="dateFormat"
                          :hide-navigation="['time']"
                          @focus="
                            (focusEvent3 = true),
                              (focusEvent1 = false),
                              (focusEvent2 = false)
                          "
                          placeholder="Select Date"
                          @update:modelValue="fromDateChanged"
                          class="
                           px-0"
                          :on-click-outside="ClickOutDepat"
                          :month-change-on-scroll="false"
                        >
                          <template #clear-icon="{}">
                            <v-icon
                              class="input-slot-image me-2"
                              v-if="focusEvent3"
                              @click="clearDate"
                              >mdi-close-circle</v-icon
                            >
                          </template>
                        </VueDatePicker>
                      </div>
                    </div>

                    <div
                      class="col-12 col-md-3 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-2 paxcolumnSizing"
                    >
                      <div class="dropdown form-control1" id="myDD">
                        <button
                          class="dropdown-toggle form-control d-flex align-center"
                          type="button"
                          id="travellerInfoOneway"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div>
                            <i
                              class="bi bi-person-lines-fill h2 icon-pos pe-2"
                            ></i>
                            <!-- <v-icon color="grey" size="40">mdi-account</v-icon> -->
                          </div>

                          <span class="text-truncate"
                            >{{ aduls + aduls1 + aduls2 }} Traveller(s),
                            {{ oneway.class }}
                          </span>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="travellerInfoOneway"
                        >
                          <ul class="drop-rest p-0">
                            <li>
                              <div class="d-flex">Adult</div>
                              <div class="ms-auto input-group plus-minus-input">
                                <div class="addbtn" @click.stop="deg1()">-</div>
                                <div class="adddata">{{ aduls }}</div>
                                <div class="addbtn" @click.stop="add1()">+</div>
                              </div>
                            </li>
                            <li>
                              <div class="d-flex">Child</div>
                              <div class="ms-auto input-group plus-minus-input">
                                <div class="addbtn" @click.stop="deg2()">-</div>
                                <div class="adddata">{{ aduls1 }}</div>
                                <div class="addbtn" @click.stop="add2()">+</div>
                              </div>
                            </li>
                            <li>
                              <div class="d-flex">Infants</div>
                              <div class="ms-auto input-group plus-minus-input">
                                <div class="addbtn" @click.stop="deg3()">-</div>
                                <div class="adddata">{{ aduls2 }}</div>
                                <div class="addbtn" @click.stop="add3()">+</div>
                              </div>
                            </li>
                          </ul>

                          <v-radio-group v-model="oneway.class">
                            <v-radio label="Economy" value="Economy"></v-radio>
                            <v-radio
                              label="Premium Economy"
                              value="Premium Economy"
                            ></v-radio>
                            <v-radio
                              label="Business"
                              value="Business"
                            ></v-radio>
                            <v-radio
                              label="First Class"
                              value="First Class"
                            ></v-radio>
                          </v-radio-group>

                          <ul class="drop-rest">
                            <li class="justify-end">
                              <button type="button" class="btn btn" onclick="">
                                Done
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-1 px-0">
                      <!-- <button
                        type="submit"
                        class="btn btn-search"
                        @click="sended()"
                      >
                        <span class="fw-bold">Search</span>
                      </button> -->
                      <button
                        type="submit"
                        class="btn btn-search"
                        @click="sendedone()"
                      >
                        <v-icon>mdi-magnify </v-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Return search -->

            <div v-if="returnForm">
              <div class="row">
                <div class="col-sm-12 col-md-12">
                  <div
                    class="search-pan row mx-0 theme-border-radius justify-content-evenly"
                  >
                    <div class="col-12 col-md-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                      <div
                        class="form-group1"
                        @focusin="handleFocus(1)"
                        @focusout="handleBlur(1)"
                      >
                        <div class="form-groupicon">
                          <i
                            class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                          ></i>
                          <!-- <v-icon color="grey" size="40">
                            mdi-map-marker
                          </v-icon> -->
                        </div>

                        <!-- <AutoComplete
                          v-model="oneway.from"
                          optionLabel="name"
                          dropdown
                          placeholder="Origin"
                          forceSelection
                          :suggestions="filteredCountries"
                          @complete="search"
                          ref="fromAutoComplete"
                          @change="onChangeFrom()"
                          @click="fromClick(oneway), (focusedElement = 'from')"
                          class="autoComInput"
                        >
                          <template #option="slotProps">
                            <div class="flex align-items-center dropdown-box" @click="focus1()">
                              <div>{{ slotProps.option.originalName }}</div>
                            </div>
                          </template>
                        </AutoComplete> -->

                        <div class="vas-autocomplete">
                          <input
                            type="text"
                            placeholder="Origin"
                            v-model="inputCity"
                            @keyup="typeCity(inputCity, 1)"
                            class="inter-input"
                          />

                          <div @click="onewaySwap" class="pos-swap">
                            <v-icon
                              size="30"
                              color="white"
                              :class="{
                                rotateSwap: !swapData,
                                normalSwap: swapData,
                              }"
                              >mdi-swap-horizontal</v-icon
                            >
                          </div>

                          <div v-if="typedrop">
                            <div
                              v-if="
                                inputCity.length > 2 && this.city.length > 0
                                  ? true
                                  : ((this.city = []), false)
                              "
                              class="autocompete-menual-dropdown"
                            >
                              <div v-for="(data, index) in city" :key="index">
                                <div
                                  @click="datachange(data)"
                                  :style="
                                    index == city.length - 1
                                      ? ''
                                      : 'border-bottom:1px solid lightgray'
                                  "
                                  class="d-flex p-2"
                                >
                                  <div
                                    style="display: flex; align-items: center"
                                  >
                                    <v-icon
                                      style="transform: rotate(45deg)"
                                      size="20"
                                      >mdi-airplane</v-icon
                                    >
                                  </div>

                                  <div class="m-1">
                                    {{ data.originalName }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2">
                      <div
                        class="form-group1"
                        @focusin="handleFocus(2)"
                        @focusout="handleBlur(2)"
                      >
                        <div class="form-groupicon">
                          <i
                            class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                          ></i>
                          <!-- <v-icon color="grey" size="40">
                            mdi-map-marker
                          </v-icon> -->
                        </div>

                        <!-- <AutoComplete
                          v-model="oneway.to"
                          optionLabel="name"
                          dropdown
                          placeholder="Destination"
                          forceSelection
                          :suggestions="filteredCountries"
                          @complete="search"
                          ref="fromAutoComplete"
                          @change="onChangeFrom()"
                          @click="fromClick(oneway), (focusedElement = 'from')"
                          class="autoComInput"
                        >
                          <template #option="slotProps">
                            <div class="flex align-items-center dropdown-box" @click="focus1()">
                              <div>{{ slotProps.option.originalName }}</div>
                            </div>
                          </template>
                        </AutoComplete> -->

                        <div class="vas-autocomplete">
                          <input
                            type="text"
                            placeholder="Destination"
                            v-model="inputCityto"
                            @keyup="typeCity(inputCityto, 2)"
                            class="inter-input"
                          />

                          <div v-if="typedrop1">
                            <div
                              v-if="
                                inputCityto.length > 2 && this.city1.length > 0
                                  ? true
                                  : ((this.city1 = []), false)
                              "
                              class="autocompete-menual-dropdown"
                            >
                              <div v-for="(data, index) in city1" :key="index">
                                <div
                                  @click="datachangeTo(data)"
                                  :style="
                                    index == city1.length - 1
                                      ? ''
                                      : 'border-bottom:1px solid lightgray'
                                  "
                                  class="d-flex p-2"
                                >
                                  <div
                                    style="display: flex; align-items: center"
                                  >
                                    <v-icon
                                      style="transform: rotate(45deg)"
                                      size="20"
                                      >mdi-airplane</v-icon
                                    >
                                  </div>

                                  <div class="ms-1">
                                    {{ data.originalName }}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2 DatecolumnSizing"
                    >
                      <div
                        class="form-control form-group roundTripDate d-flex align-center"
                      >
                        <div class="d-flex align-center">
                          <i class="bi bi-calendar3 h2 icon-pos mb-0 me-2"></i>
                        </div>

                        {{console.log(oneway.travelDate,'oooooooo')}}

                        <VueDatePicker
                          ref="dpRef2"
                          v-model="oneway.travelDate"
                          range
                          :min-date="new Date()"
                          :six-weeks="sixWeekMode"
                          @mousedown="focusedElement = 'calendar1'"
                          :format="dateFormat"
                          :max-date="
                            new Date(
                              new Date().getFullYear() + 1,
                              new Date().getMonth(),
                              new Date().getDate()
                            )
                          "
                          :hide-navigation="['time']"
                          auto-apply
                          :month-change-on-scroll="false"
                          placeholder="Departure"
                          @focus="
                            (focusEvent3 = true),
                              (focusEvent1 = false),
                              (focusEvent2 = false)
                          "
                          :multi-calendars="multiCalender"
                          @update:modelValue="fromDateChanged"
                          :on-click-outside="ClickOutDepat"
                          class="px-0 datePickInput"
                        >
                        </VueDatePicker>

                        <!-- <VueDatePicker  v-model="oneway.dedate" range placeholder="Departure" /> -->
                      </div>
                    </div>
                    <div
                      class="col-12 col-md-3 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-2 paxcolumnSizing"
                    >
                      <div class="dropdown form-control1" id="myDD">
                        <button
                          class="dropdown-toggle form-control d-flex align-center"
                          type="button"
                          id="travellerInfoOneway"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div>
                            <i
                              class="bi bi-person-lines-fill h2 icon-pos pe-2"
                            ></i>
                            <!-- <v-icon color="grey" size="40">mdi-account</v-icon> -->
                          </div>

                          <span class="text-truncate"
                            >{{ aduls + aduls1 + aduls2 }} Traveller(s),
                            {{ oneway.class }}
                          </span>
                        </button>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="travellerInfoOneway"
                        >
                          <ul class="drop-rest p-0">
                            <li>
                              <div class="d-flex">Adult</div>
                              <div class="ms-auto input-group plus-minus-input">
                                <div class="addbtn" @click.stop="deg1()">-</div>
                                <div class="adddata">{{ aduls }}</div>
                                <div class="addbtn" @click.stop="add1()">+</div>
                              </div>
                            </li>
                            <li>
                              <div class="d-flex">Child</div>
                              <div class="ms-auto input-group plus-minus-input">
                                <div class="addbtn" @click.stop="deg2()">-</div>
                                <div class="adddata">{{ aduls1 }}</div>
                                <div class="addbtn" @click.stop="add2()">+</div>
                              </div>
                            </li>
                            <li>
                              <div class="d-flex">Infants</div>
                              <div class="ms-auto input-group plus-minus-input">
                                <div class="addbtn" @click.stop="deg3()">-</div>
                                <div class="adddata">{{ aduls2 }}</div>
                                <div class="addbtn" @click.stop="add3()">+</div>
                              </div>
                            </li>
                          </ul>

                          <v-radio-group v-model="oneway.class">
                            <v-radio label="Economy" value="Economy"></v-radio>
                            <v-radio
                              label="Premium Economy"
                              value="Premium Economy"
                            ></v-radio>
                            <v-radio
                              label="Business"
                              value="Business"
                            ></v-radio>
                            <v-radio
                              label="First Class"
                              value="First Class"
                            ></v-radio>
                          </v-radio-group>

                          <ul class="drop-rest">
                            <li class="justify-end">
                              <button type="button" class="btn btn" onclick="">
                                Done
                              </button>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-1 px-0">
                      <!-- <button
                        type="submit"
                        class="btn btn-search"
                        @click="sended()"
                      >
                        <span class="fw-bold">Search</span>
                      </button> -->

                      <button
                        type="submit"
                        class="btn btn-search"
                        @click="sended()"
                      >
                        <v-icon>mdi-magnify </v-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Multicity search -->
            <div v-if="multiCityForm ? true : false">
              <div style="background: #ffffff; border-radius: 10px">
                <div
                  class="row"
                  v-for="(datas, index) of multi_city.itinery"
                  :key="index"
                >
                  <div class="col-sm-12 col-md-12">
                    <div
                      class="search-pan row mx-0 theme-border-radius justify-content-evenly"
                    >
                      <div class="col-12">
                        <div class="row">
                          <div
                            class="col-12 col-lg-6 col-xl-3 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2"
                          >
                            <div class="form-group1">
                              <div class="form-groupicon">
                                <i
                                  class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                                ></i>
                                <!-- <v-icon color="grey" size="40">
                                mdi-map-marker
                              </v-icon> -->
                              </div>

                              <div class="vas-autocomplete">
                                <input
                                  type="text"
                                  placeholder="Origin"
                                  v-model="datas.from"
                                  @keyup="typeMultiCity(datas.from, index, 1)"
                                  class="inter-input"
                                />

                                <div v-if="datas.autocom">
                                  <div
                                    v-if="
                                      this.multidrop.length > 0 ? true : false
                                    "
                                    class="autocompete-menual-dropdown"
                                  >
                                    <div
                                      v-for="(data1, index1) in multidrop"
                                      :key="index1"
                                    >
                                      <div
                                        @click="datachangeMuti(data1, index)"
                                        :style="
                                          index1 == multidrop.length - 1
                                            ? ''
                                            : 'border-bottom:1px solid lightgray'
                                        "
                                        class="d-flex p-2"
                                      >
                                        <div
                                          style="
                                            display: flex;
                                            align-items: center;
                                          "
                                        >
                                          <v-icon
                                            style="transform: rotate(45deg)"
                                            size="20"
                                            >mdi-airplane</v-icon
                                          >
                                        </div>

                                        <div class="ms-1">
                                          {{ data1.originalName }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-12 col-lg-6 col-xl-3 ps-0 mb-2 mb-xl-0 pe-xl-2"
                          >
                            <div class="form-group1">
                              <div class="form-groupicon">
                                <i
                                  class="bi bi-geo-alt-fill h2 icon-pos mb-0 me-2"
                                ></i>
                                <!-- <v-icon color="grey" size="40">
                                mdi-map-marker
                              </v-icon> -->
                              </div>

                              <!-- <AutoComplete
                              v-model="datas.to"
                              ref="multiTo"
                              optionLabel="name"
                              placeholder="Destination"
                              dropdown
                              :suggestions="filteredCountries"
                              @change="onChangeMultiTo(index)"
                              @click="multiToClick(index)"
                              @complete="search"
                              style="
                                width: 100%;
                                height: 100%;
                                position: relative;
                              ">
                              <template #option="slotProps">
                                <div class="dropdown-container">
                                  <div class="flex align-items-center dropdown-box" @click="Multifocus2(datas, index)">
                                    <div>
                                      {{ slotProps.option.originalName }}
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </AutoComplete> -->

                              <div class="vas-autocomplete">
                                <input
                                  type="text"
                                  placeholder="Destination"
                                  v-model="datas.to"
                                  @keyup="typeMultiCity(datas.to, index, 2)"
                                  class="inter-input"
                                />

                                <div v-if="datas.autocomTo">
                                  <div
                                    v-if="
                                      this.multidrop1.length > 0 ? true : false
                                    "
                                    class="autocompete-menual-dropdown"
                                  >
                                    <div
                                      v-for="(data1, index1) in multidrop1"
                                      :key="index1"
                                    >
                                      <div
                                        @click="datachangeMutiTo(data1, index)"
                                        :style="
                                          index1 == multidrop1.length - 1
                                            ? ''
                                            : 'border-bottom:1px solid lightgray'
                                        "
                                        class="d-flex p-2"
                                      >
                                        <div
                                          style="
                                            display: flex;
                                            align-items: center;
                                          "
                                        >
                                          <v-icon
                                            style="transform: rotate(45deg)"
                                            size="20"
                                            >mdi-airplane</v-icon
                                          >
                                        </div>

                                        <div class="ms-1">
                                          {{ data1.originalName }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-12 col-lg-6 col-xl-2 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2 pe-xl-2"
                          >
                            <div
                              class="form-control form-group d-flex align-center"
                            >
                              <div class="d-flex align-center">
                                <i
                                  class="bi bi-calendar3 h2 icon-pos mb-0 me-2"
                                ></i>
                              </div>

                              <VueDatePicker
                                ref="dpRef4"
                                v-model="datas.depdata"
                                :start-date="
                                  getDatepicker(multi_city.itinery, index)
                                "
                                :min-date="
                                  getDatepicker(multi_city.itinery, index)
                                "
                                :max-date="
                                  new Date(
                                    new Date().getFullYear() + 1,
                                    new Date().getMonth(),
                                    new Date().getDate()
                                  )
                                "
                                :format="dateFormat"
                                placeholder="Departure"
                                :hide-navigation="['time']"
                                auto-apply
                                :multi-calendars="multiCalender"
                                @update:modelValue="
                                  (newValue) =>
                                    multiDateChanged(newValue, index)
                                "
                                :on-click-outside="multiClickOutside()"
                                :month-change-on-scroll="false"
                                class="datePickInput px-0"
                              />

                              <div
                                @click="deleteSegment(index)"
                                style="
                                  position: relative;
                                  cursor: pointer;
                                " class="closeIcon"
                              >
                                <v-icon
                                  color="red"
                                  size="25"
                                  v-if="index > 1 ? true : false"
                                >
                                  mdi-close-circle-outline
                                </v-icon>
                              </div>
                            </div>
                          </div>
                          <div
                            class="col-12 col-lg-6 col-xl-4 ps-0 mb-2 mb-lg-0 mb-xl-0 pe-0 pe-lg-0 pe-xl-0"
                          >
                            <div
                              class="dropdown form-control1"
                              id="myDD"
                              v-if="index == 0"
                            >
                              <button
                                class="dropdown-toggle form-control d-flex align-center"
                                type="button"
                                id="travellerInfoOneway"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <div>
                                  <i
                                    class="bi bi-person-lines-fill h2 icon-pos me-2"
                                  ></i>
                                </div>

                                <span class="text-truncate"
                                  >{{ aduls + aduls1 + aduls2 }} Traveller(s),
                                  {{ multi_city.class }}
                                </span>
                              </button>
                              <div
                                class="dropdown-menu"
                                aria-labelledby="travellerInfoOneway"
                              >
                                <ul class="drop-rest p-0">
                                  <li>
                                    <div class="d-flex">Adult</div>
                                    <div
                                      class="ms-auto input-group plus-minus-input"
                                    >
                                      <div class="addbtn" @click.stop="deg1()">
                                        -
                                      </div>
                                      <div class="adddata">{{ aduls }}</div>
                                      <div class="addbtn" @click.stop="add1()">
                                        +
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="d-flex">Child</div>
                                    <div
                                      class="ms-auto input-group plus-minus-input"
                                    >
                                      <div class="addbtn" @click.stop="deg2()">
                                        -
                                      </div>
                                      <div class="adddata">{{ aduls1 }}</div>
                                      <div class="addbtn" @click.stop="add2()">
                                        +
                                      </div>
                                    </div>
                                  </li>
                                  <li>
                                    <div class="d-flex">Infants</div>
                                    <div
                                      class="ms-auto input-group plus-minus-input"
                                    >
                                      <div class="addbtn" @click.stop="deg3()">
                                        -
                                      </div>
                                      <div class="adddata">{{ aduls2 }}</div>
                                      <div class="addbtn" @click.stop="add3()">
                                        +
                                      </div>
                                    </div>
                                  </li>
                                </ul>

                                <v-radio-group v-model="multi_city.class">
                                  <v-radio
                                    label="Economy"
                                    value="Economy"
                                  ></v-radio>
                                  <v-radio
                                    label="Premium Economy"
                                    value="Premium Economy"
                                  ></v-radio>
                                  <v-radio
                                    label="Business"
                                    value="Business"
                                  ></v-radio>
                                  <v-radio
                                    label="First Class"
                                    value="First Class"
                                  ></v-radio>
                                </v-radio-group>

                                <ul class="drop-rest">
                                  <li class="justify-end">
                                    <button
                                      type="button"
                                      class="btn btn"
                                      onclick=""
                                    >
                                      Done
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>

                            <div
                              class="row"
                              v-if="index == multi_city.itinery.length - 1"
                            >
                              <div
                                class="col-12 col-lg-3 col-xl-5 mb-2 mb-md-2 mb-lg-0 d-flex justify-content-center align-items-center"
                                id="wrapper"
                              >
                                <button
                                  v-if="index !== 3 ? true : false"
                                  type="submit"
                                  class="btn btn-light font-small"
                                  id="addMulticityRow"
                                  @click="multiSend(index)"
                                >
                                  <span class="fw-bold">Add City</span>
                                </button>

                                <button
                                  v-if="index == 3 ? true : false"
                                  type="submit"
                                  class="btn btn-light font-small"
                                  id="addMulticityRow"
                                  @click="clearall1()"
                                >
                                  <span class="fw-bold">Clear All</span>
                                </button>
                              </div>
                              <div class="col-12 col-md-4">
                                <!-- <button
                                type="submit"
                                class="btn btn-search"
                                @click="sendedMulti()"
                              >
                                <span class="fw-bold">Search</span>
                              </button> -->
                                <button
                                  type="submit"
                                  class="btn btn-search"
                                  @click="sendedMulti(1)"
                                >
                                  <v-icon>mdi-magnify </v-icon>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="multi_city_form_wrapper"></div>
                        <div class="row mt-0 mt-md-0 mt-lg-0 mt-xl-2">
                          <div class="col-12 col-lg-12 col-xl-8">
                            <div class="row">
                              <div
                                class="col-12 col-lg-4 col-xl-4 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2"
                              >
                                <div class="form-group">
                                  <i
                                    class="bi bi-geo-alt-fill position-absolute h2 icon-pos"
                                  ></i>
                                  <input
                                    type="text"
                                    class="form-control ps-5"
                                    id="multiOrigin2"
                                    placeholder="Origin"
                                  />
                                </div>
                              </div>
                              <div
                                class="col-12 col-lg-4 col-xl-4 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-2"
                              >
                                <div class="form-group">
                                  <i
                                    class="bi bi-geo-alt-fill position-absolute h2 icon-pos"
                                  ></i>
                                  <input
                                    type="text"
                                    class="form-control ps-5"
                                    id="multiDestination2"
                                    placeholder="Destination"
                                  />
                                </div>
                              </div>
                              <div
                                class="col-12 col-lg-4 col-xl-4 ps-0 mb-2 mb-xl-0 pe-0 pe-lg-0 pe-xl-2"
                              >
                                <div class="form-control form-group d-flex">
                                  <i
                                    class="bi bi-calendar3 position-absolute h2 icon-pos"
                                  ></i>
                                  <span class="dep-date-input">
                                    <input
                                      type="text"
                                      class="cal-input"
                                      placeholder="Depart Date"
                                      id="datepicker4"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-12 col-xl-4 px-0">
                            <div class="row">
                              <div
                                class="col-12 col-lg-6 col-xl-5 mb-2 mb-md-2 mb-lg-0 d-flex justify-content-center align-items-center"
                                id="wrapper"
                              >
                                <button
                                  type="submit"
                                  class="btn btn-light font-small"
                                  id="addMulticityRow"
                                >
                                  <span class="fw-bold">+ Add City</span>
                                </button>
                              </div>
                              <div class="col-12 col-lg-6 col-xl-7">
                                <button
                                  type="submit"
                                  class="btn btn-search"
                                  onclick="window.location.href='flight-listing-multicity.html';"
                                >
                                  <span class="fw-bold">Search</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row mt-4 gx-0">
        <v-card rounded="2" class="p-2">
          <div class="py-0">
            <p
              style="text-decoration: none"
              class="my-0 d-flex justify-content-between align-center theme-text-accent-one"
              href="#faresummary"
              role="button"
              @click="toggleFilter"
            >
              <span class="font-medium fw-bold">
                <div class="d-flex align-center filtering">
                  <v-icon size="30px" class="filter_icon"> mdi-filter </v-icon>

                  <div class="filter-title mx-2">
                    <v-btn color="#ffc104">Filter Your Search</v-btn>
                  </div>
                </div>
              </span>
              <span>
                <i
                  class="bi bi-chevron-down theme-text-accent-two f-size-20"
                ></i>
              </span>
            </p>
          </div>
          <div
            class="collapse border-top"
            id="faresummary"
            :class="{ show: isFilter }"
          >
            <div class="pt-3">
              <div class="mb-2 mb-lg-0">
                <div>
                  <div style="padding: 3px; border-radius: 10px 10px 0px 0px">
                    <div style="float: right">
                      <a
                        style="text-decoration: none"
                        class="d-flex justify-content-between align-center theme-text-accent-one"
                        data-bs-toggle="collapse"
                        href="#faresummary"
                        role="button"
                        aria-expanded="false"
                        aria-controls="faresummary"
                      >
                        <v-icon color="red" size="30"
                          >mdi-close-circle-outline</v-icon
                        >
                      </a>
                    </div>

                    <div class="row p-2">
                      <div class="col-md-9">
                        <div class="row mt-1">
                          <div class="col-sm-4">
                            <h6>Stops</h6>
                            <div class="row gy-0">
                              <div class="col-8">
                                <v-checkbox
                                  label="Non Stop"
                                  v-model="NonStopFil"
                                  :disabled="showNonStop ? false : true"
                                  color="primary"
                                ></v-checkbox>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-8">
                                <v-checkbox
                                  :disabled="showOneStop ? false : true"
                                  label="1 Stop"
                                  v-model="OneStopFil"
                                  color="primary"
                                ></v-checkbox>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-8">
                                <v-checkbox
                                  :disabled="showOneplusStop ? false : true"
                                  label="1+ Stop"
                                  v-model="OnePlusFil"
                                  color="primary"
                                ></v-checkbox>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            <div class="stop">Fare Type</div>
                            <div class="row mt-1">
                              <div class="col-8">
                                <v-checkbox
                                  label="Refundable"
                                  color="primary"
                                  v-model="RefundableData"
                                ></v-checkbox>
                              </div>
                            </div>

                            <div class="row">
                              <div class="col-8">
                                <v-checkbox
                                  label="Non Refundable"
                                  color="primary"
                                  v-model="nonRefundableData"
                                ></v-checkbox>
                              </div>
                            </div>
                          </div>

                          <div class="col-sm-4">
                            <div class="stop">
                              Price Range
                              <span style="font-size: 13px">
                                (Avg.per pax)</span
                              >
                            </div>
                            <div class="row me-1">
                              <v-range-slider
                                v-model="changevalue"
                                :min="minimum"
                                :max="maximum"
                                :step="steps"
                                thumb-label
                                class="price_slider"
                                color="#0c4ca3"
                              ></v-range-slider>
                            </div>
                            <div class="row">
                              <div class="col-6" align="center">
                                <p>{{ currencyCode }} {{ changevalue[0] }}</p>
                              </div>
                              <div class="col-6" align="center">
                                <p>{{ currencyCode }} {{ changevalue[1] }}</p>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-md-6 col-6">
                            <div
                              class="my-2"
                              v-if="
                                this.multicityData.length > 0 ? false : true
                              "
                            >
                              <v-btn
                                color="#ffc104"
                                style="
                                  font-weight: 600;
                                  text-transform: capitalize;
                                "
                              >
                                <div v-if="onewayresulted.length > 0">
                                  {{
                                    onewayresulted[0].Depature[0].Departure
                                      .AirportCode
                                  }}
                                  -
                                  {{ getReturnFilter(onewayresulted[0]) }}
                                </div>

                                <div v-if="rountresult.length > 0">
                                  {{
                                    rountresult[0].Depature[0].Departure
                                      .AirportCode
                                  }}
                                  -
                                  {{ getReturnFilter(rountresult[0]) }}
                                </div>
                              </v-btn>

                              <div class="my-2">
                                <div style="font-size: 14px; font-weight: 500">
                                  Departure From {{ FilterTimeDepname }}
                                </div>
                              </div>

                              <div class="row mt-1">
                                <div @click="TimeFilter1()" class="col-sm-3">
                                  <v-card
                                    :color="
                                      this.timeFilterData1
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timeFilterData1
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-sunset-up</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        6am-12pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                                <div @click="TimeFilter2()" class="col-sm-3">
                                  <v-card
                                    :color="
                                      this.timeFilterData2
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timeFilterData2
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-sunny</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        12pm-6pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                                <div @click="TimeFilter3()" class="col-sm-3">
                                  <v-card
                                    :color="
                                      this.timeFilterData3
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timeFilterData3
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-night-partly-cloudy</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        after 6pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                              </div>

                              <div class="my-2">
                                <div style="font-size: 14px; font-weight: 500">
                                  Arrival at {{ FilterTimearrname }}
                                </div>
                              </div>

                              <div class="row mt-1">
                                <div @click="TimeFilterarr1()" class="col-sm-3">
                                  <v-card
                                    :color="
                                      this.timeFilterArrData1
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timeFilterArrData1
                                            ? '#002d5b'
                                            : 'grey'
                                        "
                                        >mdi-weather-sunset-up</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        6am-12pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                                <div @click="TimeFilterarr2()" class="col-sm-3">
                                  <v-card
                                    :color="
                                      this.timeFilterArrData2
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timeFilterArrData2
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-sunny</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        12pm-6pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                                <div @click="TimeFilterarr3()" class="col-sm-3">
                                  <v-card
                                    :color="
                                      this.timeFilterArrData3
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timeFilterArrData3
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-night-partly-cloudy</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        after 6pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                              </div>
                              <!-- <hr /> -->
                            </div>
                          </div>

                          <div class="col-md-6 col-6">
                            <div
                              class="my-2"
                              v-if="
                                this.multicityData.length > 0 ||
                                this.onewayresulted.length > 0
                                  ? false
                                  : true
                              "
                            >
                              <!-- <h5>Return Journey</h5> -->

                              <v-btn
                                color="#ffc104"
                                style="
                                  font-weight: 600;
                                  text-transform: capitalize;
                                "
                              >
                                <div v-if="rountresult.length > 0">
                                  {{
                                    rountresult[0].Return[0].Departure
                                      .AirportCode
                                  }}
                                  -
                                  {{ getReturnFilter1(rountresult[0]) }}
                                </div>
                              </v-btn>

                              <div class="my-2">
                                <div style="font-size: 14px; font-weight: 500">
                                  Departure From {{ FilterTimearrname }}
                                </div>
                              </div>

                              <div class="row">
                                <div @click="TimeretFilter1()" class="col-sm-3">
                                  <v-card
                                    :color="
                                      this.timearrFilterData1
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timearrFilterData1
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-sunset-up</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        6am-12pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                                <div @click="TimeretFilter2()" class="col-sm-3">
                                  <v-card
                                    :color="
                                      this.timearrFilterData2
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timearrFilterData2
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-sunny</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        12pm-6pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                                <div @click="TimeretFilter3()" class="col-sm-3">
                                  <v-card
                                    :color="
                                      this.timearrFilterData3
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timearrFilterData3
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-night-partly-cloudy</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        after 6pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                              </div>

                              <div class="my-2">
                                <div style="font-size: 14px; font-weight: 500">
                                  Arrival at {{ FilterTimeDepname }}
                                </div>
                              </div>

                              <div class="row">
                                <div
                                  @click="TimeretFilterarr1()"
                                  class="col-sm-3"
                                >
                                  <v-card
                                    :color="
                                      this.timearrFilterArrData1
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timearrFilterArrData1
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-sunset-up</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        6am-12pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                                <div
                                  @click="TimeretFilterarr2()"
                                  class="col-sm-3"
                                >
                                  <v-card
                                    :color="
                                      this.timearrFilterArrData2
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timearrFilterArrData2
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-sunny</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        12pm-6pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                                <div
                                  @click="TimeretFilterarr3()"
                                  class="col-sm-3"
                                >
                                  <v-card
                                    :color="
                                      this.timearrFilterArrData3
                                        ? 'white'
                                        : '#bae9e45e'
                                    "
                                    style="
                                      cursor: pointer;
                                      height: 65px;
                                      width: 75px;
                                    "
                                  >
                                    <div class="text-center p-2">
                                      <v-icon
                                        size="22"
                                        class="m-1"
                                        :color="
                                          this.timearrFilterArrData3
                                            ? '#002d5b '
                                            : 'grey'
                                        "
                                        >mdi-weather-night-partly-cloudy</v-icon
                                      >
                                      <p
                                        class="m-0"
                                        style="
                                          font-size: 10px;
                                          font-weight: 500;
                                        "
                                      >
                                        after 6pm
                                      </p>
                                    </div>
                                  </v-card>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="row">
                          <div
                            class="stop"
                            v-if="this.multicityData.length > 0 ? false : true"
                          >
                            Airlines
                          </div>
                          <div
                            class="row"
                            style="font-size: 14px; cursor: pointer"
                            v-if="this.multicityData.length > 0 ? false : true"
                          >
                            <div class="col-md-6">
                              <span
                                class="text-light border border-1"
                                @click="airlineSelect()"
                                style="
                                  padding: 2px 6px;
                                  border-radius: 4px;
                                  background: #63AB45;
                                "
                                >Select All</span
                              >
                            </div>
                            <div class="col-md-6">
                              <span
                                class="text-light border border-1"
                                @click="airlineClear()"
                                style="
                                  padding: 2px 6px;
                                  border-radius: 4px;
                                  background: #63AB45;
                                "
                                >Clear All</span
                              >
                            </div>
                          </div>

                          <div
                            class="row"
                            v-for="(data, index) of airlineList.slice(
                              0,
                              maxItemsToShow
                            )"
                            :key="index"
                          >
                            <div class="col-12">
                              <div
                                style="cursor: pointer"
                                class="d-flex align-center"
                                @click="selectAirline(data, index)"
                              >
                                <div>
                                  <v-checkbox
                                    class="text-truncate"
                                    color="primary"
                                    v-model="data.active"
                                  ></v-checkbox>
                                </div>
                                <div>
                                  <v-img :src="data.airlinelog" width="16" />
                                </div>
                                <div class="ms-2">
                                  <span
                                    class="f-size-15 fw-500"
                                    style="color: gray"
                                    >{{ data.airline }}</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>

                          <div v-if="airlineList.length > 5">
                            <p
                              class="m-0"
                              style="
                                cursor: pointer;
                                color: #3366cc;
                                font-size: 14px;
                              "
                              @click="showMore"
                              v-if="!readMore"
                            >
                              ( +{{ airlineList.length - maxItemsToShow }} More
                              )
                            </p>

                            <p
                              class="m-0"
                              style="
                                cursor: pointer;
                                color: #3366cc;
                                font-size: 14px;
                              "
                              @click="showMore"
                              v-if="readMore"
                            >
                              ( Show less )
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style="display: flex; justify-content: end"
                      @click="resetFilter()"
                    >
                      <v-btn
                        style="
                          text-transform: capitalize;
                          background-color: rgb(255, 193, 4);
                        "
                      >
                        Reset Filters
                      </v-btn>
                    </div>

                    <div class="row p-2 align-center" style="color: white">
                      <div class="col-6"></div>
                      <div class="col-4" align="center"></div>
                      <div class="col-2 filter-toggle" align="center">
                        <v-icon
                          size="30"
                          class="not-collapsed"
                          data-bs-toggle="collapse"
                          href="#collapse-Example"
                          aria-expanded="true"
                          aria-controls="collapse-Example"
                          v-model="isIcon"
                          @click="isIcon = !isIcon"
                          style="overflow-anchor: none"
                          >{{
                            isIcon
                              ? "mdi-chevron-up-circle-outline"
                              : "mdi-chevron-down-circle-outline"
                          }}</v-icon
                        >
                      </div>
                    </div>
                  </div>

                  <div
                    class="filter_icons"
                    style="padding: 10px"
                    :class="{ collapse: !isIcon, show: isIcon }"
                    aria-expanded="false"
                    :id="{ 'collapse-Example': isIcon }"
                  >
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </v-card>

        <div class="col-lg-12 mt-2">
          <div class="row mb-3 gx-0 d-none" style="cursor: pointer">
            <div
              class="col-md-3 col-6"
              style="
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
              "
              :class="active ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Recommend()"
              >
                <div>
                  <v-icon
                    :style="active ? 'color:#002d5b ' : 'color:grey'"
                    size="40"
                    >mdi-seal</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Recommended</div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ recperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active1 ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="bestlayTime(1)"
              >
                <div>
                  <v-icon
                    :style="active1 ? 'color:#002d5b ' : 'color:grey'"
                    size="40"
                    >mdi-star</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Best</div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ layperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active2 ? 'recment' : 'normal-rec'"
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Cheapest(1)"
              >
                <div>
                  <v-icon
                    :style="active2 ? 'color:#002d5b ' : 'color:grey'"
                    size="40"
                    >mdi-currency-usd</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Cheapest</div>
                  <div class="top_filterprice">
                    {{ recCurrecy }} {{ recperperson }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-md-3 col-6"
              :class="active3 ? 'recment' : 'normal-rec'"
              style="
                border-top-right-radius: 10px;
                border-bottom-right-radius: 10px;
              "
            >
              <div
                class="d-flex p-2"
                style="justify-content: space-around"
                @click="Shortest(1)"
              >
                <div>
                  <v-icon
                    :style="active3 ? 'color:#002d5b ' : 'color:grey'"
                    size="40"
                    >mdi-clock</v-icon
                  >
                </div>
                <div>
                  <div class="top_filterTitle">Shortest</div>
                  <div class="top_filterprice">
                    {{ shorCurrecy }} {{ shotperperson }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="mb-3" v-for="(res, index) of rountresult" :key="index">
            <v-card class="hoveredCard">
              <div class="row gx-0">
                <!-- <div class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide">
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="70px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}

                      <br />
                      {{ getid(res.Depature) }} -
                      {{ getNumber(res.Depature) }}
                    </p>
                  </div>
                </div> -->

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                >
                  <div class="row g-0 px-3 align-center d-none">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>

                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <span class="price-Amount d-none">{{
                            res.perperson
                          }}</span>
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              >
                              <span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center select_btn">
                      <v-btn
                        width="140px"
                        height="40px"
                        style="color: white; background-color: #002d5b"
                        @click.prevent="selected(res, index)"
                        >Select</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div class="col-lg-10 d-none">
                  <div class="row justify-content-between gx-0 px-2 mt-2">
                    <!-- <div class="col-4 d-flex align-center">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="45px"></v-img>
                      </div>
                      <div class="ps-2">
                        <p class="f-size-16 fw-500 mb-0">{{ res.ownername }}</p>
                        <p class="f-size-11 fw-500 text-center mb-0">{{ getid(res.Depature) }} -{{
                          getNumber(res.Depature)
                          }}</p>
                      </div>
                    </div> -->

                    <div class="col-6 d-flex align-center justify-end d-none">
                      <div class="pe-3 d-flex align-center">
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>
                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: 2px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                  margin-top: 5px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <div>
                          <!-- <v-icon size="15" style="color: gray !important;">mdi-information-outline</v-icon> -->
                        </div>
                        <!-- <span class="f-size-14 fw-500" style="margin-top: -10px;margin-bottom: 10px;">Per Person</span> -->
                      </div>

                      <div
                        class="flight-search"
                        style="padding: 0; border-radius: 4px"
                      >
                        <div class="d-flex mb-1">
                          <!-- <v-img
                            :src="require('@/assets/money-back.png')"
                            style="max-width: 30px"
                          ></v-img>
                          <v-img
                            :src="require('@/assets/passenger.png')"
                            class="ms-2"
                            style="max-width: 24px"
                          ></v-img> -->
                        </div>
                        <button
                          type="submit"
                          class="btn btn-search"
                          style="
                            width: 140px;
                            min-width: 0;
                            min-height: 0;
                          "
                          @click="selected(res, index)"
                        >
                          <span class="fw-bold">Select</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-10 Timing-section"
                  style="padding: 10px 5px; border-right: 1px dotted grey"
                >
                  <v-card class="mutilti_data" style="border: none !important">
                    <div
                      class="d-flex align-center"
                      @click="flighted1(res, index)"
                      data-bs-toggle="offcanvas"
                      data-bs-target="#staticBackdrop"
                      aria-controls="staticBackdrop"
                    >
                      <!-- <div
                          style="display: flex; align-items: center"
                          @click="radiodep(res, index)"
                        >
                          <v-radio-group v-model="res.radioData">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->

                      <v-card
                        class="card-in"
                        style="
                          overflow: unset;
                          padding: 1px;
                          width: 100%;
                          background: white !important;
                        "
                      >
                        <div class="aircode-hide">
                          <p
                            class="m-0"
                            style="
                              color: #193582;
                              background-color: rgb(197 219 243);
                              width: 10%;
                              border-radius: 20px;
                              text-align: center;
                              font-size: 14px;
                            "
                          >
                            Depature
                          </p>
                        </div>
                        <div class="aircode-show d-flex flex-row">
                          <div class="col-6">
                            <p
                              class="m-0 aircode-show"
                              style="
                                color: #193582;
                                background-color: rgb(197 219 243);
                                width: 50%;
                                border-radius: 20px;
                                text-align: center;
                                font-size: 12px;
                              "
                            >
                              Depature
                            </p>
                          </div>
                          <div class="col-6">
                            <div class="d-flex flex-row justify-end">
                              <div
                                class="d-flex d-lg-none align-center justify-end pe-0"
                              >
                                <div class="btn-icon">
                                  <!-- <span><v-icon size="20">{{ res.Refund == 'Refundable' ? 'mdi-cash' : 'mdi-cash-remove'}}</v-icon></span> -->
                                  <v-tooltip location="top">
                                    <template v-slot:activator="{ props }">
                                      <v-icon
                                        v-bind="props"
                                        style="color: gray !important"
                                        size="22"
                                        justify="center"
                                      >
                                        {{
                                          res.Refund == "Refundable"
                                            ? "mdi-cash"
                                            : "mdi-cash-remove"
                                        }}
                                      </v-icon>
                                    </template>
                                    <span>{{ res.Refund }}</span>
                                  </v-tooltip>
                                </div>
                              </div>

                              <div
                                class="d-flex d-lg-none align-center justify-start ps-0"
                              >
                                <div class="btn-icon">
                                  <v-tooltip location="top">
                                    <template v-slot:activator="{ props }">
                                      <v-icon
                                        v-bind="props"
                                        style="
                                          color: gray !important;
                                          margin-left: 15px;
                                        "
                                        size="21"
                                      >
                                        mdi-seat-passenger
                                      </v-icon>
                                    </template>
                                    <span>{{ oneway.class }}</span>
                                  </v-tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-2 g-0">
                          <div class="col-md-2 col-2 aircode-hide">
                            <div class="row d-flex flex-row">
                              <div class="col-5 airline-logo pe-0 mt-1">
                                <v-img
                                  :src="res.Depature[0].MarketingCarrier.logo"
                                  width="50px"
                                ></v-img>
                              </div>
                              <div class="col-7 ps-0" style="margin: auto 0">
                                <p class="f-size-16 fw-600 mb-1">
                                  {{ getname(res.Depature) }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 aircode-show">
                            <v-img
                              :src="res.Depature[0].MarketingCarrier.logo"
                              width="100%"
                            ></v-img>
                          </div>
                          <div class="col-md-2 col-2 pe-0 aircode-hide">
                            <p
                              class="text-center"
                              style="
                                margin-bottom: 4px;
                                font-size: 20px;
                                font-weight: 600;
                              "
                            >
                              <!-- {{ getAirportNameFrom(res.Depature) }} -->
                              {{ getTime(res.Depature) }}
                            </p>
                            <p
                              class="text-center"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 600;
                                color: grey;
                              "
                            >
                              <!-- {{ getAirportCode(res.Depature) }} -->
                              {{ getCountryName1(tripData.from) }}
                            </p>
                          </div>

                          <div class="col-md-3 col-2 pe-2 aircode-show">
                            <p
                              class="text-center text-sm-end"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getAirportCode(res.Depature) }}
                            </p>
                          </div>
                          <div class="col-md-4 col-6">
                            <p
                              class="d-lg-none res-stop m-0 text-center"
                              style="font-size: 10px"
                              v-if="res.Depaturejourney.Stops == 0"
                            >
                              Non Stop
                            </p>
                            <p
                              class="d-lg-none res-stop m-0 text-center"
                              style="font-size: 10px"
                              v-if="res.Depaturejourney.Stops != 0"
                            >
                              {{ res.Depaturejourney.Stops }} Stop
                            </p>
                            <v-img
                              :src="require('../assets/img/stop1.png')"
                              width="80%"
                              style="margin: 0 auto"
                            ></v-img>
                            <p
                              class="d-lg-none m-0 mb-1 text-center"
                              style="font-size: 10px; font-weight: 600"
                            >
                              {{ res.Depaturejourney.Time }}
                            </p>
                          </div>

                          <div class="col-md-3 col-8 justify-center d-none">
                            <!-- <div class="row g-0 justify-center"></div> -->
                            <div class="col-md-4 col-3 d-none">
                              <p
                                class="res-time text-center m-0 date-section-hide"
                              >
                                <!-- {{ getDate(res.Depature) }} -->
                              </p>
                              <p
                                class="res-time text-center m-0 date-section-show"
                              >
                                {{ getDateDeparture(res.Depature) }}
                              </p>
                              <p class="f-size-22 fw-500 text-center m-0">
                                <!-- {{ getTime(res.Depature) }} -->
                              </p>
                            </div>

                            <div
                              class="col-md-4 col-6 d-flex flex-column justify-content-around"
                              style="
                                border-left: 1px dotted gray;
                                border-right: 1px dotted gray;
                              "
                              v-if="res.Depaturejourney.Stops == 0"
                            >
                              <p class="res-stop m-0 text-center">Non-Stop</p>

                              <!-- <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div> -->

                              <!-- <div class="d-flex justify-center time-section">
                                    <v-icon color="grey" size="18px">mdi-clock-outline
                                    </v-icon>
                                    <span class="f-size-12 fw-500 ms-1">{{ res.Depaturejourney.Time }}</span>
                                  </div> -->
                            </div>

                            <div
                              class="col-md-4 col-6 d-flex flex-column justify-center"
                              v-if="res.Depaturejourney.Stops == 1"
                            >
                              <p class="res-stop m-0 text-center">
                                {{ res.Depaturejourney.Stops }} Stop
                              </p>

                              <div
                                class="d-flex align-center justify-center my-2"
                                style="position: relative"
                              >
                                <hr
                                  style="width: 40%; border: 1px solid black"
                                />

                                <v-icon
                                  size="8"
                                  @mouseover="showDetails1(res, index)"
                                  @mouseout="hideDetails1(res)"
                                  >mdi-circle</v-icon
                                >

                                <div
                                  v-if="res.Depature.length >= 2"
                                  class="hoverData1"
                                  id="tooltip"
                                  :class="{ visible: res.hoveredName1 }"
                                >
                                  <div style="text-align: center"></div>
                                  <div>
                                    <span style="font-size: 12px">{{
                                      getarrivaldata(res.Depature)
                                    }}</span>
                                    <span> | </span>
                                    <span style="font-size: 12px">{{
                                      getarrtime(res.Depature)
                                    }}</span>
                                  </div>
                                </div>

                                <hr
                                  style="width: 40%; border: 1px solid black"
                                />
                              </div>

                              <div class="d-flex justify-center time-section">
                                <v-icon color="grey">mdi-clock-outline </v-icon>
                                <span>{{ res.Depaturejourney.Time }}</span>
                              </div>
                            </div>

                            <div
                              class="col-md-4 col-6 d-flex flex-column justify-center"
                              v-if="res.Depaturejourney.Stops >= 2"
                            >
                              <p class="res-stop m-0 text-center">
                                {{ res.Depaturejourney.Stops }} Stops
                              </p>

                              <div
                                class="d-flex align-center justify-center my-2"
                                style="position: relative"
                              >
                                <hr
                                  style="width: 30%; border: 1px solid black"
                                />

                                <v-icon
                                  size="8"
                                  @mouseover="showDetails1(res, index)"
                                  @mouseout="hideDetails1(res)"
                                  >mdi-circle</v-icon
                                >

                                <div
                                  v-if="res.Depature.length >= 2"
                                  class="hoverDataStop1"
                                  id="tooltip"
                                  :class="{ visible: res.hoveredName1 }"
                                >
                                  <div style="text-align: center"></div>
                                  <div>
                                    <span style="font-size: 12px">{{
                                      getarrivaldata(res.Depature)
                                    }}</span>
                                    <span> | </span>
                                    <span style="font-size: 12px">{{
                                      getarrtime(res.Depature)
                                    }}</span>
                                  </div>
                                </div>

                                <hr
                                  style="width: 15%; border: 1px solid black"
                                />

                                <v-icon
                                  size="8"
                                  @mouseover="showDetails2(res, index)"
                                  @mouseout="hideDetails2(res)"
                                  >mdi-circle</v-icon
                                >
                                <div
                                  v-if="res.Depature.length >= 3"
                                  class="hoverDataStop2"
                                  id="tooltip"
                                  :class="{ visible: res.hoveredName2 }"
                                >
                                  <div style="text-align: center"></div>
                                  <div>
                                    <span style="font-size: 12px">{{
                                      get1arrtime(res.Depature, index)
                                    }}</span>
                                    <span> | </span>
                                    <span style="font-size: 12px">{{
                                      get2arrtime(res.Depature)
                                    }}</span>
                                  </div>
                                </div>

                                <hr
                                  style="width: 30%; border: 1px solid black"
                                />
                              </div>

                              <div class="d-flex justify-center time-section">
                                <v-icon color="grey" size="18px"
                                  >mdi-clock-outline
                                </v-icon>
                                <span class="f-size-12 fw-500 ms-1">{{
                                  res.Depaturejourney.Time
                                }}</span>
                              </div>
                            </div>

                            <div class="col-md-3 col-3 d-none">
                              <p
                                class="res-time text-center m-0 date-section-hide"
                              >
                                <!-- {{ getArrivaldate(res.Depature) }} -->
                              </p>
                              <p
                                class="res-time text-center m-0 date-section-show"
                              >
                                {{ getArrivaldateReturn(res.Depature) }}
                              </p>
                              <p class="f-size-22 fw-500 text-center m-0">
                                <!-- {{ getArrivalTime(res.Depature) }} -->
                              </p>
                            </div>
                          </div>

                          <div class="col-md-2 col-2 aircode-hide text-center">
                            <p
                              style="
                                margin-bottom: 4px;
                                font-size: 20px;
                                font-weight: 600;
                              "
                            >
                              <!-- {{ getAirportNameTo(res.Return) }} -->
                              {{ getArrivalTime(res.Depature) }}
                            </p>
                            <p
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 600;
                                color: grey;
                              "
                            >
                              <!-- {{ getArrivalCode(res.Depature) }} -->
                              {{ getCountryName2(tripData.to) }}
                            </p>
                          </div>

                          <div class="col-md-3 col-2 ps-2 aircode-show">
                            <p
                              class="text-center text-sm-start"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getArrivalCode(res.Depature) }}
                            </p>
                          </div>
                          <div class="col-md-2 text-center aircode-hide">
                            <p
                              class="m-0 mb-1"
                              style="font-size: 18px; font-weight: 600"
                            >
                              {{ res.Depaturejourney.Time }}
                            </p>
                            <div v-if="res.Depaturejourney.Stops == 0">
                              <p
                                class="res-stop m-0 text-center"
                                style="font-size: 16px"
                              >
                                Non-Stop
                              </p>
                            </div>
                            <div
                              v-if="res.Depaturejourney.Stops == 1"
                              style="height: 28px"
                            >
                              <p
                                class="res-stop m-0"
                                style="font-size: 16px"
                                @mouseover="showDetails1(res, index)"
                                @mouseout="hideDetails1(res)"
                              >
                                {{ res.Depaturejourney.Stops }} Stop
                              </p>
                              <div
                                v-if="res.Depature.length >= 2"
                                class="hoverData1"
                                id="tooltip"
                                :class="{ visible: res.hoveredName1 }"
                              >
                                <div style="text-align: center"></div>
                                <div>
                                  <span style="font-size: 12px">{{
                                    getarrivaldata(res.Depature)
                                  }}</span>
                                  <span> | </span>
                                  <span style="font-size: 12px">{{
                                    getarrtime(res.Depature)
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="res.Depaturejourney.Stops >= 2"
                              style="height: 28px"
                            >
                              <p
                                class="res-stop m-0"
                                style="font-size: 16px"
                                @mouseover="showDetails1(res, index)"
                                @mouseout="hideDetails1(res)"
                              >
                                {{ res.Depaturejourney.Stops }} Stops
                              </p>
                              <div
                                v-if="res.Depature.length >= 2"
                                class="hoverDataStop1"
                                id="tooltip"
                                :class="{ visible: res.hoveredName1 }"
                              >
                                <div style="text-align: center"></div>
                                <div>
                                  <span style="font-size: 12px">{{
                                    getarrivaldata(res.Depature)
                                  }}</span>
                                  <span> | </span>
                                  <span style="font-size: 12px">{{
                                    getarrtime(res.Depature)
                                  }}</span
                                  ><br />
                                  <span style="font-size: 12px">{{
                                    get1arrtime(res.Depature, index)
                                  }}</span>
                                  <span> | </span>
                                  <span style="font-size: 12px">{{
                                    get2arrtime(res.Depature)
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>

                    <div v-if="res.multipleDatas">
                      <div
                        v-for="(data, multindex) of multiDepatureData"
                        :key="multindex"
                      >
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            margin-top: 8px;
                            border-top: 1px solid lightgray;
                          "
                          v-if="multindex !== 0"
                        >
                          <div @click="multione(data, multindex, index)">
                            <v-radio-group v-model="data.radios">
                              <v-radio :value="true"></v-radio>
                            </v-radio-group>
                          </div>
                          <div style="width: 100%; border-radius: 10px">
                            <div class="row p-2">
                              <div
                                class="col-md-3"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName1(tripData.from) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getAirportNameFrom(data.Depature) }}
                                </p>
                              </div>

                              <div
                                class="col-md-6 col-8"
                                style="border-right: 1px dotted gray"
                              >
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(data.Depature) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(data.Depature) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(data.Depature) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Depaturejourney.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Depature.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{
                                          visible: data.hoveredName1,
                                        }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Depature)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Depaturejourney.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Depature.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{
                                          visible: data.hoveredName1,
                                        }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Depature)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(data, index)"
                                        @mouseout="hideDetails2(data)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="data.Depature.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{
                                          visible: data.hoveredName2,
                                        }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(data.Depature, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(data.Depature) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(data.Depature) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(data.Depature) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName2(tripData.to) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getretairname(data.Depature) }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>

                  <!-- <br /> -->

                  <v-card class="mutilti_data" style="border: none !important">
                    <div
                    >
                      <!-- <div
                          style="display: flex; align-items: center"
                          @click="radiodep(res, index)"
                        >
                          <v-radio-group v-model="res.radiretData">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->

                      <v-card
                        class="card-in"
                        style="overflow: unset; padding: 1px; width: 100%"
                      >
                        <p
                          class="m-0 aircode-hide"
                          style="
                            color: #193582;
                            background-color: rgb(197 219 243);
                            width: 8%;
                            border-radius: 20px;
                            text-align: center;
                            font-size: 14px;
                          "
                        >
                          Return
                        </p>
                        <p
                          class="m-0 aircode-show"
                          style="
                            color: #193582;
                            background-color: rgb(197 219 243);
                            width: 15%;
                            border-radius: 20px;
                            text-align: center;
                            font-size: 12px;
                          "
                        >
                          Return
                        </p>
                        <div class="row mt-2 g-0 mb-2">
                          <div class="col-md-2 col-2 aircode-hide">
                            <div class="row d-flex flex-row">
                              <div class="col-5 pe-0 airline-logo">
                                <v-img
                                  :src="res.Return[0].MarketingCarrier.logo"
                                  width="50px"
                                ></v-img>
                              </div>
                              <div class="col-7 ps-0" style="margin: auto 0">
                                <p class="f-size-16 fw-600 mb-1">
                                  {{ getname(res.Return) }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div class="col-1 aircode-show">
                            <v-img
                              :src="res.Return[0].MarketingCarrier.logo"
                              width="100%"
                            ></v-img>
                          </div>
                          <div class="col-md-2 col-2 pe-0 aircode-hide">
                            <p
                              class="text-center"
                              style="
                                margin-bottom: 4px;
                                font-size: 20px;
                                font-weight: 600;
                              "
                            >
                              {{ getreturnTime(res.Return) }}
                              <!-- {{ getAirportNameTo(res.Return) }} -->
                            </p>
                            <p
                              class="text-center"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 600;
                                color: grey;
                              "
                            >
                              <!-- {{ getreturnAirportCode(res.Return) }} -->
                              {{ getCountryName2(tripData.to) }}
                            </p>
                          </div>

                          <div class="col-md-3 col-2 pe-2 aircode-show">
                            <p
                              class="text-center"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 500;
                              "
                            >
                              {{ getreturnAirportCode(res.Return) }}
                            </p>
                          </div>
                          <div class="col-md-4 col-6">
                            <p
                              class="d-lg-none res-stop m-0 text-center"
                              style="font-size: 10px"
                              v-if="res.Returnjourney.Stops != 0"
                            >
                              {{ res.Returnjourney.Stops }} Stop
                            </p>
                            <p
                              class="d-lg-none res-stop m-0 text-center"
                              style="font-size: 10px"
                              v-if="res.Returnjourney.Stops == 0"
                            >
                              Non Stop
                            </p>
                            <v-img
                              :src="require('../assets/img/stop1.png')"
                              width="80%"
                              style="margin: 0 auto"
                            ></v-img>
                            <p
                              class="d-lg-none f-size-10 m-0 fw-600 text-center"
                            >
                              {{ res.Returnjourney.Time }}
                            </p>
                          </div>

                          <div class="col-md-3 col-8 d-none">
                            <div class="row g-0 mt-1 justify-center">
                              <div class="col-md-4 col-3 d-none">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getreturnDate(res.Return) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getreturnDateDeparture(res.Return) }}
                                </p>
                                <p class="f-size-22 fw-500 text-center m-0">
                                  {{ getreturnTime(res.Return) }}
                                </p>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Returnjourney.Stops == 0"
                              >
                                <p class="res-stop m-0 text-center">Non-Stop</p>
                                <!-- <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div> -->

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey" size="18px"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span class="f-size-12 fw-500 ms-1">{{
                                    res.Returnjourney.Time
                                  }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Returnjourney.Stops == 1"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Returnjourney.Stops }} Stop
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getreturndata(res.Return)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getreturntime(res.Return)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 40%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span>{{ res.Returnjourney.Time }}</span>
                                </div>
                              </div>

                              <div
                                class="col-md-4 col-6 d-flex flex-column justify-center"
                                v-if="res.Returnjourney.Stops >= 2"
                              >
                                <p class="res-stop m-0 text-center">
                                  {{ res.Returnjourney.Stops }} Stops
                                </p>

                                <div
                                  class="d-flex align-center justify-center my-2"
                                  style="position: relative"
                                >
                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                    >mdi-circle</v-icon
                                  >

                                  <div
                                    v-if="res.Depature.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getreturndata(res.Return)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getreturntime(res.Return)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 15%; border: 1px solid black"
                                  />

                                  <v-icon
                                    size="8"
                                    @mouseover="showDetails4(res, index)"
                                    @mouseout="hideDetails4(res)"
                                    >mdi-circle</v-icon
                                  >
                                  <div
                                    v-if="res.Depature.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName4 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1returntime(res.Return, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2returntime(res.Return)
                                      }}</span>
                                    </div>
                                  </div>

                                  <hr
                                    style="width: 30%; border: 1px solid black"
                                  />
                                </div>

                                <div class="d-flex justify-center time-section">
                                  <v-icon color="grey" size="18"
                                    >mdi-clock-outline
                                  </v-icon>
                                  <span class="f-size-12 fw-500 ms-1">{{
                                    res.Returnjourney.Time
                                  }}</span>
                                </div>
                              </div>

                              <div class="col-md-3 col-3 d-none">
                                <p
                                  class="res-time text-center m-0 date-section-hide"
                                >
                                  {{ getreturnArrivaldate(res.Return) }}
                                </p>
                                <p
                                  class="res-time text-center m-0 date-section-show"
                                >
                                  {{ getreturnArrivaldateReturn(res.Return) }}
                                </p>
                                <p class="f-size-22 fw-500 text-center m-0">
                                  {{ getreturnArrivalTime(res.Return) }}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div
                            class="col-md-2 col-2 ps-2 aircode-hide text-center"
                          >
                            <p
                              class="res-time"
                              style="
                                margin-bottom: 4px;
                                font-size: 20px;
                                font-weight: 600;
                              "
                            >
                              <!-- {{ getAirportNameFrom(res.Depature) }} -->
                              {{ getreturnArrivalTime(res.Return) }}
                            </p>
                            <p
                              class="res-time"
                              style="
                                margin-bottom: 4px;
                                font-size: 16px;
                                font-weight: 600;
                                color: grey;
                              "
                            >
                              <!-- {{ getreturnArrivalcode(res.Return) }} -->
                              {{ getCountryName1(tripData.from) }}
                            </p>
                          </div>

                          <div class="col-md-3 col-2 ps-2 aircode-show">
                            <p
                              class="res-time text-center"
                              style="
                                margin-bottom: 4px;
                                font-size: 18px;
                                font-weight: 500;
                              "
                            >
                              {{ getreturnArrivalcode(res.Return) }}
                            </p>
                          </div>
                          <div class="col-md-2 text-center aircode-hide">
                            <p
                              class="m-0 mb-2"
                              style="font-size: 18px; font-weight: 600"
                            >
                              {{ res.Returnjourney.Time }}
                            </p>
                            <div v-if="res.Returnjourney.Stops == 0">
                              <p
                                class="res-stop m-0 text-center"
                                style="font-size: 16px"
                              >
                                Non-Stop
                              </p>
                            </div>
                            <div
                              v-if="res.Returnjourney.Stops == 1"
                              style="height: 28px"
                            >
                              <p
                                class="res-stop m-0"
                                style="font-size: 16px"
                                @mouseover="showDetails3(res, index)"
                                @mouseout="hideDetails3(res)"
                              >
                                {{ res.Returnjourney.Stops }} Stop
                              </p>
                              <div
                                v-if="res.Depature.length >= 2"
                                class="hoverData1"
                                id="tooltip"
                                :class="{ visible: res.hoveredName3 }"
                              >
                                <div style="text-align: center"></div>
                                <div>
                                  <span style="font-size: 12px">{{
                                    getreturndata(res.Return)
                                  }}</span>
                                  <span> | </span>
                                  <span style="font-size: 12px">{{
                                    getreturntime(res.Return)
                                  }}</span>
                                </div>
                              </div>
                            </div>
                            <div
                              v-if="res.Returnjourney.Stops >= 2"
                              style="height: 28px"
                            >
                              <p
                                class="res-stop m-0"
                                style="font-size: 16px"
                                @mouseover="showDetails3(res, index)"
                                @mouseout="hideDetails3(res)"
                              >
                                {{ res.Returnjourney.Stops }} Stops
                              </p>
                              <div
                                v-if="res.Depature.length >= 2"
                                class="hoverDataStop1"
                                id="tooltip"
                                :class="{ visible: res.hoveredName3 }"
                              >
                                <div style="text-align: center"></div>
                                <div>
                                  <span style="font-size: 12px">{{
                                    getreturndata(res.Return)
                                  }}</span>
                                  <span> | </span>
                                  <span style="font-size: 12px">{{
                                    getreturntime(res.Return)
                                  }}</span
                                  ><br />
                                  <span style="font-size: 12px">{{
                                    get1returntime(res.Return, index)
                                  }}</span>
                                  <span> | </span>
                                  <span style="font-size: 12px">{{
                                    get2returntime(res.Return)
                                  }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-card>
                    </div>
                    <div v-if="res.multipleDatas">
                      <div
                        v-for="(data, multindex) of multireturnData"
                        :key="multindex"
                      >
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            margin-top: 8px;
                            border-top: 1px solid lightgray;
                          "
                          v-if="multindex !== 0"
                        >
                          <div @click="multiredone(data, multindex, index)">
                            <v-radio-group v-model="data.radiosret">
                              <v-radio :value="true"></v-radio>
                            </v-radio-group>
                          </div>
                          <div style="width: 100%; border-radius: 10px">
                            <div class="row p-2">
                              <div
                                class="col-md-3"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName1(tripData.to) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getAirportNameFrom(data.Return) }}
                                </p>
                              </div>

                              <div
                                class="col-md-6 col-8"
                                style="border-right: 1px dotted gray"
                              >
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(data.Return) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(data.Return) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(data.Return) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Returnjourney.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <!-- <div
                                        class="d-flex align-center justify-center my-2"
                                      >
                                        <hr
                                          style="
                                            width: 87%;
                                            border: 1px solid black;
                                          "
                                        />
                                      </div> -->

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{ data.Returnjourney.Time }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Returnjourney.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Returnjourney.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Return.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{
                                          visible: data.hoveredName1,
                                        }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Return)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Return)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey" size="18px"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span class="f-size-12 fw-500 ms-1">{{
                                        data.Returnjourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Returnjourney.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Returnjourney.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Return.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{
                                          visible: data.hoveredName1,
                                        }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Return)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Return)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(data, index)"
                                        @mouseout="hideDetails2(data)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="data.Return.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{
                                          visible: data.hoveredName2,
                                        }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(data.Return, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(data.Return)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey" size="18px"
                                        >mdi-clock-outline</v-icon
                                      >
                                      <span class="f-size-12 fw-500 ms-1">{{
                                        data.Returnjourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(data.Return) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(data.Return) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(data.Return) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName2(tripData.from) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getretairname(data.Return) }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>

                  <!-- Seat................ -->

                  <div class="row d-flex mb-1 gx-0 aircode-show">
                    <div class="col-5 d-lg-none">
                      <div
                        class="price-head d-flex justify-center"
                        style="margin: 0 auto"
                      >
                        <span class="price-currency">{{ res.currency }}</span>
                        <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                        <div id="demo" class="d-flex">
                          <span
                            id="f0"
                            class="price-Amount"
                            style="font-size: 26px; padding-top: 8px"
                            >{{ res.find1 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-5 d-lg-none justify-center flight-search"
                      style="padding: 0; border-radius: 0.5rem; margin: 0 auto"
                    >
                      <!-- <button type="submit" class="btn btn-search" style="
                          width: 100px;
                          height:40px;
                          min-width: 0;
                          min-height: 0;
                        " @click="onewayselect(res, index)">
                        <span class="fw-bold">Select</span>
                      </button> -->
                      <button
                        type="submit"
                        class="btn btn-search"
                        style="
                          width: 120px;
                          min-width: 0;
                          min-height: 0;
                        "
                        @click="flighted1(res, index)"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop"
                        aria-controls="staticBackdropLabel"
                      >
                        <span class="fw-bold" style="font-size: 14px"
                          >Flight Details</span
                        >
                      </button>
                    </div>

                    <!-- <div class="col-md-3 col-4 d-flex align-center justify-content-sm-center mb-2"
                      @click="flighted1(res, index)">
                      <span style="
                          color: #36c;
                          font-size: 12px;
                          margin-top: 2px;
                          cursor: pointer;
                        ">
                        Flight Details</span>

                      <v-icon style="color: #36c" size="18">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div> -->
                    <div
                      class="col-md-3 col-4"
                      @click="addmoreroundData(res, index)"
                      style="font-size: 12px"
                    >
                      {{ res.multi }}
                    </div>

                    <div class="text-center col-6 d-none fare-detail-mobile">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0"
                              v-bind="props"
                            >
                              Fare Details
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>Fare Details</h5>
                        <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                Total Price
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >Passengers</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />
                            <!-- <div class="d-flex align-end mb-2">


                            <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" style="padding-right:5px; cursor:pointer;font-size: 14px;
    letter-spacing: 1px">
                              <a href="#">Fare Summary</a></span>

                            <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                              aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon"
                              style="color:0D6EFD; cursor:pointer; overflow-anchor: none">{{ fareIcon ?
                                "mdi-chevron-up-box-outline" :
                                "mdi-chevron-down-box-outline" }}</v-icon>
                          </div> -->

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  Base Price<br />
                                  Taxes & Fees
                                </div>
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div>
                              </div>
                            </div>

                            <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-2 p-0 d-flex flex-column align-center justify-content-around select-section-hide"
                >
                  <div class="d-flex justify-end mt-1">
                    <div class="btn-icon">
                      <!-- <span><v-icon size="20">{{ res.Refund == 'Refundable' ? 'mdi-cash' : 'mdi-cash-remove'}}</v-icon></span> -->
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <v-icon
                            v-bind="props"
                            style="color: gray !important"
                            size="23"
                          >
                            {{
                              res.Refund == "Refundable"
                                ? "mdi-cash"
                                : "mdi-cash-remove"
                            }}
                          </v-icon>
                        </template>
                        <span>{{ res.Refund }}</span>
                      </v-tooltip>
                      <div></div>
                    </div>

                    <div class="btn-icon">
                      <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                          <v-icon
                            v-bind="props"
                            style="color: gray !important; margin-left: 15px"
                            size="21"
                          >
                            mdi-seat-passenger
                          </v-icon>
                        </template>
                        <span>{{ oneway.class }}</span>
                      </v-tooltip>
                    </div>
                  </div>
                  <!-- <div class="d-flex flex-row justify-center mb-2">
                        <v-img :src="require('@/assets/money-back.png')" style="max-width: 35px;"></v-img>
                        <v-img :src="require('@/assets/passenger.png')" class="ms-2" style="max-width: 30px;"></v-img>
                      </div> -->
                  <!-- <div class="d-flex align-center justify-content-center">
                      <span><v-icon size="25">{{ res.Refund == 'Refundable' ? 'mdi-cash' : 'mdi-cash-remove'}}</v-icon></span>
                      <div>
                        <span style="
                            font-size: 16px;
                            color: gray;
                            padding-left: 5px;
                          ">{{ res.Refund }}</span>
                      </div>
                    </div> -->
                  <!-- <div class="d-flex justify-center align-start">
                      <span><v-icon size="25">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 16px; color: gray">{{
                        oneway.class
                      }}</span>
                    </div> -->

                  <div class="d-flex justify-center align-center">
                    <div
                      class="price-head d-flex"
                      style="display: none !important"
                    >
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>
                        <!-- <div class="d-flex">
                              <span style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: 2px;
                                ">.</span><span id="f1" style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                  margin-top: 5px;
                                ">{{ res.find2 }}</span>
                            </div> -->
                      </div>
                    </div>
                    <div></div>
                    <div class="text-center d-none">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="
                                color: #36c;
                                font-weight: 500;
                                font-size: 16px;
                                padding: 0;
                              "
                              class="pe-0"
                              v-bind="props"
                            >
                              <v-icon
                                size="20"
                                height="40"
                                style="
                                  color: gray !important;
                                  padding-bottom: 27px;
                                "
                                >mdi-information-outline</v-icon
                              >
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>Fare Details</h5>
                            <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                Total Price
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >Passengers</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  Base Price<br />
                                  Taxes & Fees
                                </div>
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div>
                              </div>
                            </div>

                            <!-- <br>
                                <p style="font-size: 12px">
                                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                                  Your credit/debit card may be billed in multiple charges totaling
                                  the final total price.
                                </p> -->
                          </div>
                        </v-card>
                      </v-menu>
                    </div>

                    <div>
                      <div class="d-flex justify-center align-center">
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>
                            <!-- <div class="d-flex">
                              <span style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: 2px;
                                ">.</span><span id="f1" style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                  margin-top: 5px;
                                ">{{ res.find2 }}</span>
                            </div> -->
                          </div>
                        </div>
                        <div>
                          <!-- <v-icon size="15" height="40" style="color: gray !important;">mdi-information-outline</v-icon> -->
                        </div>
                        <!-- <span class="f-size-14 fw-500" style="margin-top: -10px;margin-bottom: 10px;">Per Person</span> -->
                      </div>

                      <div
                        class="flight-search px-3"
                        style="padding: 0; border-radius: 4px; margin: 0 auto;z-index:0;"
                      >
                        <!-- <div class="d-flex mb-1">
                          <v-img :src="require('@/assets/money-back.png')" style="max-width: 30px;"></v-img>
                          <v-img :src="require('@/assets/passenger.png')" class="ms-2" style="max-width: 24px;"></v-img>
                        </div> -->
                        <button
                          type="submit"
                          class="btn btn-search"
                          style="
                            width: 140px;
                            min-width: 0;
                            min-height: 0;
                          "
                          @click="flighted1(res, index)"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#staticBackdrop"
                          aria-controls="staticBackdropLabel" 
                        >
                          <span v-if="!showSpinner" class="fw-bold">Flight Details</span>
                          <v-progress-circular v-if="showSpinner"
                            color="white"
                            indeterminate
                          ></v-progress-circular>
                        </button>

                        <!-- <div class="d-flex justify-center align-center h-100">
                                <v-btn class="btn btn-search" style="min-height:40px;" @click.stop="drawer = !drawer" @click="flighted1(res, index)"> Flight Details </v-btn>
                              </div> -->
                      </div>
                    </div>

                    <!-- <div class="d-flex align-center justify-content-sm-center mb-2 pt-2"
                      @click="flighted1(res, index),drawer = !drawer" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop" >
                      <span style="
                          color: #36c;
                          font-size: 12px;
                          margin-top: 2px;
                          cursor: pointer;
                        ">
                        Flight Details</span>
                    </div> -->
                  </div>

                  <div></div>
                </div>
              </div>
            </v-card>
             </div>
            <div class="drawer-section">
              <div
                class="offcanvas offcanvas-end"
                data-bs-backdrop="static"
                tabindex="-1"
                id="staticBackdrop"
                aria-labelledby="staticBackdropLabel"
              >
                <div class="offcanvas-header d-flex justify-content-between">
                  <h5 class="offcanvas-title" id="staticBackdrop">
                    Flight Details
                  </h5>
                  <button
                    type="button"
                    @click="closeModal()"
                    class="text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    color="white"
                  >
                    <v-icon>mdi-close</v-icon>
                  </button>
                </div>

                <div class="offcanvas-body pt-0"  ref="offcanvasBody">
                  <v-divider></v-divider>
                  <div v-for="(flightdatas, index) of flightdetailsData" :key="index">
                    <div class="d-flex flex-row">
                      <div class="col-6 px-2 mx-2 mt-1 justify-center">
                        <div class="price-head d-flex" style="margin: 0 auto">
                          <span
                            class="price-currency"
                            style="font-size: 26px"
                            >{{ flightdatas.currency }}</span
                          >
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                          <div id="demo" class="d-flex">
                            <span
                              id="f0"
                              class="price-Amount"
                              style="font-size: 32px; padding-top: 6px"
                              >{{ flightdatas.find1 }}</span
                            >

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: 9px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-left: 2px;
                                  margin-top: 12px;
                                "
                                >{{ flightdatas.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-6 d-flex justify-center align-items-center"
                      >
                        <span
                          >Cabin:
                          <span class="fw-bold">{{ oneway.class }}</span></span
                        >
                      </div>
                    </div>
                    <div class="ps-3">
                      <div class="row p-2">
                        <div class="col-1 pe-0 ps-1" style="margin: auto 0">
                          <v-img
                            :src="flightdatas.Depature[0].MarketingCarrier.logo"
                            width="100%"
                            style="margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightdatas.fromTrip }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{ getDateDeparture(flightdatas.Depature) }}
                            -
                            <span class="fw-bold">{{
                              getTime(flightdatas.Depature)
                            }}</span></span
                          >
                        </div>
                        <div class="col-3 mt-2 ps-0">
                          <v-img
                            :src="require('../assets/img/flightIcon.png')"
                            width="22%"
                            style="transform: rotate(45deg); margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="from" style="font-weight: 500">
                            {{ flightdatas.toTrip }}</span
                          ><br />
                          <span class="to" style="font-weight: 500"
                            >{{ getArrivaldate(flightdatas.Depature) }}
                            -
                            <span class="fw-bold">{{
                              getArrivalTime(flightdatas.Depature)
                            }}</span></span
                          >
                        </div>
                      </div>
                      <div class="row p-2">
                        <div class="col-1 pe-0 ps-1" style="margin: auto 0">
                          <v-img
                            :src="flightdatas.Return[0].MarketingCarrier.logo"
                            width="100%"
                            style="margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightdatas.toTrip }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{ getDateDeparture(flightdatas.Return) }}
                            -
                            <span class="fw-bold">{{
                              getTime(flightdatas.Return)
                            }}</span></span
                          >
                        </div>
                        <div class="col-3 mt-2 ps-0">
                          <v-img
                             :src="require('../assets/img/flightIcon.png')"
                            width="22%"
                            style="transform: rotate(45deg); margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="from" style="font-weight: 500">
                            {{ flightdatas.fromTrip }}</span
                          ><br />
                          <span class="to" style="font-weight: 500"
                            >{{ getArrivaldate(flightdatas.Return) }} -
                            <span class="fw-bold">{{
                              getArrivalTime(flightdatas.Return)
                            }}</span></span
                          >
                        </div>
                      </div>
                    </div>
                    <v-card class="mt-4">
                      <v-tabs v-model="tab" align-tabs="center" stacked>
                        <v-tab value="tab-1" class="m-0">
                          <v-icon style="transform: rotate(45deg)"
                            >mdi-airplane</v-icon
                          >
                          Overview
                        </v-tab>

                        <!-- <v-tab value="tab-2" v-if="getbaggage(flightdatas)"> -->
                          <v-tab value="tab-2">
                          <v-icon>mdi-bag-checked</v-icon>
                          Baggage
                        </v-tab>

                        <v-tab value="tab-3" class="m-0">
                          <v-icon>mdi-ticket-account</v-icon>
                          Fare Details
                        </v-tab>
                      </v-tabs>

                      <v-card-text class="pt-0">
                        <keep-alive>
                          <v-window v-model="tab">
                            <div v-if="tab === 'tab-1'">
                              <v-card
                                style="border: 1px solid grey"
                                class="mb-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    Depature
                                  </h6>
                                  <span class="fw-bold ms-2 Depature-heading">
                                    {{ getdepName1(flightdatas.fromTrip) }} -
                                    {{ getarrName1(flightdatas.toTrip) }}</span
                                  >
                                </div>
                                <v-divider></v-divider>
                                <div
                                  v-for="(data, index) of flightdatas.Depature"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-3 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-14 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          <v-icon
                                            size="16"
                                            color="black"
                                            class="me-1"
                                            >mdi-clock-outline</v-icon
                                          >{{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-14 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="
                                      flightdatas.Depature.length - 1 !== index
                                    "
                                    style="
                                      position: relative;
                                      left: 15%;
                                      background-color: #63AB45;
                                      color: white;
                                    "
                                    class="p-1 layoverTime"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          :src="require('../assets/sandClock.png')"
                                          width="15px"
                                          style="padding-top:8px;"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ flightdatas.layoverTimes1[index] }}
                                          - LayoverTime
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>

                              <v-card style="border: 1px solid grey" class="">
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    Arrival
                                  </h6>
                                  <span
                                    class="fw-bold ms-2"
                                    style="font-size: 16px"
                                  >
                                    {{ getdepName1(flightdatas.toTrip) }} -
                                    {{ getarrName1(flightdatas.fromTrip) }}</span
                                  >
                                </div>
                                <v-divider></v-divider>
                                <div
                                  v-for="(data, index) of flightdatas.Return"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-3 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-500"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          <v-icon
                                            size="16"
                                            color="black"
                                            class="me-1"
                                            >mdi-clock-outline</v-icon
                                          >{{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-500"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="
                                      flightdatas.Return.length - 1 !== index
                                    "
                                    style="
                                      position: relative;
                                      left: 15%;
                                      background-color: #63AB45;
                                      color: white;
                                    "
                                    class="p-1 layoverTime"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          :src="require('../assets/sandClock.png')"
                                          width="15px"
                                          style="padding-top: 8px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ flightdatas.layoverTimes2[index] }}
                                          - LayoverTime
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                            </div>

                            <div v-if="tab === 'tab-2'" >
                              <div style="font-size: 15px; font-weight: 500">
                                {{ flightdatas.fromTrip }} - {{ flightdatas.toTrip }}
                              </div>
                              <!-- <div v-if="flightdatas.depBaggage.PieceAllowance.TotalQuantity > 0 "></div> -->
                              <div class="d-flex ms-2 mt-2">
                                <div style="color: gray !important">
                                  <v-icon color="gray">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span> Checked Baggage:</span>
                                  <!-- <span class="ms-1"> {{res.depBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                  <span class="ms-1">
                                    {{ flightdatas.depBaggage.PieceAllowance.TotalQuantity || 0 }}

                                    {{ flightdatas.depBaggage.PieceAllowance.TotalQuantity > 1 ? "Pieces" : "Piece"}}
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <div style="color: gray !important">
                                  <v-icon color="gray">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>Cabin Baggage:</span>
                                   <span class="ms-1">
                                    {{
                                      flightdatas.depBaggage.PieceAllowance
                                        .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                    }}
                                  </span>
                                </div>
                              </div>
                              
                              <!-- <div v-if="flightdatas.depBaggage.PieceAllowance.TotalQuantity == 0 ">
                                <p>The Depature Baggage details is Zero</p>
                              </div> -->

                              <br />
                              <hr />

                              <br />

                              <div
                                class="mt-1"
                                style="font-size: 15px; font-weight: 500"
                              >
                                {{ flightdatas.toTrip }} - {{ flightdatas.fromTrip }}
                              </div>
                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                              <div class="d-flex ms-2 mt-1">
                                <div style="color: gray !important">
                                  <v-icon color="gray">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span> Checked Baggage:</span>
                                  <!-- <span class="ms-1"> {{res.arrBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                  <span class="ms-1">
                                    {{
                                      flightdatas.arrBaggage.PieceAllowance
                                        .TotalQuantity || 0
                                    }}
                                    {{
                                      flightdatas.arrBaggage.PieceAllowance
                                        .TotalQuantity > 1
                                        ? "Pieces"
                                        : "Piece"
                                    }}
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <div style="color: gray !important">
                                  <v-icon color="gray">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>Cabin Baggage:</span>
                                  <span class="ms-1">
                                    {{
                                      flightdatas.arrBaggage.PieceAllowance
                                        .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                    }}
                                  </span>
                                </div>
                              </div>
                              
                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity == 0 ">
                                <p class="mt-1">The Arrival Baggage Quantity is Zero</p>
                              </div> -->
                            </div>

                            <div v-if="tab === 'tab-3'">
                              <div class="d-flex justify-content-between">
                                <div>
                                  <p
                                    class="m-0"
                                    style="font-size: 16px; font-weight: 600"
                                  >
                                    Total Price
                                  </p>
                                </div>
                                <div>
                                  <p
                                    class="fw-bold tot-price m-0"
                                    style="font-size: 16px"
                                  >
                                    {{ flightdatas.currency }}
                                    {{ flightdatas.TotalPrice }}
                                  </p>
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-md-12">
                                  <div class="row justify-content-between">
                                    <div class="col-6">
                                      <span
                                        style="
                                          font-size: 13px;
                                          font-weight: 500;
                                        "
                                        >Passengers</span
                                      >
                                    </div>
                                    <!-- <div class="col-md-4"></div> -->
                                    <div
                                      class="col-6"
                                      style="font-size: 14px; text-align: end"
                                    >
                                      <span v-if="aduls > 0"
                                        >{{ aduls }} x Adult</span
                                      ><br />
                                      <span v-if="aduls1 > 0"
                                        >{{ aduls1 }} x Child</span
                                      ><br />
                                      <span v-if="aduls2 > 0"
                                        >{{ aduls2 }} x Lap Infant</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mt-3">
                                <hr class="mt-1 mb-2" />

                                <hr class="mt-1 mb-2" />

                                <div
                                  class="p-1 collapse"
                                  :class="{ show: fareIcon }"
                                  id="collapse-Example"
                                >
                                  <div
                                    class="row d-flex flex-row justify-content-between"
                                  >
                                    <div class="col-6" style="font-size: 14px">
                                      Base Price<br />
                                      Taxes & Fees
                                    </div>
                                    <div class="col-4 d-flex flex-column pe-2">
                                      <div class="row">
                                        <div class="col-5 pe-0 ps-3 text-md-end text-center">
                                          {{ flightdatas.currency }}
                                        </div>
                                        <div class="col-7 text-end ps-0">
                                          {{ flightdatas.Baseprice }}
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-5 pe-0 ps-3 text-md-end text-center">
                                          {{ flightdatas.currency }}
                                        </div>
                                        <div class="col-7 text-end ps-md-2 ps-0">
                                          {{ flightdatas.Taxprice }}
                                        </div>
                                      </div>
                                    </div>
                                    <!-- <div class="d-flex flex-column">
                              <div class="row">
                                <div class="col-4 pe-0">{{ currency }}</div>
                                <div class="col-8 text-end ps-2">{{ totalbasePassanger }}</div>
                              </div>
                              <div class="row">
                                <div class="col-4 pe-0">{{ currency }}</div>
                                  <div class="col-8 text-end">{{ totalTaxPassanger }}</div>
                              </div>
                            </div> -->
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-window>
                        </keep-alive>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
                <div
                  class="offcanvas-footer flight-search p-2"
                  style="margin: auto"
                >
                  <button
                    type="submit "
                    class="btn btn-search"
                    style="
                      width: 300px;
                      min-width: 0;
                      min-height: 0;
                    "
                    @click="onewayselect(flightdetailsData, index)"
                  >
                    <span class="fw-bold" style="font-size: 16px"
                      >Book This Flight</span
                    >
                  </button>
                </div>
              </div>
            </div>
         

          <div class="mb-3" v-for="(res, index) of onewayresulted" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 flex-column align-center justify-center airlineLogo-hide"
                  style="display: none !important"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="60px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex d-none flex-column align-center justify-center airlineLogo-show"
                >
                  <div class="row g-0 px-3 align-center d-none">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center">
                      <v-btn
                        class="select_btn"
                        width="140px"
                        height="40px"
                        style="color: white; background-color: #002d5b"
                        @click.prevent="onewayselect(flightdetailsData1, index)"
                        >Select</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-10 Timing-section"
                  style="padding: 10px 5px"
                  @click="flighted2(res, index)"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#staticBackdrop1"
                  aria-controls="staticBackdrop"
                >
                  <v-card class="mutilti_data" style="border: none !important">
                    <div
                      class="mt-2 d-flex"
                      style="display: flex; align-items: center"
                    >
                      <!-- <div style="display: flex; align-items: center" @click="radiosData = 0">
                        <v-radio-group v-model="res.radios">
                          <v-radio :value="true"></v-radio>
                        </v-radio-group>
                      </div> -->
                      <div style="width: 100%">
                        <v-card
                          class="card-in"
                          style="overflow: unset; padding: 1px"
                        >
                          <div class="aircode-hide">
                            <p
                              class="m-0"
                              style="
                                color: #193582;
                                background-color: rgb(197 219 243);
                                width: 10%;
                                border-radius: 20px;
                                text-align: center;
                                font-size: 14px;
                              "
                            >
                              Depature
                            </p>
                          </div>
                          <div class="aircode-show d-flex flex-row">
                            <div class="col-6">
                              <p
                                class="m-0 aircode-show"
                                style="
                                  color: #193582;
                                  background-color: rgb(197 219 243);
                                  width: 50%;
                                  border-radius: 20px;
                                  text-align: center;
                                  font-size: 13px;
                                "
                              >
                                Depature
                              </p>
                            </div>
                            <div class="col-6">
                              <div class="d-flex flex-row justify-end">
                                <div
                                  class="d-flex d-lg-none align-center justify-end pe-0"
                                >
                                  <div class="btn-icon">
                                    <!-- <span><v-icon size="20">{{ res.Refund == 'Refundable' ? 'mdi-cash' : 'mdi-cash-remove'}}</v-icon></span> -->
                                    <v-tooltip location="top">
                                      <template v-slot:activator="{ props }">
                                        <v-icon
                                          v-bind="props"
                                          style="color: gray !important"
                                          size="22"
                                          justify="center"
                                        >
                                          {{
                                            res.Refund == "Refundable"
                                              ? "mdi-cash"
                                              : "mdi-cash-remove"
                                          }}
                                        </v-icon>
                                      </template>
                                      <span>{{ res.Refund }}</span>
                                    </v-tooltip>
                                  </div>
                                </div>

                                <div
                                  class="d-flex d-lg-none align-center justify-start ps-0"
                                >
                                  <div class="btn-icon">
                                    <v-tooltip location="top">
                                      <template v-slot:activator="{ props }">
                                        <v-icon
                                          v-bind="props"
                                          style="
                                            color: gray !important;
                                            margin-left: 15px;
                                          "
                                          size="21"
                                        >
                                          mdi-seat-passenger
                                        </v-icon>
                                      </template>
                                      <span>{{ oneway.class }}</span>
                                    </v-tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2 g-0 mb-2">
                            <div class="col-md-2 aircode-hide">
                              <div class="row d-flex flex-row">
                                <div
                                  class="col-5 airline-logo pe-0"
                                  style="margin: auto 0"
                                >
                                  <v-img
                                    :src="res.Depature[0].MarketingCarrier.logo"
                                    width="45px"
                                  ></v-img>
                                </div>
                                <div class="col-7 ps-0" style="margin: auto 0">
                                  <p class="f-size-16 fw-600 mb-1">
                                    {{ getname(res.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-1 aircode-show">
                              <v-img
                                :src="res.Depature[0].MarketingCarrier.logo"
                                width="100%"
                              ></v-img>
                            </div>
                            <div class="col-md-2 col-2 pe-0 aircode-hide">
                              <p
                                class="text-center"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 20px;
                                  font-weight: 600;
                                "
                              >
                                <!-- {{ getAirportNameFrom(res.Depature) }} -->
                                {{ getTime(res.Depature) }}
                              </p>
                              <p
                                class="text-center"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 600;
                                  color: grey;
                                "
                              >
                                {{ getCountryName1(tripData.from) }}
                              </p>
                            </div>

                            <div class="col-md-3 col-2 pe-2 aircode-show">
                              <p
                                class="text-center text-sm-end"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getAirportCode(res.Depature) }}
                              </p>
                            </div>

                            <div class="col-md-6 col-8 d-none">
                              <div class="row g-0 mt-1 justify-center">
                                <div class="col-md-4 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getDate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getDateDeparture(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getTime(res.Depature) }}
                                  </p>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 0"
                                >
                                  <p class="res-stop m-0 text-center">Direct</p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                  >
                                    <hr
                                      style="
                                        width: 87%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops == 1"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stop
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverData1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 40%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div
                                  class="col-md-4 col-6 d-flex flex-column justify-center"
                                  v-if="res.Depaturejourney.Stops >= 2"
                                >
                                  <p class="res-stop m-0 text-center">
                                    {{ res.Depaturejourney.Stops }} Stops
                                  </p>

                                  <div
                                    class="d-flex align-center justify-center my-2"
                                    style="position: relative"
                                  >
                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails1(res, index)"
                                      @mouseout="hideDetails1(res)"
                                      >mdi-circle</v-icon
                                    >

                                    <div
                                      v-if="res.Depature.length >= 2"
                                      class="hoverDataStop1"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName1 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          getarrivaldata(res.Depature)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          getarrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 15%;
                                        border: 1px solid black;
                                      "
                                    />

                                    <v-icon
                                      size="8"
                                      @mouseover="showDetails2(res, index)"
                                      @mouseout="hideDetails2(res)"
                                      >mdi-circle</v-icon
                                    >
                                    <div
                                      v-if="res.Depature.length >= 3"
                                      class="hoverDataStop2"
                                      id="tooltip"
                                      :class="{ visible: res.hoveredName2 }"
                                    >
                                      <div style="text-align: center"></div>
                                      <div>
                                        <span style="font-size: 12px">{{
                                          get1arrtime(res.Depature, index)
                                        }}</span>
                                        <span> | </span>
                                        <span style="font-size: 12px">{{
                                          get2arrtime(res.Depature)
                                        }}</span>
                                      </div>
                                    </div>

                                    <hr
                                      style="
                                        width: 30%;
                                        border: 1px solid black;
                                      "
                                    />
                                  </div>

                                  <div
                                    class="d-flex justify-center time-section"
                                  >
                                    <v-icon color="grey"
                                      >mdi-clock-outline
                                    </v-icon>
                                    <span>{{ res.Depaturejourney.Time }}</span>
                                  </div>
                                </div>

                                <div class="col-md-3 col-3">
                                  <p
                                    class="res-time text-center m-0 date-section-hide"
                                  >
                                    {{ getArrivaldate(res.Depature) }}
                                  </p>
                                  <p
                                    class="res-time text-center m-0 date-section-show"
                                  >
                                    {{ getArrivaldateReturn(res.Depature) }}
                                  </p>
                                  <p
                                    class="text-center m-0"
                                    style="font-weight: 400; font-size: 18px"
                                  >
                                    {{ getArrivalTime(res.Depature) }}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-4 col-6 mt-lg-0 mt-1">
                              <p
                                class="d-lg-none res-stop mb-1 text-center"
                                style="font-size: 10px"
                              >
                                {{ res.Depaturejourney.Stops }} Stops
                              </p>
                              <v-img
                                :src="require('../assets/img/stop1.png')"
                                width="80%"
                                style="margin: auto"
                              ></v-img>
                              <p
                                class="mt-1 mb-2 text-center d-lg-none"
                                style="font-size: 10px; font-weight: 600"
                              >
                                {{ res.Depaturejourney.Time }}
                              </p>
                            </div>

                            <div
                              class="col-md-2 col-2 ps-2 aircode-hide text-center"
                            >
                              <p
                                style="
                                  margin-bottom: 4px;
                                  font-size: 20px;
                                  font-weight: 600;
                                "
                              >
                                <!-- {{ getretairname(res.Depature) }} -->
                                {{ getArrivalTime(res.Depature) }}
                              </p>
                              <p
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 600;
                                  color: grey;
                                "
                              >
                                {{ getCountryName2(tripData.to) }}
                              </p>
                            </div>

                            <div class="col-md-3 col-2 ps-2 aircode-show">
                              <p
                                class="text-center text-sm-start"
                                style="
                                  margin-bottom: 4px;
                                  font-size: 16px;
                                  font-weight: 500;
                                "
                              >
                                {{ getArrivalCode(res.Depature) }}
                              </p>
                            </div>
                            <div class="col-md-2 text-center aircode-hide">
                              <p
                                class="m-0 mb-2"
                                style="font-size: 18px; font-weight: 600"
                              >
                                {{ res.Depaturejourney.Time }}
                              </p>
                              <div v-if="res.Depaturejourney.Stops == 0">
                                <p
                                  class="res-stop m-0 text-center"
                                  style="font-size: 16px"
                                >
                                  Non Stop
                                </p>
                              </div>
                              <div
                                v-if="res.Depaturejourney.Stops == 1"
                                style="height: 28px"
                              >
                                <p
                                  class="res-stop m-0"
                                  style="font-size: 16px"
                                  @mouseover="showDetails1(res, index)"
                                  @mouseout="hideDetails1(res)"
                                >
                                  {{ res.Depaturejourney.Stops }} Stop
                                </p>
                                <div
                                  v-if="res.Depature.length >= 2"
                                  class="hoverData1"
                                  id="tooltip"
                                  :class="{ visible: res.hoveredName1 }"
                                >
                                  <div style="text-align: center"></div>
                                  <div>
                                    <span style="font-size: 12px">{{
                                      getarrivaldata(res.Depature)
                                    }}</span>
                                    <span> | </span>
                                    <span style="font-size: 12px">{{
                                      getarrtime(res.Depature)
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                              <div
                                v-if="res.Depaturejourney.Stops >= 2"
                                style="height: 28px"
                              >
                                <p
                                  class="res-stop m-0 text-center"
                                  style="font-size: 16px"
                                  @mouseover="showDetails1(res, index)"
                                  @mouseout="hideDetails1(res)"
                                >
                                  {{ res.Depaturejourney.Stops }} Stops
                                </p>
                                <div
                                  v-if="res.Depature.length >= 2"
                                  class="hoverDataStop2"
                                  id="tooltip"
                                  :class="{ visible: res.hoveredName1 }"
                                >
                                  <div style="text-align: center"></div>
                                  <div>
                                    <span style="font-size: 12px">{{
                                      getarrivaldata(res.Depature)
                                    }}</span>
                                    <span> | </span>
                                    <span style="font-size: 12px">{{
                                      getarrtime(res.Depature)
                                    }}</span
                                    ><br />
                                    <span style="font-size: 12px">{{
                                      get1arrtime(res.Depature, index)
                                    }}</span>
                                    <span> | </span>
                                    <span style="font-size: 12px">{{
                                      get2arrtime(res.Depature)
                                    }}</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </div>
                    </div>

                    <!-- ------------multi Start-------- -->

                    <div v-if="res.multipleDatas">
                      <div
                        v-for="(data, multindex) of multiplesameData"
                        :key="multindex"
                      >
                        <div
                          style="
                            display: flex;
                            align-items: center;
                            margin-top: 8px;
                            border-top: 1px solid lightgray;
                          "
                          v-if="multindex !== 0"
                        >
                          <div @click="multione1(data, multindex, index)">
                            <v-radio-group v-model="radiosData">
                              <v-radio :value="multindex + 1"></v-radio>
                            </v-radio-group>
                          </div>
                          <div style="width: 100%; border-radius: 10px">
                            <div class="row p-2">
                              <div
                                class="col-md-3"
                                style="border-right: 1px dotted gray"
                              >
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName1(tripData.from) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getAirportNameFrom(data.Depature) }}
                                </p>
                              </div>

                              <div
                                class="col-md-6 col-8"
                                style="border-right: 1px dotted gray"
                              >
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(data.Depature) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(data.Depature) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(data.Depature) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Depaturejourney.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Depature.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Depature)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="data.Depaturejourney.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ data.Depaturejourney.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(data, index)"
                                        @mouseout="hideDetails1(data)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="data.Depature.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(data.Depature)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(data, index)"
                                        @mouseout="hideDetails2(data)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="data.Depature.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: data.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(data.Depature, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(data.Depature)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        data.Depaturejourney.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(data.Depature) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(data.Depature) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(data.Depature) }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-3">
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 15px; font-weight: 500"
                                >
                                  {{ getCountryName2(tripData.to) }}
                                </p>
                                <p
                                  class="text-end mb-0"
                                  style="font-size: 10px; font-weight: 500"
                                >
                                  {{ getretairname(data.Depature) }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-card>

                  <!-- ------------multi end-------- -->

                  <!-- Seat................ -->

                  <div class="row d-flex mb-1 gx-0 aircode-show">
                    <!-- <div
                      class="col-md-3 col-4 d-flex justify-content-sm-center fare-detail-mobile"
                    >
                      <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray"
                        >{{ getdepseat(res.Depature) }} Seats</span
                      >
                    </div> -->

                    <div class="col-5 d-lg-none">
                      <div
                        class="price-head d-flex justify-center"
                        style="margin: 0 auto"
                      >
                        <span class="price-currency">{{ res.currency }}</span>
                        <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                        <div id="demo" class="d-flex">
                          <span
                            id="f0"
                            class="price-Amount"
                            style="font-size: 26px; padding-top: 8px"
                            >{{ res.find1 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-5 d-lg-none justify-center flight-search"
                      style="padding: 0; border-radius: 0.5rem; margin: 0 auto"
                    >
                      <!-- <button type="submit" class="btn btn-search" style="
                          width: 100px;
                          height:40px;
                          min-width: 0;
                          min-height: 0;
                        " @click="onewayselect(res, index)">
                        <span class="fw-bold">Select</span>
                      </button> -->
                      <button
                        type="submit"
                        class="btn btn-search"
                        style="
                          width: 120px;
                          min-width: 0;
                          min-height: 0;
                        "
                        @click="flighted2(res, index)"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop1"
                        aria-controls="staticBackdrop"
                      >
                        <span class="fw-bold" style="font-size: 14px"
                          >Flight Details</span
                        >
                      </button>
                    </div>

                    <!-- <div class="col-md-3 col-4 d-flex align-center justify-content-sm-center"
                      @click="flighted2(res, index)">
                      <span style="color: #36c; font-size: 12px; cursor: pointer">
                        Flight Details</span>

                      <v-icon style="color: #36c" size="18">{{
                        res.Flightdepature
                          ? "mdi-close-box"
                          : "mdi-chevron-down-box"
                      }}</v-icon>
                    </div> -->

                    <div
                      class="col-md-3 col-4 d-none"
                      @click="addmoreData(res, index)"
                      style="font-size: 12px"
                    >
                      {{ res.multi }}
                    </div>

                    <div class="text-center col-4 d-none fare-detail-mobile">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0"
                              v-bind="props"
                            >
                              Fare Details
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>Fare Details</h5>
                        <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                Total Price
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >Passengers</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  Base Price<br />
                                  Taxes & Fees
                                </div>
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div>
                              </div>
                            </div>

                            <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-content-between select-section-hide"
                >
                  <div class="d-flex flex-column justify-center">
                    <div class="d-flex flex-row justify-center mt-1">
                      <div class="btn-icon">
                        <!-- <span><v-icon size="20">{{ res.Refund == 'Refundable' ? 'mdi-cash' : 'mdi-cash-remove'}}</v-icon></span> -->
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-icon
                              v-bind="props"
                              style="color: gray !important"
                              size="22"
                              justify="center"
                            >
                              {{
                                res.Refund == "Refundable"
                                  ? "mdi-cash"
                                  : "mdi-cash-remove"
                              }}
                            </v-icon>
                          </template>
                          <span>{{ res.Refund }}</span>
                        </v-tooltip>
                      </div>

                      <div class="btn-icon">
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-icon
                              v-bind="props"
                              style="color: gray !important; margin-left: 15px"
                              size="21"
                            >
                              mdi-seat-passenger
                            </v-icon>
                          </template>
                          <span>{{ oneway.class }}</span>
                        </v-tooltip>
                        <!-- <span><v-icon size="18">mdi-seat-passenger</v-icon></span> -->
                        <!-- <span style="font-size: 12px; color: gray">{{oneway.class}}</span> -->
                      </div>
                      <!-- <v-img src="../assets/money-back.png" style="max-width: 25px;"></v-img>
                        <v-img src="../assets/passenger.png" class="ms-2" style="max-width: 24px;"></v-img> -->
                    </div>

                    <div class="price-head d-flex" style="margin: 0 auto">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                      <div id="demo" class="d-flex">
                        <span
                          id="f0"
                          class="price-Amount"
                          style="font-size: 26px; padding-top: 8px"
                          >{{ res.find1 }}</span
                        >

                        <!-- <div class="d-flex">
                          <span style="
                              font-size: 20px;
                              position: relative;
                              left: 2px;
                              top: 2px;
                            ">.</span><span id="f1" style="
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            ">{{ res.find2 }}</span>
                        </div> -->
                      </div>
                      <div>
                        <div class="text-center Fare-overlay d-none">
                          <v-menu location="start">
                            <template v-slot:activator="{ props }">
                              <v-card-text
                                @click="fareOpen = !fareOpen"
                                style="color: #36c; font-weight: 500"
                                class="p-0 pt-2"
                                v-bind="props"
                              >
                                <v-icon
                                  size="20"
                                  height="40"
                                  style="color: gray !important"
                                  >mdi-information-outline</v-icon
                                >
                              </v-card-text>
                            </template>

                            <v-card
                              width="300px"
                              max-height="500px"
                              class="pa-3 m-auto"
                              style="border: 1px solid gray"
                            >
                              <!-- <h5>Fare Details</h5>
                                <hr> -->
                              <div class="d-flex justify-content-between">
                                <div>
                                  <p
                                    class="m-0"
                                    style="font-size: 18px; font-weight: 500"
                                  >
                                    Total Price
                                  </p>
                                </div>
                                <div>
                                  <p class="tot-price m-0">
                                    {{ res.currency }}
                                    {{ res.TotalPrice }}
                                  </p>
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-md-12">
                                  <div class="row justify-content-between">
                                    <div class="col-6">
                                      <span
                                        style="
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                        >Passengers</span
                                      >
                                    </div>
                                    <!-- <div class="col-md-4"></div> -->
                                    <div
                                      class="col-6"
                                      style="font-size: 14px; text-align: end"
                                    >
                                      <span v-if="aduls > 0"
                                        >{{ aduls }} x Adult</span
                                      ><br />
                                      <span v-if="aduls1 > 0"
                                        >{{ aduls1 }} x Child</span
                                      ><br />
                                      <span v-if="aduls2 > 0"
                                        >{{ aduls2 }} x Lap Infant</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mt-1">
                                <hr class="mt-1 mb-2" />
                                <hr class="mt-1 mb-2" />

                                <div
                                  class="p-1 collapse"
                                  :class="{ show: fareIcon }"
                                  id="collapse-Example"
                                >
                                  <div class="row">
                                    <div class="col-6" style="font-size: 14px">
                                      Base Price<br />
                                      Taxes & Fees
                                    </div>
                                    <div
                                      class="col-6"
                                      style="font-size: 14px; text-align: end"
                                    >
                                      {{ res.currency }}
                                      {{ res.Baseprice }}<br />
                                      {{ res.currency }}
                                      {{ res.Taxprice }}
                                    </div>
                                  </div>
                                </div>

                                <!-- <br>
                        <p style="font-size: 12px">
                          All fares are quoted in USD .Some airlines may charge bag gage fees.
                          Your credit/debit card may be billed in multiple charges totaling
                          the final total price.
                        </p> -->
                              </div>
                            </v-card>
                          </v-menu>
                        </div>
                      </div>
                    </div>

                    <!-- <span style="
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: -10px;
                        margin-bottom: 10px;
                      ">Per Person</span> -->

                    <div
                      class="flight-search"
                      style="padding: 0; border-radius: 0.5rem; margin: 0 auto"
                    >
                      <!-- <button type="submit" class="btn btn-search" style="
                          width: 100px;
                          height:40px;
                          min-width: 0;
                          min-height: 0;
                        " @click="onewayselect(res, index)">
                        <span class="fw-bold">Select</span>
                      </button> -->
                      <button
                        type="submit"
                        class="btn btn-search"
                        style="
                          width: 140px;
                          min-width: 0;
                          min-height: 0;
                        "
                        @click="flighted2(res, index)"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop1"
                        aria-controls="staticBackdrop"
                      >
                        <span class="fw-bold">Flight Details</span>
                      </button>
                    </div>
                    <!-- <div class="d-flex align-center justify-content-sm-center mb-2 pt-2"
                      @click="flighted2($event,res, index)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop">
                      <span style="color: #36c; font-size: 12px; cursor: pointer">
                        Flight Details</span>
                    </div> -->

                    <!-- <v-btn
                      class="select_btn"
                      color="#002d5b "
                      width="140px"
                      height="40px"
                      style="color: white"
                      @click.prevent="onewayselect(res, index)"
                      >Select</v-btn
                    > -->
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <!-- </div> -->
                </div>
              </div>
            </v-card>
            </div>
            <div class="drawer-section">
              <div
                class="offcanvas offcanvas-end"
                data-bs-backdrop="static"
                tabindex="-1"
                id="staticBackdrop1"
                aria-labelledby="staticBackdropLabel"
              >
                <div class="offcanvas-header d-flex justify-content-between">
                  <h5 class="offcanvas-title" id="staticBackdrop1">
                    Flight Details
                  </h5>
                  <button
                    type="button"
                    @click="closeModal()"
                    class="text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    color="white"
                  >
                    <v-icon>mdi-close</v-icon>
                  </button>
                </div>
                <div class="offcanvas-body pt-0" ref="offcanvasBody1">
                  <v-divider></v-divider>
                  <div
                    v-for="(flightdatas, index) of flightdetailsData1"
                    :key="index"
                  >{{ console.log(flightdatas,'dataaaaaa1') }}
                    <div class="d-flex flex-row px-2 mt-1">
                      <div class="col-6 text-start mx-2 price-head d-flex">
                        <span class="price-currency" style="font-size: 26px">{{
                          flightdatas.currency
                        }}</span>
                        <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                        <div id="demo" class="d-flex">
                          <span
                            id="f0"
                            class="price-Amount"
                            style="font-size: 32px; padding-top: 6px"
                            >{{ flightdatas.find1 }}</span
                          >

                          <div class="d-flex">
                            <span
                              style="
                                font-size: 20px;
                                position: relative;
                                left: 2px;
                                top: 9px;
                              "
                              >.</span
                            ><span
                              id="f1"
                              style="
                                font-size: 18px;
                                font-weight: 600;
                                margin-left: 2px;
                                margin-top: 12px;
                              "
                              >{{ flightdatas.find2 }}</span
                            >
                          </div>
                        </div>
                      </div>
                      <!-- <div><span>Per Person</span></div> -->
                      <div
                        class="col-6 d-flex justify-center align-items-center"
                      >
                        <span
                          >Cabin:
                          <span class="fw-bold">{{ oneway.class }}</span></span
                        >
                      </div>
                    </div>
                    <div class="ps-3">
                      <div class="row p-2">
                        <div class="col-1 pe-0 ps-1" style="margin: auto 0">
                          <v-img
                            :src="flightdatas.Depature[0].MarketingCarrier.logo"
                            style="margin: auto; width: 100%"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightdatas.fromTrip }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{ getDate(flightdatas.Depature) }} -
                            <span class="fw-bold">{{
                              getTime(flightdatas.Depature)
                            }}</span></span
                          >
                        </div>
                        <div class="col-3 mt-2 ps-0">
                          <v-img
                             :src="require('../assets/img/flightIcon.png')"
                            width="22%"
                            style="transform: rotate(45deg); margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightdatas.toTrip }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{ getArrivaldate(flightdatas.Depature) }}
                            -
                            <span class="fw-bold">{{
                              getArrivalTime(flightdatas.Depature)
                            }}</span></span
                          >
                        </div>
                      </div>
                    </div>
                    <v-card class="mt-4">
                      <v-tabs v-model="tab" align-tabs="center" stacked>
                        <v-tab value="tab-1" class="m-0">
                          <v-icon style="transform: rotate(45deg)"
                            >mdi-airplane</v-icon
                          >
                          Overview
                        </v-tab>

                        <!-- <v-tab value="tab-2" v-if="getbaggageOne(flightdatas)"> -->
                           <v-tab value="tab-2">
                          <v-icon>mdi-bag-checked</v-icon>
                          Baggage
                        </v-tab>

                        <v-tab value="tab-3" class="m-0">
                          <v-icon>mdi-ticket-account</v-icon>
                          Fare Details
                        </v-tab>
                      </v-tabs>

                      <v-card-text class="pt-0">
                        <keep-alive>
                          <v-window v-model="tab">
                            <div v-if="tab === 'tab-1'">
                              <!-- <TabView>
                                      <TabPanel header="Header I"> -->

                              <v-card
                                style="border: 1px solid grey"
                                class="mb-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    Depature
                                  </h6>
                                  <span
                                    class="fw-bold ms-2"
                                    style="font-size: 16px"
                                  >
                                    {{ getdepName1(flightdatas.fromTrip) }} -
                                    {{ getarrName1(flightdatas.toTrip) }}</span
                                  >
                                </div>
                                <v-divider></v-divider>

                                <div
                                  v-for="(data, index) of flightdatas.Depature"
                                  :key="data"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-3 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          <v-icon size="16" class="me-1"
                                            >mdi-clock-outline</v-icon
                                          >{{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-500"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="
                                      flightdatas.Depature.length - 1 !== index
                                    "
                                    style="
                                      position: relative;
                                      left: 15%;
                                      background-color: #63AB45;
                                      color: white;
                                    "
                                    class="p-1 layoverTime1"
                                  >
                                    <div class="d-flex justify-center">
                                      <div>
                                        <v-img
                                          :src="require('../assets/sandClock.png')"
                                          width="16px"
                                          style="margin: auto; padding-top: 8px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ flightdatas.layoverTimes[index] }}
                                          - LayoverTime
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                            </div>

                            <div v-if="tab === 'tab-2'">
                              <div style="font-size: 15px; font-weight: 500">
                                {{ flightdatas.fromTrip }} - {{ flightdatas.toTrip }}
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <div style="color: gray !important">
                                  <v-icon color="gray">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span> Checked Baggage:</span>
                                  <!-- <span class="ms-1"> {{res.BaggageData.PieceAllowance.TotalQuantity}}Piece</span> -->
                                  <span class="ms-1">
                                    {{
                                      flightdatas.BaggageData.PieceAllowance
                                        .TotalQuantity || 0
                                    }}
                                    {{
                                      flightdatas.BaggageData.PieceAllowance
                                        .TotalQuantity > 1
                                        ? "Pieces"
                                        : "Piece"
                                    }}
                                  </span>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <div style="color: gray !important">
                                  <v-icon color="gray">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>Cabin Baggage:</span>
                                  <span class="ms-1">
                                    {{
                                      flightdatas.BaggageData.PieceAllowance
                                        .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                    }}
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div v-if="tab === 'tab-3'">
                              <div class="d-flex justify-content-between">
                                <div>
                                  <p
                                    class="m-0"
                                    style="font-size: 18px; font-weight: 600"
                                  >
                                    Total Price
                                  </p>
                                </div>
                                <div>
                                  <p
                                    class="tot-price m-0 fw-bold"
                                    style="font-size: 18px"
                                  >
                                    {{ flightdatas.currency }}
                                    {{ flightdatas.TotalPrice }}
                                  </p>
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-md-12">
                                  <div class="row justify-content-between">
                                    <div class="col-6">
                                      <span
                                        style="
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                        >Passengers</span
                                      >
                                    </div>
                                    <!-- <div class="col-md-4"></div> -->
                                    <div
                                      class="col-6"
                                      style="font-size: 14px; text-align: end"
                                    >
                                      <span v-if="aduls > 0"
                                        >{{ aduls }} x Adult</span
                                      ><br />
                                      <span v-if="aduls1 > 0"
                                        >{{ aduls1 }} x Child</span
                                      ><br />
                                      <span v-if="aduls2 > 0"
                                        >{{ aduls2 }} x Lap Infant</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mt-1">
                                <hr class="mt-1 mb-2" />
                                <hr class="mt-1 mb-2" />

                                <div
                                  class="p-1 collapse"
                                  :class="{ show: fareIcon }"
                                  id="collapse-Example"
                                >
                                  <div class="row d-flex justify-content-between">
                                    <div class="col-6" style="font-size: 14px">
                                      Base Price<br />
                                      Taxes & Fees
                                    </div>
                                    <div class="col-4 d-flex flex-column pe-2">
                                      <div class="row">
                                        <div class="col-5 pe-0 ps-3 text-md-end text-center">
                                          {{ flightdatas.currency }}
                                        </div>
                                        <div class="col-7 text-end ps-0">
                                          {{ flightdatas.Baseprice }}
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-5 pe-0 ps-3 text-md-end text-center">
                                          {{ flightdatas.currency }}
                                        </div>
                                        <div class="col-7 text-end ps-lg-2 ps-0">
                                          {{ flightdatas.Taxprice }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-window>
                        </keep-alive>
                      </v-card-text>
                      <!-- </TabPanel>
                                <TabPanel header="Header II">
                                    <p class="m-0">
                                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim
                                        ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Consectetur, adipisci velit, sed quia non numquam eius modi.
                                    </p>
                                </TabPanel>
                                <TabPanel header="Header III">
                                    <p class="m-0">
                                        At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
                                        officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus.
                                    </p>
                                </TabPanel>
                            </TabView> -->
                    </v-card>
                  </div>
                </div>
                <div
                  class="offcanvas-footer flight-search p-2"
                  style="margin: auto"
                >
                  <button
                    type="submit "
                    class="btn btn-search"
                    style="
                      width: 300px;
                      min-width: 0;
                      min-height: 0;
                    "
                    @click="onewayselect(flightdetailsData1, index)"
                  >
                    <span class="fw-bold" style="font-size: 16px"
                      >Book This Flight</span
                    >
                  </button>
                </div>
              </div>
            </div>
          

          <div class="mb-3" v-for="(res, index) of multicityData" :key="index">
            <v-card class="hoveredCard" rounded="3">
              <div class="row gx-0">
                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-hide"
                  style="display: none !important"
                >
                  <div class="airline-logo">
                    <v-img :src="res.ownerlogo" width="70px"></v-img>
                  </div>
                  <div>
                    <p class="res-name text-center">
                      {{ res.ownername }}

                      <!-- <br />
                      {{ getid(res.Flight1) }} -
                      {{ getNumber(res.Flight1) }} -->
                    </p>
                  </div>
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center airlineLogo-show"
                  style="display: none !important"
                >
                  <div class="row g-0 px-3 align-center">
                    <div class="col-3">
                      <div class="airline-logo">
                        <v-img :src="res.ownerlogo" width="70px"></v-img>
                      </div>

                      <div>
                        <p
                          class="res-name text-center"
                          style="
                            padding-left: 0px !important;
                            text-align: left !important;
                          "
                        >
                          {{ res.ownername }}
                        </p>
                      </div>
                    </div>

                    <div class="col-6">
                      <div
                        class="d-flex flex-column align-center justify-center mbl-price-section"
                        style="margin-bottom: 10px"
                      >
                        <!-- <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <span class="price-Amount">{{ res.perperson }}</span>
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{res.find1}}</span>

                            <div class="d-flex">
                              <span style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: -3px;
                                ">.</span>
                                <span id="f1" style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                ">{{ res.find2 }}</span>
                            </div>
                          </div>
                        </div> -->

                        <div class="price-head d-flex">
                          <span class="price-currency">{{ res.currency }}</span>
                          <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                          <div id="demo" class="d-flex">
                            <span id="f0" class="price-Amount">{{
                              res.find1
                            }}</span>

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: 2px;
                                "
                                >.</span
                              >
                              <span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-bottom: 10px;
                                  margin-left: 2px;
                                  margin-top: 5px;
                                "
                                >{{ res.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>

                        <span
                          class="content"
                          style="
                            font-size: 14px;
                            font-weight: 500;
                            margin-top: -10px;
                            margin-bottom: 10px;
                          "
                          >Per Person</span
                        >
                      </div>
                    </div>

                    <div class="col-3 d-flex align-center">
                      <v-btn
                        class="select_btn"
                        width="140px"
                        height="40px"
                        style="color: white; background-color: #002d5b"
                        @click.prevent="selected(res, index)"
                        >Select</v-btn
                      >
                    </div>
                  </div>
                </div>

                <div
                  class="col-lg-10 Timing-section"
                  style="padding: 10px 5px; border-right: 1px dotted grey"
                >
                  <!-- ------flight1 start----- -->
                  <div class="">
                    <v-card
                      class="mutilti_data"
                      style="border: none !important"
                    >
                      <div
                        style="display: flex; align-items: center"
                        @click="flighted3(res, index), (drawer = !drawer)"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop2"
                        aria-controls="staticBackdrop"
                      >
                        <!-- <div style="display: flex; align-items: center" @click="radioData1 = 0">
                          <v-radio-group v-model="res.radios1">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->

                        <div style="width: 100% !important;height:115px;">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="aircode-hide">
                              <p
                                class="m-0"
                                style="
                                  color: #193582;
                                  background-color: rgb(197 219 243);
                                  width: 10%;
                                  border-radius: 20px;
                                  text-align: center;
                                  font-size: 14px;
                                "
                              >
                                Depature
                              </p>
                            </div>
                            <div class="aircode-show d-flex flex-row">
                              <div class="col-6">
                                <p
                                  class="m-0 aircode-show"
                                  style="
                                    color: #193582;
                                    background-color: rgb(197 219 243);
                                    width: 50%;
                                    border-radius: 20px;
                                    text-align: center;
                                    font-size: 12px;
                                  "
                                >
                                  Depature
                                </p>
                              </div>
                              <div class="col-6">
                                <div class="d-flex flex-row justify-end">
                                  <div
                                    class="d-flex d-lg-none align-center justify-end pe-0"
                                  >
                                    <div class="btn-icon">
                                      <!-- <span><v-icon size="20">{{ res.Refund == 'Refundable' ? 'mdi-cash' : 'mdi-cash-remove'}}</v-icon></span> -->
                                      <v-tooltip location="top">
                                        <template v-slot:activator="{ props }">
                                          <v-icon
                                            v-bind="props"
                                            style="color: gray !important"
                                            size="22"
                                            justify="center"
                                          >
                                            {{
                                              res.Refund == "Refundable"
                                                ? "mdi-cash"
                                                : "mdi-cash-remove"
                                            }}
                                          </v-icon>
                                        </template>
                                        <span>{{ res.Refund }}</span>
                                      </v-tooltip>
                                    </div>
                                  </div>

                                  <div
                                    class="d-flex d-lg-none align-center justify-start ps-0"
                                  >
                                    <div class="btn-icon">
                                      <v-tooltip location="top">
                                        <template v-slot:activator="{ props }">
                                          <v-icon
                                            v-bind="props"
                                            style="
                                              color: gray !important;
                                              margin-left: 15px;
                                            "
                                            size="21"
                                          >
                                            mdi-seat-passenger
                                          </v-icon>
                                        </template>
                                        <span>{{ oneway.class }}</span>
                                      </v-tooltip>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row mt-2 g-0 mb-2">
                              <div class="col-md-2 col-2 aircode-hide">
                                <div class="row d-flex flex-row">
                                  <div class="col-5 airline-logo pe-0">
                                    <v-img
                                      :src="
                                        res.Flight1[0].MarketingCarrier.logo
                                      "
                                      width="50px"
                                    ></v-img>
                                  </div>
                                  <div class="col-7 ps-0 justify-center mt-1">
                                    <p class="f-size-16 fw-600 mb-1">
                                      {{ getname(res.Flight1) }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-1 aircode-show">
                                <v-img
                                  :src="res.Flight1[0].MarketingCarrier.logo"
                                  width="100%"
                                ></v-img>
                              </div>
                              <div class="col-md-2 col-2 pe-0 aircode-hide">
                                <p
                                  class="text-center"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 20px;
                                    font-weight: 600;
                                  "
                                >
                                  <!-- {{ getAirportNameFrom(res.Flight1) }} -->
                                  {{ getTime(res.Flight1) }}
                                </p>
                                <p
                                  class="text-center"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: grey;
                                  "
                                >
                                  {{ codeData[0].from }}
                                </p>
                              </div>

                              <div class="col-md-3 col-2 pe-2 aircode-show">
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight1) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8 d-none">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight1) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight1) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight1JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight1JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight1JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight1JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight1.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight1)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(res.Flight1)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight1JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight1JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight1JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight1.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight1)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(res.Flight1)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(res, index)"
                                        @mouseout="hideDetails2(res)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="res.Flight1.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight1, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(res.Flight1)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight1JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight1) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight1) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(res.Flight1) }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 col-6">
                                <p
                                  class="text-center d-lg-none m-0"
                                  style="font-size: 10px"
                                >
                                  {{ res.Flight1JourneyTime.Stops }} Stop
                                </p>
                                <v-img
                                  :src="require('../assets/img/stop1.png')"
                                  width="80%"
                                  style="margin: 0 auto"
                                ></v-img>
                                <p
                                  class="text-center d-lg-none m-0"
                                  style="font-size: 10px; font-weight: 600"
                                >
                                  {{ res.Flight1JourneyTime.Time }}
                                </p>
                              </div>

                              <div
                                class="col-md-2 col-2 ps-2 aircode-hide text-center"
                              >
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 20px;
                                    font-weight: 600;
                                  "
                                >
                                  <!-- {{ getretairname(res.Flight1) }} -->
                                  {{ get2arrtime(res.Flight1) }}
                                </p>
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: grey;
                                  "
                                >
                                  <!-- {{ getArrivalCode(res.Depature) }} -->
                                  {{ codeData[0].to }}
                                </p>
                              </div>

                              <div class="col-md-3 col-2 ps-2 aircode-show">
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight1) }}
                                </p>
                              </div>
                              <div class="col-md-2 text-center aircode-hide">
                                <p
                                  class="m-0 mb-2"
                                  style="font-size: 16px; font-weight: 600"
                                >
                                  {{ res.Flight1JourneyTime.Time }}
                                </p>
                                <div v-if="res.Flight1JourneyTime.Stops == 0">
                                  <p
                                    class="res-stop m-0 text-center"
                                    style="font-size: 16px"
                                  >
                                    Non Stop
                                  </p>
                                </div>
                                <div
                                  v-if="res.Flight1JourneyTime.Stops == 1"
                                  style="height: 28px"
                                >
                                  <p
                                    class="res-stop m-0"
                                    style="font-size: 16px"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                  >
                                    {{ res.Flight1JourneyTime.Stops }} Stop
                                  </p>
                                  <div
                                    v-if="res.Flight1.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight1)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div v-if="res.Flight1JourneyTime.Stops >= 2">
                                  <p
                                    class="res-stop m-0 text-center"
                                    style="font-size: 16px"
                                    @mouseover="showDetails1(res, index)"
                                    @mouseout="hideDetails1(res)"
                                  >
                                    {{ res.Flight1JourneyTime.Stops }} Stops
                                  </p>
                                  <div
                                    v-if="res.Flight1.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName1 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight1)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight1)
                                      }}</span
                                      ><br />
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight1, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight1)
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas1">
                        <div
                          v-for="(res, multindex) of multiflight1"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multicityone1(res, multindex, index)">
                              <v-radio-group v-model="radioData1">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>

                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 g-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[0].from }}
                                    </p>
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(res.Flight1) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(res.Flight1) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getTime(res.Flight1) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight1JourneyTime.Stops == 0"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ res.Flight1JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight1JourneyTime.Stops == 1"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight1JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight1.length >= 2"
                                            class="hoverData1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight1)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight1)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight1JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight1JourneyTime.Stops >= 2"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight1JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight1.length >= 2"
                                            class="hoverDataStop1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight1)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight1)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails2(res, index)
                                            "
                                            @mouseout="hideDetails2(res)"
                                            >mdi-circle</v-icon
                                          >
                                          <div
                                            v-if="res.Flight1.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight1, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight1)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight1JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(res.Flight1) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(res.Flight1)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getArrivalTime(res.Flight1) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      <!-- {{ getArrivalCode(res.Depature) }} -->
                                      {{ codeData[0].to }}
                                    </p>
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(res.Flight1) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(res.Flight1) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <!-- ------flight1 end----- -->

                  <!-- ------flight2 start----- -->

                  <div class="">
                    <v-card
                      class="mutilti_data"
                      style="border: none !important"
                    >
                      <div
                        style="display: flex; align-items: center"
                        @click="flighted3(res, index)"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop2"
                        aria-controls="staticBackdrop"
                      >
                        <!-- <div style="display: flex; align-items: center" @click="radioData2 = 0">
                          <v-radio-group v-model="res.radios2">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->

                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 gx-0 mb-2">
                              <div class="col-md-2 col-2 aircode-hide">
                                <div class="row d-flex flex-row">
                                  <div
                                    class="col-5 aircode-hide airline-logo pe-0"
                                  >
                                    <v-img
                                      :src="
                                        res.Flight2[0].MarketingCarrier.logo
                                      "
                                      width="50px"
                                    ></v-img>
                                  </div>
                                  <div class="col-7 ps-0 justify-center mt-1">
                                    <p class="f-size-16 fw-600 mb-1">
                                      {{ getname(res.Flight2) }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-1 aircode-show">
                                <v-img
                                  :src="res.Flight1[0].MarketingCarrier.logo"
                                  width="100%"
                                ></v-img>
                              </div>
                              <div class="col-md-2 col-2 pe-0 aircode-hide">
                                <p
                                  class="text-center"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 20px;
                                    font-weight: 600;
                                  "
                                >
                                  <!-- {{ getAirportNameFrom(res.Flight2) }} -->
                                  {{ getTime(res.Flight2) }}
                                </p>
                                <p
                                  class="text-center"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: grey;
                                  "
                                >
                                  {{ codeData[1].from }}
                                </p>
                              </div>

                              <div class="col-md-3 col-2 pe-2 aircode-show">
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight2) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8 d-none">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight2) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight2) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(res.Flight2) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight2JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight2JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight2JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight2JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight2.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight2)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(res.Flight2)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight2JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight2JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight2JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight2.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight2)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(res.Flight2)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(res, index)"
                                        @mouseout="hideDetails2(res)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="res.Flight2.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight2, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(res.Flight2)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight2JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight2) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight2) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(res.Flight2) }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 col-6">
                                <p
                                  class="d-lg-none res-stop m-0 text-center"
                                  style="font-size: 10px"
                                  v-if="res.Flight2JourneyTime.Stops != 0"
                                >
                                  {{ res.Flight2JourneyTime.Stops }} Stop
                                </p>
                                <p
                                  class="d-lg-none res-stop m-0 text-center"
                                  style="font-size: 10px"
                                  v-if="res.Flight2JourneyTime.Stops == 0"
                                >
                                  Non Stop
                                </p>
                                <v-img
                                  :src="require('../assets/img/stop1.png')"
                                  width="80%"
                                  style="margin: 0 auto"
                                ></v-img>
                                <p
                                  class="m-0 d-lg-none text-center"
                                  style="font-size: 10px; font-weight: 600"
                                >
                                  {{ res.Flight2JourneyTime.Time }}
                                </p>
                              </div>

                              <div
                                class="col-md-2 col-2 ps-2 aircode-hide text-center"
                              >
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 20px;
                                    font-weight: 600;
                                  "
                                >
                                  {{ getarrtime(res.Flight2) }}
                                  <!-- {{ getretairname(res.Flight2) }} -->
                                </p>
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: grey;
                                  "
                                >
                                  {{ codeData[1].to }}
                                </p>
                              </div>

                              <div class="col-md-3 col-2 ps-2 aircode-show">
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight2) }}
                                </p>
                              </div>
                              <div class="col-md-2 text-center aircode-hide">
                                <p
                                  class="m-0 mb-2"
                                  style="font-size: 16px; font-weight: 600"
                                >
                                  {{ res.Flight2JourneyTime.Time }}
                                </p>
                                <div v-if="res.Flight2JourneyTime.Stops == 0">
                                  <p
                                    class="res-stop m-0 text-center"
                                    style="font-size: 16px"
                                  >
                                    Non Stop
                                  </p>
                                </div>
                                <div
                                  v-if="res.Flight2JourneyTime.Stops == 1"
                                  style="height: 28px"
                                >
                                  <p
                                    class="res-stop m-0"
                                    style="font-size: 16px"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                  >
                                    {{ res.Flight2JourneyTime.Stops }} Stop
                                  </p>
                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight2)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-if="res.Flight2JourneyTime.Stops >= 2"
                                  style="height: 28px"
                                >
                                  <p
                                    class="res-stop m-0 text-center"
                                    style="font-size: 16px"
                                    @mouseover="showDetails2(res, index)"
                                    @mouseout="hideDetails2(res)"
                                  >
                                    {{ res.Flight2JourneyTime.Stops }} Stops
                                  </p>
                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName2 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight2)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight2)
                                      }}</span
                                      ><br />
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight2, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight2)
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas2">
                        <div
                          v-for="(data, multindex) of multiflight2"
                          :key="multindex"
                        >
                          <!-- {{data.Flight2JourneyTime}} -->
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex > 0 ? true : false"
                          >
                            <div @click="multicityone2(data, multindex, index)">
                              <v-radio-group v-model="radioData2">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>
                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 gx-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[1].from }}
                                    </p>
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(data.Flight2) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(data.Flight2) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(data.Flight2) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(data.Flight2) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getTime(data.Flight2) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="
                                          data.Flight2JourneyTime.Stops == 0
                                        "
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ data.Flight2JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="
                                          data.Flight2JourneyTime.Stops == 1
                                        "
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ data.Flight2JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(data, index)
                                            "
                                            @mouseout="hideDetails1(data)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="data.Flight2.length >= 2"
                                            class="hoverData1"
                                            id="tooltip"
                                            :class="{
                                              visible: data.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(data.Flight2)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(data.Flight2)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            data.Flight2JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="
                                          data.Flight2JourneyTime.Stops >= 2
                                        "
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ data.Flight2JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(data, index)
                                            "
                                            @mouseout="hideDetails1(data)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="data.Flight2.length >= 2"
                                            class="hoverDataStop1"
                                            id="tooltip"
                                            :class="{
                                              visible: data.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(data.Flight2)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(data.Flight2)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails2(data, index)
                                            "
                                            @mouseout="hideDetails2(data)"
                                            >mdi-circle</v-icon
                                          >
                                          <div
                                            v-if="data.Flight2.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: data.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(data.Flight2, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(data.Flight2)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            data.Flight2JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(data.Flight2) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(data.Flight2)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getArrivalTime(data.Flight2) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[1].to }}
                                    </p>
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(data.Flight2) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(data.Flight2) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>

                  <!-- ------flight2 end----- -->

                  <!-- ------flight3 start----- -->
                  <div class="" v-if="res.Flight3 ? true : false">
                    <v-card
                      class="mutilti_data"
                      style="border: none !important"
                    >
                      <div
                        style="display: flex; align-items: center"
                        @click="flighted3(res, index), (drawer = !drawer)"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop2"
                        aria-controls="staticBackdrop"
                      >
                        <!-- <div style="display: flex; align-items: center" @click="radioData3 = 0">
                          <v-radio-group v-model="res.radios3">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->
                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 g-0 mb-2">
                              <div class="col-md-2 col-2 aircode-hide">
                                <div class="row d-flex flex-row">
                                  <div class="col-5 airline-logo pe-0">
                                    <v-img
                                      :src="
                                        res.Flight3[0].MarketingCarrier.logo
                                      "
                                      width="50px"
                                    ></v-img>
                                  </div>
                                  <div class="col-7 ps-0 justify-center mt-1">
                                    <p class="f-size-16 fw-600 mb-1">
                                      {{ getname(res.Flight3) }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-1 aircode-show">
                                <v-img
                                  :src="res.Flight3[0].MarketingCarrier.logo"
                                  width="100%"
                                ></v-img>
                              </div>
                              <div class="col-md-2 col-2 pe-0 aircode-hide">
                                <p
                                  class="text-center"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 20px;
                                    font-weight: 600;
                                  "
                                >
                                  {{ getTime(res.Flight3) }}
                                  <!-- {{ getAirportNameFrom(res.Flight3) }} -->
                                </p>
                                <p
                                  class="text-center"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: grey;
                                  "
                                >
                                  {{ codeData[2].from }}
                                </p>
                              </div>

                              <div class="col-md-3 col-2 pe-2 aircode-show">
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight3) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8 d-none">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight3) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight3) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight3JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight3JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight3JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight3JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight3.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight3)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(res.Flight3)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight3JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight3JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight3JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight2.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight3)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(res.Flight3)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(res, index)"
                                        @mouseout="hideDetails2(res)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="res.Flight3.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight3, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(res.Flight3)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight3JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight3) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight3) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(res.Flight3) }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 col-6">
                                <p
                                  v-if="res.Flight3JourneyTime.Stops != 0"
                                  class="d-lg-none res-stop m-0 text-center"
                                  style="font-size: 10px"
                                >
                                  {{ res.Flight3JourneyTime.Stops }} Stop
                                </p>
                                <p
                                  v-if="res.Flight3JourneyTime.Stops == 0"
                                  class="d-lg-none res-stop m-0 text-center"
                                  style="font-size: 10px"
                                >
                                  Non Stop
                                </p>
                                <v-img
                                  :src="require('../assets/img/stop1.png')"
                                  width="80%"
                                  style="margin: 0 auto"
                                ></v-img>
                                <p
                                  class="d-lg-none text-center m-0"
                                  style="font-size: 10px; font-weight: 600"
                                >
                                  {{ res.Flight3JourneyTime.Time }}
                                </p>
                              </div>

                              <div
                                class="col-md-2 col-2 ps-2 aircode-hide text-center"
                              >
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 20px;
                                    font-weight: 600;
                                  "
                                >
                                  {{ getarrtime(res.Flight3) }}
                                  <!-- {{ getretairname(res.Flight3) }} -->
                                </p>
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: grey;
                                  "
                                >
                                  {{ codeData[2].to }}
                                </p>
                              </div>

                              <div class="col-md-3 col-2 ps-2 aircode-show">
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight3) }}
                                </p>
                              </div>
                              <div class="col-md-2 text-center aircode-hide">
                                <p
                                  class="m-0 mb-2"
                                  style="font-size: 16px; font-weight: 600"
                                >
                                  {{ res.Flight3JourneyTime.Time }}
                                </p>
                                <div v-if="res.Flight3JourneyTime.Stops == 0">
                                  <p
                                    class="res-stop m-0 text-center"
                                    style="font-size: 16px"
                                  >
                                    Non Stop
                                  </p>
                                </div>
                                <div
                                  v-if="res.Flight3JourneyTime.Stops == 1"
                                  style="height: 28px"
                                >
                                  <p
                                    class="res-stop m-0"
                                    style="font-size: 16px"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                  >
                                    {{ res.Flight3JourneyTime.Stops }} Stop
                                  </p>
                                  <div
                                    v-if="res.Flight3.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight3)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight3)
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-if="res.Flight3JourneyTime.Stops >= 2"
                                  style="height: 28px"
                                >
                                  <p
                                    class="res-stop m-0 text-center"
                                    style="font-size: 16px"
                                    @mouseover="showDetails3(res, index)"
                                    @mouseout="hideDetails3(res)"
                                  >
                                    {{ res.Flight3JourneyTime.Stops }} Stops
                                  </p>
                                  <div
                                    v-if="res.Flight2.length >= 2"
                                    class="hoverDataStop1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName3 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight3)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight3)
                                      }}</span
                                      ><br />
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight3, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight3)
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas3">
                        <div
                          v-for="(res, multindex) of multiflight3"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multicityone3(res, multindex, index)">
                              <v-radio-group v-model="radioData3">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>

                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 g-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[2].from }}
                                    </p>
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(res.Flight3) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(res.Flight3) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getTime(res.Flight3) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight3JourneyTime.Stops == 0"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ res.Flight3JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight3JourneyTime.Stops == 1"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight3JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight3.length >= 2"
                                            class="hoverData1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight3)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight3)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight3JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight3JourneyTime.Stops >= 2"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight3JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight2.length >= 2"
                                            class="hoverDataStop1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight3)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight3)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails2(res, index)
                                            "
                                            @mouseout="hideDetails2(res)"
                                            >mdi-circle</v-icon
                                          >
                                          <div
                                            v-if="res.Flight3.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight3, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight3)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight3JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(res.Flight3) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(res.Flight3)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getArrivalTime(res.Flight3) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[2].to }}
                                    </p>
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(res.Flight3) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(res.Flight3) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <!-- ------flight3 end----- -->

                  <!-- ------flight4 start----- -->
                  <div class="mt-2" v-if="res.Flight4 ? true : false">
                    <v-card
                      class="mutilti_data"
                      style="border: none !important"
                    >
                      <div
                        style="display: flex; align-items: center"
                        @click="flighted3(res, index), (drawer = !drawer)"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop2"
                        aria-controls="staticBackdrop"
                        @click.stop
                      >
                        <!-- <div style="display: flex; align-items: center" @click="radioData4 = 0">
                          <v-radio-group v-model="res.radios4">
                            <v-radio :value="true"></v-radio>
                          </v-radio-group>
                        </div> -->

                        <div style="width: 100% !important">
                          <v-card
                            class="card-in"
                            style="overflow: unset; padding: 1px"
                          >
                            <div class="row mt-2 g-0 mb-2">
                              <div class="aircode-hide col-md-2 col-2">
                                <div class="row d-flex flex-row">
                                  <div class="col-5 airline-logo pe-0">
                                    <v-img
                                      :src="
                                        res.Flight4[0].MarketingCarrier.logo
                                      "
                                      width="50px"
                                    ></v-img>
                                  </div>
                                  <div class="col-7 ps-0 justify-center mt-1">
                                    <p class="f-size-16 fw-600 mb-1">
                                      {{ getname(res.Flight4) }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="aircode-show col-1">
                                <v-img
                                  :src="res.Flight4[0].MarketingCarrier.logo"
                                  width="100%"
                                ></v-img>
                              </div>
                              <div class="col-md-2 col-2 pe-0 aircode-hide">
                                <p
                                  class="text-center"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 20px;
                                    font-weight: 600;
                                  "
                                >
                                  {{ getTime(res.Flight4) }}
                                  <!-- {{ getAirportNameFrom(res.Flight4) }} -->
                                </p>
                                <p
                                  class="text-center"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: grey;
                                  "
                                >
                                  {{ codeData[3].from }}
                                </p>
                              </div>

                              <div class="col-md-3 col-2 pe-2 aircode-show">
                                <p
                                  class="text-center text-sm-end"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getAirportCode(res.Flight4) }}
                                </p>
                              </div>

                              <div class="col-md-6 col-8 d-none">
                                <div class="row g-0 mt-1 justify-center">
                                  <div class="col-md-4 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getDate(res.Flight4) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getDateDeparture(res.Flight4) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getTime(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight4JourneyTime.Stops == 0"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      Direct
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                    >
                                      <hr
                                        style="
                                          width: 87%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span
                                        >{{ res.Flight4JourneyTime.Time }}
                                      </span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight4JourneyTime.Stops == 1"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight4JourneyTime.Stops }} Stop
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight4.length >= 2"
                                        class="hoverData1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight4)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(res.Flight4)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 40%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight4JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-4 col-6 d-flex flex-column justify-center"
                                    v-if="res.Flight4JourneyTime.Stops >= 2"
                                  >
                                    <p class="res-stop m-0 text-center">
                                      {{ res.Flight4JourneyTime.Stops }} Stops
                                    </p>

                                    <div
                                      class="d-flex align-center justify-center my-2"
                                      style="position: relative"
                                    >
                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails1(res, index)"
                                        @mouseout="hideDetails1(res)"
                                        >mdi-circle</v-icon
                                      >

                                      <div
                                        v-if="res.Flight4.length >= 2"
                                        class="hoverDataStop1"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName1 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            getarrivaldata(res.Flight4)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            getarrtime(res.Flight4)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 15%;
                                          border: 1px solid black;
                                        "
                                      />

                                      <v-icon
                                        size="8"
                                        @mouseover="showDetails2(res, index)"
                                        @mouseout="hideDetails2(res)"
                                        >mdi-circle</v-icon
                                      >
                                      <div
                                        v-if="res.Flight4.length >= 3"
                                        class="hoverDataStop2"
                                        id="tooltip"
                                        :class="{ visible: res.hoveredName2 }"
                                      >
                                        <div style="text-align: center"></div>
                                        <div>
                                          <span style="font-size: 12px">{{
                                            get1arrtime(res.Flight4, index)
                                          }}</span>
                                          <span> | </span>
                                          <span style="font-size: 12px">{{
                                            get2arrtime(res.Flight4)
                                          }}</span>
                                        </div>
                                      </div>

                                      <hr
                                        style="
                                          width: 30%;
                                          border: 1px solid black;
                                        "
                                      />
                                    </div>

                                    <div
                                      class="d-flex justify-center time-section"
                                    >
                                      <v-icon color="grey"
                                        >mdi-clock-outline
                                      </v-icon>
                                      <span>{{
                                        res.Flight4JourneyTime.Time
                                      }}</span>
                                    </div>
                                  </div>

                                  <div class="col-md-3 col-3">
                                    <p
                                      class="res-time text-center m-0 date-section-hide"
                                    >
                                      {{ getArrivaldate(res.Flight4) }}
                                    </p>
                                    <p
                                      class="res-time text-center m-0 date-section-show"
                                    >
                                      {{ getArrivaldateReturn(res.Flight4) }}
                                    </p>
                                    <p
                                      class="text-center m-0"
                                      style="font-weight: 400; font-size: 18px"
                                    >
                                      {{ getArrivalTime(res.Flight4) }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-4 col-6">
                                <p
                                  v-if="res.Flight4JourneyTime.Stops != 0"
                                  class="d-lg-none text-center res-stop m-0"
                                  style="font-size: 10px"
                                >
                                  {{ res.Flight4JourneyTime.Stops }} Stop
                                </p>
                                <p
                                  v-if="res.Flight4JourneyTime.Stops == 0"
                                  class="d-lg-none text-center res-stop m-0"
                                  style="font-size: 10px"
                                >
                                  Non Stop
                                </p>
                                <v-img
                                  :src="require('../assets/img/stop1.png')"
                                  width="80%"
                                  style="margin: 0 auto"
                                ></v-img>
                                <p
                                  class="m-0 d-lg-none text-center"
                                  style="font-size: 10px; font-weight: 600"
                                >
                                  {{ res.Flight4JourneyTime.Time }}
                                </p>
                              </div>

                              <div
                                class="col-md-2 col-2 ps-2 aircode-hide text-center"
                              >
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 20px;
                                    font-weight: 600;
                                  "
                                >
                                  {{ getArrivalTime(res.Flight4) }}
                                  <!-- {{ getretairname(res.Flight4) }} -->
                                </p>
                                <p
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 600;
                                    color: grey;
                                  "
                                >
                                  {{ codeData[3].to }}
                                </p>
                              </div>

                              <div class="col-md-3 col-2 ps-2 aircode-show">
                                <p
                                  class="text-center text-sm-start"
                                  style="
                                    margin-bottom: 4px;
                                    font-size: 16px;
                                    font-weight: 500;
                                  "
                                >
                                  {{ getArrivalCode(res.Flight4) }}
                                </p>
                              </div>
                              <div class="col-md-2 text-center aircode-hide">
                                <p
                                  class="m-0 mb-2"
                                  style="font-size: 16px; font-weight: 600"
                                >
                                  {{ res.Flight4JourneyTime.Time }}
                                </p>
                                <div v-if="res.Flight4JourneyTime.Stops == 0">
                                  <p
                                    class="res-stop m-0 text-center"
                                    style="font-size: 16px"
                                  >
                                    Non Stop
                                  </p>
                                </div>
                                <div
                                  v-if="res.Flight4JourneyTime.Stops == 1"
                                  style="height: 28px"
                                >
                                  <p
                                    class="res-stop m-0"
                                    style="font-size: 16px"
                                    @mouseover="showDetails4(res, index)"
                                    @mouseout="hideDetails4(res)"
                                  >
                                    {{ res.Flight4JourneyTime.Stops }} Stop
                                  </p>
                                  <div
                                    v-if="res.Flight4.length >= 2"
                                    class="hoverData1"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName4 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        getarrivaldata(res.Flight4)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        getarrtime(res.Flight4)
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  v-if="res.Flight4JourneyTime.Stops >= 2"
                                  style="height: 28px"
                                >
                                  <p
                                    class="res-stop m-0"
                                    style="font-size: 16px"
                                    @mouseover="showDetails4(res, index)"
                                    @mouseout="hideDetails4(res)"
                                  >
                                    {{ res.Flight4JourneyTime.Stops }} Stops
                                  </p>
                                  <div
                                    v-if="res.Flight4.length >= 3"
                                    class="hoverDataStop2"
                                    id="tooltip"
                                    :class="{ visible: res.hoveredName4 }"
                                  >
                                    <div style="text-align: center"></div>
                                    <div>
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight4, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight4)
                                      }}</span
                                      ><br />
                                      <span style="font-size: 12px">{{
                                        get1arrtime(res.Flight4, index)
                                      }}</span>
                                      <span> | </span>
                                      <span style="font-size: 12px">{{
                                        get2arrtime(res.Flight4)
                                      }}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-card>
                        </div>
                      </div>

                      <div v-if="res.multipleDatas4">
                        <div
                          v-for="(res, multindex) of multiflight4"
                          :key="multindex"
                        >
                          <div
                            style="
                              display: flex;
                              align-items: center;
                              margin-top: 8px;
                              border-top: 1px solid lightgray;
                            "
                            v-if="multindex !== 0"
                          >
                            <div @click="multicityone4(res, multindex, index)">
                              <v-radio-group v-model="radioData4">
                                <v-radio :value="multindex + 1"></v-radio>
                              </v-radio-group>
                            </div>

                            <div style="width: 100% !important">
                              <v-card
                                class="card-in"
                                style="overflow: unset; padding: 1px"
                              >
                                <div class="row mt-2 g-0 mb-2">
                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-hide"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[3].from }}
                                    </p>
                                    <p
                                      class="text-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportNameFrom(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 pe-2 aircode-show"
                                    style="border-right: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-end"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getAirportCode(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div class="col-md-6 col-8">
                                    <div class="row g-0 mt-1 justify-center">
                                      <div class="col-md-4 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getDate(res.Flight4) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{ getDateDeparture(res.Flight4) }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getTime(res.Flight4) }}
                                        </p>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight4JourneyTime.Stops == 0"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          Direct
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                        >
                                          <hr
                                            style="
                                              width: 87%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span
                                            >{{ res.Flight4JourneyTime.Time }}
                                          </span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight4JourneyTime.Stops == 1"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight4JourneyTime.Stops }}
                                          Stop
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight4.length >= 2"
                                            class="hoverData1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight4)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight4)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 40%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight4JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div
                                        class="col-md-4 col-6 d-flex flex-column justify-center"
                                        v-if="res.Flight4JourneyTime.Stops >= 2"
                                      >
                                        <p class="res-stop m-0 text-center">
                                          {{ res.Flight4JourneyTime.Stops }}
                                          Stops
                                        </p>

                                        <div
                                          class="d-flex align-center justify-center my-2"
                                          style="position: relative"
                                        >
                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails1(res, index)
                                            "
                                            @mouseout="hideDetails1(res)"
                                            >mdi-circle</v-icon
                                          >

                                          <div
                                            v-if="res.Flight4.length >= 2"
                                            class="hoverDataStop1"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName1,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                getarrivaldata(res.Flight4)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                getarrtime(res.Flight4)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 15%;
                                              border: 1px solid black;
                                            "
                                          />

                                          <v-icon
                                            size="8"
                                            @mouseover="
                                              showDetails2(res, index)
                                            "
                                            @mouseout="hideDetails2(res)"
                                            >mdi-circle</v-icon
                                          >
                                          <div
                                            v-if="res.Flight4.length >= 3"
                                            class="hoverDataStop2"
                                            id="tooltip"
                                            :class="{
                                              visible: res.hoveredName2,
                                            }"
                                          >
                                            <div
                                              style="text-align: center"
                                            ></div>
                                            <div>
                                              <span style="font-size: 12px">{{
                                                get1arrtime(res.Flight4, index)
                                              }}</span>
                                              <span> | </span>
                                              <span style="font-size: 12px">{{
                                                get2arrtime(res.Flight4)
                                              }}</span>
                                            </div>
                                          </div>

                                          <hr
                                            style="
                                              width: 30%;
                                              border: 1px solid black;
                                            "
                                          />
                                        </div>

                                        <div
                                          class="d-flex justify-center time-section"
                                        >
                                          <v-icon color="grey"
                                            >mdi-clock-outline
                                          </v-icon>
                                          <span>{{
                                            res.Flight4JourneyTime.Time
                                          }}</span>
                                        </div>
                                      </div>

                                      <div class="col-md-3 col-3">
                                        <p
                                          class="res-time text-center m-0 date-section-hide"
                                        >
                                          {{ getArrivaldate(res.Flight4) }}
                                        </p>
                                        <p
                                          class="res-time text-center m-0 date-section-show"
                                        >
                                          {{
                                            getArrivaldateReturn(res.Flight4)
                                          }}
                                        </p>
                                        <p
                                          class="text-center m-0"
                                          style="
                                            font-weight: 400;
                                            font-size: 18px;
                                          "
                                        >
                                          {{ getArrivalTime(res.Flight4) }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-hide"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ codeData[3].to }}
                                    </p>
                                    <p
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 10px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getretairname(res.Flight4) }}
                                    </p>
                                  </div>

                                  <div
                                    class="col-md-3 col-2 ps-2 aircode-show"
                                    style="border-left: 1px dotted gray"
                                  >
                                    <p
                                      class="text-center text-sm-start"
                                      style="
                                        margin-bottom: 4px;
                                        font-size: 16px;
                                        font-weight: 500;
                                      "
                                    >
                                      {{ getArrivalCode(res.Flight4) }}
                                    </p>
                                  </div>
                                </div>
                              </v-card>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </div>
                  <!-- ------flight4 end----- -->

                  <!-- Seat................ -->

                  <div class="row d-flex mb-1 gx-0 aircode-show">
                    <!-- <div
                      class="col-md-3 col-4 d-flex justify-content-sm-center fare-detail-mobile"
                    >
                      <span><v-icon size="20">mdi-seat-passenger</v-icon></span>
                      <span style="font-size: 12px; color: gray"
                        >{{ getdepseat(res.Depature) }} Seats</span
                      >
                    </div> -->

                    <div class="col-5 d-lg-none">
                      <div
                        class="price-head d-flex justify-center"
                        style="margin: 0 auto"
                      >
                        <span class="price-currency">{{ res.currency }}</span>
                        <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                        <div id="demo" class="d-flex">
                          <span
                            id="f0"
                            class="price-Amount"
                            style="font-size: 26px; padding-top: 8px"
                            >{{ res.find1 }}</span
                          >
                        </div>
                      </div>
                    </div>
                    <div
                      class="col-5 d-lg-none justify-center flight-search"
                      style="padding: 0; border-radius: 0.5rem; margin: 0 auto"
                    >
                      <!-- <button type="submit" class="btn btn-search" style="
                          width: 100px;
                          height:40px;
                          min-width: 0;
                          min-height: 0;
                        " @click="onewayselect(res, index)">
                        <span class="fw-bold">Select</span>
                      </button> -->
                      <button
                        type="submit"
                        class="btn btn-search"
                        style="
                          width: 120px;
                          min-width: 0;
                          min-height: 0;
                        "
                        @click="flighted3(res, index)"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop2"
                        aria-controls="staticBackdrop"
                      >
                        <span class="fw-bold" style="font-size: 14px"
                          >Flight Details</span
                        >
                      </button>
                    </div>
                    <!-- </div>  -->

                    <div
                      class="col-md-3 col-4 d-none"
                      @click="addmoreData(res, index)"
                      style="font-size: 12px"
                    >
                      {{ res.multi }}
                    </div>

                    <div class="text-center col-4 d-none fare-detail-mobile">
                      <v-menu location="bottom">
                        <template v-slot:activator="{ props }">
                          <div class="d-flex align-center">
                            <v-card-text
                              @click="fareOpen = !fareOpen"
                              style="color: #36c; font-weight: 500"
                              class="pe-0"
                              v-bind="props"
                            >
                              Fare Details
                            </v-card-text>
                            <!-- <v-icon size="24" color="#36c">{{ fareOpen ? "mdi-menu-right" : "mdi-menu-left" }}</v-icon> -->
                          </div>
                        </template>

                        <v-card
                          width="250px"
                          max-height="500px"
                          class="pa-3 m-auto"
                          style="border: 1px solid gray"
                        >
                          <!-- <h5>Fare Details</h5>
                        <hr> -->
                          <div class="d-flex justify-content-between">
                            <div>
                              <p
                                class="m-0"
                                style="font-size: 16px; font-weight: 500"
                              >
                                Total Price
                              </p>
                            </div>
                            <div>
                              <p class="tot-price m-0">
                                {{ res.currency }}
                                {{ res.TotalPrice }}
                              </p>
                            </div>
                          </div>

                          <div class="row mt-2">
                            <div class="col-md-12">
                              <div class="row justify-content-between">
                                <div class="col-6">
                                  <span
                                    style="font-size: 13px; font-weight: 500"
                                    >Passengers</span
                                  >
                                </div>
                                <!-- <div class="col-md-4"></div> -->
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  <span v-if="aduls > 0"
                                    >{{ aduls }} x Adult</span
                                  ><br />
                                  <span v-if="aduls1 > 0"
                                    >{{ aduls1 }} x Child</span
                                  ><br />
                                  <span v-if="aduls2 > 0"
                                    >{{ aduls2 }} x Lap Infant</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="mt-3">
                            <hr class="mt-1 mb-2" />

                            <hr class="mt-1 mb-2" />

                            <div
                              class="p-1 collapse"
                              :class="{ show: fareIcon }"
                              id="collapse-Example"
                            >
                              <div class="row">
                                <div class="col-6" style="font-size: 14px">
                                  Base Price<br />
                                  Taxes & Fees
                                </div>
                                <div
                                  class="col-6"
                                  style="font-size: 14px; text-align: end"
                                >
                                  {{ res.currency }}
                                  {{ res.Baseprice }}<br />
                                  {{ res.currency }}
                                  {{ res.Taxprice }}
                                </div>
                              </div>
                            </div>

                            <!-- <br>
                <p style="font-size: 12px">
                  All fares are quoted in USD .Some airlines may charge bag gage fees.
                  Your credit/debit card may be billed in multiple charges totaling
                  the final total price.
                </p> -->
                          </div>
                        </v-card>
                      </v-menu>
                    </div>
                  </div>

                  <!-- Seat End................ -->
                </div>

                <div
                  class="col-lg-2 d-flex flex-column align-center justify-center select-section-hide1"
                >
                  <div
                    class="d-flex flex-column justify-center"
                    style="margin-top: 35px; margin-bottom: 26px"
                  >
                    <div class="d-flex flex-row justify-center mt-1">
                      <div class="btn-icon">
                        <!-- <span><v-icon size="20">{{ res.Refund == 'Refundable' ? 'mdi-cash' : 'mdi-cash-remove'}}</v-icon></span> -->
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-icon
                              v-bind="props"
                              style="color: gray !important"
                              size="22"
                              justify="center"
                            >
                              {{
                                res.Refund == "Refundable"
                                  ? "mdi-cash"
                                  : "mdi-cash-remove"
                              }}
                            </v-icon>
                          </template>
                          <span>{{ res.Refund }}</span>
                        </v-tooltip>
                        <div>
                          <!-- <span style="
                            font-size: 12px;
                            color: gray;
                            padding-left: 5px;
                          ">{{ res.Refund }}</span> -->
                        </div>
                      </div>

                      <div class="btn-icon">
                        <v-tooltip location="top">
                          <template v-slot:activator="{ props }">
                            <v-icon
                              v-bind="props"
                              style="color: gray !important; margin-left: 15px"
                              size="21"
                            >
                              mdi-seat-passenger
                            </v-icon>
                          </template>
                          <span>{{ classType }}</span>
                        </v-tooltip>
                        <!-- <span><v-icon size="18">mdi-seat-passenger</v-icon></span> -->
                        <!-- <span style="font-size: 12px; color: gray">{{oneway.class}}</span> -->
                      </div>
                      <!-- <v-img src="../assets/money-back.png" style="max-width: 25px;"></v-img>
                        <v-img src="../assets/passenger.png" class="ms-2" style="max-width: 24px;"></v-img> -->
                    </div>
                    <!-- <div class="d-flex justify-center mb-1">
                          <v-img :src="require('@/assets/money-back.png')" style="max-width: 30px;"></v-img>
                          <v-img :src="require('@/assets/passenger.png')" class="ms-2" style="max-width: 24px;"></v-img>
                        </div> -->
                    <div class="price-head d-flex">
                      <span class="price-currency">{{ res.currency }}</span>
                      <!-- <span class="price-Amount d-none">{{ getPriceperson(res.perperson) }}</span> -->
                      <div id="demo" class="d-flex">
                        <span id="f0" class="price-Amount">{{
                          res.find1
                        }}</span>

                        <!-- <div class="d-flex">
                          <span style="
                              font-size: 20px;
                              position: relative;
                              left: 2px;
                              top: 2px;
                            ">.</span>
                          <span id="f1" style="
                              font-size: 18px;
                              font-weight: 600;
                              margin-bottom: 10px;
                              margin-left: 2px;
                              margin-top: 5px;
                            ">{{ res.find2 }}</span>
                        </div> -->
                      </div>
                      <div>
                        <div class="text-center Fare-overlay pt-2 d-none">
                          <v-menu location="start">
                            <template v-slot:activator="{ props }">
                              <v-card-text
                                @click="fareOpen = !fareOpen"
                                style="
                                  color: #36c;
                                  font-weight: 500;
                                  padding: 0;
                                "
                                v-bind="props"
                              >
                                <v-icon size="20" style="color: gray !important"
                                  >mdi-information-outline</v-icon
                                >
                              </v-card-text>
                            </template>

                            <v-card
                              width="300px"
                              max-height="500px"
                              class="pa-3 m-auto"
                              style="border: 1px solid gray"
                            >
                              <!-- <h5>Fare Details</h5>
                                <hr> -->
                              <div class="d-flex justify-content-between">
                                <div>
                                  <p
                                    class="m-0"
                                    style="font-size: 18px; font-weight: 500"
                                  >
                                    Total Price
                                  </p>
                                </div>
                                <div>
                                  <p class="tot-price m-0">
                                    {{ res.currency }}
                                    {{ res.TotalPrice }}
                                  </p>
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-md-12">
                                  <div class="row justify-content-between">
                                    <div class="col-6">
                                      <span
                                        style="
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                        >Passengers</span
                                      >
                                    </div>
                                    <!-- <div class="col-md-4"></div> -->
                                    <div
                                      class="col-6"
                                      style="font-size: 14px; text-align: end"
                                    >
                                      <span v-if="aduls > 0"
                                        >{{ aduls }} x Adult</span
                                      ><br />
                                      <span v-if="aduls1 > 0"
                                        >{{ aduls1 }} x Child</span
                                      ><br />
                                      <span v-if="aduls2 > 0"
                                        >{{ aduls2 }} x Lap Infant</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mt-1">
                                <hr class="mt-1" />
                                <!-- <div class="d-flex align-end mb-2">
                                    <span class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                                      aria-expanded="true" aria-controls="collapse-Example" style="
                                        padding-right: 5px;
                                        cursor: pointer;
                                        font-size: 14px;
                                        letter-spacing: 1px;
                                      ">
                                      <a href="#">Fare Summary</a></span>

                                    <v-icon size="18" class="not-collapsed" data-bs-toggle="collapse" href="#collapse-Example"
                                      aria-expanded="true" aria-controls="collapse-Example" v-model="fareIcon" style="
                                        color: 0D6EFD;
                                        cursor: pointer;
                                        overflow-anchor: none;
                                      ">{{
                                        fareIcon
                                          ? "mdi-chevron-up-box-outline"
                                          : "mdi-chevron-down-box-outline"
                                      }}</v-icon>
                                  </div> -->

                                <hr class="mt-1 mb-2" />

                                <div
                                  class="p-1 collapse"
                                  :class="{ show: fareIcon }"
                                  id="collapse-Example"
                                >
                                  <div class="row">
                                    <div class="col-6" style="font-size: 14px">
                                      Base Price<br />
                                      Taxes & Fees
                                    </div>
                                    <div
                                      class="col-6"
                                      style="font-size: 14px; text-align: end"
                                    >
                                      {{ res.currency }}
                                      {{ res.Baseprice }}<br />
                                      {{ res.currency }}
                                      {{ res.Taxprice }}
                                    </div>
                                  </div>
                                </div>

                                <!-- <br /> -->
                                <!-- <p style="font-size: 12px">
                                    All fares are quoted in USD .Some airlines may
                                    charge bag gage fees. Your credit/debit card may be
                                    billed in multiple charges totaling the final total
                                    price.
                                  </p> -->
                              </div>
                            </v-card>
                          </v-menu>
                        </div>
                      </div>
                    </div>

                    <!-- <span style="
                        font-size: 14px;
                        font-weight: 500;
                        margin-top: -10px;
                        margin-bottom: 10px;
                      ">Per Person</span> -->

                    <div
                      class="flight-search"
                      style="padding: 0; border-radius: 0.5rem; margin: 0 auto"
                    >
                      <button
                        type="submit"
                        class="btn btn-search"
                        style="
                          width: 140px;
                          min-width: 0;
                          min-height: 0;
                        "
                        @click="flighted3(res, index)"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#staticBackdrop2"
                        aria-controls="staticBackdrop"
                      >
                        <span class="fw-bold">Flight Details</span>
                      </button>
                      <!-- <button type="submit" class="btn btn-search" style="
                          width: 140px;
                          height: 40px;
                          min-width: 0;
                          min-height: 0;
                        " @click="flighted3(res, index)" @click.stop="drawer = !drawer">
                        <span class="fw-bold">Flight Details</span>
                      </button> -->
                    </div>
                    <!-- <v-btn
                      class="select_btn"
                      color="#002d5b "
                      width="140px"
                      height="40px"
                      style="color: white"
                      @click.prevent="selected(res, index)"
                      >Select</v-btn
                    > -->
                    <!-- <div class="d-flex align-center justify-content-sm-center mb-2 pt-2"
                      @click="flighted3(res, index),drawer = !drawer" data-bs-toggle="offcanvas" data-bs-target="#offcanvasWithBackdrop" aria-controls="offcanvasWithBackdrop">
                      <span style="
                          color: #36c;
                          font-size: 12px;
                          margin-top: 2px;
                          cursor: pointer;
                        ">
                        Flight Details</span>
                    </div> -->
                  </div>

                  <!-- <div> -->
                  <!-- <p class="tect-center"
                      style="color: #36c;font-size: 14px; font-weight:600; margin-top: 2px;cursor: pointer;">
                      Price Details</p> -->

                  <!-- </div> -->
                </div>
              </div>
            </v-card>
          </div>
            <div class="drawer-section">
              <div
                class="offcanvas offcanvas-end"
                data-bs-backdrop="static"
                tabindex="-1"
                id="staticBackdrop2"
                aria-labelledby="staticBackdropLabel"
              >
                <div class="offcanvas-header d-flex justify-content-between">
                  <h5 class="offcanvas-title" id="staticBackdrop2">
                    Flight Details
                  </h5>
                  <button
                    type="button"
                    @click="closeModal()"
                    class="text-reset"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                    color="white"
                  >
                    <v-icon>mdi-close</v-icon>
                  </button>
                </div>
                <div class="offcanvas-body pt-0" ref="offcanvasBody2">
                  <v-divider></v-divider>
                  <div
                    v-for="(flightDatas, index) of flightdetailsData2"
                    :key="index"
                  >{{ console.log(flightdetailsData2,'datasaaassasa') }}
                    <div class="d-flex flex-row">
                      <div class="col-6 px-2 mx-2 mt-1">
                        <div class="price-head d-flex" style="margin: 0 auto">
                          <span
                            class="price-currency"
                            style="font-size: 26px"
                            >{{ flightDatas.currency }}</span
                          >
                          <!-- <span class="price-Amount">{{ res.perperson }}</span> -->

                          <div id="demo" class="d-flex">
                            <span
                              id="f0"
                              class="price-Amount"
                              style="font-size: 30px; padding-top: 6px"
                              >{{ flightDatas.find1 }}</span
                            >

                            <div class="d-flex">
                              <span
                                style="
                                  font-size: 20px;
                                  position: relative;
                                  left: 2px;
                                  top: 9px;
                                "
                                >.</span
                              ><span
                                id="f1"
                                style="
                                  font-size: 18px;
                                  font-weight: 600;
                                  margin-left: 2px;
                                  margin-top: 12px;
                                "
                                >{{ flightDatas.find2 }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-6 d-flex justify-center align-items-center"
                      >
                        <span
                          >Cabin:
                          <span class="fw-bold">{{ classType }}</span></span
                        >
                      </div>
                    </div>
                    <div class="ps-3">
                      <div class="row p-2">
                        <div class="col-1 pe-0 ps-1" style="margin: auto 0">
                          <v-img
                            :src="flightDatas.Flight1[0].MarketingCarrier.logo"
                            width="100%"
                            style="margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightDatas.muticityData[0].from }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{
                              getdatedFlight(
                                flightDatas.Flight1[0].Departure.Date
                              )
                            }}
                            -
                            <span class="fw-bold">{{
                              getflightdep(flightDatas.Flight1[0])
                            }}</span></span
                          >
                        </div>
                        <div class="col-3 mt-2 ps-0">
                          <v-img
                             :src="require('../assets/img/flightIcon.png')"
                            width="22%"
                            style="transform: rotate(45deg); margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightDatas.muticityData[0].to }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{
                              getdatedFlight1(
                                flightDatas.Flight1[
                                  flightDatas.Flight1.length - 1
                                ].Arrival.Date
                              )
                            }}
                            -
                            <span class="fw-bold">{{
                              getflightarr(
                                flightDatas.Flight1[
                                  flightDatas.Flight1.length - 1
                                ]
                              )
                            }}</span></span
                          >
                        </div>
                      </div>
                      <div class="row p-2">
                        <div class="col-1 pe-0 ps-1" style="margin: auto 0">
                          <v-img
                            :src="flightDatas.Flight2[0].MarketingCarrier.logo"
                            width="100%"
                            style="margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightDatas.muticityData[1].from }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{
                              getdatedFlight(
                                flightDatas.Flight2[0].Departure.Date
                              )
                            }}
                            -
                            <span class="fw-bold">{{
                              getflightdep(flightDatas.Flight2[0])
                            }}</span></span
                          >
                        </div>
                        <div class="col-3 mt-2 ps-0">
                          <v-img
                             :src="require('../assets/img/flightIcon.png')"
                            width="22%"
                            style="transform: rotate(45deg); margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightDatas.muticityData[1].to }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{
                              getdatedFlight1(
                                flightDatas.Flight2[
                                  flightDatas.Flight2.length - 1
                                ].Arrival.Date
                              )
                            }}
                            -
                            <span class="fw-bold">{{
                              getflightarr(
                                flightDatas.Flight2[
                                  flightDatas.Flight2.length - 1
                                ]
                              )
                            }}</span></span
                          >
                        </div>
                      </div>
                      <div v-if="flightDatas.Flight3" class="row p-2">
                        <div class="col-1 pe-0 ps-1" style="margin: auto 0">
                          <v-img
                            :src="flightDatas.Flight3[0].MarketingCarrier.logo"
                            width="100%"
                            style="margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightDatas.muticityData[2].from }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{
                              getdatedFlight(
                                flightDatas.Flight3[0].Departure.Date
                              )
                            }}
                            -
                            <span class="fw-bold">{{
                              getflightdep(flightDatas.Flight3[0])
                            }}</span></span
                          >
                        </div>
                        <div class="col-3 ps-0 mt-2">
                          <v-img
                             :src="require('../assets/img/flightIcon.png')"
                            width="22%"
                            style="transform: rotate(45deg); margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightDatas.muticityData[2].to }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{
                              getdatedFlight1(
                                flightDatas.Flight3[
                                  flightDatas.Flight3.length - 1
                                ].Arrival.Date
                              )
                            }}
                            -
                            <span class="fw-bold">{{
                              getflightarr(
                                flightDatas.Flight3[
                                  flightDatas.Flight3.length - 1
                                ]
                              )
                            }}</span></span
                          >
                          <!-- <span v-else class="ms-2" style="font-size:14px;">{{ getdatedFlight1(res.Flight3[res.Flight3.length-1].Arrival.Date) }} - <span class="fw-bold">{{ getflightarr(res.Flight3[0]) }}</span></span> -->
                        </div>
                      </div>
                      <div v-if="flightDatas.Flight4" class="row p-2">
                        <div class="col-1 pe-0 ps-1" style="margin: auto 0">
                          <v-img
                            :src="flightDatas.Flight4[0].MarketingCarrier.logo"
                            width="100%"
                            style="margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightDatas.muticityData[3].from }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{
                              getdatedFlight(
                                flightDatas.Flight4[0].Departure.Date
                              )
                            }}
                            -
                            <span class="fw-bold">{{
                              getflightdep(flightDatas.Flight4[0])
                            }}</span></span
                          >
                        </div>
                        <div class="col-3 ps-0 mt-2">
                          <v-img
                             :src="require('../assets/img/flightIcon.png')"
                            width="22%"
                            style="transform: rotate(45deg); margin: auto"
                          ></v-img>
                        </div>
                        <div class="col-4 ps-0 pe-0 text-left fromTrip">
                          <span class="ms-2 from" style="font-weight: 500">
                            {{ flightDatas.muticityData[3].to }}</span
                          ><br />
                          <span class="ms-2 to" style="font-weight: 500"
                            >{{
                              getdatedFlight1(
                                flightDatas.Flight4[
                                  flightDatas.Flight4.length - 1
                                ].Arrival.Date
                              )
                            }}
                            -
                            <span class="fw-bold">{{
                              getflightarr(
                                flightDatas.Flight4[
                                  flightDatas.Flight4.length - 1
                                ]
                              )
                            }}</span></span
                          >
                        </div>
                      </div>
                    </div>
                    <v-card class="mt-4">
                      <v-tabs v-model="tab" align-tabs="center" stacked>
                        <v-tab value="tab-1" class="m-0">
                          <v-icon style="transform: rotate(45deg)"
                            >mdi-airplane</v-icon
                          >
                          Overview
                        </v-tab>

                        <!-- <v-tab value="tab-2" v-if="getbaggageMulti(flightDatas)"> -->
                          <v-tab value="tab-2">
                          <v-icon>mdi-bag-checked</v-icon>
                          Baggage
                        </v-tab>

                        <v-tab value="tab-3" class="m-0">
                          <v-icon>mdi-ticket-account</v-icon>
                          Fare Details
                        </v-tab>
                      </v-tabs>

                      <v-card-text class="pt-0">
                        <keep-alive>
                          <v-window v-model="tab">
                            <div v-if="tab == 'tab-1'">
                              <v-card
                                style="border: 1px solid grey"
                                class="mb-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    Flight1
                                  </h6>
                                  <span class="fw-bold">
                                    {{
                                      getdepNameMulti(
                                        flightDatas.muticityData[0]
                                      )
                                    }}
                                    -
                                    {{
                                      getarrNameMulti(
                                        flightDatas.muticityData[0]
                                      )
                                    }}</span
                                  >
                                </div>

                                <v-divider></v-divider>
                                <div
                                  v-for="(data, index) of flightDatas.Flight1"
                                  :key="index"
                                >
                                  {{ console.log(res, "dataaaaa...2") }}
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-4 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="
                                      flightDatas.Flight1.length - 1 !== index
                                    "
                                    style="
                                      position: relative;
                                      left: 13%;
                                      background-color: #63AB45;
                                      color: white;
                                    "
                                    class="p-1 layoverTime"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          :src="require('../assets/sandClock.png')"
                                          width="15px"
                                          style="padding-top: 8px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ flightDatas.layoverTimes1[index] }}
                                          - LayoverTime
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                              <v-card
                                style="border: 1px solid grey"
                                class="my-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    Flight2
                                  </h6>
                                  <span class="fw-bold">
                                    {{
                                      getdepNameMulti(
                                        flightDatas.muticityData[1]
                                      )
                                    }}
                                    -
                                    {{
                                      getarrNameMulti(
                                        flightDatas.muticityData[1]
                                      )
                                    }}</span
                                  >
                                </div>
                                <v-divider></v-divider>
                                <div
                                  v-for="(data, index) of flightDatas.Flight2"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-4 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="
                                      flightDatas.Flight2.length - 1 !== index
                                    "
                                    style="
                                      position: relative;
                                      left: 13%;
                                      background-color: #63AB45;
                                      color: white;
                                    "
                                    class="p-1 layoverTime"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          :src="require('../assets/sandClock.png')"
                                          width="15px"
                                          style="padding-top: 8px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ flightDatas.layoverTimes2[index] }}
                                          - LayoverTime
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                              <v-card
                                v-if="flightDatas.Flight3"
                                style="border: 1px solid grey"
                                class="my-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    Flight3
                                  </h6>
                                  <span class="fw-bold">
                                    {{
                                      getdepNameMulti(
                                        flightDatas.muticityData[2]
                                      )
                                    }}
                                    -
                                    {{
                                      getarrNameMulti(
                                        flightDatas.muticityData[2]
                                      )
                                    }}</span
                                  >
                                </div>
                                <v-divider></v-divider>
                                <div
                                  v-for="(data, index) of flightDatas.Flight3"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-4 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span
                                          class="fw-600"
                                          style="font-size: 16px"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="
                                      flightDatas.Flight3.length - 1 !== index
                                    "
                                    style="
                                      position: relative;
                                      left: 13%;
                                      background-color: #63AB45;
                                      color: white;
                                    "
                                    class="p-1 layoverTime"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          :src="require('../assets/sandClock.png')"
                                          width="15px"
                                          style="padding-top: 8px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ flightDatas.layoverTimes3[index] }}
                                          - LayoverTime
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                              <v-card
                                v-if="flightDatas.Flight4"
                                style="border: 1px solid grey"
                                class="my-4"
                              >
                                <div class="d-flex flex-row align-items-center">
                                  <h6 class="p-2 mb-0" style="font-size: 14px">
                                    Flight4
                                  </h6>
                                  <span class="fw-bold">
                                    {{
                                      getdepNameMulti(
                                        flightDatas.muticityData[3]
                                      )
                                    }}
                                    -
                                    {{
                                      getarrNameMulti(
                                        flightDatas.muticityData[3]
                                      )
                                    }}</span
                                  >
                                </div>
                                <v-divider></v-divider>
                                <div
                                  v-for="(data, index) of flightDatas.Flight4"
                                  :key="index"
                                >
                                  <v-timeline
                                    truncate-line="both"
                                    class="ms-4 my-4"
                                  >
                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                      justify="left"
                                    >
                                      <template v-slot:opposite>
                                        <div>
                                          <span
                                            class="f-size-14 airline-date"
                                            >{{
                                              getdatedFlight(
                                                data.Departure.Date
                                              )
                                            }}</span
                                          >
                                          <span
                                            class="f-size-16 airline-time fw-bold"
                                            style="margin-left: 5px"
                                            >{{ getflightdep(data) }}</span
                                          >
                                        </div>
                                      </template>
                                      <div>
                                        <span class="fw-600 f-size-16"
                                          >{{
                                            data.Departure.AirportCode
                                          }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Departure.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      justify="left"
                                    >
                                      <template v-slot:icon>
                                        <v-avatar
                                          :image="data.MarketingCarrier.logo"
                                          style="background: white"
                                        ></v-avatar>
                                      </template>
                                      <template v-slot:opposite>
                                        <div>
                                          <p class="f-size-14 airline-name m-0">
                                            {{ data.MarketingCarrier.Name }}

                                            <br />
                                            <span class="f-size-12 airline-id">
                                              {{
                                                data.MarketingCarrier.AirlineID
                                              }}
                                              -
                                              {{
                                                data.MarketingCarrier
                                                  .FlightNumber
                                              }}
                                            </span>
                                          </p>
                                        </div>
                                      </template>
                                      <div>
                                        <p class="m-0" style="font-size: 14px">
                                          {{
                                            data.FlightDetail.FlightDuration
                                              .Value
                                          }}
                                        </p>
                                      </div>
                                    </v-timeline-item>

                                    <v-timeline-item
                                      class="my-2"
                                      size="x-small"
                                    >
                                      <template v-slot:opposite>
                                        <span class="f-size-14 airline-date">{{
                                          getdatedFlight1(data.Arrival.Date)
                                        }}</span>
                                        <span
                                          class="f-size-16 airline-time fw-bold"
                                          style="margin-left: 5px"
                                          >{{ getflightarr(data) }}</span
                                        >
                                      </template>
                                      <div>
                                        <span class="fw-600 f-size-16"
                                          >{{ data.Arrival.AirportCode }} </span
                                        ><br />
                                        <p class="m-0" style="font-size: 12px">
                                          {{ data.Arrival.AirportName }}
                                        </p>
                                      </div>
                                    </v-timeline-item>
                                  </v-timeline>

                                  <v-card
                                    v-if="
                                      flightDatas.Flight4.length - 1 !== index
                                    "
                                    style="
                                      position: relative;
                                      left: 13%;
                                      background-color: #63AB45;
                                      color: white;
                                    "
                                    class="p-1 layoverTime"
                                  >
                                    <div class="d-flex justify-center">
                                      <div class="">
                                        <v-img
                                          :src="require('../assets/sandClock.png')"
                                          width="15px"
                                          style="padding-top: 8px"
                                        ></v-img>
                                      </div>
                                      <div>
                                        <p
                                          class="m-0 ms-2"
                                          style="font-size: 14px"
                                        >
                                          {{ flightDatas.layoverTimes4[index] }}
                                          - LayoverTime
                                        </p>
                                      </div>
                                    </div>
                                  </v-card>
                                </div>
                              </v-card>
                            </div>

                            <div v-if="tab == 'tab-2'">
                              <div v-if="flightDatas.Flight1">
                                <div style="font-size: 15px; font-weight: 500">
                                  {{ flightDatas.muticityData[0].from }} -
                                  {{ flightDatas.muticityData[0].to }}
                                </div>
                                <!-- <div v-if="flightDatas.BaggageData1.PieceAllowance.TotalQuantity > 0"></div> -->
                                <div class="d-flex ms-2 mt-2">
                                  <div style="color: gray !important">
                                    <v-icon color="gray">
                                      mdi-bag-suitcase
                                    </v-icon>
                                  </div>
                                  <div
                                    style="
                                      font-size: 14px;
                                      font-weight: 400;
                                      margin-left: 5px;
                                    "
                                  >
                                    <span> Checked Baggage:</span>
                                    <span class="ms-1">
                                      {{
                                        flightDatas.BaggageData1.PieceAllowance
                                          .TotalQuantity || 0
                                      }}
                                      {{
                                        flightDatas.BaggageData1.PieceAllowance
                                          .TotalQuantity > 1
                                          ? "Pieces"
                                          : "Piece"
                                      }}
                                    </span>
                                  </div>
                                </div>

                                <div class="d-flex ms-2 mt-2">
                                  <div style="color: gray !important">
                                    <v-icon color="gray">
                                      mdi-bag-checked
                                    </v-icon>
                                  </div>
                                  <div
                                    style="
                                      font-size: 14px;
                                      font-weight: 400;
                                      margin-left: 5px;
                                    "
                                  >
                                    <span>Cabin Baggage:</span>
                                    <span class="ms-1">
                                      {{
                                        flightDatas.BaggageData1.PieceAllowance
                                          .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                      }}
                                    </span>
                                  </div>
                                </div>
                                
                                <!-- <div v-if="flightDatas.BaggageData1.PieceAllowance.TotalQuantity == 0">
                                  <p class="mt-1">The Baggage Of this Flight is Zero</p>
                                </div> -->
                              </div>

                              <br />
                              <hr />
                              <br />

                              <!-- -------flight2------ -->

                              <div v-if="flightDatas.Flight2">
                                <div style="font-size: 15px; font-weight: 500">
                                  {{ flightDatas.muticityData[1].from }} -
                                  {{ flightDatas.muticityData[1].to }}
                                </div>
                                <!-- <div v-if="flightDatas.BaggageData2.PieceAllowance.TotalQuantity > 0"></div> -->
                                <div class="d-flex ms-2 mt-2">
                                  <div style="color: gray !important">
                                    <v-icon color="gray">
                                      mdi-bag-suitcase
                                    </v-icon>
                                  </div>
                                  <div
                                    style="
                                      font-size: 14px;
                                      font-weight: 400;
                                      margin-left: 5px;
                                    "
                                  >
                                    <span> Checked Baggage:</span>
                                    <span class="ms-1">
                                      {{
                                        flightDatas.BaggageData2.PieceAllowance
                                          .TotalQuantity || 0
                                      }}
                                      {{
                                        flightDatas.BaggageData2.PieceAllowance
                                          .TotalQuantity > 1
                                          ? "Pieces"
                                          : "Piece"
                                      }}
                                    </span>
                                  </div>
                                </div>

                                <div class="d-flex ms-2 mt-2">
                                  <div style="color: gray !important">
                                    <v-icon color="gray">
                                      mdi-bag-checked
                                    </v-icon>
                                  </div>
                                  <div
                                    style="
                                      font-size: 14px;
                                      font-weight: 400;
                                      margin-left: 5px;
                                    "
                                  >
                                    <span>Cabin Baggage:</span>
                                    <span class="ms-1">
                                      {{
                                        flightDatas.BaggageData1.PieceAllowance
                                          .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                      }}
                                    </span>
                                  </div>
                                </div>
                                
                                 <!-- <div v-if="flightDatas.BaggageData2.PieceAllowance.TotalQuantity == 0">
                                  <p class="mt-1">The Baggage Of this Flight is Zero</p>
                                </div> -->
                              </div>

                              <br />
                              <hr v-if="flightDatas.Flight3" />
                              <br />

                              <!-- -----flight3---- -->

                              <div v-if="flightDatas.Flight3">
                                <div style="font-size: 15px; font-weight: 500">
                                  {{ flightDatas.muticityData[2].from }} -
                                  {{ flightDatas.muticityData[2].to }}
                                </div>
                                <!-- <div v-if="flightDatas.BaggageData3.PieceAllowance.TotalQuantity > 0"> </div> -->
                                <div class="d-flex ms-2 mt-2">
                                  <div style="color: gray !important">
                                    <v-icon color="gray">
                                      mdi-bag-suitcase
                                    </v-icon>
                                  </div>
                                  <div
                                    style="
                                      font-size: 14px;
                                      font-weight: 400;
                                      margin-left: 5px;
                                    "
                                  >
                                    <span> Checked Baggage:</span>
                                    <span class="ms-1">
                                      {{
                                        flightDatas.BaggageData3.PieceAllowance
                                          .TotalQuantity || 0
                                      }}
                                      {{
                                        flightDatas.BaggageData3.PieceAllowance
                                          .TotalQuantity > 1
                                          ? "Pieces"
                                          : "Piece"
                                      }}
                                    </span>
                                  </div>
                                </div>

                                <div class="d-flex ms-2 mt-2">
                                  <div style="color: gray !important">
                                    <v-icon color="gray">
                                      mdi-bag-checked
                                    </v-icon>
                                  </div>
                                  <div
                                    style="
                                      font-size: 14px;
                                      font-weight: 400;
                                      margin-left: 5px;
                                    "
                                  >
                                    <span>Cabin Baggage:</span>
                                    <span class="ms-1">
                                      {{
                                        flightDatas.BaggageData1.PieceAllowance
                                          .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                      }}
                                    </span>
                                  </div>
                                </div>
                               
                                 <!-- <div v-if="flightDatas.BaggageData3.PieceAllowance.TotalQuantity == 0">
                                  <p class="mt-1">The Baggage Of this Flight is Zero</p>
                                </div> -->
                              </div>

                              <br />
                              <hr v-if="flightDatas.Flight4"/>
                              <br v-if="flightDatas.Flight4"/>

                              <!-- ------flight4----- -->
                              <div v-if="flightDatas.Flight4">
                                <div style="font-size: 15px; font-weight: 500">
                                  {{ flightDatas.muticityData[3].from }} -
                                  {{ flightDatas.muticityData[3].to }}
                                </div>
                                <!-- <div v-if="flightDatas.BaggageData4.PieceAllowance.TotalQuantity > 0"></div> -->
                                <div class="d-flex ms-2 mt-2">
                                  <div style="color: gray !important">
                                    <v-icon color="gray">
                                      mdi-bag-suitcase
                                    </v-icon>
                                  </div>
                                  <div
                                    style="
                                      font-size: 14px;
                                      font-weight: 400;
                                      margin-left: 5px;
                                    "
                                  >
                                    <span> Checked Baggage:</span>
                                    <span class="ms-1">
                                      {{
                                        flightDatas.BaggageData4.PieceAllowance
                                          .TotalQuantity || 0
                                      }}
                                      {{
                                        flightDatas.BaggageData4.PieceAllowance
                                          .TotalQuantity > 1
                                          ? "Pieces"
                                          : "Piece"
                                      }}
                                    </span>
                                  </div>
                                </div>

                                <div class="d-flex ms-2 mt-2">
                                  <div style="color: gray !important">
                                    <v-icon color="gray">
                                      mdi-bag-checked
                                    </v-icon>
                                  </div>
                                  <div
                                    style="
                                      font-size: 14px;
                                      font-weight: 400;
                                      margin-left: 5px;
                                    "
                                  >
                                    <span>Cabin Baggage:</span>
                                    <span class="ms-1">
                                      {{
                                        flightDatas.BaggageData1.PieceAllowance
                                          .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                      }}
                                    </span>
                                  </div>
                                </div>
                                
                                <!-- <div v-if="flightDatas.BaggageData1.PieceAllowance.TotalQuantity == 0">
                                  <p class="mt-1">The Baggage Of this Flight is Zero</p>
                                </div> -->
                              </div>
                            </div>

                            <div v-if="tab === 'tab-3'">
                              <div class="d-flex justify-content-between">
                                <div>
                                  <p
                                    class="m-0"
                                    style="font-size: 18px; font-weight: 600"
                                  >
                                    Total Price
                                  </p>
                                </div>
                                <div>
                                  <p
                                    class="tot-price m-0 fw-bold"
                                    style="font-size: 18px"
                                  >
                                    {{ flightDatas.currency }}
                                    {{ flightDatas.TotalPrice }}
                                  </p>
                                </div>
                              </div>

                              <div class="row mt-2">
                                <div class="col-md-12">
                                  <div class="row justify-content-between">
                                    <div class="col-6">
                                      <span
                                        style="
                                          font-size: 14px;
                                          font-weight: 500;
                                        "
                                        >Passengers</span
                                      >
                                    </div>
                                    <!-- <div class="col-md-4"></div> -->
                                    <div
                                      class="col-6"
                                      style="font-size: 14px; text-align: end"
                                    >
                                      <span v-if="aduls > 0"
                                        >{{ aduls }} x Adult</span
                                      ><br />
                                      <span v-if="aduls1 > 0"
                                        >{{ aduls1 }} x Child</span
                                      ><br />
                                      <span v-if="aduls2 > 0"
                                        >{{ aduls2 }} x Lap Infant</span
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="mt-1">
                                <hr class="mt-1 mb-2" />
                                <hr class="mt-1 mb-2" />

                                <div
                                  class="p-1 collapse"
                                  :class="{ show: fareIcon }"
                                  id="collapse-Example"
                                >
                                  <div class="row d-flex justify-content-between">
                                    <div class="col-6" style="font-size: 14px">
                                      Base Price<br />
                                      Taxes & Fees
                                    </div>
                                    <div class="col-4 d-flex flex-column pe-2">
                                      <div class="row">
                                        <div class="col-5 pe-0 ps-3 text-md-end text-center">
                                          {{ flightDatas.currency }}
                                        </div>
                                        <div class="col-7 text-end ps-0">
                                          {{ flightDatas.Baseprice }}
                                        </div>
                                      </div>
                                      <div class="row">
                                        <div class="col-5 pe-0 ps-3 text-md-end text-center">
                                          {{ flightDatas.currency }}
                                        </div>
                                        <div class="col-7 text-end ps-md-2 ps-0">
                                          {{ flightDatas.Taxprice }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </v-window>
                        </keep-alive>
                      </v-card-text>
                    </v-card>
                  </div>
                </div>
                <div
                  class="offcanvas-footer flight-search p-2"
                  style="margin: auto"
                >
                  <button
                    type="submit "
                    class="btn btn-search"
                    style="
                      width: 300px;
                      min-width: 0;
                      min-height: 0;
                    "
                    @click="selected(flightdetailsData2, index)"
                  >
                    <span class="fw-bold" style="font-size: 16px"
                      >Book This Flight</span
                    >
                  </button>
                </div>
              </div>
            </div>
          
        </div>
      </div>
    </div>
  </div>


  <div class="toast" :class="isNotActive ? 'active border-danger' : ''">
        <div class="toast-content">
          <i class="bi bi-x-lg toast-check" :class="isNotActive ? 'bg-danger' : 'bg-danger'"></i>
          <div class="message">
            <!-- <span class="message-text text-1">Please Enter Correct Details</span> -->
            <!-- <span class="message-text text-1">{{ ErrorMessage }}</span> -->
            <span class="message-text text-2" style="font-size:16px; font-weight: 500; color:black;">
              Please enter valid details</span>
          </div>
        </div>

        <i class="bi bi-x-lg toast-close" @click="closeToast"></i>
        <div class="progress" :class="isNotActive ? 'active' : ''" style="color:#ff0000;"></div>
    </div>
  <!-- <div
    v-if="alert"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    class="toast bg-error"
    data-bs-autohide="false"
    style="display: block !important; z-index: 9999 !important; top: 20%"
  >
    <div class="toast-body d-flex justify-content-between">
      Please Fill Your Details
      <button
        type="button"
        @click="alert = false"
        class="text-reset"
        data-bs-dismiss="toast"
        aria-label="Close"
        color="white"
      >
        <v-icon>mdi-close</v-icon>
      </button>
    </div>
  </div> -->

  <div v-if="searchloader">
    <loader></loader>
  </div>

  <div v-if="dataLoader">
    <pageLoader></pageLoader>
  </div>
</template>

<script>
import loader from "@/components/loaderComponent.vue";
import multicityService from "@/service/multicityService";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";

import axios from "axios";
const totalPricefilter = require("lodash");

import pageLoader from "@/components/loaderComponent.vue";
// import * as bootstrap from 'bootstrap';

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  components: {
    VueDatePicker,
    // TabView,
    // TabPanel,
    // AutoComplete,
    loader,
    // pageError,
    pageLoader,
  },

  data() {
    return {
      showSpinner:false,
      alert: false,
      isFilter: false,
      openOffCanvas: false,
      tab: null,
      flightData: [],

      isNotActive:false,
      // pageErrorData:false,
      drawer: null,
      dialog1: true,
      headerStyles: ["headerCom", "headerCom1", "headerCom2", "headerCom3"],
      footerStyles: ["footerCom", "footerCom1", "footerCom2", "footerCom3"],
      strResult: "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ",
      selectedHeader: "headerCom1",
      selectedFooter: "footerCom",

      inputCity: "",
      inputCityto: "",
      typedrop: false,
      typedrop1: false,
      city: [],
      city1: [],

      dataLoader: false,
      dialog: false,
      fareOpen: false,
      multiCalender: true,
      totaladult: 0,
      classSelect: false,
      triggerRadio: false,
      vj: "",
      modifySection: false,
      FlightDetail: false,
      FlightDetail1: false,
      dateFormat: " dd MMM ",
      detail: {
        from: "",
        to: "",
        dedate: "",
        redate: "",
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },
      items: [],
      airlogodata: [],
      oneway1: false,
      roundtrip: false,
      multitrip: false,
      multicityTotalData: [],
      searchresult: false,
      searchloader: true,
      isIcon1: true,
      fareIcon: true,
      isIcon: true,
      date1: "",
      date2: "",
      value: "1",
      class: "Business",
      travels: false,
      red: 1,
      red1: 1,
      // paxhide: true,

      roundModify: true,
      onewayModify: false,
      multiModify: false,

      find1: [],
      find2: [],

      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      offerData5: [],

      returnData: [],
      Oneway: false,
      rount: true,
      multicity: false,
      airData: [],
      flight: [],
      flight1: [],
      flightTime: [],
      flightseg: [],
      segkey: [],
      SegmentKey: [],
      arriv: [],
      dep: [],
      splitValues: [],
      splitValues1: [],
      seats: [],
      seg: [],
      uniqueId: [],
      Journey: [],
      Journey1: [],
      flightsegmet: [],
      flightsegmetkey: [],
      datas: [],
      journeyData: [],
      changevalue: [],
      steps: null,
      value1: [0, 100],
      value2: [0, 24],
      datasource: [],
      air: [],
      codeData: [],
      airnam: [],
      airnumber: [],
      airflight: [],
      flightprice: [],
      price: [],
      flightdate: [],
      time: [],
      arrivaltime: [],
      Renuvable: [],
      vasan: [],
      vasan1: [],
      totalprice: [],
      segmentref: [],
      stop: [],
      time1: [],
      swapData: false,
      multicityData: [],
      multicityData1: [],
      aircode: [],
      depcode: [],
      airname: [],
      depname: [],
      airtime: [],
      deptime: [],
      airid: [],
      airnum: [],
      airstop: [],
      offerpay: [],
      airstime: [],
      airfname: [],
      airdate: [],
      depdate: [],
      airref: [],
      airprice: [],
      sortingPrice: [],
      depnamedate: [],
      localData: [],
      rountresult: [],
      filterData: [],
      onewayresulted: [],
      onewayfilterData: [],
      multiFilterData: [],
      multishowData: [],
      routtime: [],
      airportname: "",
      traveltime: "",
      Adult: [],
      Child: [],
      Infrant: [],
      maximum: null,
      minimum: null,
      countries: [{ name: "Australia", code: "AU" }],
      selectedCountry: "",
      loading: false,
      fromAirports: [],
      airitems: [],

      aduls: 1,
      aduls1: 0,
      aduls2: 0,
      addition: 0,
      classType: "Economy",

      oneway: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      tripData: {
        from: null,
        to: null,
        dedate: null,
        redate: null,
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "",
      },

      data_id: "",
      roundOfferId: [],
      airlineList: [],
      airlineArr: [],
      oneway_id: "",
      onewayOfferId: [],

      responseData: [],
      clicked1: false,
      clicked2: false,
      clicked3: false,
      datePickerIsOpen: false,

      fromErrorMessage: false,
      toErrorMessage: false,
      fromDateError: false,
      toDateError: false,

      disButton: false,
      loadSearch: false,

      hoveredName: false,
      hoverDetail: "Hello....",

      storefilterData: [],

      showNonStop: false,
      showOneStop: false,
      showOneplusStop: false,
      recperperson: "",
      recCurrecy: "",
      shotperperson: "",
      layperperson: "",
      shorCurrecy: "",
      active: false,
      active1: false,
      active2: false,
      active3: false,
      NonStopFil: true,
      OneStopFil: true,
      OnePlusFil: true,
      RefundableData: true,
      nonRefundableData: true,
      radiosData: 0,
      radioData1: 0,
      radioData2: 0,
      radioData3: 0,
      radioData4: 0,
      nonStop: [],
      oneStop: [],
      onePlusStop: [],
      flightdetailsData: [],
      flightdetailsData1: [],
      flightdetailsData2: [],
      filNonstop: [],
      filOneStop: [],
      filOnePlusStop: [],
      concatStops: [],
      totalpassenger: [],
      onewaymultiData: [],
      rountmultiData: [],
      airCanada: false,
      qatarAirline: false,
      // layoverTimes: [],
      // layoverTimes1: [],
      // input: [
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     clicked3:false,
      //   },
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //     clicked3:false,
      //   },
      // ],

      input: [
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter: false,
          maximumletter1: false,
          clicked3: false,
        },
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          maximumletter1: false,
          clicked3: false,
        },
      ],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight5: [],
      resulted: [],
      timeFilterData1: false,
      timeFilterData2: false,
      timeFilterData3: false,
      timeFilterArrData1: false,
      timeFilterArrData2: false,
      timeFilterArrData3: false,
      multiplesameData: [],
      multiDepatureData: [],
      multireturnData: [],
      multiflight1: [],
      multiflight2: [],
      multiflight3: [],
      multiflight4: [],

      multiCityForm: false,
      returnForm: false,
      onewayForm: false,

      timearrFilterData1: false,
      timearrFilterData2: false,
      timearrFilterData3: false,
      timearrFilterArrData1: false,
      timearrFilterArrData2: false,
      timearrFilterArrData3: false,
      totalMinutesT1: "",
      totalMinutesT2: "",
      preferCurrency: "",
      metaCurrency: "",
      countryCodeData: "",

      onewaydata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-10-19",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ONEWAY",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      Rountdata: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [
                  {
                    Departure: {
                      AirportCode: "MAA",
                      Date: "2023-08-25",
                    },
                    Arrival: {
                      AirportCode: "BOM",
                    },
                  },
                  {
                    Departure: {
                      AirportCode: "BOM",
                      Date: "2023-10-27",
                    },
                    Arrival: {
                      AirportCode: "MAA",
                    },
                  },
                ],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [
                  {
                    PassengerID: "T1",
                    PTC: "ADT",
                  },
                ],
              },
            },
            Preference: {
              TripType: "ROUND",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      MulticityReq: {
        request_type: "air_search",
        request: {
          AirShoppingRQ: {
            CoreQuery: {
              OriginDestinations: {
                OriginDestination: [],
              },
            },
            DataLists: {
              PassengerList: {
                Passenger: [],
              },
            },
            Preference: {
              TripType: "MULTICITY",
              CabinType: "Y",
              Currency: "USD",
              FareType: ["PUB"],
            },
            MetaData: {
              Currency: "USD",
            },
          },
        },
        country: "US",
        token: "Tc7RQaX79Hrx5CFK",
      },

      itinaryRequest: {
        SearchClass: "",
        OriginCity1: "YVR",
        DestinationCity1: "SEL",
        OriginCity2: "SEL",
        DestinationCity2: "YVR",
        TripType: "RoundTrip",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        Slice2: "2,3",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      itinaryRequestoneway: {
        SearchClass: "",
        OriginCity1: "",
        DestinationCity1: "",
        OriginCity2: "",
        DestinationCity2: "",
        TripType: "Oneway",
        PointOfSaleCountry: "CA",
        DisplayedPrice: "5915.24",
        DisplayedPriceCurrency: "CAD",
        UserLanguage: "en",
        UserCurrency: "CAD",
        Adult: "1",
        Child: "0",
        InfantLap: "0",
        Slice1: "1",
        mTime: "2023-09-06T12:49:58.885351Z",
        ugid: "43a49112d6a5-4b03-970af4d126a1deb1",
        ReferralId: "btgfs",
        HitId: "ca-65093a3b3f7c02-50740846-5893",
      },

      formMultiData: [],
      FilterTimeDepname: "",
      FilterTimearrname: "",
      multi_city: {
        itinery: [
          {
            from: null,
            to: null,
            depdata: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocom: false,
            autocoTo: false,
          },
          {
            from: null,
            to: null,
            depdata: null,
            multiFromError: false,
            multiToError: false,
            multiDateError: false,
            maximumletter1: false,
            clicked3: false,
            multiSearchBtn: true,
            focusMultiEvent1: false,
            focusMultiEvent2: false,
            focusMultiEvent3: false,
            autocom: false,
            autocoTo: false,
          },
        ],
        class: "Economy",
        child: "",
        adult: "",
        infrant: "",
        city: "3",
      },
      multidrop: [],
      multidrop1: [],
      showMulti_data: {},

      // multi_city: [
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //   },
      //   {
      //     from: null,
      //     to: null,
      //     depdata: null,
      //     multiFromError: false,
      //     multiToError: false,
      //     multiDateError: false,
      //   }
      // ],
      addmore: true,
      clearall: false,
      deleteSeg: true,

      makeSearchUrl: "",
      airLogoApi: "",

      airline_logoData: [],

      airlineIcaoUrl: "",
      airlineIcaoType: "",

      readMore: false,
      maxItemsToShow: 5,
      filterAirline: [],
    };
  },

  methods: {
    getbaggage(data){
     
      let depBag = data.depBaggage.PieceAllowance.TotalQuantity
      let arrBag = data.arrBaggage.PieceAllowance.TotalQuantity
      if((depBag > 0 && arrBag > 0) || (depBag > 0 || arrBag > 0)){
      //    console.log(data.depBaggage.PieceAllowance.TotalQuantity,'getbaggagegetbaggagegetbaggage...1')
      // console.log(data.arrBaggage.PieceAllowance.TotalQuantity,'getbaggagegetbaggagegetbaggage...2')
        return true
      }
      else{
      //    console.log(data.depBaggage.PieceAllowance.TotalQuantity,'getbaggagegetbaggagegetbaggage...3')
      // console.log(data.arrBaggage.PieceAllowance.TotalQuantity,'getbaggagegetbaggagegetbaggage...4')
        return false
      }
    },
    getbaggageOne(data){
     
      let depBag = data.BaggageData.PieceAllowance.TotalQuantity
      if(depBag > 0 ){
        return true
      }
      else{
        return false
      }
    },
    getbaggageMulti(data){
      console.log(data,'getbaggageMultigetbaggageMulti')
      let bag1 = data.BaggageData1.PieceAllowance.TotalQuantity
      let bag2 = data.BaggageData2.PieceAllowance.TotalQuantity
      let bag3 = data.BaggageData3.PieceAllowance.TotalQuantity
      let bag4 = null
      if(data.BaggageData4){
        bag4 = data.BaggageData4.PieceAllowance.TotalQuantity
      }
      if((bag1 > 0 && bag2 > 0 && bag3 > 0 && bag4 > 0) ||
       (bag1 > 0 || bag2 > 0 || bag3 > 0 || bag4 > 0) ){
        return true
      }
      else{
        return false
      }
    },
    toggleFilter() {
      this.isFilter = !this.isFilter;
    },

    closeToast(){
      this.isNotActive = false;
      // this.alert = false
    },

    // testLayoverTime(dataTime){
    //   console.log(dataTime,'dataTimedataTimedataTime')
    // },
    showMore() {
      this.readMore = !this.readMore;

      // let $data = []
      console.log(this.filterAirline, "this.filterAirline ");

      if (this.readMore) {
        this.maxItemsToShow = this.airlineList.length;
      } else {
        this.maxItemsToShow = 5;
      }
    },

    // showLess() {
    //   this.readMore = false;
    // },

    getRandomChars(length) {
      let result = "";
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * this.strResult.length);
        result += this.strResult[randomIndex];
      }
      // console.log(result, "result");
      return result;
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },

    generateBookingRefId() {
      const randomChars1 = this.getRandomChars(8);
      const randomNum = this.getRandomInt(10, 99);
      const randomChars2 = this.getRandomChars(8);

      this.uniqueId = randomChars1 + randomNum + randomChars2;
      // console.log(
      //   this.uniqueId,
      //   "bookingRefIdbookingRefIdbookingRefId"
      // );
    },

    showDetails1(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }

      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
    },
    hideDetails1(res) {
      res.hoveredName1 = false;
      res.hoveredName2 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails2(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName2 = true;
        } else {
          v.hoveredName2 = false;
        }
      });
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(i, "oooooooooooppppppppppppppppppp");
          if (i == index) {
            v.hoveredName1 = true;
          } else {
            v.hoveredName1 = false;
          }
        });
      }
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName2 = true;
          } else {
            v.hoveredName2 = false;
          }
        });
      }
    },
    hideDetails2(res) {
      res.hoveredName2 = false;
    },

    showDetails3(res, index) {
      this.rountresult.map((v, i) => {
        // console.log(v, "ppppp");
        if (i == index) {
          v.hoveredName3 = true;
        } else {
          v.hoveredName3 = false;
        }
      });
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName3 = true;
          } else {
            v.hoveredName3 = false;
          }
        });
      }
    },
    hideDetails3(res) {
      res.hoveredName3 = false;

      // console.log(res, "oooooooooooooooo");
    },

    showDetails4(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.hoveredName4 = true;
        } else {
          v.hoveredName4 = false;
        }
      });
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          if (i == index) {
            v.hoveredName4 = true;
          } else {
            v.hoveredName4 = false;
          }
        });
      }
    },
    hideDetails4(res) {
      res.hoveredName4 = false;
    },

    act(data) {
      this.red1 = data;
      console.log(this.red1, "pppppppppppppppppppppppppp");

      if (this.red1 == 2) {
        this.onewayForm = true;
        (this.returnForm = false), (this.multiCityForm = false);
      }
      if (this.red1 == 1) {
        this.onewayForm = false;
        this.returnForm = true;
        this.multiCityForm = false;
      }
      if (this.red1 == 3) {
        this.onewayForm = false;
        this.returnForm = false;
        this.multiCityForm = true;
        console.log(
          "dmdkdfnnf",
          this.onewayForm,
          this.returnForm,
          this.multiCityForm
        );
      }
    },

    actActive() {
      // console.log(this.red1, "TRTRTRTR1");
      if (this.red1 == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.roundModify = false;
        this.onewayModify = true;
      } else if (this.red1 == 1) {
        this.Oneway = false;
        this.rount = true;
        this.multicity = false;
        this.roundModify = true;
        this.onewayModify = false;
      } else {
        this.Oneway = false;
        this.rount = false;
        this.multicity = true;
        // this.paxhide = false
      }
    },

    deg1() {
      // this.adulted1();
      if (this.aduls == this.aduls2 && this.aduls2 > 1) {
        this.deg3();
      }
      if (this.aduls > 1) {
        this.aduls = this.aduls - 1;

        this.added();
      }
    },
    add1() {
      console.log(this.aduls, "1111");
      if (this.aduls < 9 && this.addition < 9) {
        this.aduls = this.aduls + 1;
        console.log(this.aduls, "2222");
        // this.adulted();
        this.added();
      }
    },

    deg2() {
      if (this.aduls1 > 0) {
        this.aduls1 = this.aduls1 - 1;

        // this.Childed1();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    add2() {
      if (this.aduls1 < 8 && this.addition < 9) {
        this.aduls1 = this.aduls1 + 1;

        // console.log(this.aduls1, "2222");

        // this.Childed();
        this.added();
      }
      // console.log( this.Child,'concatenatedArray')
    },
    deg3() {
      if (this.aduls2 > 0) {
        this.aduls2 = this.aduls2 - 1;

        // this.Infranted1();
        this.added();
      }
    },
    add3() {
      if (this.addition < 9 && this.aduls > this.aduls2) {
        this.aduls2 = this.aduls2 + 1;

        // console.log(this.aduls2, "3333");

        // this.Infranted();
        this.added();
      }
    },

    added() {
      this.addition = this.aduls + this.aduls1 + this.aduls2;
      this.concatenatedArray = this.Adult.concat(this.Child).concat(
        this.Infrant
      );

      // console.log(this.concatenatedArray, "concatenatedArray");
    },

    localdone() {
      localStorage.setItem("value1", "this.aduls");
    },

    selectFunction() {
      this.classSelect = !this.classSelect;
      this.$refs.mySelect.focus();
      // console.log(this.classSelect, "ffffaaaallllssseeee");
      if (!this.classSelect) {
        this.$refs.countrySelect.focus();
      }
    },

    closeDropdown() {
      setTimeout(() => {
        this.$refs.mySelect.blur();
        if (this.classType) {
          this.$refs.countrySelect.blur();
          console.log("working:");
        }
      }, 50);
    },

    multiSend() {
      if (this.multi_city.itinery.length < 4) {
        this.multi_city.itinery.push({
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
          autocom: false,
          autocoTo: false,
        });

        if (this.multi_city.itinery.length == 4) {
          console.log(
            this.multi_city,
            this.multi_city.itinery.length,
            "lengthlen.....gthlength"
          );

          this.addmore = false;
        }
        // else{
        //   this.addmore = true;
        // }
      }

      if (this.multi_city.itinery.length > 2) {
        this.clearall = true;
        this.deleteSeg = true;
      }

      for (let i = 1; i < this.multi_city.itinery.length; i++) {
        this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      }
    },

    deleteSegment() {
      if (this.multi_city.itinery.length == 3) {
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = false;
        this.addmore = true;
      } else if (this.multi_city.itinery.length > 2) {
        console.log(this.multi_city.itinery.length, "lengthlengthl,..111..");
        this.multi_city.itinery.splice(-1);
        this.deleteSeg = true;
        this.addmore = true;
      } else {
        this.deleteSeg = false;
      }
    },

    clearall1() {
      this.multi_city.itinery.splice(2, 5);

      (this.multi_city.itinery = [
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
        {
          from: null,
          to: null,
          depdata: null,
          multiFromError: false,
          multiToError: false,
          multiDateError: false,
        },
      ]),
        (this.clearall = false);
      this.addmore = true;
    },

    // getprice(res) {
    //   return (((res.TotalAmount) / this.totaladult).toFixed(2));
    // },

    getdepseat(data) {
      // console.log(data,'ppppppppppppppppppppppp')
      return data[0].Seat;
    },
    getdepName1(data) {
      // console.log(data, "getdepcode1");
      return data;
      // return data.split("(")[0];
    },
    getarrName1(data) {
      return data.split("(")[0];
    },
    getdepNameMulti(data) {
      console.log(data, "getdepcode1");
      return data.from;
    },
    getarrNameMulti(data) {
      return data.to;
    },

    getretseat(data) {
      return data[0].Seat;
    },
    // getlogo($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    // getlogo1($event) {
    //   return $event[0].MarketingCarrier.logo;
    // },
    getlogo($event) {
      console.log($event, "dataassss");
    },
    getlogo1(data) {
      return data.MarketingCarrier.logo;
    },
    getname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },
    getNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },

    // All Date Section
    getDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // All Date Section End

    // Mobile Date Format Section

    getDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    getreturnDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },

    getreturnArrivaldateReturn($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },

    // Mobile Date Show End

    getAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getCountryName1($event) {
      // this.FilterTimeDepname = $event.split("(")[0];
      // return $event.split("(")[0];
      return $event;
    },
    getCountryName2($event) {
      // this.FilterTimearrname = $event.split("(")[0];
      // return $event.split("(")[0];
      return $event;
    },

    getAirportNameFrom($event) {
      return $event[0].Departure.AirportName;
    },

    getAirportNameTo($event) {
      return $event[0].Departure.AirportName;
    },

    getarrivaldata($event) {
      return $event[0].Arrival.AirportName;
    },
    getreturndata($event) {
      return $event[0].Arrival.AirportName;
    },
    getarrtime($event) {
      return $event[0].Arrival.Time;
    },
    getreturntime($event) {
      return $event[0].Arrival.Time;
    },
    get1arrtime($event) {
      // console.log($event, '$event')

      return $event[$event.length - 1].Departure.AirportName;
    },
    get1returntime($event) {
      return $event[$event.length - 1].Departure.AirportName;
    },

    get2arrtime($event) {
      return $event[$event.length - 1].Departure.Time;
    },

    get2returntime($event) {
      return $event[$event.length - 1].Departure.Time;
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getArrivalCode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getretairname($event) {
      return $event[$event.length - 1].Arrival.AirportName;
    },
    getreturnname($event) {
      return $event[0].MarketingCarrier.Name;
    },
    getreturnNumber($event) {
      return $event[0].MarketingCarrier.FlightNumber;
    },
    getreturnid($event) {
      return $event[0].MarketingCarrier.AirlineID;
    },

    getdeparDate($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format(
        "ddd MMM DD"
      );
    },

    getdated($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdatedFlight($event) {
      return moment($event).format("MMM DD");
    },
    getdatedFlightMulti($event) {
      return moment($event[0].Date).format("MMM DD");
    },
    getdatedFlight1($event) {
      return moment($event).format("MMM DD");
    },
    getdated1($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated2($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getreturnAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },
    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getreturnTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getreturnArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getreturnArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },
    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    //  --------filter Start---------------------

    Recommend() {
      this.active = true;
      this.active1 = false;
      this.active2 = false;
      this.active3 = false;

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.onewayresulted[0].currency;
        this.recperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.rountresult[0].currency;
        this.recperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        this.multicityData.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.multicityData[0].currency;
        this.recperperson = this.multicityData[0].perperson;
      }
    },
    Cheapest(data) {
      if (data == 1) {
        this.active = false;
        this.active1 = false;
        this.active2 = true;
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        // this.onewayfilterData.sort((a, b) => a.perperson - b.perperson)
        this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.onewayresulted[0].currency;
        this.recperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.rountresult[0].currency;
        this.recperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;
        this.multicityData.sort((a, b) => a.perperson - b.perperson);
        this.recCurrecy = this.multicityData[0].currency;
        this.recperperson = this.multicityData[0].perperson;
      }
    },

    Shortest(data) {
      if (data == "1") {
        this.active = false;
        this.active1 = false;
        this.active2 = false;
        this.active3 = true;
      } else {
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) =>
          a.Depaturejourney.Time.localeCompare(
            b.Depaturejourney.Time,
            undefined,
            { numeric: true }
          )
        );
        this.shorCurrecy = this.onewayresulted[0].currency;
        this.shotperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort(
          (a, b) =>
            a.Depaturejourney.Time.localeCompare(
              b.Depaturejourney.Time,
              undefined,
              { numeric: true }
            ) &&
            a.Returnjourney.Time.localeCompare(
              b.Returnjourney.Time,
              undefined,
              { numeric: true }
            )
        );
        this.shorCurrecy = this.rountresult[0].currency;
        this.shotperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;

        this.multicityData.sort((a, b) => {
          const compareFlight1 = a.Flight1JourneyTime.Time.localeCompare(
            b.Flight1JourneyTime.Time,
            undefined,
            { numeric: true }
          );
          const compareFlight2 = a.Flight2JourneyTime.Time.localeCompare(
            b.Flight2JourneyTime.Time,
            undefined,
            { numeric: true }
          );

          let compareFlight3 = 0;
          if (
            a.Flight3JourneyTime &&
            a.Flight3JourneyTime.Time &&
            b.Flight3JourneyTime &&
            b.Flight3JourneyTime.Time
          ) {
            compareFlight3 = a.Flight3JourneyTime.Time.localeCompare(
              b.Flight3JourneyTime.Time,
              undefined,
              { numeric: true }
            );
          }

          let compareFlight4 = 0;
          if (
            a.Flight4JourneyTime &&
            a.Flight4JourneyTime.Time &&
            b.Flight4JourneyTime &&
            b.Flight4JourneyTime.Time
          ) {
            compareFlight4 = a.Flight4JourneyTime.Time.localeCompare(
              b.Flight4JourneyTime.Time,
              undefined,
              { numeric: true }
            );
          }

          return (
            compareFlight1 || compareFlight2 || compareFlight3 || compareFlight4
          );
        });

        this.shorCurrecy = this.multicityData[0].currency;
        this.shotperperson = this.multicityData[0].perperson;
      }
    },

    bestlayTime(data) {
      if (data == 1) {
        this.active = false;
        this.active1 = true;
        this.active2 = false;
        this.active3 = false;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.onewayresulted[0].perperson;
      } else if (this.filterData.length > 0) {
        this.rountresult.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.rountresult[0].perperson;
      } else if (this.multiFilterData.length > 0) {
        // this.multicityData = this.multiFilterData;

        this.multicityData.sort((a, b) => a.layoverFilter - b.layoverFilter);
        this.layperperson = this.multicityData[0].perperson;
      }
    },

    // --------filter end---------------------

    closeRet(res, index) {
      this.rountresult.map((v, i) => {
        if (i == index) {
          v.Flightreturn = false;
        }
      });
    },

    flighted1(res, index) {
      // this.showSpinner = true
      this.flightdetailsData = [];
      this.flightdetailsData.push(res);


      this.rountresult.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
      console.log(index, res, "pppppppiiithis....1")
      console.log(this.flightdetailsData,"pppppppiiithis....2")
    },



    flighted2(res, index) {
      // this.openOffCanvas = true;
      this.flightdetailsData1 = [];
      this.flightdetailsData1.push(res);
      console.log(
        this.flightdetailsData1,
        "this.flightdetailsDatathis.flightdetailsData"
      );
      this.onewayresulted.find((v, i) =>
        i == index 
        ? (v.Flightdepature = true) 
        : (v.Flightdepature = false)
      );
    },

    flighted3(res, index) {
      console.log(index, res);
      this.flightdetailsData2 = [];
      this.flightdetailsData2.push(res);
      this.multicityData.find((v, i) =>
        i == index
          ? (v.Flightdepature = !v.Flightdepature)
          : (v.Flightdepature = false)
      );
      // document.documentElement.style.overflow = 'hidden';
      // document.body.scroll = "no";
    },
    closeModal() {
      this.openOffCanvas = false;
      this.tab = "tab-1";
      if (this.$refs.offcanvasBody) {
        this.$refs.offcanvasBody.scrollTop = 0;
      }
       if (this.$refs.offcanvasBody1) {
        this.$refs.offcanvasBody1.scrollTop = 0;
      }
       if (this.$refs.offcanvasBody2) {
        this.$refs.offcanvasBody2.scrollTop = 0;
      }
      // this.showSpinner = false;
      // document.documentElement.style.overflow = 'scroll';
    },
    handleFocus(buttonNumber) {
      if (buttonNumber === 1) {
        // this.isFocused1 = true;
      } else if (buttonNumber === 2) {
        // this.isFocused2 = true;
      }
    },
    handleBlur(buttonNumber) {
      if (buttonNumber === 1) {
        // this.isFocused1 = false;
      } else if (buttonNumber === 2) {
        // this.isFocused2 = false;
      }
    },
    closeDep(res, index) {
      if (this.rountresult) {
        this.rountresult.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.onewayresulted) {
        this.onewayresulted.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
      if (this.multicityData) {
        this.multicityData.map((v, i) => {
          // console.log(v, "nnnn");
          if (i == index) {
            v.Flightdepature = false;
          }
        });
      }
    },

    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightdep1(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarrival(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    onewayselect(result, index) {
      console.log(result, index, "resultyyy, index");
      this.dataLoader = true;
      this.searchresult = false;

      setTimeout(() => {
        this.dataLoader = false;

        localStorage.removeItem("itineryData");
        localStorage.removeItem("uniId");
        console.log(result, index,'resultttt....1');
        localStorage.setItem(
          `${result[0].currency}-${result[0].selectId}`,
          JSON.stringify(result[0])
        );
        // this.$router.push({
        //   path: "/flightinfo",
        //   query: { pass_id1: `${result.currency}-${result.selectId}` },
        // });

        this.$router.push({
          path: "/flightDetailsCom",
          query: { pass_id1: `${result[0].currency}-${result[0].selectId}` },
        });
      }, 3000);
    },

    multidata() {
      multicityService.getAllUser().then((v) => {
        this.offerpay = v.data.OffersGroup.AirlineOffers[0].Offer;

        this.Journey = v.data.DataLists.FlightList.Flight;

        this.Journey1 = v.data.DataLists.FlightSegmentList.FlightSegment;
        this.Journey.forEach((a) => {
          this.flightsegmet.push(a.SegmentReferences.split(" "));
        });
        this.Journey1.forEach((t) => {
          this.flightsegmetkey.push(t);
        });

        this.Journey.forEach((q) => {
          this.airstime.push(q.Journey.Time);
          this.airstop.push(q.Journey.Stops);
        });
        for (let i = 0; i <= this.offerpay.length - 1; i++) {
          let item = [];
          item = this.offerpay[i].OfferItem;
          item.forEach((t) => {
            this.airprice.push(
              t.TotalPriceDetail.TotalAmount.BookingCurrencyPrice
            );
            if (
              t.PassengerType === "ADT" ||
              t.PassengerType == "ChD" ||
              t.PassengerType == "INF"
            ) {
              if (t.Refundable === "true") {
                this.airref.push("Refundable");
              } else {
                this.airref.push("NON Refundable");
              }
            }
          });
        }

        this.airprice.forEach((c) => {
          this.multicityData1.push({ airprice: c });
        });
      });
    },

    mydata() {
      try {
        this.resulted = JSON.parse(localStorage.getItem("myData"));
      } catch (error) {
        console.error("An error occurred:", error);
      }
    },

    deatailData() {
      if (this.detail.city == 2) {
        this.Oneway = true;
        (this.rount = false), (this.multicity = false);
        this.oneway1 = true;
      }
      if (this.detail.city == 1) {
        this.roundtrip = true;
        this.Oneway = false;
        (this.rount = true), (this.multicity = false);
      }
      if (this.detail.city == 3) {
        this.multitrip = true;
        this.Oneway = false;
        (this.rount = false), (this.multicity = true);
      }
    },

    // RoundTrip datePicker

    getDatepicker($data, index) {
      if (index > 0) {
        // console.log($data, index, '$data,index....1')

        return $data[index - 1].depdata;
      } else {
        // console.log($data, index, '$data,index....2')

        return `${new Date()}`;
      }
    },

    fromDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
        this.clicked2 = false;
        this.fromDateError = false;
        this.oneway.redate = null;
        if (this.$refs.dpRef2) {
          this.$refs.dpRef2.openMenu();
        }
        console.log(this.oneway.dedate, "ddddddddd...1....eeeeeeeeeee");
      } else {
        this.clicked1 = false;
        this.fromDateError = true;

        console.log(this.oneway.dedate, "ddddddddd...2....eeeeeeeeeee");
      }
    },

    ClickOutDepat() {
      this.$refs.dpRef1.closeMenu();

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    toDateChanged(newValue) {
      this.oneway.redate = newValue;
      // console.log(this.oneway.redate, "ttttttttttt");

      if (this.oneway.redate) {
        this.clicked2 = true;
        this.toDateError = false;
      } else {
        this.clicked2 = false;
        this.toDateError = true;
      }
    },

    triggerFunction() {
      // alert("Hiii...");
      // this.triggerRadio = !this.triggerRadio
      // if (this.triggerRadio) {
      //   console.log(this.triggerRadio)
      //   this.$refs.radioSelect2.focus();
      // }
    },

    ClickOutReturn() {
      this.$refs.dpRef2.closeMenu();
      // console.log(this.oneway.redate, "kkkkkkkkk");

      if (this.oneway.redate) {
        this.clicked2 = true;
      } else {
        this.clicked2 = false;
      }
    },
    // RoundTrip datePicker End

    // Oneway datePicker
    onewayDateChanged(newValue) {
      this.oneway.dedate = newValue;

      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    onewayClickOutside() {
      this.$refs.dpRef3.closeMenu();
      if (this.oneway.dedate) {
        this.clicked1 = true;
      } else {
        this.clicked1 = false;
      }
    },

    multiDateChanged(newValue, index) {
      // console.log(index, newValue, "didididid...");

      this.multi_city.itinery.map((v, i) => {
        if (index == i) {
          if (newValue) {
            v.clicked3 = true;
            v.multiDateError = false;
          } else {
            v.clicked3 = false;
            v.multiDateError = true;
          }
        }
      });

      this.multi_city.itinery.forEach((v, i) => {
        if (index < i) {
          if (newValue > new Date(v.depdata)) {
            v.depdata = null;
          }
        }
      });
    },

    multiClickOutside() {
      this.multi_city.itinery.clicked3 = false;
      this.datePickerIsOpen = false;
    },
    // Multicity datepicker End

    roundData() {
      this.oneway.class = this.$route.query.class;

      if (this.$route.query.from.name) {
        this.oneway.from = this.$route.query.from.name;
        this.tripData.from = this.$route.query.from.name;
      } else {
        this.oneway.from = this.$route.query.from;
        this.tripData.from = this.$route.query.from;
      }
      if (this.$route.query.to.name) {
        this.oneway.to = this.$route.query.to.name;
        this.tripData.to = this.$route.query.to.name;
      } else {
        this.oneway.to = this.$route.query.to;
        this.tripData.to = this.$route.query.to;
      }
      this.oneway.dedate = moment(this.$route.query.dedate).format(
        "DD MMM-YYYY"
      );
      this.tripData.dedate = moment(this.$route.query.dedate).format(
        "DD MMM-YYYY"
      );
      this.oneway.redate = moment(this.$route.query.redate).format(
        "DD MMM-YYYY"
      );
      this.tripData.redate = moment(this.$route.query.redate).format(
        "DD MMM-YYYY"
      );

      console.log(this.oneway.travelDate, "oneway.travelDate");
      this.oneway.class = this.$route.query.class;
      this.tripData.class = this.$route.query.class;

      if (this.$route.query.adult) {
        for (let i = 0; i < this.$route.query.adult; i++) {
          this.Adult.push({
            PassengerID: "T",
            PTC: "ADT",
          });
        }
      }

      if (this.$route.query.child) {
        for (let i = 0; i < this.$route.query.child; i++) {
          this.Child.push({
            PassengerID: "T",
            PTC: "CHD",
          });
        }
      }
      if (this.$route.query.infrant) {
        for (let i = 0; i < this.$route.query.infrant; i++) {
          this.Infrant.push({
            PassengerID: "T",
            PTC: "INF",
          });
        }
      }

      let totalpass = [];
      totalpass = [...this.Adult, ...this.Child, ...this.Infrant];

      totalpass.forEach((v, index) => {
        let obj = {
          PassengerID: `${v.PassengerID}${index + 1}`,
          PTC: v.PTC,
        };
        this.totalpassenger.push(obj);
      });
      // console.log(this.totalpassenger, "aadjj");
      this.Rountdata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;
      this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;

      this.aduls = parseFloat(this.$route.query.adult);
      this.tripData.aduls = parseFloat(this.$route.query.adult);

      this.aduls2 = parseFloat(this.$route.query.infrant);
      this.tripData.aduls2 = parseFloat(this.$route.query.infrant);

      this.aduls1 = parseFloat(this.$route.query.child);
      this.tripData.aduls1 = parseFloat(this.$route.query.child);
      this.totaladult = this.aduls + this.aduls1 + this.aduls2;
      this.select = this.$route.query.class;
      this.tripData.select = this.$route.query.class;
    },

    multiDataConvert() {
      console.log(this.multi_city, "this.$route.querythis.$route.query.....3");
      this.red = this.multi_city.city;
      this.red1 = this.multi_city.city;
      if (this.multi_city.adult) {
        for (let i = 0; i < this.multi_city.adult; i++) {
          this.Adult.push({
            PassengerID: "T",
            PTC: "ADT",
          });
        }
      }

      if (this.multi_city.child) {
        for (let i = 0; i < this.multi_city.child; i++) {
          this.Child.push({
            PassengerID: "T",
            PTC: "CHD",
          });
        }
      }
      if (this.multi_city.infrant) {
        for (let i = 0; i < this.multi_city.infrant; i++) {
          this.Infrant.push({
            PassengerID: "T",
            PTC: "INF",
          });
        }
      }

      let totalpass = [];
      totalpass = [...this.Adult, ...this.Child, ...this.Infrant];
      console.log(totalpass, "paaasssss");
      totalpass.forEach((v, index) => {
        let obj = {
          PassengerID: `${v.PassengerID}${index + 1}`,
          PTC: v.PTC,
        };
        this.totalpassenger.push(obj);
      });
      this.multi_city.itinery.forEach((s) => {
        let $result = {
          Departure: {
            AirportCode: "",
            Date: "",
          },
          Arrival: {
            AirportCode: "",
          },
        };
        let tripData = {
          from: "",
          to: "",
        };
        if (s.from) {
          let res = "";
          res = s.from.split("(");
          console.log(res);
          tripData.from = res[0];
          $result.Departure.AirportCode = res[res.length - 1].replace(")", "");
        } else {
          let res = "";
          res = s.from.name.split("(");
          tripData.from = res[0];
          $result.Departure.AirportCode = res[res.length - 1].replace(")", "");
        }

        if (s.to) {
          let res = "";
          res = s.to.split("(");
          tripData.to = res[0];
          $result.Arrival.AirportCode = res[res.length - 1].replace(")", "");
        } else {
          let res = "";
          res = s.to.name.split("(");
          tripData.to = res[0];
          $result.Arrival.AirportCode = res[res.length - 1].replace(")", "");
        }
        if (s.depdata) {
          $result.Departure.Date = moment(s.depdata).format("YYYY-MM-DD");
        }
        // console.log($result, '$result$result')
        this.MulticityReq.request.AirShoppingRQ.CoreQuery.OriginDestinations.OriginDestination.push(
          $result
        );
        this.codeData.push(tripData);
      });

      console.log(this.codeData, "this.$route.querythis.$route.query.....2");

      console.log(this.totalpassenger, "aadjj");
      this.MulticityReq.request.AirShoppingRQ.DataLists.PassengerList.Passenger =
        this.totalpassenger;
    },

    multicityresult() {
      this.multiDataConvert();

      this.multi_city.class = this.classType;
      let multiCabin_type = this.multi_city.class;

      console.log(
        multiCabin_type,
        "multiCabin_typemultiCabin_typemultiCabin_type"
      );

      if (multiCabin_type == "Economy") {
        multiCabin_type = "Y";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else if (multiCabin_type == "Business") {
        multiCabin_type = "C";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      } else {
        multiCabin_type = "F";
        this.MulticityReq.request.AirShoppingRQ.Preference.CabinType =
          multiCabin_type;
      }

      this.MulticityReq.request.AirShoppingRQ.Preference.Currency =
        this.preferCurrency;
      this.MulticityReq.request.AirShoppingRQ.MetaData.Currency =
        this.metaCurrency;
      this.MulticityReq.country = this.countryCodeData;
      console.log(
        this.MulticityReq,
        "MulticityReqMulticityReqMulticityReqMulticityReq"
      );

      axios
        .post(this.makeSearchUrl, this.MulticityReq, {
          headers: {
            "Content-Type": "application/json",
          },
        })

        .then((response) => {
          // if (response.data) {
          //   this.multicityTotalData.push(response.data);
          //   this.multicityconvert();
          // }

          if (response.data.Search_Response == "Error") {
            console.log(response, "multi_citymulti_citymulti_city.....1");

            this.$router.push("/pageError");
          } else if (response.data) {
            this.multicityTotalData.push(response.data);
            this.multicityconvert();
            console.log(response, "multi_citymulti_citymulti_city.....2");
          }
        })

        .catch((error) => {
          console.log(error);
          this.$router.push("/pageError");
        });
    },

    multicityconvert() {
      let baggageList = [];

      for (let i = 0; i < this.multicityTotalData.length; i++) {
        console.log(this.multicityTotalData[i], "power");

        let shoppingId = this.multicityTotalData[i].ShoppingResponseId;
        let Journey = [];
        let flightsegment = [];
        Journey = this.multicityTotalData[i].DataLists.FlightList.Flight;
        baggageList =
          this.multicityTotalData[i].DataLists.BaggageAllowanceList
            .BaggageAllowance;

        console.log(Journey, "Journey");
        flightsegment =
          this.multicityTotalData[i].DataLists.FlightSegmentList.FlightSegment;
        // console.log(flightsegment, "flightsegment");
        let $data = [];
        $data = this.multicityTotalData[i].OffersGroup.AirlineOffers.Offer;

        let source1 = [];
        let source2 = [];
        let source3 = [];
        let source4 = [];
        let source5 = [];
        let Baggage1 = [];
        let Baggage2 = [];
        let Baggage3 = [];
        let Baggage4 = [];
        let Baggage5 = [];

        $data.forEach((v) => {
          v.OfferItem[0][0].FareComponent.forEach((s, index) => {
            console.log(v, "multicityTotalData");

            if (index == 0) {
              Baggage1.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData1.push(s.SegmentRefs.split(" "));

              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source1.push(Journey[i].Journey);
                }
              }
            }
            if (index == 1) {
              Baggage2.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData2.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source2.push(Journey[i].Journey);
                }
              }
            }
            if (index == 2) {
              Baggage3.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData3.push(s.SegmentRefs.split(" "));
              console.log(this.offerData3, "this.offerData3this.offerData3");
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source3.push(Journey[i].Journey);
                }
              }
            }
            if (index == 3) {
              Baggage4.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData4.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source4.push(Journey[i].Journey);
                }
              }
            }
            if (index == 4) {
              Baggage5.push(
                v.OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              this.offerData5.push(s.SegmentRefs.split(" "));
              for (let i = 0; i <= Journey.length - 1; i++) {
                if (s.SegmentRefs == Journey[i].SegmentReferences) {
                  source5.push(Journey[i].Journey);
                }
              }
            }
          });
        });
        console.log(flightsegment, "this.flightsegment");
        let BaggageData1 = [];
        if (this.offerData1.length > 0) {
          Baggage1.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData1.push(c);
              }
            });
          });
          for (let i = 0; i < this.offerData1.length; i++) {
            let seg = [];
            this.offerData1[i].forEach((f, index) => {
              let items = [];
              items = flightsegment.filter((v) => v.SegmentKey == f);
              if (items.length > 0) {
                seg.push(items[0]);
              }
              if (index == this.offerData1[i].length - 1) {
                this.multicityData.push({ Flight1: seg });
              }
            });
          }
        }
        let BaggageData2 = [];
        if (this.offerData2.length > 0) {
          Baggage2.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData2.push(c);
              }
            });
          });

          for (let i = 0; i < this.offerData2.length; i++) {
            let seg = [];
            this.offerData2[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData2[i].length - 1) {
                this.Flight2.push(seg);
              }
            });
          }
        }
        let BaggageData3 = [];
        if (this.offerData3.length > 0) {
          Baggage3.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData3.push(c);
              }
            });
          });

          // console.log(this.offerData3,'this.offerData3this.offerData36557')
          for (let i = 0; i < this.offerData3.length; i++) {
            let seg = [];
            this.offerData3[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              console.log(
                index,
                this.offerData3[i].length - 1,
                "this.offerData2[i].length - 1"
              );
              if (index == this.offerData3[i].length - 1) {
                this.Flight3.push(seg);
                // console.log(this.Flight3,'this.Flight3this.Flight3this.Flight3this.Flight3')
              }
            });
          }
        }
        let BaggageData4 = [];
        if (this.offerData4.length > 0) {
          Baggage4.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData4.push(c);
              }
            });
          });

          for (let i = 0; i < this.offerData4.length; i++) {
            let seg = [];
            this.offerData4[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData4[i].length - 1) {
                this.Flight4.push(seg);
              }
            });
          }
        }
        let BaggageData5 = [];
        if (this.offerData5.length > 0) {
          Baggage5.forEach((v) => {
            baggageList.forEach((c) => {
              if (v == c.BaggageAllowanceID) {
                BaggageData5.push(c);
              }
            });
          });

          for (let i = 0; i < this.offerData5.length; i++) {
            let seg = [];
            this.offerData5[i].forEach((s, index) => {
              let item = [];
              item = flightsegment.filter((v) => v.SegmentKey == s);
              if (item.length > 0) {
                seg.push(item[0]);
              }
              if (index == this.offerData5[i].length - 1) {
                this.Flight5.push(seg);
              }
            });
          }
        }
        for (let i = 0; i < this.airlogodata.length - 1; i++) {
          for (let j = 0; j < flightsegment.length - 1; j++) {
            if (
              flightsegment[j].MarketingCarrier.AirlineID ==
              this.airlogodata[i].id
            ) {
              flightsegment[j].MarketingCarrier.logo = this.airlogodata[i].logo;
            }
          }
        }

        let currency = [];
        let Baseprice = [];
        let Tax = [];
        let perperson = [];
        let ownername = [];
        let ownerlogo = [];
        let offerId = [];
        let TotalPrice = [];
        let refund = [];

        $data.forEach((v) => {
          // console.log(v, "offeriddatataa");
          offerId.push(v.OfferID);
        });

        $data.forEach((s) => {
          if (s.ReqCurrency == "USD") {
            currency.push("US$");
          } else if (s.ReqCurrency == "CAD") {
            currency.push("CA$");
          } else if (s.ReqCurrency == "AUD") {
            currency.push("AU$");
          }

          ownername.push(s.OwnerName);
          for (let i = 0; i < this.airline_logoData.length - 1; i++) {
            if (s.Owner == this.airline_logoData[i].id) {
              ownerlogo.push(this.airline_logoData[i].logo);
            }
          }

          Baseprice.push(
            parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
          );
          Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
          perperson.push(
            parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
          );
          TotalPrice.push(
            parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
          );

          s.OfferItem.forEach((v) => {
            console.log(
              v[0].FareDetail[0].Price.NonRefundable,
              "Price.RefundablePrice.Refundable"
            );

            if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
              refund.push("Refundable");
            } else {
              refund.push("Non Refundable");
            }
          });
        });

        for (let i = 0; i < perperson.length; i++) {
          let nume1 = perperson[i];
          // console.log(nume1, "llldlldldldldld")
          this.find1.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[0]
          );
          this.find2.push(
            (Math.round(nume1 * 100) / 100).toFixed(2).toString().split(".")[1]
          );
        }

        for (let i = 0; i < this.multicityData.length; i++) {
          this.multicityData[i].selectId = offerId[i];
          this.multicityData[i].currency = currency[i];
          this.multicityData[i].Baseprice = Baseprice[i];
          this.multicityData[i].TotalPrice = TotalPrice[i];
          this.multicityData[i].BaggageData1 = BaggageData1[i];
          this.multicityData[i].BaggageData2 = BaggageData2[i];
          this.multicityData[i].Taxprice = Tax[i];
          this.multicityData[i].perperson = perperson[i];
          this.multicityData[i].Flight2 = this.Flight2[i];
          this.multicityData[i].find1 = this.find1[i];
          this.multicityData[i].find2 = this.find2[i];
          this.multicityData[i].Flightdepature = false;
          this.multicityData[i].hoveredName1 = false;
          this.multicityData[i].ShoppingResponseId = shoppingId;
          this.multicityData[i].Adult = this.multi_city.adult;
          this.multicityData[i].Child = this.multi_city.child;
          this.multicityData[i].Inf = this.multi_city.infrant;
          this.multicityData[i].muticityData = this.codeData;
          this.multicityData[i].ownerlogo = ownerlogo[i];
          this.multicityData[i].ownername = ownername[i];
          this.multicityData[i].Flight1JourneyTime = source1[i];
          this.multicityData[i].Flight2JourneyTime = source2[i];
          this.multicityData[i].Refund = refund[i];
          this.multicityData[i].cabinClass = this.multi_city.class;
          this.multicityData[i].multipleDatas1 = false;
          this.multicityData[i].multipleDatas2 = false;
          this.multicityData[i].radios1 = true;
          this.multicityData[i].radios2 = true;
          this.multicityData[i].radioData1 = false;
          this.multicityData[i].radioData2 = false;
          if (this.Flight3.length > 0) {
            this.multicityData[i].Flight3 = this.Flight3[i];
            this.multicityData[i].Flight3JourneyTime = source3[i];
            this.multicityData[i].multipleDatas3 = false;
            this.multicityData[i].radios3 = true;
            this.multicityData[i].radioData3 = false;
            this.multicityData[i].BaggageData3 = BaggageData3[i];
          }

          if (this.Flight4.length > 0) {
            this.multicityData[i].BaggageData4 = BaggageData4[i];
            this.multicityData[i].Flight4 = this.Flight4[i];
            this.multicityData[i].Flight4JourneyTime = source4[i];
            this.multicityData[i].multipleDatas4 = false;
            this.multicityData[i].radios4 = true;
            this.multicityData[i].radioData3 = false;
          }
          if (this.Flight5.length > 0) {
            this.multicityData[i].Flight5 = this.Flight5[i];
            this.multicityData[i].Flight5JourneyTime = source5[i];
            this.multicityData[i].BaggageData5 = BaggageData5[i];
          }
        }
        this.getmultitime1();

        console.log(
          this.multicityData,
          "multicityDatamulticityDatamulticityData...."
        );
        this.multishowData = this.multicityData;
        this.multiFilterData = this.multicityData;
        // this.multipriceData();
        this.priceRange();
        this.multicitystopFilter();
        this.Shortest();
        this.Recommend();
        this.Cheapest();
        this.bestlayTime();

        this.multicityData = totalPricefilter.sortBy(this.multiFilterData, [
          "TotalAmount",
        ]);

        if (this.multicityData) {
          this.searchresult = true;
          this.searchloader = false;

          document.getElementById("navbar").style.display = "block";
          document.getElementById("food").style.display = "block";
        }
        // console.log(this.multicityData, "this.multicityDatathis.multicityData");
      }
    },

    async airlineLogo_func() {
      let airlogoUrl = "";
      let airlogoType = "";

      airlogoUrl = this.airLogoApi.url;
      airlogoType = this.airLogoApi.type;

      await axios
        .get(airlogoUrl, {
          headers: {
            "Content-Type": airlogoType,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airline_logoData = response.data;
            this.airlogodata = this.airline_logoData;
            // console.log(this.airline_logoData, "ressloglooglooglooglool")
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });
    },

    roundedtrip() {
      for (let i = 0; i < this.localData.length; i++) {
        // console.log(this.localData[i],"this.localData[i]this.localData[i]this.localData[i]");

        if (this.localData[i]) {
          this.localData[i].forEach((v) => {
            // console.log(v.DataLists, "good");
            // let seats = [];
            let baggageList = [];
            this.Journey = v.DataLists.FlightList.Flight;
            let shoppingId = v.ShoppingResponseId;
            // console.log(shoppingId, "shoppingIdshoppingId");
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = v.OffersGroup.AirlineOffers.Offer;
            baggageList = v.DataLists.BaggageAllowanceList.BaggageAllowance;
            let offerId = [];
            // $data.forEach((v=>{
            //   offerId.push(v.OffersGroup.AirlineOffers.Offer.offerId)
            // }))
            // offerId.push(v.OffersGroup.AirlineOffers.Offer)

            let arrsplit = [];
            let depsplit = [];
            let resultdata = [];
            let resultdata1 = [];
            let refund = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let perperson = [];
            let TotalPrice = [];

            $data.forEach((v) => {
              // console.log(v, i, "offeriddatataa");
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              if (s.ReqCurrency == "USD") {
                currency.push("US$");
              } else if (s.ReqCurrency == "CAD") {
                currency.push("CA$");
              } else if (s.ReqCurrency == "AUD") {
                currency.push("AU$");
              }

              // console.log(this.airline_logoData,"vlvlvlvlvvl")

              ownername.push(s.OwnerName);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);

                  // console.log(ownerlogo,'owmpownwonwown')
                }
              }
              // console.log(s.ReqCurrency, "currency");
              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );
              pricedetail.push(s.OfferItem);

              s.OfferItem.forEach((v) => {
                // console.log(v, "kannannaaannnanannananannanan");
                resultdata1 = v[0].FareComponent[0].SegmentRefs;
                resultdata = v[0].FareComponent[1].SegmentRefs;

                // console.log(
                //   v[0].FareDetail[0].Price.NonRefundable,
                //   "kannannaaannnanannananannanan"
                // );

                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }
              });
              arrsplit.push(resultdata.split(" "));
              depsplit.push(resultdata1.split(" "));
            });
            // console.log(arrsplit, "$data");

            // console.log(refund, 'ppppppppppppppppppppppppppppppppp')
            this.Journey1 = v.DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              // console.log(a,'thesplitvalueaaaaaaaaaaaaaa')
              this.flightsegmet.push(a.SegmentReferences.toString().split(" "));
              // console.log(this.flightsegmet ,'thesplitvalue')
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
              // console.log(this.flightsegmetkey, "vasanthjourney");
            });

            // seats = v.DataLists.PriceClassList.PriceClass;
            // console.log(seats, "seatsseatsseats");
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              // console.log(depsplit,'depsplit')
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.rountresult.push({ Depature: seg });
                }
              });
            }

            for (let i = 0; i <= arrsplit.length - 1; i++) {
              // console.log(arrsplit,'arrsplit')
              let seg = [];
              arrsplit[i].forEach((f, index) => {
                this.items = this.flightsegmetkey.filter(
                  (v) => v.SegmentKey == f
                );
                if (this.items.length > 0) {
                  seg.push(this.items[0]);
                }
                if (index == arrsplit[i].length - 1) {
                  this.returnData.push(seg);
                }
              });
            }
            let Arrjourney = [];
            let depjourney = [];
            let depBaggage = [];
            let arrBaggage = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              depBaggage.push(
                $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              arrBaggage.push(
                $data[j].OfferItem[0][0].BaggageAllowance[1].BaggageAllowanceRef
              );
              let result1 = $data[j].OfferItem[0][0].FareComponent[1];
              let source = [];
              let source1 = [];
              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                } else if (
                  result1.SegmentRefs == this.Journey[i].SegmentReferences
                ) {
                  source1 = this.Journey[i].Journey;
                }
              }
              Arrjourney.push(source);
              depjourney.push(source1);
            }

            let BaggageData = [];
            let BaggageData1 = [];
            depBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });
            arrBaggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData1.push(c);
                }
              });
            });

            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];

              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }
            // console.log(this.find1, "this.find1this.find1");
            for (let i = 0; i <= this.rountresult.length - 1; i++) {
              this.rountresult[i].Baseprice = Baseprice[i];
              this.rountresult[i].TotalPrice = TotalPrice[i];
              this.rountresult[i].Taxprice = Tax[i];
              this.rountresult[i].pricedetail = pricedetail[i];
              this.rountresult[i].Return = this.returnData[i];
              this.rountresult[i].depBaggage = BaggageData[i];
              this.rountresult[i].arrBaggage = BaggageData1[i];
              this.rountresult[i].Flightreturn = false;
              this.rountresult[i].multipleDatas = false;
              this.rountresult[i].Flightdepature = false;
              this.rountresult[i].hoveredName1 = false;
              this.rountresult[i].hoveredName2 = false;
              this.rountresult[i].hoveredName3 = false;
              this.rountresult[i].hoveredName4 = false;
              this.rountresult[i].Refund = refund[i];
              this.rountresult[i].currency = currency[i];
              this.rountresult[i].ownerlogo = ownerlogo[i];
              this.rountresult[i].ownername = ownername[i];
              this.rountresult[i].Returnjourney = depjourney[i];
              this.rountresult[i].Depaturejourney = Arrjourney[i];
              this.rountresult[i].perperson = perperson[i];
              this.rountresult[i].cabinClass = this.oneway.class;
              this.rountresult[i].find1 = this.find1[i];
              this.rountresult[i].find2 = this.find2[i];
              this.rountresult[i].ShoppingResponseId = shoppingId;
              this.rountresult[i].selectId = offerId[i];
              this.rountresult[i].Adult = this.aduls;
              this.rountresult[i].Child = this.aduls1;
              this.rountresult[i].Inf = this.aduls2;
              this.rountresult[i].radioData = true;
              this.rountresult[i].radiretData = true;
              this.rountresult[i].radios = false;
              this.rountresult[i].radiosret = false;
              this.rountresult[i].fromTrip = this.tripData.from;
              this.rountresult[i].toTrip = this.tripData.to;
            }
            this.rountmultiData = this.rountresult;
            this.filterData = this.rountresult;
            console.log(this.rountresult, "resulppppppppppppppppppppppppt");
            this.gettimedep();
            this.gettimeret();
            // this.singlepriceRountData();
            // this.filterData = this.rountresult;
            this.priceRange();
            // this.calculateTotalTime();
            this.Shortest();
            this.Recommend();
            this.Cheapest();
            this.bestlayTime();
            this.airlineFilter();
            this.stopFilter();

            if (this.rountresult) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }
            // console.log(this.rountresult, "resulppppppppppppppppppppppppt");

            // this.filterShowing();

            // console.log(this.rountresult, 'result222222222222222222222222')
            this.filterData = this.rountresult;
            this.filterData.sort((a, b) => a.perperson - b.perperson);
            this.rountresult = this.filterData;
          });
        }
      }
    },
    // -------depTime start------

    singlepriceRountData() {
      let $event = [];
      // let $event1 = [];
      let $data = this.rountresult;
      this.rountresult.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.TotalPrice == v.TotalPrice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.rountresult = $event.sort((a, b) => a.perperson - b.perperson);

      this.rountresult.forEach((a, i) => {
        let count = 0;

        $data.forEach((b) => {
          if (
            a.TotalPrice == b.TotalPrice &&
            a.ownername == b.ownername &&
            (a.Depaturejourney.Time !== b.Depaturejourney.Time ||
              a.Returnjourney.Time !== b.Returnjourney.Time)
          ) {
            // console.log(
            //   a.ownername,
            //   a.Baseprice,
            //   a.Depaturejourney.Time,
            //   i,
            //   "aaaaaaaaaa"
            // );
            // console.log(
            //   b.ownername,
            //   b.Baseprice,
            //   b.Depaturejourney.Time,
            //   j,
            //   "bbbbbbbbb"
            // );
            count++;
          }
        });
        console.log();
        if (count >= 1) {
          this.rountresult[i].multi = "Show more Options";
        }
      });

      this.filterData = this.rountresult;
      console.log($event, "this.onewayfilterData6666666$event");
    },

    addmoreroundData(data, index) {
      console.log(data, index, this.rountresult, "vasanrhtttt");

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radios = false;
          v.radiosret = false;
          v.multipleDatas = !v.multipleDatas;
        } else {
          v.multipleDatas = false;
        }
      });
      let $event = [];

      this.rountmultiData.forEach((v) => {
        if (data.TotalPrice == v.TotalPrice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });
      console.log($event, "ppppppwww");
      let $result = [];
      $event.forEach((a) => {
        if (
          !$result.some(
            (b) =>
              b.Depaturejourney.Time == a.Depaturejourney.Time &&
              b.Returnjourney.Time == a.Returnjourney.Time
          )
        ) {
          $result.push(a);
        }
      });

      this.multiplesameData = $result;
      let $data = [];
      let $data1 = [];
      this.multiplesameData.forEach((k) => {
        if (
          !$data.some((b) => b.Depaturejourney.Time == k.Depaturejourney.Time)
        ) {
          $data.push(k);
        }
        if (!$data1.some((b) => b.Returnjourney.Time == k.Returnjourney.Time)) {
          $data1.push(k);
        }
      });
      this.multiDepatureData = $data;
      this.multireturnData = $data1;
    },

    multipriceData() {
      let $event = [];
      // let $event1 = [];
      let $data = this.multicityData;
      this.multicityData.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.TotalPrice == v.TotalPrice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.multicityData = $event.sort((a, b) => a.perperson - b.perperson);

      this.multicityData.forEach((a, i) => {
        let count = 0;

        $data.forEach((b) => {
          if (a.TotalPrice == b.TotalPrice && a.ownername == b.ownername) {
            if (
              a.Flight1JourneyTime.Time !== b.Flight1JourneyTime.Time ||
              a.Flight2JourneyTime.Time !== b.Flight2JourneyTime.Time ||
              (a.Flight3JourneyTime &&
                a.Flight3JourneyTime.Time !== b.Flight3JourneyTime &&
                b.Flight3JourneyTime.Time) ||
              (a.Flight4JourneyTime &&
                a.Flight4JourneyTime.Time !== b.Flight4JourneyTime &&
                b.Flight4JourneyTime.Time)
            ) {
              count++;
              console.log(a, i);
            }
          }
        });
        console.log();
        if (count > 1) {
          this.multicityData[i].multi = "Show more Options";
        }
      });

      this.multiFilterData = this.multicityData;
      console.log($event, "this.onewayfilterData6666666$event");
    },

    multiroundData(data, index) {
      console.log(data, index, this.multicityData, "vasanrhtttt");

      this.multicityData.find((v, i) => {
        if (i == index) {
          console.log(i, index, v, "lllll");

          v.multipleDatas4 = !v.multipleDatas4;
          v.multipleDatas3 = !v.multipleDatas3;
          v.multipleDatas2 = !v.multipleDatas2;
          v.multipleDatas1 = !v.multipleDatas1;
        } else {
          v.multipleDatas1 = false;
          v.multipleDatas2 = false;
          v.multipleDatas3 = false;
          v.multipleDatas4 = false;
        }
      });
      let $event = [];

      this.multishowData.forEach((v) => {
        if (data.TotalPrice == v.TotalPrice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });

      console.log($event, "$event$event");

      let $result = [];
      $event.forEach((a) => {
        if (
          !$result.some(
            (b) =>
              b.Flight1JourneyTime.Time == a.Flight1JourneyTime.Time &&
              b.Flight2JourneyTime.Time == a.Flight2JourneyTime.Time &&
              (b.Flight3JourneyTime && b.Flight3JourneyTime.Time
                ? b.Flight3JourneyTime.Time
                : "" == a.Flight3JourneyTime && a.Flight3JourneyTime.Time
                ? b.Flight3JourneyTime.Time
                : "") &&
              (b.Flight4JourneyTime && b.Flight4JourneyTime.Time
                ? b.Flight4JourneyTime.Time
                : "" == a.Flight4JourneyTime && a.Flight4JourneyTime.Time
                ? b.Flight4JourneyTime.Time
                : "")
          )
        ) {
          $result.push(a);
        }
      });

      this.multiplesameData = $result;
      let $data1 = [];
      let $data2 = [];
      let $data3 = [];
      let $data4 = [];
      // let $data1 = [];
      this.multiplesameData.forEach((k) => {
        if (
          !$data1.some(
            (b) =>
              b.Flight1JourneyTime.Time == k.Flight1JourneyTime.Time ||
              b.Flight2JourneyTime.Time == k.Flight2JourneyTime.Time ||
              (b.Flight3JourneyTime && b.Flight3JourneyTime.Time
                ? b.Flight3JourneyTime.Time
                : "" == k.Flight3JourneyTime && k.Flight3JourneyTime.Time
                ? k.Flight3JourneyTime.Time
                : "") ||
              (b.Flight4JourneyTime && b.Flight4JourneyTime.Time
                ? b.Flight4JourneyTime.Time
                : "" == k.Flight4JourneyTime && k.Flight4JourneyTime.Time
                ? k.Flight4JourneyTime.Time
                : "")
          )
        ) {
          $data1.push(k);
        }

        if (
          !$data2.some(
            (b) => b.Flight2JourneyTime.Time == k.Flight2JourneyTime.Time
          )
        ) {
          $data2.push(k);
        }

        if (
          !$data3.some((b) =>
            b.Flight3JourneyTime && b.Flight3JourneyTime.Time
              ? b.Flight3JourneyTime.Time
              : "" == k.Flight3JourneyTime && k.Flight3JourneyTime.Time
              ? k.Flight3JourneyTime.Time
              : ""
          )
        ) {
          $data3.push(k);
        }

        if (
          !$data4.some((b) =>
            b.Flight4JourneyTime && b.Flight4JourneyTime.Time
              ? b.Flight4JourneyTime.Time
              : "" == k.Flight4JourneyTime && k.Flight4JourneyTime.Time
              ? k.Flight4JourneyTime.Time
              : ""
          )
        ) {
          $data4.push(k);
        }
      });
      this.multiflight1 = $data1;
      this.multiflight2 = $data2;
      this.multiflight3 = $data3;
      this.multiflight4 = $data4;

      console.log(this.multiflight1, "this.multiflight1");
      console.log(this.multiflight2, "this.multiflight2");
      console.log(this.multiflight3, "this.multiflight3");
      console.log(this.multiflight4, "this.multiflight4");
    },

    radiodep(res, index) {
      console.log(res, index, "res, indexrrrrrr");
      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radioData = true;
          v.radiretData = true;

          this.multireturnData.forEach((d) => {
            d.radiosret = false;
          });
          this.multiDepatureData.forEach((d) => {
            d.radios = false;
          });
        }
      });
    },

    multione(data, multindex, index) {
      //  console.log(data, multindex,index,'data, multindex,indexuuuuufdfdjjb')

      this.multiDepatureData.find((e, j) => {
        console.log(j, multindex, index, "nanduuuuu");
        if (multindex == j) {
          console.log(e, "wwwwwwwwww");
          e.radios = true;
        } else {
          console.log(e, "wwwwwtttttwwwww");
          e.radios = false;
        }
      });

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radios = true;
          v.radioData = false;
          v.radiretData = false;
          this.multireturnData.forEach((c, k) => {
            if (c.Returnjourney.Time == data.Returnjourney.Time) {
              console.log(c, k, (c.radiosret = false), "wwww");
              c.radiosret = false;
              v.radiosret = true;
              k == 0 ? (v.radiretData = true) : (c.radiosret = false);

              this.multireturnData.forEach((d, j) => {
                if (k == j) {
                  d.radiosret = true;
                } else {
                  d.radiosret = false;
                }
              });
            } else {
              c.radiosret = false;
            }
          });
        }
      });

      this.flightdetailsData = data;
    },

    multiredone(data, multindex, index) {
      console.log(data, multindex, index, "data, multindex,indexrrrrrrrr");

      this.rountresult.find((v, i) => {
        if (i == index) {
          v.radiosret = true;
          v.radioData = false;
          v.radiretData = false;
          this.multiDepatureData.forEach((c, k) => {
            if (c.Depaturejourney.Time == data.Depaturejourney.Time) {
              if (k == 0) {
                console.log(c, k, "wwww");
                v.radioData = true;
              } else {
                this.multiDepatureData.forEach((d, j) => {
                  if (k == j) {
                    console.log(d, "konoo");
                    d.radios = true;
                  } else {
                    d.radios = false;
                  }
                });
              }
            }
          });
        } else {
          v.radiosret = false;
        }
      });
      this.multireturnData.find((e, j) => {
        console.log(e, j, "ppppprrrrrrrrr");
        if (multindex == j) {
          e.radiosret = true;
        } else {
          e.radiosret = false;
        }
      });
      this.flightdetailsData = data;
    },

    multicityone1(data, multindex, index) {
      console.log(data, multindex, index);
      this.radioData1 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios1 = false;
        }
      });
    },

    multicityone2(data, multindex, index) {
      this.radioData2 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios2 = false;
        }
      });
    },

    multicityone3(data, multindex, index) {
      this.radioData3 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios3 = false;
        }
      });
    },
    multicityone4(data, multindex, index) {
      this.radioData4 = 1;
      console.log(data, multindex, index);
      this.multicityData.find((c, i) => {
        if (i == index) {
          c.radios4 = false;
        }
      });
    },
    resetFilter() {
      (this.NonStopFil = true),
        (this.OneStopFil = true),
        (this.OnePlusFil = true),
        (this.RefundableData = true),
        (this.nonRefundableData = true),
        (this.timearrFilterData1 = false),
        (this.timearrFilterData2 = false),
        (this.timearrFilterData3 = false),
        (this.timearrFilterArrData1 = false),
        (this.timearrFilterArrData2 = false),
        (this.timearrFilterArrData3 = false),
        (this.timeFilterData1 = false),
        (this.timeFilterData2 = false),
        (this.timeFilterData3 = false),
        (this.timeFilterArrData1 = false),
        (this.timeFilterArrData2 = false),
        (this.timeFilterArrData3 = false),
        this.airlineList.forEach((v) => {
          v.active = true;
        });
    },
    TimeFilter1() {
      this.timeFilterData1 = !this.timeFilterData1;

      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData1 && this.timeFilterData2) {
        this.timetwoFilter1();
      } else if (this.timeFilterData1 && this.timeFilterData3) {
        this.timetwoFilter2();
      } else if (this.timeFilterData1) {
        // alert("pppp");
        this.TimeFilterData6();
      } else {
        this.removeFilter1();
      }
      console.log(this.rountresult, "443");
    },
    TimeFilter2() {
      this.timeFilterData2 = !this.timeFilterData2;
      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData1 && this.timeFilterData2) {
        this.timetwoFilter1();
      } else if (this.timeFilterData3 && this.timeFilterData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterData2) {
        this.TimeFilterDataafter12();
        // console('1111')
        console.log(this.rountresult, "443");
      } else {
        this.removeFilter2();
      }
    },
    TimeFilter3() {
      this.timeFilterData3 = !this.timeFilterData3;
      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData3 && this.timeFilterData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterData3 && this.timeFilterData1) {
        this.timetwoFilter2();
      } else if (this.timeFilterData3) {
        this.TimeFilterDataafter18();
      } else {
        this.removeFilter3();
      }
    },

    // -----------deptime  end-------

    releseFilter() {
      if (
        this.timeFilterData1 &&
        this.timeFilterData2 &&
        this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterData1 &&
        !this.timeFilterData2 &&
        !this.timeFilterData3
      ) {
        this.totalTimeData();
      } else if (this.timeFilterData1 && this.timeFilterData2) {
        this.timetwoFilter1();
      } else if (this.timeFilterData1 && this.timeFilterData3) {
        this.timetwoFilter2();
      } else if (this.timeFilterData1) {
        this.TimeFilterData6();
      } else if (this.timeFilterData2) {
        this.TimeFilterDataafter12();
      } else if (this.timeFilterData3) {
        this.TimeFilterDataafter18();
      }
    },

    // -------arrtime start ---------

    TimeFilterarr1() {
      // alert("ppppp");
      this.timeFilterArrData1 = !this.timeFilterArrData1;

      if (
        this.timeFilterArrData1 &&
        this.timeFilterArrData2 &&
        this.timeFilterArrData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterArrData1 &&
        !this.timeFilterArrData2 &&
        !this.timeFilterArrData3
      ) {
        if (
          this.timeFilterData1 ||
          this.timeFilterData2 ||
          this.timeFilterData3
        ) {
          this.releseFilter();
        } else {
          this.totalTimeData();
        }
      } else if (this.timeFilterArrData2 && this.timeFilterArrData1) {
        console.log("ttttttt");
        this.timetwoFilter1();
      } else if (this.timeFilterArrData1 && this.timeFilterArrData3) {
        this.timetwoFilter2();
      } else if (this.timeFilterArrData1) {
        this.TimeFilterData6();
      } else {
        this.removeFilter1();
      }
      console.log(this.rountresult, "443");
    },
    TimeFilterarr2() {
      this.timeFilterArrData2 = !this.timeFilterArrData2;
      if (
        this.timeFilterArrData1 &&
        this.timeFilterArrData2 &&
        this.timeFilterArrData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterArrData1 &&
        !this.timeFilterArrData2 &&
        !this.timeFilterArrData3
      ) {
        if (
          this.timeFilterData1 ||
          this.timeFilterData2 ||
          this.timeFilterData3
        ) {
          this.releseFilter();
        } else {
          this.totalTimeData();
        }
      } else if (this.timeFilterArrData1 && this.timeFilterArrData2) {
        // console('2222')
        this.timetwoFilter1();
      } else if (this.timeFilterArrData3 && this.timeFilterArrData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterArrData2) {
        this.TimeFilterDataafter12();
        // console('1111')
        console.log(this.rountresult, "443");
      } else {
        this.removeFilter2();
      }
    },
    TimeFilterarr3() {
      this.timeFilterArrData3 = !this.timeFilterArrData3;
      if (
        this.timeFilterArrData1 &&
        this.timeFilterArrData2 &&
        this.timeFilterArrData3
      ) {
        this.totalTimeData();
      } else if (
        !this.timeFilterArrData1 &&
        !this.timeFilterArrData2 &&
        !this.timeFilterArrData3
      ) {
        if (
          this.timeFilterData1 ||
          this.timeFilterData2 ||
          this.timeFilterData3
        ) {
          this.releseFilter();
        } else {
          this.totalTimeData();
        }
      } else if (this.timeFilterArrData3 && this.timeFilterArrData2) {
        this.timetwoFilter3();
      } else if (this.timeFilterArrData3 && this.timeFilterArrData1) {
        this.timetwoFilter2();
      } else if (this.timeFilterArrData3) {
        //  console.log('oopopoo')
        this.TimeFilterDataafter18();
      } else {
        this.removeFilter3();
      }
    },
    // --------arrtime end------

    TimeFilterData6() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          console.log("vasanth");
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          console.log("keerthi");
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        console.log($twoFilter, "$twoFilter$twoFilter");
        if (
          $twoFilter &&
          (this.timeFilterData1 || this.timeFilterData2 || this.timeFilterData3)
        ) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "12:00"
          ) {
            console.log(
              v.Depature[0].Arrival.Time,
              " v.Depature[0].Arrival.Time"
            );
            $result.push(v);
            console.log("ppopopoo", $result);
          }
        } else if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
          ) {
            $result.push(v);

            console.log(
              "ppopopoo555",
              this.timeFilterArrData1,
              this.timeFilterArrData2,
              this.timeFilterArrData3
            );
          }
        }
      });
      if (this.filterData.length > 0) {
        console.log("ppopopoo11", $result);
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    TimeFilterDataafter12() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timeFilterData1 || this.timeFilterData2 || this.timeFilterData3)
        ) {
          if (
            v.Depature[0].Departure.Time > "12:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            $result.push(v);
          }
        } else if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    TimeFilterDataafter18() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timeFilterData1 || this.timeFilterData2 || this.timeFilterData3)
        ) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            // console.log('pp')
          } else {
            $result.push(v);
          }
        } else if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            //  console.log('pp')
          } else {
            $result.push(v);
          }
        }
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }

      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    timetwoFilter1() {
      // alert("kee");
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
          //  console.log('keerthi',this.filterData)
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }

      $event.forEach((v) => {
        v.Depature.forEach((c, i) => {
          if (i === 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "06:00" && departureTime < "18:00") {
              $result.push(v);
              console.log("oooo", departureTime);
            }
          }

          if (
            (i == v.Depature.length - 1 && this.timeFilterArrData1) ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "06:00" && arrivalTime < "18:00") {
              $result.push(v);
              console.log("oooo222");
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    timetwoFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "12:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            //  <!-- $result.push(v) -->
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    timetwoFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "12:00"
          ) {
            //  <!-- $result.push(v) -->
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    removeFilter1() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
          console.log("keerthi", $event);
        } else {
          $event = this.rountresult;
          console.log("keerthi", $event);
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "12:00"
          ) {
            console.log(
              v.Depature[0].Departure.Time,
              "v.Depature[0].Departure.Time "
            );
          } else {
            $result.push(v);
            console.log($result, "$result");
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "12:00"
          ) {
            console.log("llllll");
          } else {
            $result.push(v);
            console.log("keerthi$resul6666666666666", $result);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    removeFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          console.log("vasanth");
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "12:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            //  <!-- $result.push(v) -->
          } else {
            $result.push(v);
          }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "12:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    removeFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          // console.log('keerthi')
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if ($twoFilter) {
          if (
            v.Depature[0].Departure.Time > "06:00" &&
            v.Depature[0].Departure.Time < "18:00"
          ) {
            $result.push(v);
          }
          //        else {
          //        $result.push(v)
          //  }
        }

        if (
          this.timeFilterArrData1 ||
          this.timeFilterArrData2 ||
          this.timeFilterArrData3
        ) {
          if (
            v.Depature[v.Depature.length - 1].Arrival.Time > "06:00" &&
            v.Depature[v.Depature.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
          }
          //        else {
          //        $result.push(v)
          //  }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    totalTimeData() {
      let $event = [];

      if (this.filterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          // console.log('vasanth')
          $event = this.rountresult;
        } else {
          // console.log('keerthi')
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timeFilterData1 ||
            this.timeFilterData2 ||
            this.timeFilterData3) &&
          (this.timeFilterArrData1 ||
            this.timeFilterArrData2 ||
            this.timeFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayfilterData;
        }
      }

      if (this.filterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.rountresult = $event;
      }
      if (this.onewayfilterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.onewayresulted = $event;
      }
    },

    // -----------------return journey time filter start--------------

    TimeretFilter1() {
      this.timearrFilterData1 = !this.timearrFilterData1;

      if (
        this.timearrFilterData1 &&
        this.timearrFilterData2 &&
        this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterData1 &&
        !this.timearrFilterData2 &&
        !this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterData1 && this.timearrFilterData2) {
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterData1 && this.timearrFilterData3) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterData1) {
        this.TimeFilterDataArrival6();
      } else {
        this.removeReturnFilter1();
      }
      console.log(this.rountresult, "443");
    },
    TimeretFilter2() {
      this.timearrFilterData2 = !this.timearrFilterData2;
      if (
        this.timearrFilterData1 &&
        this.timearrFilterData2 &&
        this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterData1 &&
        !this.timearrFilterData2 &&
        !this.timearrFilterData
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterData1 && this.timearrFilterData2) {
        // console('2222')
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterData2 && this.timearrFilterData3) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterData2) {
        // alert('pppp')
        this.TimeFilterDataArrival2();
        // console('1111')
        console.log(this.rountresult, "443");
      } else {
        this.removeReturnFilter2();
      }
    },
    TimeretFilter3() {
      this.timearrFilterData3 = !this.timearrFilterData3;
      if (
        this.timearrFilterData1 &&
        this.timearrFilterData2 &&
        this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterData1 &&
        !this.timearrFilterData2 &&
        !this.timearrFilterData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterData3 && this.timearrFilterData2) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterData3 && this.timearrFilterData1) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterData3) {
        this.TimeFilterDataArriva18();
      } else {
        this.removeReturnFilter3();
      }
    },

    TimeretFilterarr1() {
      this.timearrFilterArrData1 = !this.timearrFilterArrData1;

      if (
        this.timearrFilterArrData1 &&
        this.timearrFilterArrData2 &&
        this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterArrData1 &&
        !this.timearrFilterArrData2 &&
        !this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData2) {
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterArrData1) {
        this.TimeFilterDataArrival6();
      } else {
        this.removeReturnFilter1();
      }
      // console.log(this.rountresult,'443')
    },
    TimeretFilterarr2() {
      this.timearrFilterArrData2 = !this.timearrFilterArrData2;
      if (
        this.timearrFilterArrData1 &&
        this.timearrFilterArrData2 &&
        this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterArrData1 &&
        !this.timearrFilterArrData2 &&
        !this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData2) {
        // console('2222')
        this.timetwoReturnFilter1();
      } else if (this.timearrFilterArrData2 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterArrData2) {
        this.TimeFilterDataArrival2();
        // console('1111')
        // console.log(this.rountresult, '443')
      } else {
        this.removeReturnFilter2();
      }
    },
    TimeretFilterarr3() {
      this.timearrFilterArrData3 = !this.timearrFilterArrData3;
      if (
        this.timearrFilterArrData1 &&
        this.timearrFilterArrData2 &&
        this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (
        !this.timearrFilterArrData1 &&
        !this.timearrFilterArrData2 &&
        !this.timearrFilterArrData3
      ) {
        this.totalReturnTimeData();
      } else if (this.timearrFilterArrData2 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter3();
      } else if (this.timearrFilterArrData1 && this.timearrFilterArrData3) {
        this.timetwoReturnFilter2();
      } else if (this.timearrFilterArrData3) {
        this.TimeFilterDataArriva18();
      } else {
        this.removeReturnFilter3();
      }
    },

    TimeFilterDataArrival6() {
      // console.log("sgninngin");
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        console.log(v, "pp");
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          if (
            v.Return[0].Departure.Time > "06:00" &&
            v.Return[0].Departure.Time < "12:00"
          ) {
            $result.push(v);
            console.log(v, "pp1111");
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "12:00"
          ) {
            console.log($result, "$result222222");
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    TimeFilterDataArrival2() {
      // alert("pppp");
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          console.log(v, v.Return[0].Departure.Time, "pp333111");
          if (
            v.Return[0].Departure.Time > "12:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            $result.push(v);
            console.log(v, "pp111");
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          console.log(v, "pp22555222");
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "12:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
            console.log(v, "pp22222");
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    TimeFilterDataArriva18() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        console.log(v, "pp");
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          if (
            v.Return[0].Departure.Time > "06:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            // $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    timetwoReturnFilter1() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }

      $event.forEach((v) => {
        v.Return.forEach((c, i) => {
          if (i == 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "06:00" && departureTime < "18:00") {
              $result.push(v);
            }
          }

          if (
            (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "06:00" && arrivalTime < "18:00") {
              $result.push(v);
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    timetwoReturnFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }

      $event.forEach((v) => {
        v.Return.forEach((c, i) => {
          if (i == 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "12:00" && departureTime < "18:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }

          if (
            (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "12:00" && arrivalTime < "18:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },
    timetwoReturnFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayfilterData;
        }
      }

      $event.forEach((v) => {
        v.Return.forEach((c, i) => {
          if (i == 0 && $twoFilter) {
            const departureTime = c.Departure.Time;
            if (departureTime > "06:00" && departureTime < "12:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }

          if (
            (i == v.Return.length - 1 && this.timearrFilterArrData1) ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3
          ) {
            const arrivalTime = c.Arrival.Time;
            if (arrivalTime > "06:00" && arrivalTime < "12:00") {
              // $result.push(v);
            } else {
              $result.push(v);
            }
          }
        });
      });

      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    totalReturnTimeData() {
      let $event = [];

      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          //  $twoFilter=false
        } else {
          $event = this.filterData;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          //  $twoFilter=false
        } else {
          $event = this.onewayfilterData;
        }
      }

      if (this.filterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.rountresult = $event;
      }
      if (this.onewayfilterData.length > 0) {
        $event.sort((a, b) => a.perperson - b.perperson);
        this.onewayresulted = $event;
      }
    },

    removeReturnFilter1() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          console.log("pppppp2222");
          if (
            v.Return[0].Departure.Time > "06:00" &&
            v.Return[0].Departure.Time < "12:00"
          ) {
            // console.log('tttv')
          } else {
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          console.log("pppppp222266666666666666");
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "12:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    removeReturnFilter2() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          if (
            v.Return[0].Departure.Time > "12:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            // console.log('tttv')
          } else {
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "12:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            //  $result.push(v)
          } else {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    removeReturnFilter3() {
      let $event = [];
      let $result = [];
      let $twoFilter = true;
      if (this.filterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.rountresult;
          $twoFilter = false;
        } else {
          $event = this.rountresult;
        }
      }

      if (this.onewayfilterData.length > 0) {
        if (
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3) &&
          (this.timearrFilterArrData1 ||
            this.timearrFilterArrData2 ||
            this.timearrFilterArrData3)
        ) {
          $event = this.onewayresulted;
          $twoFilter = false;
        } else {
          $event = this.onewayresulted;
        }
      }
      $event.forEach((v) => {
        if (
          $twoFilter &&
          (this.timearrFilterData1 ||
            this.timearrFilterData2 ||
            this.timearrFilterData3)
        ) {
          if (
            v.Return[0].Departure.Time > "06:00" &&
            v.Return[0].Departure.Time < "18:00"
          ) {
            console.log("tttv22", v.Return[0].Departure.Time);
            $result.push(v);
          }
        }

        if (
          this.timearrFilterArrData1 ||
          this.timearrFilterArrData2 ||
          this.timearrFilterArrData3
        ) {
          if (
            v.Return[v.Return.length - 1].Arrival.Time > "06:00" &&
            v.Return[v.Return.length - 1].Arrival.Time < "18:00"
          ) {
            $result.push(v);
          }
        }
      });
      if (this.filterData.length > 0) {
        this.rountresult = $result;
      }
      if (this.onewayfilterData.length > 0) {
        this.onewayresulted = $result;
      }
    },

    airlineFilter() {
      let storeAirline = [];
      let $event = [];
      if (this.filterData.length > 0) {
        $event = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $event = this.onewayfilterData;
      }
      let airlogo = [];
      $event.forEach((v) => {
        if (!storeAirline.includes(v.ownername)) {
          storeAirline.push(v.ownername);
          airlogo.push(v.ownerlogo);
        }
      });
      storeAirline.forEach((s, index) => {
        let obj = {
          airline: s,
          active: true,
          airlinelog: airlogo[index],
        };
        this.airlineList.push(obj);
      });
      console.log(this.airlineList, "airline logos");
      this.filterAirline = this.airlineList;
    },

    selectAirline(data, index) {
      let $data = this.filterData;
      let $result = [];
      this.airlineList.map((a, i) => {
        if (i == index) {
          a.active = !a.active;
        }
        if (a.active) {
          // console.log(count,'count')
          $data.forEach((c) => {
            if (c.ownername == a.airline) {
              $result.push(c);
            }
          });
        }

        $result.sort((a, b) => a.perperson - b.perperson);
        if (this.filterData.length > 0) {
          this.rountresult = $result;
        }

        if (this.onewayfilterData.length > 0) {
          this.onewayresulted = $result;
        }
      });

      //  this.Cheapest()
      //   this.Shortest()
      // this.bestlayTime()
      //  this.Recommend()
      $result.sort((a, b) => a.perperson - b.perperson);
      this.rountresult = $result;
    },

    airlineClear() {
      let $result = [];
      let $data = [];

      if (this.filterData.length > 0) {
        $data = this.filterData;
      }

      if (this.onewayfilterData.length > 0) {
        $data = this.onewayfilterData;
      }

      this.airlineList.forEach((v, i) => {
        if (i !== 0) {
          v.active = false;
        } else {
          v.active = true;
          $data.forEach((c) => {
            if (c.ownername == v.airline) {
              $result.push(c);
            }
          });
        }
      });

      $result.sort((a, b) => a.perperson - b.perperson);
      this.rountresult = $result;
    },

    airlineSelect() {
      this.airlineList.forEach((v) => {
        v.active = true;
      });
      this.rountresult = this.filterData;
    },

    stopFilter() {
      this.filterData.forEach((v) => {
        if (v.Depaturejourney.Stops == 0 || v.Returnjourney.Stops == 0) {
          this.showNonStop = true;
        } else if (v.Depaturejourney.Stops == 1 || v.Returnjourney.Stops == 1) {
          this.showOneStop = true;
        } else if (v.Depaturejourney.Stops >= 1 || v.Returnjourney.Stops >= 1) {
          this.showOneplusStop = true;
        }
      });
    },

    multicitystopFilter() {
      this.multiFilterData.forEach((v) => {
        if (
          v.Flight1JourneyTime.Stops == 0 ||
          v.Flight2JourneyTime.Stops == 0 ||
          (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
            ? v.Flight3JourneyTime.Stops
            : "" == 0) ||
          (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
            ? v.Flight4JourneyTime.Stops
            : "" == 0)
        ) {
          this.showNonStop = true;
        } else if (
          v.Flight1JourneyTime.Stops == 1 ||
          v.Flight1JourneyTime.Stops == 1 ||
          (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
            ? v.Flight3JourneyTime.Stops
            : "" == 1) ||
          (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
            ? v.Flight4JourneyTime.Stops
            : "" == 1)
        ) {
          this.showOneStop = true;
        } else if (
          v.Flight1JourneyTime.Stops >= 1 ||
          v.Flight1JourneyTime.Stops >= 1 ||
          (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
            ? v.Flight3JourneyTime.Stops
            : "" >= 1) ||
          (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
            ? v.Flight4JourneyTime.Stops
            : "" >= 1)
        ) {
          this.showOneplusStop = true;
        }
      });
    },

    getReturnFilter($data) {
      console.log(
        $data.Depature[$data.Depature.length - 1].Arrival.AirportCode,
        "lllllllll"
      );
      return $data.Depature[$data.Depature.length - 1].Arrival.AirportCode;
    },
    getReturnFilter1($data) {
      return $data.Return[$data.Return.length - 1].Arrival.AirportCode;
    },

    gettimedep() {
      // for (let i = 0; i < this.rountresult.length; i++) {
      //   for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
      //     const currentIndex = j;
      //     const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

      //     const time1 = new Date(
      //       `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
      //     );
      //     const time2 = new Date(
      //       `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
      //     );

      //     const diffInMilliseconds = Math.abs(time2 - time1);
      //     const diffInMinutes = Math.floor(diffInMilliseconds / (1000 * 60));

      //     const hours = Math.floor(diffInMinutes / 60);
      //     const minutes = diffInMinutes % 60;

      //     this.rountresult[i].Depature[currentIndex].layoverTimes = `${hours
      //       .toString()
      //       .padStart(2, "0")} h:${minutes.toString().padStart(2, "0")} min`;
      //   }
      // }

      let layoverTime = [];
      for (let i = 0; i < this.rountresult.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.rountresult[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Depature.length;

          const time1 = new Date(
            `${this.rountresult[i].Depature[currentIndex].Arrival.Date}T${this.rountresult[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Depature[nextIndex].Departure.Date}T${this.rountresult[i].Depature[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(diffInHours + " h " + remainingMinutes + " min");
        }
        layoverTime.push(layoverTime1);
      }

      console.log(layoverTime, "ppppppppp");
      this.rountresult.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes1 = c;
          }
        });
      });
    },

    calculateTotalTime() {
      let $data = [];

      if (this.rountresult.length > 0) {
        this.rountresult.forEach((c) => {
          this.totalMinutesT1 = 0;
          c.Depature.forEach((v) => {
            let timeString = v.layoverTimes;
            const [hours, minutes] = timeString.split(" ");
            let $res =
              parseInt(hours) * 60 + parseInt(minutes.replace("h:", ""));
            this.totalMinutesT1 += $res;
          });
          $data.push(this.totalMinutesT1);
        });
        this.rountresult.forEach((element, i) => {
          element.layoverFilter = $data[i];
        });
      } else if (this.onewayresulted.length > 0) {
        this.onewayresulted.forEach((c) => {
          this.totalMinutesT1 = 0;
          c.Depature.forEach((v) => {
            let timeString = v.layoverTimes;
            const [hours, minutes] = timeString.split(" ");
            let $res =
              parseInt(hours) * 60 + parseInt(minutes.replace("h:", ""));
            this.totalMinutesT1 += $res;
          });
          $data.push(this.totalMinutesT1);
        });
        this.onewayresulted.forEach((element, i) => {
          element.layoverFilter = $data[i];
        });
      }
    },

    gettimeret() {
      let layoverTime = [];
      for (let i = 0; i < this.rountresult.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.rountresult[i].Return.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.rountresult[i].Return.length;

          const time1 = new Date(
            `${this.rountresult[i].Return[currentIndex].Arrival.Date}T${this.rountresult[i].Return[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.rountresult[i].Return[nextIndex].Departure.Date}T${this.rountresult[i].Return[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(diffInHours + " h " + remainingMinutes + " min");
        }
        layoverTime.push(layoverTime1);
      }

      console.log(layoverTime, "ppppppppp");
      this.rountresult.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes2 = c;
          }
        });
      });
    },

    async getlayTime() {
      let layoverTime = [];
      for (let i = 0; i < this.onewayresulted.length; i++) {
        let layoverTime1 = [];
        for (let j = 0; j < this.onewayresulted[i].Depature.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.onewayresulted[i].Depature.length;

          const time1 = new Date(
            `${this.onewayresulted[i].Depature[currentIndex].Arrival.Date}T${this.onewayresulted[i].Depature[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.onewayresulted[i].Depature[nextIndex].Departure.Date}T${this.onewayresulted[i].Depature[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layoverTime1.push(diffInHours + " h " + remainingMinutes + " min");
        }
        layoverTime.push(layoverTime1);
      }

      console.log(layoverTime, "ppppppppp");
      this.onewayresulted.forEach((a, i) => {
        layoverTime.forEach((c, j) => {
          if (i == j) {
            a.layoverTimes = c;
          }
        });
      });
    },

    getmultitime1() {
      let layoverTime1 = [];
      let layoverTime2 = [];
      let layoverTime3 = [];
      let layoverTime4 = [];

      for (let i = 0; i < this.multicityData.length; i++) {
        let layover1 = [];
        for (let j = 0; j < this.multicityData[i].Flight1.length; j++) {
          const currentIndex = j;
          const nextIndex = (j + 1) % this.multicityData[i].Flight1.length;

          const time1 = new Date(
            `${this.multicityData[i].Flight1[currentIndex].Arrival.Date}T${this.multicityData[i].Flight1[currentIndex].Arrival.Time}`
          );
          const time2 = new Date(
            `${this.multicityData[i].Flight1[nextIndex].Departure.Date}T${this.multicityData[i].Flight1[nextIndex].Departure.Time}`
          );

          let diffInMilliseconds = time2 - time1;
          let diffInMinutes = diffInMilliseconds / (1000 * 60);
          let diffInHours = Math.floor(diffInMinutes / 60);
          let remainingMinutes = diffInMinutes % 60;

          layover1.push(diffInHours + " h " + remainingMinutes + " min");
        }
        layoverTime1.push(layover1);

        this.multicityData.forEach((a, i) => {
          layoverTime1.forEach((c, j) => {
            if (i == j) {
              a.layoverTimes1 = c;
            }
          });
        });

        if (this.multicityData[i].Flight2) {
          let layover2 = [];
          for (let j = 0; j < this.multicityData[i].Flight2.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight2.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight2[currentIndex].Arrival.Date}T${this.multicityData[i].Flight2[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight2[nextIndex].Departure.Date}T${this.multicityData[i].Flight2[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover2.push(diffInHours + " h " + remainingMinutes + " min");
          }
          layoverTime2.push(layover2);

          this.multicityData.forEach((a, i) => {
            layoverTime2.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes2 = c;
              }
            });
          });
        }
        if (this.multicityData[i].Flight3) {
          let layover3 = [];
          for (let j = 0; j < this.multicityData[i].Flight3.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight3.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight3[currentIndex].Arrival.Date}T${this.multicityData[i].Flight3[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight3[nextIndex].Departure.Date}T${this.multicityData[i].Flight3[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover3.push(diffInHours + " h " + remainingMinutes + " min");
          }
          layoverTime3.push(layover3);

          this.multicityData.forEach((a, i) => {
            layoverTime3.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes3 = c;
              }
            });
          });
        }

        if (this.multicityData[i].Flight4) {
          let layover4 = [];
          for (let j = 0; j < this.multicityData[i].Flight4.length; j++) {
            const currentIndex = j;
            const nextIndex = (j + 1) % this.multicityData[i].Flight4.length;

            const time1 = new Date(
              `${this.multicityData[i].Flight4[currentIndex].Arrival.Date}T${this.multicityData[i].Flight4[currentIndex].Arrival.Time}`
            );
            const time2 = new Date(
              `${this.multicityData[i].Flight4[nextIndex].Departure.Date}T${this.multicityData[i].Flight4[nextIndex].Departure.Time}`
            );

            let diffInMilliseconds = time2 - time1;
            let diffInMinutes = diffInMilliseconds / (1000 * 60);
            let diffInHours = Math.floor(diffInMinutes / 60);
            let remainingMinutes = diffInMinutes % 60;

            layover4.push(diffInHours + " h " + remainingMinutes + " min");
          }
          layoverTime4.push(layover4);

          this.multicityData.forEach((a, i) => {
            layoverTime4.forEach((c, j) => {
              if (i == j) {
                a.layoverTimes4 = c;
              }
            });
          });
        }
      }
    },

    priceRange() {
      let min = Infinity;
      let max = -Infinity;

      for (let i = 0; i < this.filterData.length; i++) {
        const totalAmount = parseFloat(this.filterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.onewayfilterData.length; i++) {
        const totalAmount = parseFloat(this.onewayfilterData[i].perperson);
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      for (let i = 0; i < this.multiFilterData.length; i++) {
        const totalAmount = parseFloat(this.multiFilterData[i].perperson);

        console.log(totalAmount, "tatatatat,,,.....");
        if (totalAmount < min) {
          min = totalAmount;
        }
        if (totalAmount > max) {
          max = totalAmount;
        }
      }

      this.minimum = min.toFixed(2);
      this.maximum = max.toFixed(2);
      this.steps = ((max - min) / 50).toFixed(2);
      this.changevalue[0] = min.toFixed(2);
      this.changevalue[1] = max.toFixed(2);

      this.currencyCode = this.preferCurrency;
    },

    // Filter initailly Working ------->

    onewaytrip() {
      let onewayresult = [];
      onewayresult = JSON.parse(localStorage.getItem("onewaytrip"));
      console.log(onewayresult, "onewayresult");
      let baggageList = [];
      if (onewayresult) {
        for (let i = 0; i < onewayresult.length; i++) {
          if (onewayresult[i]) {
            this.Journey = onewayresult[i].DataLists.FlightList.Flight;
            baggageList =
              onewayresult[i].DataLists.BaggageAllowanceList.BaggageAllowance;
            let shoppingId = onewayresult[i].ShoppingResponseId;
            let refund = [];
            let Baseprice = [];
            let Tax = [];
            let pricedetail = [];
            let $data = onewayresult[i].OffersGroup.AirlineOffers.Offer;
            // this.oneway.class = onewayresult[i]

            let $data_onewayId =
              onewayresult[i].OffersGroup.AirlineOffers.Offer;

            //  console.log($data,'onewaaayyyyyyy....1...')

            let offerId = [];
            let depsplit = [];
            let resultdata1 = [];
            let currency = [];
            let ownerlogo = [];
            let ownername = [];
            let perperson = [];
            let TotalPrice = [];
            $data_onewayId.forEach((v) => {
              offerId.push(v.OfferID);
            });

            $data.forEach((s) => {
              if (s.ReqCurrency == "USD") {
                currency.push("US$");
              } else if (s.ReqCurrency == "CAD") {
                currency.push("CA$");
              } else if (s.ReqCurrency == "AUD") {
                currency.push("AU$");
              }
              ownername.push(s.OwnerName);
              for (let i = 0; i < this.airline_logoData.length - 1; i++) {
                if (s.Owner == this.airline_logoData[i].id) {
                  ownerlogo.push(this.airline_logoData[i].logo);
                }
              }

              Baseprice.push(
                parseFloat(s.BasePrice.BookingCurrencyPrice).toFixed(2)
              );
              Tax.push(parseFloat(s.TaxPrice.BookingCurrencyPrice).toFixed(2));
              perperson.push(
                parseFloat(s.PerPerson.BookingCurrencyPrice).toFixed(2)
              );
              TotalPrice.push(
                parseFloat(s.TotalPrice.BookingCurrencyPrice).toFixed(2)
              );

              pricedetail.push(s.OfferItem);

              s.OfferItem.forEach((v) => {
                if (v[0].FareDetail[0].Price.NonRefundable !== "true") {
                  refund.push("Refundable");
                } else {
                  refund.push("Non Refundable");
                }

                if (v[0].FareComponent[0].SegmentRefs) {
                  resultdata1 = v[0].FareComponent[0].SegmentRefs;
                }
              });

              depsplit.push(resultdata1.split(" "));
            });

            // console.log(depsplit, '$data')
            this.Journey1 =
              onewayresult[i].DataLists.FlightSegmentList.FlightSegment;
            // console.log( this.Journey1,'flight1')Seatsseats
            this.Journey.forEach((a) => {
              this.routtime.push(a.Journey);
              this.flightsegmet.push(a.SegmentReferences.split(" "));
            });
            this.Journey1.forEach((t) => {
              this.flightsegmetkey.push(t);
            });
            // let seats = [];
            // seats = onewayresult[i].DataLists.PriceClassList.PriceClass;
            // seats.forEach((c) => {
            //   c.ClassOfService.forEach((v) => {
            //     for (let i = 0; i < this.flightsegmetkey.length - 1; i++) {
            //       if (v.SegementRef == this.flightsegmetkey[i].SegmentKey) {
            //         this.flightsegmetkey[i].Seat = v.Code.SeatsLeft;
            //       }
            //     }
            //   });
            // });

            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              for (let j = 0; j < this.flightsegmetkey.length - 1; j++) {
                if (
                  this.flightsegmetkey[j].MarketingCarrier.AirlineID ==
                  this.airlogodata[i].id
                ) {
                  this.flightsegmetkey[j].MarketingCarrier.logo =
                    this.airlogodata[i].logo;
                }
              }
            }

            for (let i = 0; i <= depsplit.length - 1; i++) {
              let seg = [];
              depsplit[i].forEach((f, index) => {
                let items = [];
                items = this.flightsegmetkey.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == depsplit[i].length - 1) {
                  this.onewayresulted.push({ Depature: seg });
                }
              });
            }

            let depjourney = [];
            let Baggage = [];
            for (let j = 0; j <= $data.length - 1; j++) {
              let result = $data[j].OfferItem[0][0].FareComponent[0];
              Baggage.push(
                $data[j].OfferItem[0][0].BaggageAllowance[0].BaggageAllowanceRef
              );
              let source = [];

              for (let i = 0; i <= this.Journey.length - 1; i++) {
                if (result.SegmentRefs == this.Journey[i].SegmentReferences) {
                  source = this.Journey[i].Journey;
                }
              }

              depjourney.push(source);
            }
            let BaggageData = [];

            Baggage.forEach((v) => {
              baggageList.forEach((c) => {
                if (v == c.BaggageAllowanceID) {
                  BaggageData.push(c);
                }
              });
            });

            for (let i = 0; i < perperson.length; i++) {
              let nume1 = perperson[i];
              // console.log(nume1, "llldlldldldldld")
              this.find1.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[0]
              );
              this.find2.push(
                (Math.round(nume1 * 100) / 100)
                  .toFixed(2)
                  .toString()
                  .split(".")[1]
              );
            }

            for (let i = 0; i < this.onewayresulted.length; i++) {
              this.onewayresulted[i].Baseprice = Baseprice[i];
              this.onewayresulted[i].TotalPrice = TotalPrice[i];
              this.onewayresulted[i].Taxprice = Tax[i];
              this.onewayresulted[i].pricedetail = pricedetail[i];
              this.onewayresulted[i].Flightreturn = false;
              this.onewayresulted[i].Flightdepature = false;
              this.onewayresulted[i].multipleDatas = false;
              this.onewayresulted[i].hoveredName1 = false;
              this.onewayresulted[i].hoveredName2 = false;
              this.onewayresulted[i].hoveredName3 = false;
              this.onewayresulted[i].hoveredName4 = false;
              this.onewayresulted[i].onewaymulticheck = true;
              this.onewayresulted[i].Refund = refund[i];
              this.onewayresulted[i].BaggageData = BaggageData[i];
              this.onewayresulted[i].currency = currency[i];
              this.onewayresulted[i].ownerlogo = ownerlogo[i];
              this.onewayresulted[i].ownername = ownername[i];
              this.onewayresulted[i].Depaturejourney = depjourney[i];
              this.onewayresulted[i].perperson = perperson[i];
              this.onewayresulted[i].cabinClass = this.oneway.class;
              this.onewayresulted[i].find1 = this.find1[i];
              this.onewayresulted[i].find2 = this.find2[i];
              this.onewayresulted[i].ShoppingResponseId = shoppingId;
              this.onewayresulted[i].selectId = offerId[i];
              this.onewayresulted[i].Adult = this.aduls;
              this.onewayresulted[i].Child = this.aduls1;
              this.onewayresulted[i].Inf = this.aduls2;
              this.onewayresulted[i].radios = true;
              this.onewayresulted[i].radiosData = false;
              this.onewayresulted[i].fromTrip = this.tripData.from;
              this.onewayresulted[i].toTrip = this.tripData.to;
            }

            console.log(this.onewayresulted, "oneway.....w...w..w..w");
            this.onewaymultiData = this.onewayresulted;
            this.onewayfilterData = this.onewayresulted;
            this.getlayTime();
            // this.gettimeret();
            // this.calculateTotalTime();
            // this.singlepriceData();
            this.airlineFilter();
            this.priceRange();
            this.Shortest();
            this.stopFilterOneway();
            this.Recommend();
            this.Cheapest();
            this.bestlayTime();

            if (this.onewayfilterData) {
              this.searchresult = true;
              this.searchloader = false;

              document.getElementById("navbar").style.display = "block";
              document.getElementById("food").style.display = "block";
            }
            this.onewayresulted.sort((a, b) => a.perperson - b.perperson);
          }
        }
      }
    },

    multione1(data, multindex, index) {
      // console.log(data, multindex, index, 'data, multindex,index')
      this.radiosData = 1;
      this.onewayresulted.find((c, i) => {
        if (i == index) {
          c.radios = false;
        }
      });

      this.flightdetailsData = data;
    },

    singlepriceData() {
      let $event = [];
      let $data = this.onewayresulted;
      this.onewayresulted.forEach((v) => {
        if (
          !$event.some(
            (item) =>
              item.TotalPrice == v.TotalPrice && item.ownername == v.ownername
          )
        ) {
          $event.push(v);
        }
      });
      this.onewayresulted = $event.sort((a, b) => a.perperson - b.perperson);

      this.onewayresulted.forEach((a, i) => {
        let count = 0;

        $data.forEach((b) => {
          if (
            a.TotalPrice == b.TotalPrice &&
            a.ownername == b.ownername &&
            a.Depaturejourney.Time !== b.Depaturejourney.Time
          ) {
            count++;
          }
        });
        console.log();
        if (count >= 1) {
          this.onewayresulted[i].multi = "Show more Options";
        }
      });

      this.onewayfilterData = this.onewayresulted;
      console.log($event, "this.onewayfilterData6666666$event");
    },

    addmoreData(data, index) {
      console.log(data, index, "vasanrh");
      this.radios = 1;
      this.onewayresulted.find((v, i) => {
        if (i == index) {
          v.multipleDatas = !v.multipleDatas;
          v.onewaymulticheck = !v.onewaymulticheck;
        } else {
          v.multipleDatas = false;
          v.onewaymulticheck = true;
        }
      });

      let $event = [];
      this.onewaymultiData.forEach((v) => {
        if (data.TotalPrice == v.TotalPrice && data.ownername == v.ownername) {
          $event.push(v);
        }
      });
      let $data = [];
      $event.forEach((k) => {
        if (
          !$data.some((b) => b.Depaturejourney.Time == k.Depaturejourney.Time)
        ) {
          $data.push(k);
        }
      });

      this.multiplesameData = $data;
    },

    stopFilterOneway() {
      this.onewayfilterData.forEach((v) => {
        if (v.Depaturejourney.Stops == 0) {
          this.showNonStop = true;
        } else if (v.Depaturejourney.Stops == 1) {
          this.showOneStop = true;
        } else if (v.Depaturejourney.Stops >= 1) {
          this.showOneplusStop = true;
        }
      });
    },

    sended() {
      // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
      this.inputCity = this.oneway.from;
      this.inputCityto = this.oneway.to;
      this.onewayfilterData = [];
      this.rountresult = [];
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        this.oneway.class = this.classType;
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");
        this.urlredate = moment(this.oneway.redate).format("YYYY-MM-DD");
        console.log(this.urldedate,this.urlredate,'dedatededate')
        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          redate: this.urlredate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };
        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({ path: "/search", query: rountdetail });

        // this.sended1()
      } else {
        this.isNotActive = true;
        // this.alert = true;
        setTimeout(()=>{
          this.isNotActive = false;
          // this.alert = true;
        },2000)

        this.validateOnewayForm();
      }
    },

    sendedone() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");
      this.inputCity = this.oneway.from;
      this.inputCityto = this.oneway.to;
      this.onewayfilterData = [];
      this.rountresult = [];

      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        // this.oneway.class = this.select;
        let result1 = [];
        console.log(this.oneway, "onewayonewayonewayonewayoneway");
        result1 = JSON.stringify(this.oneway);
        localStorage.setItem("rountData", result1);

        if (this.oneway.from.name) {
          this.roundfrom = this.oneway.from.name;
        } else if (this.oneway.from) {
          this.roundfrom = this.oneway.from;
        }

        if (this.oneway.to.name) {
          this.roundto = this.oneway.to.name;
        } else if (this.oneway.to) {
          this.roundto = this.oneway.to;
        }

        this.urldedate = moment(this.oneway.dedate).format("YYYY-MM-DD");

        let rountdetail = {
          from: this.roundfrom,
          to: this.roundto,
          dedate: this.urldedate,
          class: this.oneway.class,
          child: this.oneway.child,
          adult: this.oneway.adult,
          infrant: this.oneway.infrant,
          city: this.oneway.city,
        };
        setTimeout(() => {
          location.reload();
        }, 500);

        this.$router.push({ path: "/search", query: rountdetail });

        // this.sended1()
      } else {
        this.isNotActive = true;
        // this.alert = true;
        setTimeout(()=>{
          this.isNotActive = false;
          // this.alert = true;
        },2000)

        this.validateOnewayForm();
      }
    },

    sended1() {
      // console.log("kkkkkkkkkkkkkkkkkk");
      localStorage.removeItem("onewaytrip");
      localStorage.removeItem("rounttrip");
      this.onewayresulted = [];

      // if (this.concatenatedArray) {
      //   this.concatenatedArray.forEach((v) => {
      //     console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
      //     resul.push(v);
      //   });
      // } else {
      //   console.log(this.concatenatedArray);
      // }

      this.oneway.city = this.red1;
      this.oneway.adult = this.aduls;
      this.oneway.infrant = this.aduls2;
      this.oneway.child = this.aduls1;
      // this.oneway.class = this.select;
      let rountdetail = {
        from: null,
        to: null,
        dedate: this.oneway.dedate,
        class: this.oneway.class,
        child: this.oneway.child,
        adult: this.oneway.adult,
        infrant: this.oneway.infrant,
        city: this.oneway.city,
      };
      let result1 = [];
      result1 = JSON.stringify(this.oneway);
      localStorage.setItem("rountData", result1);

      if (
        this.oneway.from &&
        this.oneway.to &&
        this.oneway.dedate &&
        this.oneway.redate
      ) {
        this.loadSearch = true;
        this.disButton = true;
        let res = "";
        if (this.oneway.from.name) {
          rountdetail.from = this.oneway.from.name;
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.from = this.oneway.from;
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          rountdetail.to = this.oneway.to.name;
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          rountdetail.to = this.oneway.to;
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let result =
          this.Rountdata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;

          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          result[1].Departure.AirportCode = this.roundto;
          result[1].Departure.Date = moment(
            this.oneway.redate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[1].Arrival.AirportCode = this.roundfrom;
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else {
          cabin_type = "F";
          this.Rountdata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        this.Rountdata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.Rountdata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;
        this.Rountdata.country = this.countryCodeData;

        console.log(
          this.makeSearchUrl,
          this.Rountdata,
          "this.Rountdatathis.Rountdatathis.Rountdatathis.Rountdatathis.Rountdata"
        );

        axios
          .post(this.makeSearchUrl, this.Rountdata, {
            headers: {
              "Content-Type": "application/json",
            },
          })

          .then((response) => {
            // console.log(response," this.responseData this.responseData this.responseData");

            this.responseData.push(response.data);

            console.log(
              this.responseData,
              "this.responseData this.responseData this.responseData"
            );

            let rountData = [];
            rountData = JSON.stringify(this.responseData);
            localStorage.setItem("rounttrip", rountData);

            let result = "";
            result = localStorage.getItem("rounttrip");
            this.localData.push(JSON.parse(result));
            // console.log(this.localData, " this.localData this.localData");
            if (this.localData) {
              this.roundedtrip();
            }

            // if (response.data.Errors =='Errors') {
            //   this.searchresult = false;
            //   this.searchloader = true;
            //   setTimeout(() => {
            //     this.pageError = true;
            //     this.searchresult = false;
            //   this.searchloader = false;
            //   }, 7000);
            // }

            // else {

            //   this.responseData.push(response.data.AirShoppingRS);
            //   console.log(this.responseData,'this.responseDatathis.responseData')
            //   console.log(
            //     this.responseData,
            //     " this.responseData this.responseData this.responseData"
            //   );
            //   let rountData = [];
            //   rountData = JSON.stringify(this.responseData);
            //   localStorage.setItem("rounttrip", rountData);
            //   let result = "";
            //   result = localStorage.getItem("rounttrip");
            //   this.localData.push(JSON.parse(result));
            //   console.log(this.localData, " this.localData this.localData");
            //   if (this.localData) {
            //     this.roundedtrip();
            //   }
            // }
          })

          .catch((error) => {
            console.log(error);
            this.$router.push("/pageError");
          });
      } else {
        this.alert = true;
        // alert("please enter your detail");
        this.validateOnewayForm();
      }

      // console.log(this.responseData, "0077777777777");
    },

    sendedone1() {
      localStorage.removeItem("rounttrip");
      localStorage.removeItem("onewaytrip");

      let resul =
        this.onewaydata.request.AirShoppingRQ.DataLists.PassengerList.Passenger;

      // console.log(resul, "5555555555555555555555");
      if (this.concatenatedArray) {
        this.concatenatedArray.forEach((v) => {
          // console.log(v, "vvvvvvvvvvvvvvvvvvvvvvvvvv");
          resul.push(v);
        });
      } else {
        // console.log(this.concatenatedArray);
      }
      console.log(
        this.oneway.dedate,
        "this.oneway.dedatethis.oneway.dedatethis.oneway.dedate"
      );
      if (this.oneway.from && this.oneway.to && this.oneway.dedate) {
        this.loadSearch = true;
        this.disButton = true;

        this.oneway.city = this.red1;
        this.oneway.adult = this.aduls;
        this.oneway.infrant = this.aduls2;
        this.oneway.child = this.aduls1;
        // this.oneway.class = this.select;
        // console.log(this.oneway, "this.onewaythis.onewaythis.oneway");
        let result1 = [];
        result1 = JSON.stringify(this.oneway);
        // console.log(result1)
        localStorage.setItem("rountData", result1);

        let res = "";

        if (this.oneway.from.name) {
          res = this.oneway.from.name.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res = this.oneway.from.split(" ");
          this.roundfrom = res[res.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        let res1 = "";
        if (this.oneway.to.name) {
          res1 = this.oneway.to.name.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        } else {
          res1 = this.oneway.to.split(" ");
          this.roundto = res1[res1.length - 1]
            .replace("(", "")
            .replace(")", "");
        }

        this.urldedate = moment(this.oneway.dedate, "DD MMM-YYYY").format(
          "YYYY-MM-DD"
        );
        // let rountdetail = {
        //   from: this.roundfrom,
        //   to: this.roundto,
        //   dedate: this.urldedate,
        //   redate: this.urlredate,
        //   class: this.oneway.class,
        //   child: this.oneway.child,
        //   adult: this.oneway.adult,
        //   infrant: this.oneway.infrant,
        //   city: this.oneway.city,
        // };

        let result =
          this.onewaydata.request.AirShoppingRQ.CoreQuery.OriginDestinations
            .OriginDestination;
        for (let i = 0; i <= result.length - 1; i++) {
          result[0].Departure.AirportCode = this.roundfrom;
          result[0].Departure.Date = moment(
            this.oneway.dedate,
            "DD MMM-YYYY"
          ).format("YYYY-MM-DD");
          result[0].Arrival.AirportCode = this.roundto;
          // result[1].Departure.AirportCode = this.roundto
          // result[1].Departure.Date = moment(this.oneway.redate).format('YYYY-MM-DD')
          // result[1].Arrival.AirportCode = this.roundfrom
        }

        let cabin_type = this.oneway.class;
        if (cabin_type == "Economy") {
          cabin_type = "Y";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else if (cabin_type == "Business") {
          cabin_type = "C";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        } else {
          cabin_type = "F";
          this.onewaydata.request.AirShoppingRQ.Preference.CabinType =
            cabin_type;
        }

        this.onewaydata.request.AirShoppingRQ.Preference.Currency =
          this.preferCurrency;
        this.onewaydata.request.AirShoppingRQ.MetaData.Currency =
          this.metaCurrency;
        this.onewaydata.country = this.countryCodeData;
        console.log(this.onewaydata, "this.onewaydatathis.onewaydata");

        axios
          .post(this.makeSearchUrl, this.onewaydata, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            // console.log(response, "responseresponseresponse");
            this.responseData.push(response.data);
            // console.log(
            //   this.responseData,
            //   "this.responseDatathis.responseData"
            // );
            let rountData = [];
            rountData = JSON.stringify(this.responseData);

            localStorage.setItem("onewaytrip", rountData);

            if (rountData) {
              this.onewaytrip();
            }

            //             if (response.data.AirShoppingRS.Errors) {
            //               this.searchresult = false;
            //               this.searchloader = true;
            //               setTimeout(() => {
            //                 this.pageError = true;
            //                 this.searchresult = false;
            //               this.searchloader = false;
            //               }, 7000);
            //             }

            // else{
            //   this.responseData.push(response.data.AirShoppingRS);
            // console.og(this.responseData,'this.responseDatathis.responseData')
            // let rountData = [];
            // rountData = JSON.stringify(this.responseData);

            // localStorage.setItem("onewaytrip", rountData);
            // this.$router.push({ path: "/search", query: rountdetail });

            // if (rountData) {
            //   this.onewaytrip();
            // }
            // }
          })
          .catch((error) => {
            console.log(error);
            this.$router.push("/pageError");
          });
      } else {
        this.alert = true;
        // alert("please enter your detail");

        this.validateOnewayForm();
      }
    },

    sendedMulti($data) {
      this.multi_city.adult = this.aduls;
      this.multi_city.child = this.aduls1;
      this.multi_city.infrant = this.aduls2;
      this.multi_city.class = this.classType;

      console.log(this.multi_city, "datasdatasdatas");

      let multicityData = {
        itinery: [],
        class: "",
        child: "",
        adult: "",
        infrant: "",
        city: "3",
      };

      multicityData.class = this.multi_city.class;
      multicityData.adult = this.multi_city.adult;
      multicityData.child = this.multi_city.child;
      multicityData.infrant = this.multi_city.infrant;
      multicityData.city = this.multi_city.city;
      this.multi_city.itinery.forEach((v) => {
        console.log(v, "multy");
        let itineraryObject = {
          from: v.from.name ? v.from.name : v.from,
          to: v.to.name ? v.to.name : v.to,
          depdata: moment(v.depdata).format("YYYY-MM-DD"),
        };
        multicityData.itinery.push(itineraryObject);
      });

      console.log(multicityData, "this.multicityData5555555555555");
      if ($data == 1) {
        setTimeout(() => {
          location.reload();
        }, 500);
      }

      let muti_city = JSON.stringify(multicityData);
      this.$router.push({ path: "/search", query: { muti_city } });
    },

    sendedMulticity() {
      for (let i = 0; i < this.multi_city.itinery.length; i++) {
        if (this.multi_city.itinery[i].from) {
          this.multi_city.itinery[i].multiFromError = false;

          // console.log(this.multi_city.itinery[i].from, "mememememe.....");
        } else {
          this.multi_city.itinery[i].multiFromError = true;
        }

        if (this.multi_city.itinery[i].to) {
          this.multi_city.itinery[i].multiToError = false;
        } else {
          this.multi_city.itinery[i].multiToError = true;
        }

        if (this.multi_city.itinery[i].depdata) {
          this.multi_city.itinery[i].multiDateError = false;
        } else {
          this.multi_city.itinery[i].multiDateError = true;
        }
      }
    },

    selected(result) {
      this.dataLoader = true;
      console.log(result, "ppppp....1");
      if (result[0].Flight1) {
        setTimeout(() => {
          let obj = {
            adult: this.multi_city.adult,
            child: this.multi_city.child,
            inf: this.multi_city.infrant,
            class: this.multi_city.class,
          };
          console.log(obj, "ppp");

          localStorage.setItem(`${"pass"}-${result[0].selectId}`, obj);
          localStorage.setItem(
            `${"multi"}-${result[0].selectId}`,
            JSON.stringify(result[0])
          );

          this.$router.push({
            path: "/flightDetailsCom",
            query: { pass_uid: `${"multi"}-${result[0].selectId}` },
          });
        }, 3000);
      } else {
        console.log(result, "ppppp....2");
        this.dataLoader = true;
        setTimeout(() => {
          this.dataLoader = false;
          localStorage.setItem(
            `${result[0].currency}-${result[0].selectId}`,
            JSON.stringify(result[0])
          );

          this.$router.push({
            path: "/flightDetailsCom",
            query: { pass_uid: `${result[0].currency}-${result[0].selectId}` },
          });
        }, 3000);
      }
    },

    fromClick($event) {
      // console.log($event, "p.....1");
      if ($event.from) {
        $event.from = "";
        this.fromErrorMessage = true;

        // console.log(this.fromErrorMessage, "p.....2");
      }
    },

    toClick($event) {
      // console.log($event, "p.....");
      if ($event.to) {
        $event.to = "";
        this.toErrorMessage = true;

        // console.log(this.toErrorMessage, "p.....3");
      }
    },

    multiFromClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.from = null;
            v.multiFromError = true;

            // console.log("vvvvv......4444");
            if (!v.from) {
              v.multiFromError = true;

              // console.log("vvvvv......555");
            } else {
              v.multiFromError = false;

              // console.log("vvvvv......666");
            }
          }
        }
      });
    },

    multiToClick(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.to = null;
            v.multiToError = true;

            // console.log("vvvvv......111");
            if (!v.to) {
              // console.log("vvvvv......222");
              v.multiToError = true;
            } else {
              v.multiToError = false;

              // console.log("vvvvv......333");
            }
          }
        }
      });
    },

    onChangeFrom() {
      // if (event.length >= 3) {
      //   this.$refs.fromAutoComplete.$el.querySelector("input").blur();
      // }
      setTimeout(() => {
        if (this.oneway.from) {
          this.fromErrorMessage = false;
          // this.$refs.fromAutoComplete.$el.querySelector("input").blur();
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.from) {
          this.fromErrorMessage = true;
        }
      }, 100);
    },

    onChangeTo() {
      setTimeout(() => {
        if (this.oneway.to) {
          this.toErrorMessage = false;
          // this.$refs.secondAutoComplete.$el.querySelector("input").blur();
        }
      }, 50);

      setTimeout(() => {
        if (!this.oneway.to) {
          this.toErrorMessage = true;
        }
      }, 100);
    },

    onChangeMultiFrom(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.from) {
            v.multiFromError = false;
          } else {
            v.multiFromError = true;
          }
        }
      });
    },

    onChangeMultiTo(index) {
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          if (v.to) {
            v.multiToError = false;
          } else {
            v.multiToError = true;
          }
        }
      });

      for (let i = 1; i < this.multi_city.itinery.length; i++) {
        this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
      }
    },

    validateOnewayForm() {
      if (this.oneway.from) {
        this.fromErrorMessage = false;
      } else {
        this.fromErrorMessage = true;
      }

      if (this.oneway.to) {
        this.toErrorMessage = false;
      } else {
        this.toErrorMessage = true;
      }

      if (this.oneway.dedate) {
        this.fromDateError = false;
      } else {
        this.fromDateError = true;
      }

      if (this.oneway.redate) {
        this.toDateError = false;
      } else {
        this.toDateError = true;
      }
      // this.fromErrorMessage = !this.oneway.from;
      // this.toErrorMessage = !this.oneway.to;
      // this.fromDateError = !this.oneway.dedate;
      // this.toDateError = !this.oneway.redate;
    },

    // autocompletJson() {

    //   const getConfigData = getConfigDataFromLocalStorage();

    //   this.airlineIcaoUrl =
    //     getConfigData.payload.portal_configuration.content_data.airports_icao.url;
    //   this.airlineIcaoType =
    //     getConfigData.payload.portal_configuration.content_data.airports_icao.type;

    //   axios
    //     .get(this.airlineIcaoUrl, {
    //       headers: {
    //         "Content-Type": this.airlineIcaoType,
    //       },
    //     })
    //     .then((response) => {
    //       let airlineIcao = response.data;

    //       if (airlineIcao) {

    //         Object.keys(airlineIcao).forEach((icao) => {
    //           const airport = airlineIcao[icao];

    //           if (airport.iata !== "") {
    //             this.fromAirports.push({
    //               iata: airport.iata,
    //               icao: airport.icao,
    //               places: ` ${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
    //             });
    //           }
    //         });
    //       }
    //     })

    //     .catch((error) => {
    //       console.log(error, "erroroor.....");
    //     });
    // },

    async typeCity(event, $data) {
      let $event = [];
      console.log(event, $data, "searchsearchsearchsearch");

      if (event.length >= 3) {
        await axios
          .get(
            `${"https://booking.traveloplex.com/api/airportautocomplete?airportString"}=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, "response");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.toUpperCase();
        // console.log(searchString,'searchStringsearchString')
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
            }
          });

          this.airitems = iataMatch.concat(otherMatches);

          $event = this.airitems.map((item) => {
            const parts = item.places.split(", ");
            if (parts.length >= 2) {
              const name = `${parts[1]} (${parts[0]})`;
              const originalName = item.places;
              return {
                name,
                originalName,
              };
            }
          });

          if ($data == 1) {
            $event.forEach((v) => {
              if (
                !this.city.some((item) => item.originalName == v.originalName)
              ) {
                this.city.push(v);
              }
            });
            this.typedrop = true;
          } else if ($data == 2) {
            $event.forEach((v) => {
              if (
                !this.city1.some((item) => item.originalName == v.originalName)
              ) {
                this.city1.push(v);
              }
            });

            this.typedrop1 = true;
          }

          console.log(this.city, this.city1, "vasanth");
          this.loading = false;
        }, 1000);
      } else {
        // if (this.oneway.from) {
        //   this.maximumletter = true;
        // }
        // if (this.oneway.to) {
        //   this.maximumletter1 = true;
        // }
      }
    },
    datachange(data) {
      this.oneway.from = data.name;
      this.inputCity = data.name;
      this.typedrop = false;
    },
    datachangeTo(data) {
      this.oneway.to = data.name;
      this.inputCityto = data.name;
      this.typedrop1 = false;
    },
    datachangeMuti(data, index) {
      console.log(data, index, "data,index1");
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.from = data.name;
          v.autocom = false;
        }
      });
      console.log(this.multi_city, "this.multiDatathis.multiData");
    },
    datachangeMutiTo(data, index) {
      console.log(data, index, "data,index1");
      this.multi_city.itinery.map((v, i) => {
        if (i == index) {
          v.to = data.name;
          v.autocomTo = false;
        }
      });
      console.log(this.multi_city, "this.multiDatathis.multiData");
    },

    async typeMultiCity(event, index, $num) {
      console.log(event, index, $num, "searchsearchsearchsearch");
      let $event = [];
      console.log(event, index, "searchsearchsearchsearch");

      if (event.length >= 3) {
        if ($num == 1 && event.length > 2) {
          this.multi_city.itinery.map((v, i) => {
            v.autocomTo = false;
            v.fromloader = true;
            if (i == index && event.length > 2) {
              v.autocomfrom = true;
            } else {
              v.autocomfrom = false;
              v.fromloader = false;
              this.multidrop = [];
            }
          });
        } else if ($num == 2 && event.length > 0) {
          this.multi_city.itinery.map((v, i) => {
            v.autocomfrom = false;
            if (i == index && event.length > 2) {
              v.autocomTo = true;
              v.toloader = true;
            } else {
              v.autocomTo = false;
              v.toloader = false;
              this.multidrop1 = [];
            }
          });

          for (let i = 1; i < this.multi_city.itinery.length; i++) {
            this.multi_city.itinery[i].from = this.multi_city.itinery[i - 1].to;
          }
        }

        await axios
          .get(
            ` ${"https://booking.traveloplex.com/api/airportautocomplete?airportString"}=${event}`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, "response");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.toUpperCase();
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
            }
          });

          this.airitems = iataMatch.concat(otherMatches);

          $event = this.airitems.map((item) => {
            const parts = item.places.split(", ");
            if (parts.length >= 2) {
              const name = `${parts[1]} (${parts[0]})`;
              const originalName = item.places;
              return {
                name,
                originalName,
              };
            }
          });
          if ($num == 1) {
            this.multi_city.itinery.map((v) => {
              v.fromloader = false;
            });

            $event.forEach((v) => {
              if (
                !this.multidrop.some(
                  (item) => item.originalName == v.originalName
                )
              ) {
                this.multidrop.push(v);
              }
            });
          } else if ($num == 2) {
            this.multi_city.itinery.map((v) => {
              v.toloader = false;
            });

            $event.forEach((v) => {
              if (
                !this.multidrop1.some(
                  (item) => item.originalName == v.originalName
                )
              ) {
                this.multidrop1.push(v);
              }
            });
          }

          console.log(this.multidrop, "vasanth");
          this.loading = false;
        }, 1000);
      }
    },

    async search(event) {
      console.log(event, "searchsearchsearchsearch");

      if (event.query.length >= 1) {
        this.formres = false;
        if (event.query.length >= 1) {
          this.formres1 = false;
        }
      }

      if (event.query.length >= 3) {
        await axios
          .get(
            `${"https://booking.traveloplex.com/api/airportautocomplete?airportString"}=${
              event.query
            }`,
            {
              headers: {
                "Content-Type": this.airlineIcaoType,
              },
            }
          )
          .then((response) => {
            console.log(response, "response");

            let airlineIcao = response.data;
            if (airlineIcao) {
              Object.keys(airlineIcao).forEach((icao) => {
                const airport = airlineIcao[icao];

                if (airport.iata !== "") {
                  this.fromAirports.push({
                    iata: airport.iata,
                    icao: airport.icao,
                    places: `${airport.iata}, ${airport.city}, ${airport.state}, ${airport.country}, ${airport.name}`,
                  });
                }
              });
            }
          })

          .catch((error) => {
            console.log(error, "erroroor.....");
          });

        this.maximumletter = false;
        this.maximumletter1 = false;
        this.loading = true;
        const searchString = event.query.toUpperCase();
        // console.log(searchString,'searchStringsearchString')
        setTimeout(() => {
          const iataMatch = [];
          const otherMatches = [];
          this.fromAirports.forEach((e) => {
            const iata = e.iata ? e.iata.toUpperCase() : "";
            const placesLowerCase = e.places.toLowerCase();
            if (iata === searchString) {
              iataMatch.push(e);
              // console.log(iataMatch,'iataMatchiataMatch')
            } else if (placesLowerCase.includes(searchString.toLowerCase())) {
              otherMatches.push(e);
              //  console.log(otherMatches,'otherMatches')
            }
          });

          this.airitems = iataMatch.concat(otherMatches);
          this.loading = false;
        }, 1000);
      } else {
        if (this.oneway.from) {
          this.maximumletter = true;
        }
        if (this.oneway.to) {
          this.maximumletter1 = true;
        }
      }
    },

    onewaySwap() {
      this.swapData = !this.swapData;
      let $dataFrom = this.inputCity;
      let $dataTo = this.inputCityto;
      this.inputCity = $dataTo;
      this.inputCityto = $dataFrom;
    },

    focus1() {
      setTimeout(() => {
        this.$refs.fromAutoComplete.$el.querySelector("input").blur();
      }, 50);

      if (!this.oneway.to) {
        setTimeout(() => {
          this.$refs.secondAutoComplete.$el.querySelector("input").focus();
        }, 100);
      }
    },

    focus2() {
      setTimeout(() => {
        this.$refs.secondAutoComplete.$el.querySelector("input").blur();
      }, 50);

      if (!this.oneway.dedate) {
        this.$refs.dpRef1.openMenu();
      }
    },

    focus3() {
      setTimeout(() => {
        this.$refs.onewayTo.$el.querySelector("input").focus();
      }, 10);
    },

    focus4() {
      this.$refs.dpRef3.openMenu();
    },

    focus5(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    focus6(index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        for (let i = 0; i < 1; i++) {
          // console.log(this.multi_city.itinery[0].to, "bfbfbfbf.....");
          this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        }
      }, 10);
    },

    Multifocus1(index) {
      setTimeout(() => {
        if (this.$refs.multiTo.length > 0) {
          const inputElement =
            this.$refs.multiTo[index].$el.querySelector("input");
          if (inputElement) {
            inputElement.focus();
          }
        }
      }, 10);
    },

    Multifocus2(data, index) {
      setTimeout(() => {
        if (this.$refs.dpRef4.length > 0) {
          const inputElement = this.$refs.dpRef4[index];
          if (inputElement) {
            inputElement.openMenu();
          }
        }

        console.log(data, "bfbfbfbf.....");
        // return data.to.name

        // for (let i = 0; i < 1; i++) {
        //   //console.log(this.multiData[0].to, 'bfbfbfbf.....')
        //   this.input[1].from = this.input[0].to;
        // }

        for (let i = 0; i < 1; i++) {
          this.multi_city.itinery[1].from = this.multi_city.itinery[0].to;
        }
      }, 10);
    },

    handleResize() {
      if (window.innerWidth < 992) {
        this.isIcon = false;
      } else {
        this.isIcon = true;
      }
    },
    handleCalenderResize() {
      if (window.innerWidth < 400) {
        this.multiCalender = false;
      } else {
        this.multiCalender = true;
      }
    },

    // Form Header section

    getMultifrom(data) {
      // console.log(data, 'datatatetee')

      if (data.itinery[0].from === null) {
        return null;
      } else {
        const $data = data.itinery[0]?.from?.name;
        return $data || data.itinery[0].from;
      }

      // if (data.itinery[0].from.name) {
      //   return data.itinery[0].from.name
      // }
      // else {
      //   return data.itinery[0].from
      // }
    },
    getMultiDedate1(data) {
      if (data.itinery[0].depdata) {
        // return data.itinery[0].depdata
        return moment(data.itinery[0].depdata, "DD-MMM-YYYY").format(
          "DD-MMM-YYYY"
        );
      } else {
        return "";
      }
    },

    getMultiTo(data) {
      // console.log(data, "Testing...1...1...1.");

      if (data.itinery[data.itinery.length - 1].to === null) {
        return null;
      } else {
        const $data = data.itinery[data.itinery.length - 1]?.to?.name;
        return $data || data.itinery[data.itinery.length - 1].to;
      }
    },

    getMultiDedate2(data) {
      if (data.itinery[data.itinery.length - 1].depdata) {
        // return data.itinery[data.itinery.length - 1].depdata
        return moment(
          data.itinery[data.itinery.length - 1].depdata,
          "DD-MMM-YYYY"
        ).format("DD-MMM-YYYY");
      } else {
        return "";
      }
    },

    getMultiClass(data) {
      console.log(data, "mdatatiCadata");
      if (data.class) {
        return data.class;
      } else {
        return "";
      }
    },

    //     getMultifrom(data) {
    //       if (data.length>0) {

    //   console.log(data,'wrthofjsdosjkdjk')

    //    if (data.itinery[0].from) {
    //           return data.itinery[0].from
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate1(data) {

    //          if (data.length>0) {
    //    if (data.itinery[0].depdata) {
    //           return data.itinery[0].depdata
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },

    //     getMultiTo(data) {

    //         if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].to) {
    //           return data.itinery[data.itinery.length - 1].to
    //       }
    // }

    //        else {
    //        return data
    //       }

    //     },
    //     getMultiDedate2(data) {

    //          if (data.length>0) {
    //    if (data.itinery[data.itinery.length - 1].depdata) {
    //           return data.itinery[data.itinery.length - 1].depdata
    //       }
    // }

    //        else {
    //        return data
    //       }
    //     },

    //     getMultiClass(data) {
    //       if (data.classes) {
    //         return data.classes
    //       }
    //       else {
    //         return ''
    //       }

    //     },
    getMultiAdult(data) {
      // console.log(data, 'lkllklklklk...22..2.2.2')
      if (data.adult > 0) {
        return data.adult;
      } else {
        return "";
      }
    },
    getMultiChild(data) {
      if (data.child > 0) {
        return data.child;
      } else {
        return "";
      }
    },
    getMultiInfrant(data) {
      if (data.infrant > 0) {
        return data.infrant;
      } else {
        return "";
      }
    },

    // Form Header section End

    getConfig() {
      // console.log(this.$store.state.data,'homehoemehojoke...')

      // let getConfigData = this.$store.state.data
      // let portalLocal = localStorage.getItem("portalData")

      // let getConfigData1 = JSON.parse(portalLocal)

      const getConfigData1 = getConfigDataFromLocalStorage();

      if (getConfigData1) {
        this.makeSearchUrl =
          getConfigData1.payload.portal_configuration.API_endpoints.search;
        this.airLogoApi =
          getConfigData1.payload.portal_configuration.content_data.airlines_logo;
        this.preferCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.metaCurrency =
          getConfigData1.payload.portal_configuration.currency.iso_code;
        this.countryCodeData =
          getConfigData1.payload.portal_configuration.country.iso_code;
        console.log(this.countryCodeData, "vvvvvvvvvvvvvvvvvvvvvvv");

        // this.getConfigFunc();
      }
    },
  },

  // AutoComplete Functions End

  watch: {
    classType(newSelectValue) {
      console.log("select property changed to:", newSelectValue);

      if (newSelectValue) {
        setTimeout(() => {
          const focusDrop = this.$refs.countrySelect.focus();
          if (focusDrop) {
            this.$refs.countrySelect.focus();
          }

          this.classSelect = false;
          console.log(this.classSelect);
        }, 100);
      }
    },

    RefundableData(data) {
      if (
        (data && this.nonRefundableData) ||
        (!data && !this.nonRefundableData)
      ) {
        if (this.onewayfilterData.length > 0) {
          this.onewayresulted = this.onewayfilterData;
        } else if (this.filterData.length > 0) {
          this.rountresult = this.filterData;
        } else if (this.multiFilterData.length > 0) {
          this.multicityData = this.multiFilterData;
        }
      } else if (data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayfilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }

        if (this.filterData.length > 0) {
          let $data = this.filterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.rountresult = $result;
        }

        if (this.multiFilterData.length > 0) {
          let $data = this.multiFilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.multicityData = $result;
        }
      } else if (!data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayresulted;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }
        if (this.filterData.length > 0) {
          let $data = this.rountresult;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Refundable") {
              $result.push(v);
            }
          });
          this.rountresult = $result;
        }
        if (this.multiFilterData.length > 0) {
          let $data = this.multicityData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Refundable") {
              $result.push(v);
            }
          });
          this.multicityData = $result;
        }
      }
    },

    nonRefundableData(data) {
      if ((data && this.RefundableData) || (!data && !this.RefundableData)) {
        if (this.onewayfilterData.length > 0) {
          this.onewayresulted = this.onewayfilterData;
        } else if (this.filterData.length > 0) {
          this.rountresult = this.filterData;
        } else if (this.multiFilterData.length > 0) {
          this.multicityData = this.multiFilterData;
        }
      } else if (data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayfilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }
        if (this.filterData.length > 0) {
          console.log("ppppp", this.filterData);
          let $data = this.filterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result555");
          this.rountresult = $result;
        }
        if (this.multiFilterData.length > 0) {
          let $data = this.multiFilterData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund == "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.multicityData = $result;
        }
      } else if (!data) {
        if (this.onewayfilterData.length > 0) {
          let $data = this.onewayresulted;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Non Refundable") {
              $result.push(v);
            }
          });
          console.log($result, "$result$result");
          this.onewayresulted = $result;
        }

        if (this.filterData.length > 0) {
          let $data = this.rountresult;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== " Non Refundable") {
              $result.push(v);
            }
          });
          this.rountresult = $result;
        }
        if (this.multiFilterData.length > 0) {
          let $data = this.multicityData;
          let $result = [];
          $data.forEach((v) => {
            if (v.Refund !== "Non Refundable") {
              $result.push(v);
            }
          });
          this.multicityData = $result;
        }
      }
    },

    changevalue(newVal) {
      this.rountresult = [];
      this.onewayresulted = [];
      let filterData = [];
      let sortAmount = [];

      console.log("Slider value changed:", newVal);

      for (let i = 0; i < this.filterData.length; i++) {
        if (
          this.filterData[i].perperson >= newVal[0] &&
          this.filterData[i].perperson <= newVal[1]
        ) {
          sortAmount.push(this.filterData[i]);
        }
      }
      // this.rountresult = totalPricefilter.sortBy(sortAmount, ["perperson"]);
      // console.log(this.rountresult,'pppppppppppppppppppp')

      for (let i = 0; i < this.onewayfilterData.length; i++) {
        if (
          this.onewayfilterData[i].perperson >= newVal[0] &&
          this.onewayfilterData[i].perperson <= newVal[1]
        ) {
          sortAmount.push(this.onewayfilterData[i]);
        }
      }
      // this.onewayresulted = totalPricefilter.sortBy(sortAmount, ["perperson"]);

      for (let i = 0; i < this.multiFilterData.length; i++) {
        if (
          this.multiFilterData[i].perperson >= newVal[0] &&
          this.multiFilterData[i].perperson <= newVal[1]
        ) {
          sortAmount.push(this.multiFilterData[i]);
        }
      }
      filterData = sortAmount;
      filterData.sort((a, b) => a.perperson - b.perperson);

      if (this.multiFilterData.length > 0) {
        this.multicityData = filterData;
      } else if (this.onewayfilterData.length > 0) {
        this.onewayresulted = filterData;
      } else if (this.filterData.length > 0) {
        this.rountresult = filterData;

        console.log(this.rountresult, "0000000");
      }

      this.Shortest();
      this.Recommend();
      this.Cheapest();
      this.bestlayTime();
    },

    NonStopFil(newVal) {
      let onewayfilterData = [];
      let roundfilterData = [];
      let FilterDataWay = [];
      let multifilterData = [];
      let oneway = false;
      let round = false;
      let multi = false;
      if (this.onewayfilterData.length > 0) {
        onewayfilterData = this.onewayfilterData;
        oneway = true;
      } else if (this.multiFilterData.length > 0) {
        multifilterData = this.multiFilterData;
        multi = true;
      } else {
        console.log(this.filterData, "this.filterData  ");
        roundfilterData = this.filterData;
        round = true;
      }

      if (newVal && this.OneStopFil && this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (!newVal && !this.OneStopFil && !this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (newVal && this.OneStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1 || v.Returnjourney.Stops <= 1) {
              FilterDataWay.push(v);
            }
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops <= 1 ||
              v.Flight2JourneyTime.Stops <= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 3) <= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 3) <= 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal && this.OnePlusFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1 || v.Returnjourney.Stops !== 1) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 1 ||
              v.Flight2JourneyTime.Stops !== 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) !== 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) !== 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal) {
        //  console.log('one',roundfilterData)
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 0) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 0 || v.Returnjourney.Stops == 0) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops == 0 ||
              v.Flight2JourneyTime.Stops == 0 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) == 0 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) == 0
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWayncbgdghghg");
            this.multicityData = FilterDataWay;
          });
        }
      } else {
        let $data = [];
        let $result = [];
        if (oneway) {
          $data = this.onewayresulted;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops !== 0) {
              $result.push(v);
            }
            this.onewayresulted = $result;
          });
        } else if (round) {
          let $data = this.rountresult;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops == 0 || v.Returnjourney.Stops == 0) {
              //  console.log("good")
            } else {
              $result.push(v);
            }
            this.rountresult = $result;
          });
        } else if (multi) {
          let $data = this.multicityData;
          $data.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 0 ||
              v.Flight2JourneyTime.Stops !== 0 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) !== 0 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) !== 0
            ) {
              $result.push(v);
            }
            this.multicityData = $result;
          });
        }
      }
    },

    OneStopFil(newVal) {
      let onewayfilterData = [];
      let roundfilterData = [];
      let FilterDataWay = [];
      let multifilterData = [];
      let multi = false;
      let oneway = false;
      let round = false;
      // console.log(this.rountresult, 'this.rountresult')
      if (this.onewayfilterData.length > 0) {
        onewayfilterData = this.onewayfilterData;
        oneway = true;
      } else if (this.multiFilterData.length > 0) {
        multifilterData = this.multiFilterData;
        multi = true;
      } else {
        console.log(this.filterData, "this.filterData  ");
        roundfilterData = this.filterData;
        round = true;
      }

      if (newVal && this.NonStopFil && this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (!newVal && !this.NonStopFil && !this.OnePlusFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (newVal && this.NonStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1 || v.Returnjourney.Stops <= 1) {
              FilterDataWay.push(v);
            }
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops <= 1 ||
              v.Flight2JourneyTime.Stops <= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 4) <= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 4) <= 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal && this.OnePlusFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 0) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 0 || v.Returnjourney.Stops !== 0) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 0 ||
              v.Flight2JourneyTime.Stops !== 0 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) !== 0 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) !== 0
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal) {
        console.log("one", roundfilterData);
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops == 1 || v.Returnjourney.Stops == 1) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops == 1 ||
              v.Flight2JourneyTime.Stops == 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) == 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) == 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else {
        let $data = [];
        let $result = [];
        if (oneway) {
          $data = this.onewayresulted;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1) {
              $result.push(v);
            }
            this.onewayresulted = $result;
          });
        } else if (round) {
          let $data = this.rountresult;

          $data.forEach((v) => {
            if (!v.Depaturejourney.Stops == 1 || !v.Returnjourney.Stops == 1) {
              //  $result.push(v)
            } else {
              $result.push(v);
            }

            this.rountresult = $result;
          });
        } else if (multi) {
          let $data = this.multicityData;
          $data.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 1 ||
              v.Flight2JourneyTime.Stops !== 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) !== 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) !== 1
            ) {
              $result.push(v);
            }
            this.multicityData = $result;
          });
        }
      }
    },

    OnePlusFil(newVal) {
      let onewayfilterData = [];
      let roundfilterData = [];
      let FilterDataWay = [];
      let oneway = false;
      let round = false;
      let multifilterData = [];
      let multi = false;
      // console.log(this.rountresult, 'this.rountresult')
      if (this.onewayfilterData.length > 0) {
        onewayfilterData = this.onewayfilterData;
        oneway = true;
      }
      if (this.filterData.length > 0) {
        console.log(this.filterData, "this.filterData  ");
        roundfilterData = this.filterData;
        round = true;
      }
      if (this.multiFilterData.length > 0) {
        multifilterData = this.multiFilterData;
        multi = true;
      }

      if (newVal && this.NonStopFil && this.OneStopFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (!newVal && !this.NonStopFil && !this.OneStopFil) {
        if (oneway) {
          this.onewayresulted = this.onewayfilterData;
        } else if (round) {
          this.rountresult = this.filterData;
        } else if (multi) {
          this.multicityData = this.multiFilterData;
        }
      } else if (newVal && this.NonStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops !== 1 || v.Returnjourney.Stops !== 1) {
              FilterDataWay.push(v);
            }
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops !== 1 ||
              v.Flight2JourneyTime.Stops !== 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 1) !== 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 1) !== 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal && this.OneStopFil) {
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops >= 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (round) {
          // console.log(round,'roundroundround')
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops >= 1 || v.Returnjourney.Stops >= 1) {
              FilterDataWay.push(v);
            }

            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops >= 1 ||
              v.Flight2JourneyTime.Stops >= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) >= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) >= 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else if (newVal) {
        console.log("one", roundfilterData);
        if (oneway) {
          onewayfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops > 1) {
              FilterDataWay.push(v);
            }

            this.onewayresulted = FilterDataWay;
          });
        } else if (roundfilterData.length > 0) {
          console.log(newVal, "newVal");
          console.log(
            roundfilterData,
            "roundfilterDataroundfilterDataroundfilterData"
          );
          roundfilterData.forEach((v) => {
            if (v.Depaturejourney.Stops > 1 || v.Returnjourney.Stops > 1) {
              console.log(v.Depaturejourney.Stops, "v.Depaturejourney.Stops");
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWayFilterDataWay");
            this.rountresult = FilterDataWay;
          });
        } else if (multi) {
          multifilterData.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops > 1 ||
              v.Flight2JourneyTime.Stops > 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 0) > 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 0) > 1
            ) {
              FilterDataWay.push(v);
            }
            console.log(FilterDataWay, "FilterDataWay");
            this.multicityData = FilterDataWay;
          });
        }
      } else {
        let $data = [];
        let $result = [];
        if (oneway) {
          $data = this.onewayresulted;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1) {
              $result.push(v);
            }
            this.onewayresulted = $result;
          });
        } else if (round) {
          let $data = this.rountresult;

          $data.forEach((v) => {
            if (v.Depaturejourney.Stops <= 1 || v.Returnjourney.Stops <= 1) {
              $result.push(v);
            }
            this.rountresult = $result;
          });
        } else if (multi) {
          let $data = this.multicityData;
          $data.forEach((v) => {
            if (
              v.Flight1JourneyTime.Stops <= 1 ||
              v.Flight2JourneyTime.Stops <= 1 ||
              (v.Flight3JourneyTime && v.Flight3JourneyTime.Stops
                ? v.Flight3JourneyTime.Stops
                : 3) <= 1 ||
              (v.Flight4JourneyTime && v.Flight4JourneyTime.Stops
                ? v.Flight4JourneyTime.Stops
                : 3) <= 1
            ) {
              $result.push(v);
            }
            this.multicityData = $result;
          });
        }
      }
    },

    "oneway.travelDate"(value) {
      console.log(value, "pppppppppReturn666666666....");
      if (value) {
        this.oneway.dedate = value[0];
        this.oneway.redate = value[1];

        this.focusEvent3 = false;
      }
      // else {
      //   this.oneway.travelDate = null;
      // }
    },

    // "oneway.dedate"(value) {
    //   if (value) {
    //     this.focusEvent3 = false;
    //   } else {
    //     this.oneway.dedate = null;
    //   }
    // },
  },

  computed: {
    filteredCountries() {
      return this.airitems.map((item) => {
        const parts = item.places.split(", ");
        if (parts.length >= 2) {
          const name = `${parts[1]} (${parts[0]})`;
          // const name = `${parts[0]} - ${parts[1]}`;
          const originalName = item.places;
          return {
            name,
            originalName,
          };
        }
      });
    },
  },

  created() {
    this.getConfig();
    this.airlineLogo_func();

    localStorage.removeItem("rounttrip");
    localStorage.removeItem("onewaytrip");

    this.red = this.$route.query.city;
    this.red1 = this.$route.query.city;
    this.oneway.class = this.$route.query.class;
    this.inputCity = this.$route.query.from;
    this.inputCityto = this.$route.query.to;
    this.oneway.dedate = this.$route.query.dedate;
    console.log(this.$route.query.dedate, "this.$route.query.dedate");
    this.oneway.redate = moment(this.$route.query.redate).format("DD MMM");
    if (this.$route.query.redate) {
      this.oneway.travelDate = [
        moment(this.$route.query.dedate).format("DD MMM"),
        moment(this.$route.query.redate).format("DD MMM"),
      ];
    }

    console.log(this.oneway.travelDate, "pppppppppReturn");

    if (this.red || this.red1) {
      this.roundData();
      this.mydata();
    } else {
      console.log(this.$route.query, "this.$route.query.dedate");
      this.roundModify = false;
      this.onewayModify = false;
      this.multiModify = true;
      console.log(this.red1, "reeddd111....");

      localStorage.removeItem("rountData");
      this.multi_city = JSON.parse(this.$route.query.muti_city);

      this.classType = this.multi_city.class;
      this.aduls = this.multi_city.adult;
      this.aduls1 = this.multi_city.infrant;
      this.aduls2 = this.multi_city.child;
      console.log(this.aduls, "adultDatasssssss");

      console.log(
        this.multi_city,
        "this.showMulti_datauerthis.showMulti_datay"
      );

      this.multicityresult();

      console.log(this.multi_city, "this.$route.querythis.$route.query....1");
      if (this.multi_city.itinery.length > 2) {
        this.clearall = true;
      }

      if (this.multi_city.itinery.length == 4) {
        console.log(
          this.multi_city,
          this.multi_city.itinery.length,
          "lengthlen.....gthlength"
        );
        this.addmore = false;
      }
    }

    if (this.red1 == 1) {
      this.sended1();
      this.isActiveTab = "return";
      this.returnForm = true;
    }
    if (this.red1 == 2) {
      console.log("sendedone1sendedone1");
      this.sendedone1();
      this.isActiveTab = "oneway";
      this.onewayForm = true;
    }
    if (this.red1 == 3) {
      this.sendedMulti();
      this.isActiveTab = "multiCity";
      this.multiCityForm = true;
      // alert('oooo')
    }
    this.generateBookingRefId();
    this.actActive();

    this.deatailData();
    this.clicked1 = true;
    this.clicked2 = true;
    // window.addEventListener("resize", this.handleResize);
    // window.addEventListener("resize", this.handleCalenderResize);
  },

  mounted() {
    document.getElementById("navbar").style.display = "none";
    document.getElementById("food").style.display = "none";

    // window.addEventListener("resize", this.handleResize);
    // window.addEventListener("resize", this.handleCalenderResize);

    // this.handleResize();
    // this.handleCalenderResize();
  },
};
</script>

<style scoped>
@import "@/assets/main.css";




/* Toast Styles */
  /* body{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
    overflow: hidden;
  } */
  
  .toast{
    width:370px;
    position: fixed;
    top: 80px;
    right: 10px;
    border-radius: 6px;
    background: #fff;
    padding: 20px 35px 20px 25px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    border-left: 8px solid #ff0000;
    overflow: hidden;
    transform: translateX(calc(100% + 30px));
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.25, 1.35);
    z-index: 9;
  }
  .toast.active{
    transform: translateX(0);
  }
  .toast-content{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .toast-check{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
    width: 35px;
    background-color: #ff0000;
    border-radius: 50%;
    color: #fff;
    font-size: 20px;
  }
  .message{
    display: flex;
    flex-direction: column;
    margin: 0 20px;
  }
  .message-text{
    font-size: 20px;
    font-weight: 600;
  }
  .text-1{
    color: #333;
  }
  .text-2{
    color: #666;
    font-weight: 400;
    font-size: 13px;
  }
  .toast-close{
    position: absolute;
    top: 10px;
    right: 15px;
    padding: 5px;
    cursor: pointer;
    opacity: 0.7;
  }
  .toast-close:hover{
    opacity: 1;
  }
  .progress{
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 100%;
    background: #ddd;
  }
  
  .progress::before{
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background-color: var(--progress-color, #ff0000);
    background-color: var(--progress-color, #ff0000);
  }
  .progress.active::before{
    animation: progress 2s linear forwards;
  }
  
  .toast:not(.show) {
      display: block;
  }
  
  
  @keyframes progress {
    100%{
      right: 100%;
    }
  }
  .toast-btn{
    padding: 10px 40px;
    font-size: 20px;
    outline: none;
    border: none;
    background-color: #ff0000;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
    transition: 0.3s;
  }
  .toast-btn:hover{
    background-color: #ff0000;
  }
  /* toast style end */














.inter-input {
  height: 40px;
  width: auto;
  font-weight: 600;
}

.dropdown-toggle .text-truncate {
  font-weight: 600;
}

/* Filter By Section*/

/* ----------------start----- */

.owl-carousel,
.owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item,
.owl-carousel .owl-wrapper {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
  display: none;
}

.no-js .owl-carousel,
.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
  background: 0 0;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height 0.5s ease-in-out;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  /* background: url(owl.video.play.png) no-repeat; */
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 0.1s ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-play-icon,
.owl-carousel .owl-video-playing .owl-video-tn {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 0.4s ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
.owl-theme .owl-dots,
.owl-theme .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}

.owl-theme .owl-nav {
  margin-top: 10px;
}

.owl-theme .owl-nav [class*="owl-"] {
  color: #fff;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #d6d6d6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}

.owl-theme .owl-nav [class*="owl-"]:hover {
  background: #869791;
  color: #fff;
  text-decoration: none;
}

.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #d6d6d6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 0.2s ease;
  border-radius: 30px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #869791;
}

/* Old Css */

.autoComInput {
  width: 100%;
  height: auto;
  font-size: 14px;
  /* color: #242424; */
  font-weight: 500;
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-autocomplete-input::placeholder {
  color: #9e9e9e !important;
  font-weight: 500;
  font-size: 16px;
  /* color: #70717270 !important; */
}

>>> .datePickInput .dp__input::placeholder {
  color: #9e9e9e !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  /* color: #70717270 !important; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}

>>> .p-autocomplete-dd .p-autocomplete-dropdown {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0px;
  display: none;
}

.v-select .v-field .v-text-field__prefix,
.v-select .v-field .v-text-field__suffix,
.v-select .v-field .v-field__input,
.v-select .v-field.v-field {
  cursor: pointer;
  height: 41px;
  box-shadow: none !important;
}

body {
  zoom: 0.9;
  -ms-zoom: 0.9;
  -webkit-zoom: 0.9;
  -moz-transform: scale(0.9, 0.9);
  -moz-transform-origin: top;
}

>>> .captcha_section .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  position: relative !important;
  top: -6px !important;
  padding: 0px 6px !important;
}

>>> .captcha_section
  .v-input--density-default
  .v-field--variant-solo-filled
  .v-label.v-field-label--floating {
  top: 8px;
  margin: 0px 7px;
  font-size: 10px;
}

>>> .captcha_section .v-field--center-affix .v-label.v-field-label {
  font-size: 11px;
  top: 50%;
  transform: translateY(-50%);
}

>>> .captcha_section .v-field__input {
  padding: 0px 6px;
}

#visible {
  visibility: hidden;
}

.container-styles {
  border-radius: 20px;
}

>>> .v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

>>> .v-overlay__content {
  outline: none;
  position: absolute;
  pointer-events: auto;
  contain: layout;
}

.recent_style {
  position: absolute;
  background: white;
  width: 340px;
  margin: 12px 12px;
  border-radius: 20px;
  z-index: 5;
  border: 1px solid gray;
}

/* >>>.v-selection-control__input {
  bottom: 6px;
} */

>>> .subscribe-inputFields .v-input__details {
  display: block !important;
  position: relative;
  top: 5px;
}

>>> .subscribe-inputFields .v-input__details .v-messages__message {
  font-size: 10px !important;
}

.v-select .v-select__selection-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
}

.v-selection-control__wrapper {
  width: 30px;
  max-height: 23px;
}

.login-card {
  position: absolute;
  z-index: 2;
}

/* Datepicker Style Section */

.dp__icon {
  display: none;
}

/* .v-icon {
   width: 100%;
 } */

>>> .dp__input {
  border: none;
  border-radius: 0px !important;
  padding: 0px;
  height: auto;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  height: 43px;
  background-color: #fff0;
  font-size: 14px;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  /* color: grey; */
}

.calendar-container {
  display: flex;
  align-items: center;
  position: relative;
}

.label-top {
  position: absolute;
  top: -6px;
  left: -46px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
}

.datepicker-label {
  position: absolute;
  bottom: -38px;
  transform: translateY(-100%);
  background-color: transparent;
  pointer-events: none;
  z-index: 1;
  line-height: 1;
  color: #9ca3af;
  font-size: 14px;
  font-weight: 300 !important;
}

.label-top2 {
  position: absolute;
  top: -6px;
  left: -46px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 28px;
  left: -8px;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: gray;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.multiLabel-top {
  position: absolute;
  right: 87px;
  transform: translateY(-100%);
  background-color: white;
  padding: 0 8px;
  font-size: 15px;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute;
  right: 69px;
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
  font-weight: 600;
}

.delete-icon {
  display: flex;
  justify-content: end;
  position: absolute;
  left: 12px;
  z-index: -1;
}

>>> .dp__clear_icon {
  top: 52% !important;
}

/* Datepicker Style Section End */

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100% !important;
  border-radius: 50% !important;
  margin: 8px !important;
}

.main-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

>>> .v-field__input {
  color: grey !important;
}

.form-btn {
  padding-left: 40px;
  color: white;
}

.btn-box {
  background-color: transparent;
  margin: 5px;
  padding: 2px;
  box-shadow: none;
  font-size: 15px !important;
}

.btn-active {
  background-color: #ff5400;
  color: white;
}

.v-checkbox .v-input__details {
  display: none;
}

/* Login Page End */

/* Login Remember Btn */

/* .v-selection-control {
  align-items: start;
} */

.v-label {
  align-items: start;
}

.labeling1 {
  position: relative;
  /* bottom: -6px; */
  top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: black;
  left: 30px;
  z-index: 2;
}

.labeling {
  position: relative;
  /* bottom: -6px; */
  top: 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgb(51, 6, 148);
  left: 30px;
  z-index: 2;
}

.labeling:focus {
  color: red !important;
}

/* .back {
   background-image: url('../assets/bg.png');
   background-repeat: no-repeat;
   background-size: 800px;
   background-position: top right 24%;
   z-index: 1;
   border-radius: 20px;
 } */

body {
  font-family: var(--normal-font);
}

/* navbar */
.logo {
  width: 180px;
}

.nav-link {
  font-weight: var(--font-600);
  font-size: var(--font-14);
}

.nav-link.active {
  color: var(--primary-color) !important;
}

.btn-white {
  background-color: white;
  padding: 10px 25px;
  border-radius: 15px;
}

.btn-text {
  color: var(--primary-color);
  font-weight: var(--font-700);
}

.v-select .v-field.v-field {
  cursor: pointer;
  box-shadow: none;
}

/* .v-btn__content {
  color: #FF5400;
  font-size: 17px;
  font-weight: 600;
  text-transform: capitalize;
} */

.book-ticket {
  /* color: #FF5400; */
  background-color: #f0f0f0;
}

.whatts-logo {
  display: block;
  position: fixed;
  cursor: pointer;
  right: 20px;
  bottom: 112px;
  z-index: 9999;
  width: 65px;
  height: 65px;
}

/* .whatts-logo {
   display: block;
   position: fixed;
   cursor: pointer;
   right: 30px;
   bottom: 40px;
   z-index: 9999;
 } */

/* navbar end */

/*Flight banner */
.banner-left-small-title {
  color: var(--primary-color) !important;
}

/* .bannar-bg {
   background-image: url('../assets/bg-map.png');
   background-size: 55% 83%;
   background-repeat: no-repeat;
   background-position: center;
 } */

.flight-right {
  width: 500px !important;
  height: 500px;
}

.banner-left-small-title h6 {
  font-weight: var(--font-600);
}

.banner-small-text {
  color: var(--primary-color) !important;
}

.banner-left-title h4 {
  font-family: var(--title-font);
  font-weight: var(--font-600);
  font-size: 60px;
}

.location {
  color: #ff5400;
  width: 22px;
}

.form-control {
  border: none;
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
  /* background-color: unset !important; */
  /* width: 150px; */
}

.form-control:focus {
  color: unset !important;
  border-color: unset !important;
  outline: none !important;
  box-shadow: none !important;
}

.form-select {
  color: unset !important;
  /* border-color:unset !important; */
  outline: none !important;
  box-shadow: none !important;
  border: none;
  border-bottom: 1px solid lightgrey;
  border-radius: 0;
  /* width: 150px; */
}

.form-select:focus {
  border-color: unset !important;
}

.form-control::placeholder {
  font-size: 14px;
  font-weight: var(--font-500);
}

.input-group-text {
  text-align: center;
  white-space: nowrap;
  border: none;
}

.form-location {
  width: 18px;
  margin-top: -15px;
}

/* .btn {
  background-color: #ff5400;
  color: #fff;
} */

/* .btn:hover {
  border: 1px solid #ff5400;
  color: #ff5400;
} */

/* banner end */

/* Explore Top Destination */

.top-destination {
  color: #ff5400;
  font-size: 14px;
}

.heading {
  font-family: var(--title-font);
  font-weight: var(--font-600);
}

.card.top-card {
  height: 330px;
  border-radius: 25px;
}

.card.top-card .card-tour {
  height: 220px;
  width: auto;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}

/* .icon-container {
    margin-right: 10px;
  } */

.calendar-wrapper {
  display: inline-block;
  border-bottom: 1px solid lightgray;
}

.calendar-label {
  display: block;
  margin-bottom: 5px;
}

.card-body .star {
  color: #ff5400;
  margin-top: 20px;
}

.card-body .rate {
  color: #ff5400;
}

.how-we-work {
  color: #ff5400;
  font-weight: 600;
  font-size: 14px;
}

.how-we-section {
  margin-top: 100px;
}

.choose {
  width: 40px;
}

.choose-item {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  width: 75px;
  height: 75px;
  border-radius: 10px;
}

.choose-para {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}

.car-right-wrapper {
  width: 700px;
  height: 700px;
}

.lorem {
  font-size: 11px;
}

.session-bg {
  /* background-image: url("../assets/bg1.png"); */
  background-repeat: no-repeat;
  background-position: top 100px right -290%;
}

.travel-type {
  background-color: #fff7ed;
  /* background-color: rgb(243, 216, 207); */
}

.trvel-para {
  color: #ff5400;
  font-weight: 600;
}

.owl-theme .owl-nav [class*="owl-"] {
  border: 2px solid darkturquoise;
  padding: 15px !important;
  border-radius: 50% !important;
  background-color: rgb(243, 216, 207) !important;
  width: 50px;
  margin-right: 20px;
  color: darkturquoise;
}

.owl-images img {
  height: 350px;
  border-radius: 30px;
}

.owl-theme .item {
  width: 330px;
}

.owl-images {
  position: relative;
}

.badge {
  position: absolute;
  top: 40px;
  left: 15px;
  background-color: #ff5400;
  color: #fff;
  border-radius: 30px;
}

.testi-para {
  color: #ff5400;
  font-weight: 600;
}

.testi-div {
  margin-top: 100px;
}

.card img {
  width: 100%;
  height: 100%;
}

.test-name {
  font-size: 18px;
  font-weight: 400;
}

.card-text {
  font-size: 14px;
}

.card {
  height: 250px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px,
    rgba(0, 0, 0, 0.05) 0px 5px 10px;
  border: none;
  border-radius: 25px;
}

.card-relative {
  position: relative;
}

.quotes {
  position: absolute;
  top: -35px;
}

/* Subscribe Section */

.subscribe {
  /* background-color: navy !important; */
  background-color: #019df2 !important;
  color: white;
  border-radius: 14px;
  line-height: 28px;
}

input {
  outline: none;
}

>>> .v-switch__track {
  height: 12px;
  width: 24px;
}

>>> .v-switch__thumb {
  width: 16px;
  height: 16px;
}

.subscribe-input {
  border-radius: 17px;
  border: none;
  padding: 15px 20px;
  background-color: #fff;
}

>>> .dp__input {
  font-family: "Poppins", sans-serif !important;
}

.rounded-shadow {
  box-shadow: 0px 0px 4px 0px #002d5b;
}

>>> .subscribe-inputFields .v-input__control {
  display: flex;
  grid-area: control;
  height: 20px !important;
}

>>> .subscribe-inputFields .v-field__input {
  padding-top: 0;
}

>>> .subscribe-inputFields .v-field__field {
  top: -3px;
}

/* >>>.v-field__field {
  height: 40px;
} */

>>> .input-styles-2 .v-input .v-input__control {
  height: 25px !important;
}

>>> .v-text-field .v-field--no-label input,
.v-text-field .v-field--active input {
  opacity: 1;
  position: relative;
  top: -5px;
}

>>> .subscribe-inputFields .v-input--density-default .v-field--variant-solo,
.v-input--density-default .v-field--variant-solo-inverted,
.v-input--density-default .v-field--variant-solo-filled,
.v-input--density-default .v-field--variant-filled {
  --v-input-control-height: 50px;
  --v-field-padding-bottom: -7px;
}

>>> .subscribe-inputFields .v-field--variant-solo {
  box-shadow: none;
}

>>> .subscribe-inputFields
  .v-input--density-default
  .v-field--variant-solo
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-solo-inverted
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-filled
  .v-label.v-field-label--floating,
.v-input--density-default
  .v-field--variant-solo-filled
  .v-label.v-field-label--floating {
  top: -1px;
}

/* .subscribe-btn {
   border-radius: 5px;
   border: none;
   width: 200px;
   height: 35px;
   background-color: #002d5b  !important;
   color: white !important;
 } */

.subscribe-button {
  min-width: 0px !important;
  width: 40px;
  height: 40px;
  font-size: 16px;
  display: inline-block;
  border-radius: 50%;
  padding: 0px;
  background-color: #002d5b !important;
  color: white !important;
}

.subscribe-inputFields {
  border: 1px solid lightgray;
  border-radius: 32px;
}

.subscribe-inputFields .v-field--rounded {
  border-radius: 9999px 0px 0px 9999px;
  box-shadow: none;
}

.subscribe-inputFields .v-field--variant-solo {
  box-shadow: none;
}

>>> .v-input__details {
  display: none;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.email-error {
  position: absolute;
  margin-top: 20px !important;
  margin-bottom: -25px;
  margin-right: 65px;
}

.agree-error {
  position: absolute;
  margin-top: 40px;
  margin-left: 40px;
  font-size: 14px;
}

/* Subscribe Section End */

.form-input {
  border: none;
}

.btn-badge {
  background-color: aqua;
  border: none;
  border-radius: 40px;
}

.btn-badge:hover {
  background-color: aqua;
  border: none;
  color: black;
}

.py-8 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.social-icons {
  color: var(--primary-color) !important;
}

.fs-14 {
  font-size: 14px;
  list-style-type: none;
}

.fs-14 li {
  padding: 6px;
}

section {
  margin: auto !important;
}

.slide-card {
  border-radius: 4%;
  width: 258px;
  height: 300px;
}

.card-test {
  position: relative;
  padding: 10px 15px;
  border-radius: 8%;
  max-height: 500px;
}

.card-img {
  position: absolute;
  width: 100px;
  height: 100px;
  top: -50%;
  right: 0;
  transform: translateY(-50%);
}

.radio {
  border-radius: 5px;
  background-color: black;
  color: white;
  font-size: 12px !important;
  font-weight: 500;
  box-shadow: 0 4px 16px rgba(17, 17, 26, 0.05),
    0 8px 32px rgba(17, 17, 26, 0.05) !important;
}

.v-input--density-default {
  --v-input-control-height: 38px;
  /* position: relative;
  top: 0px; */
}

.normal-radio {
  font-size: 14px !important;
  font-weight: 400px;
  height: 32px;
  width: 98px;
  padding-right: 10px;
  padding-right: 16px;
  border: 1px solid #0000003d;
  box-shadow: none;
}

.normal-radio span {
  font-size: 12px !important;
  font-weight: 500;
  /* color: black; */
}

.cart-adult {
  padding: 2px 15px 15px 15px;
  width: 214px;
  cursor: pointer;
  position: absolute;
  top: 131px;
  left: 252px;
}

.adult {
  height: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  /* background-color: #F7F7F7; */
}

.booknow {
  padding-right: 20px;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

>>> .p-datepicker-group-container {
  background-color: #ffffff !important;
  width: 200px;
  padding: 5px 3px;
  border: 1px solid lightgray;
  position: relative;
  right: 14px;
}

.calendar-wrapper {
  border-bottom: 1px solid #a9a1a1;
  max-width: 500px;
}

.calendar-wrapper {
  border-bottom: 1px solid #a9a1a1;
  max-width: 100% !important;
}

>>> .p-datepicker {
  width: auto;
  background: white !important;
}

/* Flight Search Box */

.search-btn {
  min-width: 0px !important;
  width: 60px;
  height: 60px;
  font-size: 16px;
  display: inline-block;
  border-radius: 50%;
  padding: 0px;
}

.search-btn span {
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin: 10px;
}

.formbottom_ClearBtn {
  border: 1px solid lightgray;
  border-radius: 30px;
  padding: 5px 8px;
  background-color: red;
}

.formbottom_addBtn {
  border: 1px solid lightgray;
  border-radius: 30px;
  padding: 5px 5px;
  background-color: green;
}

/* .search-btn {
   background-color: #002d5b ;
   color: white !important;
   text-transform: capitalize;
 } */

.mdi-airplane-takeoff::before {
  content: "\F05D5";
  margin-top: 0px;
  margin-right: 10px;
  /* color: gray; */
}

.mdi-airplane-landing::before {
  content: "\F05D4";
  margin-top: 0px;
  margin-right: 10px;
  /* color: gray; */
}

>>> .dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

.p-component * {
  box-sizing: border-box;
  /* padding: 2px;
  padding-top: 8px;
  padding-bottom: 8px; */
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-icon {
  display: none;
}

/* >>>.p-autocomplete-panel {
   color: white;
   border-radius: 10px;
 } */

.p-error-from {
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: -16px;
}

.p-error-to {
  font-size: 12px;
  color: red;
  position: absolute;
  bottom: 1px;
}

.dropdown-box {
  padding: 8px 10px;
  /* border-radius: 10px; */
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
  /* csdffsdfs */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

.p-float-label {
  width: 100%;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  text-align: left;
}

.p-float-label label {
  position: absolute;
  top: 5px;
  left: -1px;
  font-weight: 600;
  font-size: 1.125rem;
  margin-top: -0.5rem;
  line-height: 1.75rem;
}

/* .p-float-label label {
   position: absolute;
   pointer-events: none;
   top: 17px;
   left: 0px;
   margin-top: -0.5rem;
   transition-property: all;
   transition-timing-function: ease;
   line-height: 1;
   color: #242424;
   font-weight: 600;
   font-size: 16px;
 } */

>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

/* End Flight Search Box */

/* .flight-back {
   background-image: url('../assets/travel-pattern-bg4.svg');
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-size: cover;
     background-position-y: 70px;
   background-color:#e0e9f191;
   background: linear-gradient(90deg, rgba(148, 187, 233, 0.7512254901960784) 0%, rgba(238, 174, 202, 1) 50%, rgba(148, 187, 233, 0.7456232492997199) 100%);

 } */

.form-card {
  width: 100%;
  padding: 20px;
  overflow: unset;
  z-index: 1;
  cursor: pointer;
  /* background-color: #ffffffe8; */
  box-shadow: none !important;
  border-radius: 0px 20px 20px 20px !important;
}

/* .form-card {
   width: 100%;
   padding: 20px 0px 20px 20px;
   overflow: unset;
   z-index: 1;
   cursor: pointer;
   background-color: #ffffffe8;
   box-shadow: none !important;
   border-radius: 0px 0px 0px 20px !important;
 } */

.form-container {
  position: relative;
  top: 20px;
}

.banner-lg-text {
  font-size: 72px;
  font-weight: 500;
}

.banner-sm-text {
  font-size: 18px;
}

.DatecolumnSizing {
  width: 214px !important;
}

.paxcolumnSizing {
  width: 240px !important;
}

@media only screen and (max-width: 480px) {
  .form-container {
    position: relative;
    top: 0px;
  }

  .banner-lg-text {
    font-size: 45px;
    font-weight: 500;
  }

  .banner-sm-text {
    font-size: 14px;
  }

  .mobile {
    display: none;
  }

  .flight1 {
    display: none !important;
  }

  .form-card {
    padding: 20px;
    border-radius: 0px 20px 20px 20px !important;
  }

  .pax-fonts span {
    font-size: 12px !important;
    color: #242424;
    font-weight: 500;
  }

  /* .flight-back {
     background-image: url('../assets/flight.png');
     background-repeat: no-repeat;
     background-attachment: fixed;
     background-size: cover;
     background-position-y: 70px;

   } */

  /* .subcribe-card {
     margin-top: 10% !important
   } */

  .DatecolumnSizing {
    width: 100% !important;
  }

  .paxcolumnSizing {
    width: 100% !important;
  }
}

@media only screen and (max-width: 992px) {
  .car-right {
    width: 100%;
    height: auto;
    margin-top: 0;
  }

  .session-bg {
    background-image: none;
  }

  .back {
    background-image: none;
  }

  .flight-right {
    width: 80% !important;
  }

  .form-btn {
    padding: 10px 0px 0px 0px !important;
  }

  .btn-box,
  .pipe {
    color: blueviolet !important;
  }

  .btn-active {
    background-color: blueviolet;
    color: white !important;
  }

  .sub-text {
    font-size: 26px;
  }

  /* .subscribe-btn {
    width: 40%;
    height: 100%;
    font-size: 18px;
  } */

  .cart-adult {
    top: 113px !important;
    left: 275px !important;
  }

  .email-error {
    width: 100%;
  }

  .agree-error {
    margin-top: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
    padding-right: 15px;
  }

  .cart-adult {
    top: 134px;
    left: 188px;
  }

  .email-error {
    margin-right: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .owl-theme .owl-nav [class*="owl-"] {
    margin-right: 0 !important;
  }

  .owl-theme .item {
    width: auto;
  }

  .dropdown_section {
    width: auto !important;
  }

  /* .email-error {
    margin-right: 465px;
  } */
}

@media only screen and (max-width: 576px) {
  .cart-adult {
    top: 280px !important;
    left: 181px !important;
  }

  /* .email-error {
    margin-right: 240px;
  } */
}

.p-float-label::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

.p-float-label::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

.p-float-label::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}

/* Scroll Bar Work */

>>> .pv_id_2_list {
  overflow: auto !important;
  max-height: 100px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar {
  width: 5px !important;
  background-color: navy !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey !important;
  border-radius: 10px !important;
}

>>> .p-autocomplete-panel.p-component.p-ripple-disabled::-webkit-scrollbar-thumb {
  background: lightgrey !important;
  border-radius: 10px !important;
}

>>> .v-field__outline {
  display: none;
}

.input-styles {
  padding: 5px;
  height: 92px;
  /* box-shadow: 15px 10px 7px -18px gray;
   clip-path: inset(0px -16px 12px 0px); */
  padding: 1rem 1.5rem;
}

.styles_input1 {
  border-radius: 0px 0px 0px 60px !important;
}

.styles_input2 {
  border-radius: 0px 60px 0px 0px !important;
}

.input-styles-1 {
  height: 32px;
  width: 180px;
  padding: 4px 7px;
  border-radius: 15px;
  border: 1px solid #0000003d;
}

.input-styles-focus {
  box-shadow: 0 4px 16px rgba(17, 17, 26, 0.05),
    0 8px 32px rgba(17, 17, 26, 0.05);
  padding: 1rem 1.5rem;
  border-radius: 60px !important;
  height: 92px;
  -webkit-clip-path: none !important;
  clip-path: none !important;
}

/* .input-styles {
   box-shadow: 0px 1px 2px 0px gray;
   padding: 5px;
   background: #ffffff;
   border-radius: 7px;
   height: 44px;
 } */

.input-styles-2 {
  box-shadow: none;
  padding: 8px 5px 8px 12px;
  border-radius: 20px;
  border: 1px solid #0000003d;
  height: 32px;
  color: black;
  width: 115px;
}

>>> .select-option .v-select__selection-text {
  color: black !important;
  font-size: 12px !important;
  font-weight: 500;
  position: relative;
  top: -4px;
}

>>> .v-menu > .v-overlay__content {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  left: 505px !important;
  width: 290px !important;
  border-radius: 20px !important;
  position: relative !important;
}

>>> .v-overlay-container .v-overlay .v-select__content {
  left: 2.8px !important;
  width: 300px !important;
  border-radius: 20px !important;
  position: relative !important;
}

/* .form-card {
   width: 100%;
   padding: 20px 0px 20px 20px;
   overflow: unset;
   z-index: 1;
   cursor: pointer;
   background-color: #ffffffe8;
   box-shadow: none !important;
   border-radius: 0px 0px 0px 20px;
 } */

>>> .select-1 .v-field__append-inner {
  padding-top: 15px !important;
}

>>> .select-1 .v-field__input {
  padding-top: 5px;
}

>>> .select-1 .v-input--density-default .v-field--variant-plain,
.v-input--density-default .v-field--variant-underlined {
  --input-control-height: 52px !important;
  --v-field-padding-bottom: 0px;
}

>>> .select-2 .v-field__append-inner {
  padding-top: 0px !important;
  top: -3px;
  position: relative;
}

>>> .select-2 .v-field__input {
  padding: 2px 0px 2px 2px !important;
}

>>> .select-2 .v-input--density-default .v-field--variant-plain,
.v-input--density-default .v-field--variant-underlined {
  --v-input-control-height: 48px;
  --v-field-padding-bottom: 10px !important;
}

>>> .select-option .v-input {
  --v-input-chips-margin-bottom: 0 !important;
  --v-input-chips-margin-top: -3px !important;
}

>>> .select-1 .v-list.v-theme--light.v-list--density-default.v-list--one-line {
  background: #f9f8f8 !important;
}

/* >>>.input-styles .dp__today {
   border: 1px solid #002d5b  !important;
 } */

>>> .input-styles .dp__menu {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px !important;
  border-radius: 20px !important;
  border: 1px solid #d3d3d361 !important;
  top: 84px !important;
}

>>> .input-styles .dp__cell_inner {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50% !important;
}

>>> .input-styles .dp__range_between {
  border-radius: 0px !important;
}

>>> .input-styles .dp__date_hover_end:hover {
  background: #1976d2 !important;
  color: white !important;
  border-radius: 50% !important;
}

>>> .input-styles .dp__calendar_item:hover {
  background: #f3f3f3 !important;
  /* color:black !important; */
  border-radius: 50% !important;
}

/* >>>.input-styles .dp__range_end, .dp__range_start, .dp__active_date {
    border-radius: 50% !important;
    margin: 8px !important;
}
 >>>.input-styles .dp__cell_inner:hover{
    border-radius: 50% !important;
    margin: 8px !important;
} */

>>> .dp__date_hover:hover {
  background: #002d5b;
  color: white;
}

.back-bg {
  width: 100%;
  /* height: 500px; */
  border-radius: 20px;
  padding: 20px;
}

.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.toTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 52%;
  top: 60px;
  color: gray;
}

.p-float-label .p-inputwrapper-focus ~ label {
  display: none !important;
}

.p-float-label .p-inputwrapper-filled ~ label {
  display: none !important;
}

>>> .normal-radio .v-label {
  font-weight: 600 !important;
  opacity: 1 !important;
}

.pax-fonts span {
  font-size: 12px;
  color: black;
  font-weight: 500;
}

.contact-box {
  box-shadow: none;
  background: none;
  border: 1px solid lightgray;
}

>>> .contact-box .v-icon {
  transform: rotate(45deg);
}

.resu-form:hover {
  background-color: lightgray;
  /* padding:5px; */
}

.resu-form {
  padding: 10px;
}

.resu-card {
  border-radius: 10px;
  width: 350px;
  height: 200px;
  text-align: start;
}

/* Cookies Styles */

.cookie-info {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #333;
  color: white;
  text-align: center;
}

.cookie-info p {
  margin: 0;
}

.cookie-info button {
  margin-top: 5px;
  padding: 5px 10px;
  background-color: #ccc;
  border: none;
  cursor: pointer;
}

/* ----------ne adddd--- */

.form-group1 {
  display: flex;
  background: #f5faff;
  height: 100%;
  border-radius: 10px;
  align-items: center;
  position: relative;
}

.form-group1.focused {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 4, 0.5);
}

.form-control1 {
  background: #f5faff !important;
  border-radius:0.5rem;
  /* height: 100%; */
}

.form-groupicon {
  align-items: center;
  display: flex;
  margin-left: 8px;
}

>>> .datePickInput .dp__month_year_row {
  background-color: #ffc104;
  padding: 0px 10px;
}

>>> .datePickInput .dp__inner_nav {
  border: 1px solid black;
  background-color: black;
  color: white;
  width: 15px;
  height: 15px;
}

>>> .datePickInput .dp__calendar_item {
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  border-radius: 10px;
  margin: 2px;
}

>>> .datePickInput .dp__date_hover:hover {
  border: 1px solid #ffc104;
  background: #ffc104;
}

>>> .datePickInput .dp__input_icon {
  display: none;
}

.addbtn {
  width: 30px;
  height: 25px;
  background: #63AB45;
  text-align: center;
  color: white;
  cursor: pointer;
}

.addbtn:hover {
  background: #ffc104;
  color: #63AB45;
}

.adddata {
  width: 70px;
  height: 25px;
  color: block;
  background: #f5faff;
  text-align: center;
}

/* .swap {
  position: relative;
  left:48px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #002d5b;
  cursor: pointer;
}

.swap:hover {
  background: #ffc104;
  color: #002d5b;
} */

.logoimg {
  width: 36px;
  height: 36px;
  border-radius: 10px;
}

@media only screen and (max-width: 768px) {
  .filter-toggle {
    display: block;
  }

  .airline-logo {
    width: auto;
  }

  .airline-logo .v-img {
    width: 65%;
  }

  .res-name {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    margin-top: 5px;
    padding: 0px 0px 0px 12px;
  }

  /* .res-stop {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px !important;
    position: relative;
    top: 2px;
    right: 10px;
    color: grey;
  } */

  .time-section span {
    /* font-size: 9px !important;
    font-weight: 500 !important; */
    color: grey;
  }

  .airline-logo {
    padding: 5px;
  }

  .airline-logo .v-img {
    width: 20px !important;
  }

  .airline-profile {
    padding: 0;
  }

  .airline-name {
    font-size: 8px;
  }

  .airline-id {
    font-size: 6px;
  }

  .aircode-name {
    font-size: 11px;
  }

  .airline-date {
    font-size: 8px;
  }

  .airline-time {
    font-size: 10px;
  }

  .class-type .v-icon {
    font-size: 11px !important;
  }

  .class-type span {
    font-size: 9px;
  }
}


/* ------------enddd--------------- */

>>> .filter_icons .v-selection-control--density-default {
  --v-selection-control-size: 30px !important;
}

>>> .filter_icons .v-selection-control .v-label {
  font-size: 14px;
  font-weight: 500 !important;
}

>>> .filter_icons .v-selection-control__input .v-icon {
  font-size: 20px !important;
}

>>> .filter_icons .price_slider .v-slider-track {
  --v-slider-track-size: 3px !important;
}

>>> .filter_icons .price_slider .v-slider-thumb {
  --v-slider-thumb-size: 16px !important;
}

/* Filter By Section End*/

.bg-image {
  height: auto;
  /* background-image: url("../assets/tourist_vector.jpg"); */
  background-size: 100% 100%;
  background-position: left;
}

.dp__pointer {
  cursor: pointer;
  border: none;
  border-bottom: 1px solid grey;
  border-radius: 0px;
  width: 100%;
}

>>> .dp__input_icon {
  cursor: pointer;
  position: absolute;
  top: 64%;
  left: 82%;
  color: black !important;
  transform: translateY(-50%);
  color: var(--dp-icon-color);
}

.mdi-menu-down::before {
  content: "\F035D222";
  display: none !important;
}

.v-input__append > .v-icon {
  position: relative;
  right: 43px;
  top: -4px;
}

.dp__input_wrap {
  position: relative;
  width: 100%;
  box-sizing: unset;
  top: -10px;
}

.labeling {
  position: relative;
  bottom: 9px;
}

/* 
.v-input--density-default {
  --v-input-control-height: 38px;
  position: relative;
  top: -22px;
} */

.v-slider.v-input--horizontal {
  position: relative;
  top: 0px;
}

.cart-adult {
  padding: 15px;
  width: 500px;
  cursor: pointer;
  position: relative;
  top: -65px;
}

>>> .dp__clear_icon {
  display: black;
  position: absolute;
  top: 50%;
  /* right: 0; */
  transform: translateY(-50%);
  cursor: pointer;
}

>>> .dp__overlay_col[aria-disabled="true"] {
  display: none;
}

.adult {
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #f7f7f7;
}

.adult-plus {
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: #05386d;
}

.adult-title {
  font-size: 12px;
  font-weight: 600;
  color: #05386d;
}

.price-head .price-currency {
  font-size: 18px;
  font-weight: 600;
  color: black;
  padding-top: 11px;
  padding-right: 6px;
  /* font-size: 25px;
  font-weight: 600;
  color: black;
  padding-top: 6px;
  padding-right: 6px; */
}

.price-head .price-Amount {
  font-size: 36px;
  font-weight: 600;
  color: black;
}

.radio {
  /* background-color: #FFB80A; */
  border-radius: 5px;
  color: black !important;
  width: 120px;
  font-size: 15px !important;
  font-weight: 400px;
}

.normal-radio {
  font-size: 14px !important;
  font-weight: 400;
  height: 30px;
  width: auto;
  /* width: 150px; */
}

.air-title {
  background-color: #ff5400;
  color: white;
  padding: 5px;
}

.cart1 {
  box-shadow: none;
  border: 1px solid lightgray;
  font-size: 15px;
  font-weight: 500;
  max-width: 208px;
  border-radius: 5px;
}

.cart1 p {
  text-align: center;
}

hr {
  margin: 0rem 0 !important;
}

.f-size-16 {
  font-size: 16px;
}

.f-size-15 {
  font-size: 15px;
}

.f-size-14 {
  font-size: 14px;
}

.f-size-13 {
  font-size: 13px;
}

.f-size-12 {
  font-size: 12px;
}

.f-size-11 {
  font-size: 11px;
}

.f-size-10 {
  font-size: 10px;
}

.f-size-9 {
  font-size: 9px;
}

.fw-500 {
  font-weight: 500;
}

.rec-num {
  font-size: 14px;
  font-weight: 600;
  color: navy;
}

.bord {
  border-right: 2px solid gray;
  position: relative;
  top: -2px;
  height: 63px;
}

.depar {
  background-color: lightgray;
  padding: 3px;
}

.res-name {
  font-size: 15px;
  font-weight: 500;
  margin: 0;
  margin-top: 5px;
  padding: 0px 0px 0px 12px;
}

.res-stop {
  font-size: 12px;
  font-weight: 600;
  color: grey;
}

/* .res-stop {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 0px !important;
  position: relative;
  top: 2px;
  right: 12px;
  color: grey;
} */

.res-time {
  font-size: 11px;
  font-weight: 500;
}

.dot-1 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 42%;
  left: 30%;
  z-index: 1;
}

.dot-2 {
  width: 8px;
  height: 8px;
  border: 1px solid black;
  background-color: black;
  border-radius: 50%;
  position: relative;
  top: 18%;
  left: 50%;
  z-index: 1;
}

.res-icon {
  border: 1px solid #05386d;
  position: relative;
  top: 14px;
  width: 92%;
}

.res-icon1 {
  float: right;
  color: #05386d;
  font-size: 18px;
}

>>> .dp__menu_inner {
  padding: var(--dp-menu-padding);
  box-shadow: 0px 0px 4px 0px black;
  background-color: #f5f5f521;
}

>>> .v-input__details {
  display: none;
}

.v-input--density-default {
  --v-input-control-height: 38px;
}

.stop {
  font-size: 16px;
  font-weight: 500;
  padding: 7px;
}

.v-slider.v-input--horizontal .v-slider-thumb {
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: 3px solid #3c3cde;
  border-radius: 50px;
}

.v-slider.v-input--horizontal .v-slider-track__fill {
  height: 3px;
  background-color: #7474e0;
}

/*  */
.v-slider-thumb__surface {
  height: 12px !important;
  width: 12px !important;
}

.v-checkbox .v-selection-control {
  min-height: var(--v-input-control-height);
  position: relative;
  top: 12px;
}

>>> .v-timeline-divider__dot--size-large {
  height: 22px;
  width: 56px;
}

>>> .v-timeline-divider__inner-dot {
  border-radius: 15px;
}

>>> .v-timeline-divider__inner-dot span {
  font-size: 11px;
  font-weight: 600;
}

.mdi-airplane-takeoff {
  content: "\F05D5";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

.mdi-airplane-landing {
  content: "\F05D4";
  margin-top: 0px;
  margin-right: 10px;
  color: gray;
}

>>> .dp__input_icon {
  display: none;
  top: 64%;
  left: -5% !important;
}

>>> .p-autocomplete-loader {
  position: absolute;
  top: 50%;
  right: 5px;
  margin-top: -0.5rem;
  left: 13 5px !important;
  display: block !important;
}

>>> .p-icon {
  display: none;
}

.p-error {
  font-size: 12px;
  color: red;
  position: absolute;
}

.dropdown-box {
  padding: 8px 10px;
  color: white;
  background-color: lightslategray;
  border-bottom: 1px solid white;
}

.dropdown-box:hover {
  background-color: black;
  color: white;
  border-radius: 10px;
  /* background-color: lightgray;
  color: black; */
}

>>> .p-autocomplete-dd .p-autocomplete-input,
.p-autocomplete-dd .p-autocomplete-multiple-container {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  outline: none !important;
}

.p-float-label {
  width: 100%;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.p-float-label label {
  position: absolute;
  pointer-events: none;
  top: 14px !important;
  left: 0px;
  margin-top: -0.5rem;
  transition-property: all;
  transition-timing-function: ease;
  line-height: 1;
  /* font-size: 16px;
  color: #242424;
  font-weight:600; */
}

.p-component * {
  box-sizing: border-box;
  /* padding: 2px;
  padding-top: 4px;
  padding-bottom: 4px; */
}

>>> .p-input-filled {
  box-shadow: 1px 1px 6px 0px black !important;
}

/* .dp__icon {
  display: block;
} */

.v-icon {
  --v-icon-size-multiplier: 1;
  align-items: center;
  justify-content: flex-start;
}

>>> .dp__input {
  border: none;
  /* border-bottom: 1px solid lightgrey !important; */
  border-radius: 0px !important;
  padding-left: 2px;
  padding-right: 2px;
  height: 24px;
  font-family: "Poppins", sans-serif !important;
}

.mdi-calendar {
  margin-right: 10px;
  width: auto;
  color: grey;
}

.calendar-container {
  display: flex;
  position: relative;
}

.label-top {
  position: absolute;
  top: -10px !important;
  left: -48px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label {
  position: absolute;
  top: 28px !important;
  left: -8px !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 16px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.label-top1 {
  position: absolute;
  top: 0 !important;
  left: -8px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 14px;
  color: #242424;
  font-weight: 600;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.datepicker-label2 {
  position: absolute;
  top: 28px !important;
  left: -8px !important;
  transform: translateY(-110%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 1rem;
  font-weight: 600;
  color: #242424;
  font-size: 16px;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.multiLabel-top {
  position: absolute;
  left: -8px !important;
  bottom: 0px !important;
  transform: translateY(-100%);
  background-color: transparent;
  padding: 0 8px;
  font-size: 12px;
  color: #242424;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
}

.multiDatepicker-label {
  position: absolute !important;
  top: 28px !important;
  left: -9px !important;
  transform: translateY(-100%);
  background-color: transparent !important;
  padding: 0 8px;
  font-size: 1rem;
  color: #999;
  /* transition: all 0.3s ease-out; */
  pointer-events: none;
  z-index: 1;
}

.delete-icon {
  display: flex;
  justify-content: end;
}

.hoverData1 {
  position: relative;
  left: 50%;
  top: -50px;
  width: 200px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverDataStop1 {
  position: relative;
  left: 38%;
  top: -61px;
  width: 250px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: auto;
}

.hoverDataStop2 {
  position: relative;
  left: 45%;
  top: -63px;
  width: 260px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
  height: auto;
}

.content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* cursor: pointer; */
}

/* .content:hover{
  width:100%;
  font-size:13px;
} */
.hoverData2 {
  position: absolute;
  left: 63%;
  bottom: 63px;
  border-radius: 5px;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.hoverData1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop1.visible {
  visibility: visible;
  opacity: 1;
}

.hoverDataStop2.visible {
  visibility: visible;
  opacity: 1;
}

.hoverData2.visible {
  visibility: visible;
  opacity: 1;
}

/* .hoverData1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
} */

/* .hoverDataStop1::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
} */

/* .hoverDataStop2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
} */

.hoverData2::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%) rotate(45deg);
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.8);
}

.refunt {
  color: blue;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 1px;
}

>>> .v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}

.filter-toggle {
  display: none;
}

.v-card {
  /* border-radius: 10px !important; */
  z-index: revert-layer;
  overflow: unset;
}

.hoveredCard:hover {
  cursor: pointer;
  /* box-shadow: 0px 0px 4px 0px black; */
  /* box-shadow: none; */
  /* border: 2px solid lightgray; */
  /* border-radius: 8px !important; */
}

.card-in {
  box-shadow: none;
}

/* .card-in:hover {
  box-shadow: 0px 0px 1px 0px black;
} */

/* .card-in {
  box-shadow: 0px 0px 1px 0px black;
} */

.time-section span {
  /* font-size: 9px;
  margin-left: 5px;
  font-weight: 500; */
  color: grey;
}

.clear_style {
  border: 1px solid #ffa0a0;
  border-radius: 5px;
  padding: 4px 6px;
  height: 30px;
  cursor: pointer;
}

.clear_style:hover {
  background-color: #ffe9e9;
}

.add_style {
  border: 1px solid #a0ffa0;
  border-radius: 5px;
  padding: 4px;
  height: 30px;
  cursor: pointer;
}

.add_style:hover {
  background-color: #e9ffe9;
}

.Timing-section {
  border-right: 1px dotted grey;
}

@media only screen and (max-width: 991px) {
  .filter-toggle {
    display: block;
  }

  .magnify-btn .v-btn {
    width: 140px !important;
  }

  .Timing-section {
    padding: 0px 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .filter-toggle {
    display: block;
  }

  .airline-logo {
    width: auto;
  }

  .airline-logo .v-img {
    width: 65%;
  }

  .res-name {
    font-size: 15px;
    font-weight: 500;
    margin: 0;
    margin-top: 5px;
    padding: 0px 0px 0px 12px;
  }

  /* .res-stop {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 0px !important;
    position: relative;
    top: 2px;
    right: 10px;
    color: grey;
  } */

  .time-section span {
    /* font-size: 9px !important;
    font-weight: 500 !important; */
    color: grey;
  }

  .airline-logo {
    padding: 5px;
  }

  .airline-logo .v-img {
    width: 20px !important;
  }

  .airline-profile {
    padding: 0;
  }

  .airline-name {
    font-size: 12px;
  }

  .airline-id {
    font-size: 12px;
  }

  .aircode-name {
    font-size: 12px;
  }

  .airline-date {
    font-size: 11px;
  }

  .airline-time {
    font-size: 12px;
  }

  .class-type .v-icon {
    font-size: 11px !important;
  }

  .class-type span {
    font-size: 9px;
  }
}

@media only screen and (min-width: 479px) {
  .aircode-hide {
    display: block;
  }

  .aircode-show {
    display: none;
  }

  .airlineLogo-hide {
    display: flex !important;
  }

  .airlineLogo-show {
    display: none !important;
  }

  .select-section-hide {
    display: flex !important;
  }

  .select-section-hide1 {
    display: flex !important;
  }

  .date-section-hide {
    display: block;
  }

  .date-section-show {
    display: none;
  }

  .fare-detail-mobile {
    display: none;
  }
}

@media only screen and (max-width: 480px) {
  .hoverDataStop1 {
    left: 38%;
  }

  .hoverDataStop2 {
    left: 59%;
  }

  .airlineLogo-hide {
    display: none !important;
  }

  .airlineLogo-show {
    display: block !important;
  }

  .pax-fonts span[data-v-92fd54d6] {
    font-size: 12px;
    color: #242424;
    font-weight: 700;
  }

  .aircode-hide {
    display: none;
  }

  .aircode-show {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .select-section-hide {
    display: none !important;
  }

  .select-section-hide1 {
    display: none !important;
  }

  .res-name {
    font-size: 10px;
    font-weight: 500;
    margin: 0;
  }

  .airlineLogo-show .mbl-price-section {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .mbl-price-section .price-currency {
    margin-bottom: 0px !important;
    font-size: 14px !important;
    font-weight: 600;
    color: black;
    padding-top: 10px;
    padding-right: 4px;
  }

  .airlineLogo-show .mbl-price-section .price-Amount {
    margin-bottom: 0px !important;
    font-size: 26px !important;
  }

  .airlineLogo-show .mbl-price-section .content {
    margin-bottom: 0px !important;
  }

  .airlineLogo-show .v-btn {
    height: 30px !important;
  }

  >>> .airlineLogo-show .v-btn .v-btn__content {
    font-size: 15px !important;
  }

  .date-section-hide {
    display: none;
  }

  .date-section-show {
    display: block;
  }

  .Timing-section {
    padding: 0px 20px !important;
    border: none !important;
  }

  .fare-detail-mobile {
    display: block;
  }

  .fare-detail-mobile .v-card-text {
    color: rgb(51, 102, 204);
    font-weight: 500;
    padding-left: 0 !important;
    text-align: left;
    width: auto !important;
  }

  .fare-detail-mobile .v-icon {
    font-size: 24px !important;
    height: 18px !important;
    width: 24px !important;
    position: relative !important;

    top: 2px !important;
  }
  .price-head .price-currency{
    padding-top:15px;
  }
}

>>> .v-field__outline {
  display: none;
}

.input-styles {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 38px;
}

.input-styles-1 {
  box-shadow: 0px 1px 2px 0px gray;
  padding: 5px;
  background: #ffffff82;
  border-radius: 7px;
  height: 30px;
  width: 100%;
}

.form-card {
  width: 90%;
  padding: 20px;
  overflow: unset;
  z-index: 1;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: none !important;
}

.back-bg {
  margin: 20px;
  width: 500px;
  height: 500px;
  border-radius: 30px;
}

.fromTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 20px;
  top: 60px;
  color: gray;
}

.toTop {
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  left: 52%;
  top: 60px;
  color: gray;
}

>>> .normal-radio .v-label {
  font-weight: 600 !important;
  opacity: 1 !important;
}

.round-mode,
.oneway-mode {
  padding: 10px 10px;
  overflow: unset;
  box-shadow: none;
  border: 2px solid lightgray;
  border-radius: 5px !important;
}

/* Traveller Pax Dropdown section */

>>> .input-styles .select-1 {
  --v-field-padding-top--plain-underlined: 12px;
}

>>> .input-styles .select-1 .v-input__control {
  height: 36px;
}

>>> .input-styles .select-1 .v-field__input {
  padding-top: 2;
  align-items: baseline;
}

>>> .input-styles .select-1 .v-field__append-inner {
  padding-top: 0;
}

>>> .select-2 .v-input {
  --v-input-chips-margin-bottom: 0 !important;
  --v-input-chips-margin-top: -3px !important;
}

>>> .select-2 .v-input--density-default {
  top: 0;
}

>>> .select-2 .v-input__control {
  height: 28px;
}

>>> .select-2 .v-field__input {
  padding-top: 0px;
  align-items: baseline;
}

>>> .select-2 .v-field__append-inner {
  padding-top: 0px;
}

/* Traveller Pax Dropdown section End */

.p-float-label .p-inputwrapper-focus ~ label {
  /* display:none !important; */
  font-size: 14px !important;
  top: -1.2rem !important;
  left: -38px;
  color: #242424;
  font-weight: 600;
}

.p-float-label .p-inputwrapper-filled ~ label {
  /* display:none !important; */
  font-size: 14px !important;
  top: -1.2rem !important;
  left: -38px;
  color: #242424;
  font-weight: 600;
}

.p-inputtext {
  font-size: 16px !important;
  font-weight: 600px !important;
}

.pax-fonts span {
  font-size: 16px;
  color: #242424;
  font-weight: 600;
}

.dialog-bottom-transition-enter-active,
.dialog-bottom-transition-leave-active {
  transition: transform 0.2s ease-in-out;
}

>>> .dp__month_year_select {
  pointer-events: none;
}

>>> .dp__today {
  border: 1px solid #002d5b !important;
}

>>> .dp__date_hover:hover {
  background: #002d5b;
  color: white;
}

>>> .dp__menu.dp__menu_index.dp__theme_light.dp__menu_transitioned {
  top: -10px !important;
  left: -50px !important;
  transform: none !important;
}

#navbar {
  display: block;
}

#food {
  display: block;
}

.recment {
  border: 1px solid rgb(31 206 229);
  background-color: white;
}

.normal-rec {
  border: 1px solid rgb(163, 161, 161) !important;
}

.top_filterprice {
  font-size: 18px;
  font-weight: 500;
}

.top_filterTitle {
  font-size: 14px;
}

.mutilti_data {
  padding: 2px 4px;
  max-height: 250px;
  min-height:95px;
  /* overflow: scroll;
  overflow-x: hidden; */
  border: 1px solid lightgray;
  box-shadow: none;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: lightgrey;
  border-radius: 10px;
}

.select_btn:hover {
  background: #ffc104 !important;
  transition: all 0.2s;
}

>>> .drawer-section .v-navigation-drawer__scrim {
  background: #e9e9e9 !important;
  position: fixed !important;
  opacity: 0.01 !important;
  pointer-events: none !important;
}

>>> .v-navigation-drawer {
  width: 45% !important;
}

>>> .v-navigation-drawer--temporary {
  box-shadow: none !important;
}

>>> .v-timeline-divider__inner-dot {
  background: #63AB45 !important;
}

>>> .v-timeline--vertical.v-timeline {
  grid-row-gap: 24px !important;
}

>>> .v-timeline {
  justify-content: left !important;
}

>>> .drawer-section .v-list-item__content {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.offcanvas-header {
  background: #5293cb !important;
  color: white !important;
}

>>> .btn-icon .v-btn--size-default {
  --v-btn-height: 11px !important;
}

.drawer-section .text-reset:hover {
  color: red !important;
  background: white;
  border-radius: 8px;
}

.autocompete-menual-dropdown {
  border: 1px solid lightgray;
  background-color: white;
  cursor: pointer;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  width: 350px;
  max-width: 100%;
  padding: 4px;
  border-radius: 2px;
  margin-top: 18px;
  margin-left: -44px;
  z-index: 99999;
  font-size: 15px;
  font-weight: 500;
}

/* .row > * {
  width:0 !important;
} */
>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: space-evenly;
}

>>> .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>> .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #002d5b;
}

v-tabs v-tab .flightIcon::before {
  transform: rotate(90deg);
}

.drawer-section .offcanvas {
  width: 37%;
}
.layoverTime{
  width:52%;
}
.layoverTime1{
  width:65%;
}
.closeIcon{
  left:38px;
}

@media screen and (max-width: 480px) {
  .fromTrip .from {
    font-size: 12px !important;
  }
  .fromTrip .to {
    font-size: 12px !important;
  }
  .drawer-section .offcanvas {
    width: 100% !important;
  }

  >>> .roundTripDate .dp__clear_icon {
    display: block !important;
    right: -15px !important;
  }
  
  .drawer-section .Depature-heading {
    font-size: 14px !important;
  }
  .layoverTime{
  width:70%;
  }
  .layoverTime1{
  width:70%;
  }
  .closeIcon{
    left:5px;
  }
}
.fromTrip .from {
  font-size: 14px !important;
}
.fromTrip .to {
  font-size: 14px;
}
>>> .roundTripDate .dp__clear_icon {
  display: none;
}

.filtering .filter_icon {
  height: 40px !important;
  width: 40px !important;
  border: 1px solid #ffc104;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.drawer-section .Depature-heading {
  font-size: 16px;
}
.form-group{
  margin-bottom:0px;
}
>>>.dp--clear-btn{
  display:block !important;
  right:-12px;
}
.offcanvas-title{
  color:white;
}
.dropdown-menu.show{
  z-index:9;
}
>>>.dp__clear_icon{
  right:0 !important;
}
</style>
