<template>
  <div class="pagewrap modify-search" v-if="pageloader" tabindex="0">
    <v-form ref="form" v-model="valid1" @submit.prevent="submitform">
        <div class="head-wrapper">
           
            <!-- review booking flight-->
            <div class="flight-search">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-6 mb-4 mb-md-0">
                            <span class="fw-bold theme-text-white">Traveller Details</span>
                        </div>
                        <div class="col-12 col-md-6 mb-4 mb-md-0">
                            <ul class="reviewStatus step3">
                                <li><span class="numbering completed">1</span><span
                                        class="reviewText grayText font12 ">Flight Selected</span></li>
                                <li><span class="numbering onpage">2</span><span
                                        class="reviewText grayText font12 active">Review</span></li>
                                <li><span class="numbering ">3</span><span class="reviewText grayText font12 ">Traveller
                                        &amp; Addons</span></li>
                                <li><span class="numbering ">4</span><span class="reviewText grayText font12 ">Make
                                        Payment</span></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- body section -->
         
          
        <div class="content-section">
            <!-- flight result page - oneway start -->
            <div class="review-flight py-3" style="background-color:#efeeee;">
                <div class="container">
                    <div class="row">
                        <div class="col-12 col-md-9">
                            <div class="d-flex justify-content-between align-items-start mb-3" style="display:none !important;">
                                <div class="fw-bold">Flight Details</div>
                            </div>
                            <!-- flight details section -->
                            <div class="d-none" v-for="(data, index) of roundresult" :key="index">
                                <div class="fw-bold" v-if="data.Return">RoundTrip</div>
                                <div class="fw-bold" v-if="!data.Return">OneWay</div>
                                <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                                    <div class="row border-bottom py-2 m-auto">
                                        <div class="col-8">
                                            <div class="row d-flex flex-row">
                                                <div v-if="areLogosequal(data.Depature)" class=" d-flex align-items-center flex-wrap pe-0" 
                                                   :class="sameLogoData.length == 1 ? 'col-1' : 'col-2'" :style="sameLogoData.length >= 2 ? 'width:10%' : ''">
                                                    <div v-for="(logo,index) in sameLogoData" :key="index" :style="sameLogoData.length >= 2 ? 'width:50%' : ''">
                                                      <v-img :src="logo" width="40px" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                <div class="col-10">
                                                    <div class="fw-bold">{{ getdepName(data.fromTrip) }} To {{ getarrName(data.toTrip) }}</div>
                                                    <div class="font-medium" v-if="data.Return">Departure  {{ depdate(data.Depature) }} - Arrival {{ getstartdate(data.Return) }}</div>
                                                    <div class="font-medium" v-else>Departure {{ depdate(data.Depature) }} </div>
                                                </div>
                                           </div>
                                        </div>
                                        <div class="col-4 text-end align-self-center">
                                            <a class="font-small theme-text-accent-one" data-bs-toggle="collapse"
                                                href="#collapseDepart" role="button" aria-expanded="false"
                                                aria-controls="collapseDepart">Flight
                                                details<i class="bi bi-chevron-down ps-1"></i></a>

                                        </div>
                                    </div>
                                    <div class="collapse" id="collapseDepart">
                                        <div class="row" >
                                            <div class="col-sm-12">
                                                <div class="px-3">
                                                    <ul class="row py-3" v-for="(data1, index) of data.Depature" :key="index">
                                                        <li class="col-12 pb-3">
                                                            <div class="float-start">
                                                                <v-img :src="data1.MarketingCarrier.logo" width="30px" ></v-img>
                                                            </div>
                                                            <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                                                <div class="font-medium"> {{ data1.MarketingCarrier.Name }}</div>
                                                                <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                                            </div>
                                                              <div
                                                                class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                                Class - SS</div>
                                                            <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                                            <div class="font-medium float-start mx-5">{{data.Refund}}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                                            </div>
                                                            <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span
                                                                    class="fw-normal">{{getdated(data1.Departure.Date)}}</span></div>
                                                            <div class="font-small">{{ data1.Departure.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="float-start"><i class="bi bi-clock pe-2 fs-6"></i>
                                                            </div>
                                                            <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{ data1.layoverTimes }}</span>
                                                                <span class="font-small d-block">{{ (data.Depaturejourney.Stops) }} Stop{{ data.Depaturejourney.Stops !== 1 ? 's' : '' }}</span> <span
                                                                    class="font-small d-block">Equipment:{{data1.Equipment.AircraftCode}}</span>
                                                            </div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                                            <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span
                                                                    class="fw-normal">{{getdated1(data1.Arrival.Date)}}</span></div>
                                                            <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                                                class="font-small text-uppercase fw-bold"> <v-icon class="bi bi-briefcase me-1" font-size="16px"></v-icon> Baggage </span>
                                                            <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                                                class="font-small d-block">CABIN : 7 Kgs</span>
                                                        </li>
                                                        <li class="col-12 text-center">
                                                            <div
                                                                class="border d-inline-block theme-border-radius font-small p-2"  v-if="index !== data.Depature.length - 1">
                                                                Layover Time: {{ data.Depaturejourney.Time }}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- flight details section -->
                                <div class="theme-box-shadow theme-border-radius bg-light mb-3" v-if="data.Return">
                                    <div class="row border-bottom py-2 m-auto">
                                        <div class="col-8">
                                            <div class="row d-flex flex-row">
                                                <div v-if="areLogosequal1(data.Return)" class=" d-flex align-items-center flex-wrap pe-0" 
                                                   :class="sameLogoData1.length == 1 ? 'col-1' : 'col-2'" :style="sameLogoData1.length >= 2 ? 'width:10%' : ''">
                                                    <div v-for="(logo,index) in sameLogoData1" :key="index" :style="sameLogoData1.length >= 2 ? 'width:50%' : ''">
                                                      <v-img :src="logo" width="40px" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                <div class="col-10">
                                                    <div class="fw-bold">{{ getdepName(data.toTrip) }} To {{ getarrName(data.fromTrip) }}</div>
                                                    <div class="font-medium" v-if="data.Return">Departure  {{ depdate(data.Return) }} - Arrival {{ getstartdate(data.Depature) }}</div>
                                                </div>
                                           </div>
                                        </div>
                                        <div class="col-4 text-end align-self-center">
                                            <a class="font-small theme-text-accent-one" data-bs-toggle="collapse"
                                                href="#collapseReturn" role="button" aria-expanded="false"
                                                aria-controls="collapseReturn">Flight Details
                                                <i class="bi bi-chevron-down ps-1"></i></a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapseReturn">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="px-3" v-for="(data1, index) of data.Return" :key="index">
                                                    <ul class="row py-3">
                                                        <li class="col-12 pb-3">
                                                            <div class="float-start">
                                                                <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                                            </div>
                                                            <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                                                <div class="font-medium">{{ data1.MarketingCarrier.Name }}</div>
                                                                <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                                            </div>
                                                            <div
                                                                class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                                Class - SS</div>
                                                            <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                                            <div class="font-medium float-start mx-5">{{data.Refund}}</div>

                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                                            </div>
                                                            <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span
                                                                    class="fw-normal">{{ getdated(data1.Departure.Date) }}</span></div>
                                                            <div class="font-small">{{ data1.Departure.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="float-start mt-1"><i class="bi bi-clock pe-2 fs-6"></i>
                                                            </div>
                                                            <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{ data1.layoverTimes }}</span>
                                                                <span class="font-small d-block">{{ data.Returnjourney.Stops }} Stop{{ data.Returnjourney.Stops !== 1 ? 's' : '' }}</span> <span
                                                                    class="font-small d-block">Equipment:{{data1.Equipment.AircraftCode}}</span>
                                                            </div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                                            <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span
                                                                    class="fw-normal">{{ getdated1(data1.Arrival.Date) }}</span></div>
                                                            <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                                                class="font-small text-uppercase fw-bold"> <i
                                                                    class="bi bi-briefcase me-2 fs-6"></i> Baggage </span>
                                                            <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                                                class="font-small d-block">CABIN : 7 Kgs</span>
                                                        </li>
                                                        <li class="col-12 text-center">
                                                            <div
                                                                class="border d-inline-block theme-border-radius font-small p-2"  v-if="index !== data.Return.length - 1">
                                                                Layover Time: {{ data.Returnjourney.Time }}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="d-none" v-for="(data, index) of multiresult" :key="index">
                              {{ console.log(data,'datavaluee') }}
                                <div class="fw-bold">Multicity</div>
                                <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                                    <div class="row border-bottom py-2 m-auto">
                                        <div class="col-8">
                                            <div class="row d-flex flex-row">
                                                <div v-if="areLogosequalMulti(data.Flight1)" class=" d-flex align-items-center flex-wrap pe-0" 
                                                   :class="sameLogoDataMulti.length == 1 ? 'col-1' : 'col-2'" :style="sameLogoDataMulti.length >= 2 ? 'width:10%' : ''">
                                                    <div v-for="(logo,index) in sameLogoDataMulti" :key="index" :style="sameLogoDataMulti.length >= 2 ? 'width:50%' : ''">
                                                      <v-img :src="logo" width="40px" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                <div class="col-10">
                                                    <div class="fw-bold">{{ getdepName1(data.muticityData[0]) }} To {{ getarrName1(data.muticityData[0]) }}</div>
                                                    <div class="font-medium">Departure  {{ depdate(data.Flight1) }} - Arrival {{ getstartdate(data.Flight1) }}</div>
                                                </div>
                                           </div>
                                        </div>
                                        <div class="col-4 text-end align-self-center">
                                            <a class="font-small theme-text-accent-one" data-bs-toggle="collapse"
                                                href="#collapseDepart" role="button" aria-expanded="false"
                                                aria-controls="collapseDepart">Flight
                                                details<i class="bi bi-chevron-down ps-1"></i></a>

                                        </div>
                                    </div>
                                    <div class="collapse" id="collapseDepart">
                                        <div class="row" v-for="(data1, index) of data.Flight1" :key="index">
                                            <div class="col-sm-12">
                                                <div class="px-3">
                                                    <ul class="row py-3">
                                                        <li class="col-12 pb-3">
                                                            <div class="float-start">
                                                                <v-img :src="data1.MarketingCarrier.logo" width="30px" ></v-img>
                                                            </div>
                                                            <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                                                <div class="font-medium"> {{ data1.MarketingCarrier.Name }}</div>
                                                                <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                                            </div>
                                                            <div
                                                                class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                                Class - SS</div>
                                                            <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                                            <div class="font-medium float-start mx-5">{{data.Refund}}</div>

                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                                            </div>
                                                            <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span
                                                                    class="fw-normal">{{getdated(data1.Departure.Date)}}</span></div>
                                                            <div class="font-small">{{ data1.Departure.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="float-start mt-1"><i class="bi bi-clock pe-2 fs-6"></i>
                                                            </div>
                                                            <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{ data1.layoverTimes }}</span>
                                                                <span class="font-small d-block">{{ (data.Flight1JourneyTime.Stops) }} Stop{{ data.Flight1JourneyTime.Stops !== 1 ? 's' : '' }}</span> <span
                                                                    class="font-small d-block">Equipment:{{data1.Equipment.AircraftCode}}</span>
                                                            </div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                                            <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span
                                                                    class="fw-normal">{{getdated1(data1.Arrival.Date)}}</span></div>
                                                            <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                                                class="font-small text-uppercase fw-bold"> <v-icon class="bi bi-briefcase me-1" font-size="16px"></v-icon> Baggage </span>
                                                            <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                                                class="font-small d-block">CABIN : 7 Kgs</span>
                                                        </li>
                                                        <li class="col-12 text-center">
                                                            <div
                                                                class="border d-inline-block theme-border-radius font-small p-2"  v-if="index !== data.Flight1.length - 1">
                                                                Layover Time: {{ data.Flight1JourneyTime.Time }}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- flight details section -->
                                <div class="theme-box-shadow theme-border-radius bg-light mb-3">
                                    <div class="row border-bottom py-2 m-auto">
                                       <div class="col-8">
                                            <div class="row d-flex flex-row">
                                                <div v-if="areLogosequalMulti1(data.Flight2)" class=" d-flex align-items-center flex-wrap pe-0" 
                                                   :class="sameLogoDataMulti1.length == 1 ? 'col-1' : 'col-2'" :style="sameLogoDataMulti1.length >= 2 ? 'width:10%' : ''">
                                                    <div v-for="(logo,index) in sameLogoDataMulti1" :key="index" :style="sameLogoDataMulti1.length >= 2 ? 'width:50%' : ''">
                                                      <v-img :src="logo" width="40px" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                <div class="col-10">
                                                    <div class="fw-bold">{{ getdepName1(data.muticityData[1]) }} To {{ getarrName1(data.muticityData[1]) }}</div>
                                                    <div class="font-medium">Departure  {{ depdate(data.Flight2) }} - Arrival {{ getstartdate(data.Flight2) }}</div>
                                                </div>
                                           </div>
                                        </div>
                                        <div class="col-4 text-end align-self-center">
                                            <a class="font-small theme-text-accent-one" data-bs-toggle="collapse"
                                                href="#collapseReturn" role="button" aria-expanded="false"
                                                aria-controls="collapseReturn">Flight
                                                Details
                                                <i class="bi bi-chevron-down ps-1"></i></a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapseReturn">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="px-3" v-for="(data1, index) of data.Flight2" :key="index">
                                                    <ul class="row py-3">
                                                        <li class="col-12 pb-3">
                                                            <div class="float-start">
                                                                <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                                            </div>
                                                            <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                                                <div class="font-medium">{{ data1.MarketingCarrier.Name }}</div>
                                                                <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                                            </div>
                                                            <div
                                                                class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                                Class - SS</div>
                                                            <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                                            <div class="font-medium float-start mx-5">{{data.Refund}}</div>

                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                                            </div>
                                                            <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span
                                                                    class="fw-normal">{{ getdated(data1.Departure.Date) }}</span></div>
                                                            <div class="font-small">{{ data1.Departure.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="float-start mt-1"><i class="bi bi-clock pe-2 fs-6"></i>
                                                            </div>
                                                            <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{ data1.layoverTimes }}</span>
                                                                <span class="font-small d-block">{{ data.Flight2JourneyTime.Stops }} Stop{{ data.Flight2JourneyTime.Stops !== 1 ? 's' : '' }}</span> <span
                                                                    class="font-small d-block">Equipment:{{data1.Equipment.AircraftCode}}</span>
                                                            </div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                                            <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span
                                                                    class="fw-normal">{{ getdated1(data1.Arrival.Date) }}</span></div>
                                                            <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                                                class="font-small text-uppercase fw-bold"> <i
                                                                    class="bi bi-briefcase me-2 fs-6"></i> Baggage </span>
                                                            <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                                                class="font-small d-block">CABIN : 7 Kgs</span>
                                                        </li>
                                                        <li class="col-12 text-center">
                                                            <div
                                                                class="border d-inline-block theme-border-radius font-small p-2"  v-if="index !== data.Flight2.length - 1">
                                                                Layover Time: {{ data.Flight2JourneyTime.Time }}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                 <div v-if="data.Flight3" class="theme-box-shadow theme-border-radius bg-light mb-3">
                                    <div class="row border-bottom py-2 m-auto">
                                        <div class="col-8">
                                            <div class="row d-flex flex-row">
                                                <div v-if="areLogosequalMulti2(data.Flight3)" class=" d-flex align-items-center flex-wrap pe-0" 
                                                   :class="sameLogoDataMulti2.length == 1 ? 'col-1' : 'col-2'" :style="sameLogoDataMulti2.length >= 2 ? 'width:10%' : ''">
                                                    <div v-for="(logo,index) in sameLogoDataMulti2" :key="index" :style="sameLogoDataMulti2.length >= 2 ? 'width:50%' : ''">
                                                      <v-img :src="logo" width="40px" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                <div class="col-10">
                                                    <div class="fw-bold">{{ getdepName1(data.muticityData[2]) }} To {{ getarrName1(data.muticityData[2]) }}</div>
                                                    <div class="font-medium">Departure  {{ depdate(data.Flight3) }} - Arrival {{ getstartdate(data.Flight3) }}</div>
                                                </div>
                                           </div>
                                        </div>
                                        <div class="col-4 text-end align-self-center">
                                            <a class="font-small theme-text-accent-one" data-bs-toggle="collapse"
                                                href="#collapseFlight3" role="button" aria-expanded="false"
                                                aria-controls="collapseFlight3">Flight
                                                Details
                                                <i class="bi bi-chevron-down ps-1"></i></a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapseFlight3">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="px-3" v-for="(data1, index) of data.Flight3" :key="index">
                                                    <ul class="row py-3">
                                                        <li class="col-12 pb-3">
                                                            <div class="float-start">
                                                                <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                                            </div>
                                                            <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                                                <div class="font-medium">{{ data1.MarketingCarrier.Name }}</div>
                                                                <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                                            </div>
                                                            <div
                                                                class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                                Class - SS</div>
                                                            <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                                            <div class="font-medium float-start mx-5">{{data.Refund}}</div>

                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                                            </div>
                                                            <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span
                                                                    class="fw-normal">{{ getdated(data1.Departure.Date) }}</span></div>
                                                            <div class="font-small">{{ data1.Departure.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="float-start mt-1"><i class="bi bi-clock pe-2 fs-6"></i>
                                                            </div>
                                                            <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{ data1.layoverTimes }}</span>
                                                                <span class="font-small d-block">{{ data.Flight3JourneyTime.Stops }} Stop{{ data.Flight3JourneyTime.Stops !== 1 ? 's' : '' }}</span> <span
                                                                    class="font-small d-block">Equipment:{{data1.Equipment.AircraftCode}}</span>
                                                            </div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                                            <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span
                                                                    class="fw-normal">{{ getdated1(data1.Arrival.Date) }}</span></div>
                                                            <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                                                class="font-small text-uppercase fw-bold"> <i
                                                                    class="bi bi-briefcase me-2 fs-6"></i> Baggage </span>
                                                            <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                                                class="font-small d-block">CABIN : 7 Kgs</span>
                                                        </li>
                                                        <li class="col-12 text-center">
                                                            <div
                                                                class="border d-inline-block theme-border-radius font-small p-2"  v-if="index !== data.Flight3.length - 1">
                                                                Layover Time: {{ data.Flight3JourneyTime.Time }}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="data.Flight4" class="theme-box-shadow theme-border-radius bg-light mb-3">
                                    <div class="row border-bottom py-2 m-auto">
                                        <div class="col-8">
                                            <div class="row d-flex flex-row">
                                                <div v-if="areLogosequalMulti3(data.Flight4)" class=" d-flex align-items-center flex-wrap pe-0" 
                                                   :class="sameLogoDataMulti3.length == 1 ? 'col-1' : 'col-2'" :style="sameLogoDataMulti3.length >= 2 ? 'width:10%' : ''">
                                                    <div v-for="(logo,index) in sameLogoDataMulti3" :key="index" :style="sameLogoDataMulti3.length >= 2 ? 'width:50%' : ''">
                                                      <v-img :src="logo" width="40px" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                <div class="col-10">
                                                    <div class="fw-bold">{{ getdepName1(data.muticityData[3]) }} To {{ getarrName1(data.muticityData[3]) }}</div>
                                                    <div class="font-medium">Departure  {{ depdate(data.Flight4) }} - Arrival {{ getstartdate(data.Flight4) }}</div>
                                                </div>
                                           </div>
                                        </div>
                                        <div class="col-4 text-end align-self-center">
                                            <a class="font-small theme-text-accent-one" data-bs-toggle="collapse"
                                                href="#collapseFlight4" role="button" aria-expanded="false"
                                                aria-controls="collapseFlight4">Flight
                                                Details
                                                <i class="bi bi-chevron-down ps-1"></i></a>
                                        </div>
                                    </div>
                                    <div class="collapse" id="collapseFlight4">
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="px-3" v-for="(data1, index) of data.Flight4" :key="index">
                                                    <ul class="row py-3">
                                                        <li class="col-12 pb-3">
                                                            <div class="float-start">
                                                                <v-img :src="data1.MarketingCarrier.logo" width="30px"></v-img>
                                                            </div>
                                                            <div class="float-start ms-2 d-flex flex-column" style="width:15%">
                                                                <div class="font-medium">{{ data1.MarketingCarrier.Name }}</div>
                                                                <div class="font-small">{{ data1.MarketingCarrier.FlightNumber }}</div>
                                                            </div>
                                                            <div
                                                                class="float-start rounded theme-bg-secondary theme-text-white font-medium px-2 ms-5">
                                                                Class - SS</div>
                                                            <div class="font-medium float-start mx-5">{{ data.cabinClass }}</div>
                                                            <div class="font-medium float-start mx-5">{{data.Refund}}</div>

                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Departure.Name }}
                                                            </div>
                                                            <div class="font-medium fw-bold">{{ getflightdep(data1) }} | <span
                                                                    class="fw-normal">{{ getdated(data1.Departure.Date) }}</span></div>
                                                            <div class="font-small">{{ data1.Departure.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="float-start mt-1"><i class="bi bi-clock pe-2 fs-6"></i>
                                                            </div>
                                                            <div class="float-start d-flex flex-column"> <span class="font-medium d-block">{{ data1.layoverTimes }}</span>
                                                                <span class="font-small d-block">{{ data.Flight4JourneyTime.Stops }} Stop{{ data.Flight4JourneyTime.Stops !== 1 ? 's' : '' }}</span> <span
                                                                    class="font-small d-block">Equipment:{{data1.Equipment.AircraftCode}}</span>
                                                            </div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3">
                                                            <div class="font-medium fw-bold text-uppercase">{{ data1.Arrival.Name }}</div>
                                                            <div class="font-medium fw-bold">{{ getflightarr(data1) }} | <span
                                                                    class="fw-normal">{{ getdated1(data1.Arrival.Date) }}</span></div>
                                                            <div class="font-small">{{ data1.Arrival.AirportName }}</div>
                                                        </li>
                                                        <li class="col-12 col-md-6 col-lg-3 pb-3"> <span
                                                                class="font-small text-uppercase fw-bold"> <i
                                                                    class="bi bi-briefcase me-2 fs-6"></i> Baggage </span>
                                                            <span class="font-small d-block">CHECK-IN : 15 Kgs</span><span
                                                                class="font-small d-block">CABIN : 7 Kgs</span>
                                                        </li>
                                                        <li class="col-12 text-center">
                                                            <div
                                                                class="border d-inline-block theme-border-radius font-small p-2"  v-if="index !== data.Flight4.length - 1">
                                                                Layover Time: {{ data.Flight4JourneyTime.Time }}</div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- travel details section -->
                          
                            <div class="d-flex justify-content-between align-items-start mb-3">
                                <div class="fw-bold">Traveller Details {{ adultData.length + childData.length + infrantData.length}} </div>
                            </div>
                            <!-- add traveller details -->
                            <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                                <div class="col-12 border-bottom">
                                    <!-- flight details section -->
                                    <div class="theme-box-shadow theme-border-radius bg-light mb-3"
                                     v-for="(data, index) of adultData" :key="index">
                                    {{ console.log(adultData,'adulttt11222') }}
                                        <div class="row border-bottom py-2 m-auto">
                                            <div class="col-8">
                                                <div class="fw-bold font-medium">Adult {{index + 1}}</div>
                                            </div>
                                            <div class="col-4 text-end align-self-center">
                                                <a class="font-small" data-bs-toggle="collapse" :href="'#collapseAdult-' + index"
                                                    role="button" aria-expanded="false" :aria-controls="'collapseAdult-' + index" :class="!dropdown1 ? 'not-collapsed' : 'collapsed'"
                                                        @click="dropdown1 == !dropdown1"><i
                                                        class="bi bi-chevron-down ps-1"
                                                       ></i></a>

                                            </div>
                                        </div>

                                        <div class="collapse" :id="'collapseAdult-' + index" :class="!dropdown1 ? 'collapse' : 'collapse show'">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="px-3">
                                                        <ul class="row pt-3 m-0 ps-0">
                                                            <li class="col-12 col-md-2 title">
                                                                <label class="visually-hidden"
                                                                    for="inlineFormSelectTitle">Preference</label>
                                                                <!-- <select class="form-select" id="inlineFormSelectTitle" >
                                                                    <option >Title</option>
                                                                    <option selected value="1">Mr.</option>
                                                                    <option value="2">Mrs.</option>
                                                                    <option value="3">Ms.</option>
                                                                </select> -->
                                                                <v-select 
                                                                    :items="['Mr', 'Miss', 'Mrs', 'Ms']"
                                                                    v-model="data.salutation"
                                                                    variant="underlined"
                                                                ></v-select>
                                                            </li>
                                                            <li class="col-12 col-md-3 pb-3 ">
                                                                 <v-text-field
                                                                    label="First Name"
                                                                    variant="underlined"
                                                                    v-model="data.fname"
                                                                    :rules="firstName"
                                                                    @keydown="filterkey($event)"
                                                                    class="Name"
                                                                ></v-text-field>
                                                            </li>
                                                            <li class="col-12 col-md-3 pb-3">
                                                                <v-text-field
                                                                    label="Middle Name"
                                                                    variant="underlined"
                                                                    v-model="data.middle"
                                                                    @keydown="filterkey($event)"
                                                                    class="Name"
                                                                ></v-text-field>
                                                            </li>
                                                            <li class="col-12 col-md-3 pb-3">
                                                                <v-text-field
                                                                    label="Last Name"
                                                                    variant="underlined"
                                                                    v-model="data.last"
                                                                    :rules="lastName"
                                                                    @keydown="filterkey($event)"
                                                                    class="Name"
                                                                ></v-text-field>
                                                            </li>
                                                            <li class="col-12 col-lg-3 pb-3 gender">
                                                                <label for="inlineFormSelectTitle3" style="font-size:14px;">Gender</label>
                                                                <v-select
                                                                    v-model="data.gender"
                                                                    :items="['Male', 'Female']"
                                                                     variant="underlined"
                                                                    :rules="[(v) => !!v || 'This field is required']"
                                                                ></v-select>
                                                            </li>
                                                            <li class=" col-12 col-lg-9 pb-3 datePickerSection">
                                                                <label for="inlineFormSelectTitle3" style="font-size:14px;">Date of
                                                                    Birth</label>
                                                                    <div class="row gx-0">
                                                                <div class="col-12 col-md-8 d-flex g-0 border-0">
                                                                    <div class="col-4 col-lg-6 p-0">
                                                                        <v-text-field
                                                                            v-model="data.dateDob.year"
                                                                            class="yearPick" 
                                                                            id="inlineFormSelectTitle3"
                                                                            label="Year"
                                                                            placeholder="Year"
                                                                            :rules="yearRules(data.Type, index)"
                                                                            maxlength="4"
                                                                            variant="underlined"
                                                                            @keypress="filter(event)"
                                                                            @input="inputHandle(data, index)"
                                                                            ref="yearInput"
                                                                            ></v-text-field>
                                                                    </div>
                                                                    <div class="col-4 col-lg-6">
                                                                        <v-select
                                                                            class="monthPick border-0" id="inlineFormSelectTitle4"
                                                                            label="Month"
                                                                            v-model="data.dateDob.month"
                                                                            :items="data.isYearValid ? monthListOriginal : []"
                                                                            variant="underlined"
                                                                            :rules="monthRules(data.Type, index)"
                                                                            @click="inputChange(data, index)"
                                                                            ></v-select>
                                                                    </div>
                                                                    <div class="col-4 col-lg-6">
                                                                        
                                                                        <v-text-field
                                                                            v-model="data.dateDob.date"
                                                                            id="inlineFormSelectTitle15"
                                                                            class="datePick border-0"
                                                                            label="Date"
                                                                            placeholder="Date"
                                                                            :rules="dateRules(data, index)"
                                                                            maxlength="2"
                                                                            variant="underlined"
                                                                            @keypress="filter(event)"
                                                                            ></v-text-field>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </li>
                                                            
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="adultData.length < adult" class="d-flex justify-content-between align-items-start mb-3" style="cursor:pointer;">
                                        <div class="font-medium" style="color:#008CFF" @click="addAdult"><i class="bi bi-plus-lg me-1"></i>Add Adult</div>
                                    </div>
                                    <div v-if="errorMessage.length && errorMessage.includes('Please add the required number of adults') && adultData.length !== adult">
                                      <div class="text-danger">Please add the required number of Adult</div>
                                    </div>
                                </div>
                                <!-- child details section -->
                                <!-- <div class="d-flex align-items-between my-3">
                                    <div class="flex-grow-1">
                                        <span class="fw-bold text-uppercase">Child</span>
                                    </div>
                                </div> -->
                                <!-- repetable element -->
                                <div class="col-12 border-bottom">
                                    <div class="theme-box-shadow theme-border-radius bg-light mb-3"  
                                    v-for="(data, index) of childData" :key="data">
                                        <div class="row border-bottom py-2 m-auto">
                                            <div class="col-8">
                                                <div class="fw-bold font-medium">Child {{ index + 1 }}</div>
                                            </div>
                                            <div class="col-4 text-end align-self-center">
                                                <a class="font-small" data-bs-toggle="collapse" :href="'#collapseChild-' + index"
                                                    role="button" aria-expanded="false" :aria-controls="'collapseChild-' + index" :class="!dropdown1 ? 'not-collapsed' : 'collapsed'"
                                                        @click="dropdown1 == !dropdown1"><i
                                                        class="bi bi-chevron-down ps-1"
                                                       ></i></a>

                                            </div>
                                        </div>
                                        <div class="collapse" :id="'collapseChild-' + index" :class="!dropdown1 ? 'collapse' : 'collapse show'">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="px-3">
                                                        <ul class="row pt-3 m-0 ps-0">
                                                            <li class="col-12 col-md-2 title">
                                                                <label class="visually-hidden"
                                                                    for="inlineFormSelectTitle5">Preference</label>
                                                                <v-select
                                                                    :items="['Mr', 'Miss']"
                                                                    v-model="data.salutation"
                                                                    variant="underlined"
                                                                ></v-select>
                                                            </li>
                                                            <li class="col-12 col-md-3 pb-3">
                                                                <v-text-field
                                                                    label="First Name"
                                                                    variant="underlined"
                                                                    v-model="data.fname"
                                                                    :rules="firstName"
                                                                    @keydown="filterkey($event)"
                                                                    class="Name"
                                                                ></v-text-field>
                                                            </li>
                                                            <li class="col-12 col-md-3 pb-3">
                                                               <v-text-field
                                                                    label="Middle Name"
                                                                    variant="underlined"
                                                                    v-model="data.middle"
                                                                    @keydown="filterkey($event)"
                                                                    class="Name"
                                                                ></v-text-field>
                                                            </li>
                                                            <li class="col-12 col-md-3 pb-3">
                                                                <v-text-field
                                                                    label="Last Name"
                                                                    variant="underlined"
                                                                    v-model="data.last"
                                                                    :rules="lastName"
                                                                    @keydown="filterkey($event)"
                                                                    class="Name"
                                                                ></v-text-field>
                                                            </li>
                                                            <li class="col-12 col-lg-3 pb-3 gender">
                                                                <label for="inlineFormSelectTitle3" style="font-size:14px;">Gender</label>
                                                                <v-select
                                                                    v-model="data.gender"
                                                                    :items="['Male', 'Female']"
                                                                    variant="underlined"
                                                                    :rules="[(v) => !!v || 'this field is required']"
                                                                  ></v-select>
                                                            </li>
                                                            <li class="col-12 col-lg-9 pb-3 datePickerSection">
                                                                <label for="inlineFormSelectTitle3" style="font-size:14px;">Date of
                                                                    Birth</label>
                                                                    <div class="row gx-0">
                                                                        <div class="col-12 col-md-8 d-flex g-0 border-0">
                                                                            <div class="col-4 col-lg-6 p-0">
                                                                                <v-text-field
                                                                                    v-model="data.dateDob.year"
                                                                                    class="yearPick" 
                                                                                    id="inlineFormSelectTitle3"
                                                                                    label="Year"
                                                                                    placeholder="Year"
                                                                                    :rules="yearRules(data.Type, index)"
                                                                                    maxlength="4"
                                                                                    variant="underlined"
                                                                                    @keypress="filter(event)"
                                                                                    @input="inputHandle(data, index)"
                                                                                    ref="yearInput"
                                                                                    ></v-text-field>
                                                                            </div>
                                                                            <div class="col-4 col-lg-6">
                                                                                <v-select
                                                                                    class="monthPick border-0" id="inlineFormSelectTitle4"
                                                                                    label="Month"
                                                                                    v-model="data.dateDob.month"
                                                                                    :items="data.isYearValid ? monthListOriginal : []"
                                                                                    variant="underlined"
                                                                                    :rules="monthRules(data.Type, index)"
                                                                                    @click="inputChange(data, index)"
                                                                                    ></v-select>
                                                                            </div>
                                                                            <div class="col-4 col-lg-6">
                                                                                
                                                                                <v-text-field
                                                                                    v-model="data.dateDob.date"
                                                                                    id="inlineFormSelectTitle15"
                                                                                    class="datePick border-0"
                                                                                    label="Date"
                                                                                    placeholder="Date"
                                                                                    :rules="dateRules(data, index)"
                                                                                    maxlength="2"
                                                                                    variant="underlined"
                                                                                    @keypress="filter(event)"
                                                                                    ></v-text-field>
                                                                            </div>
                                                                        </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="childData.length < child" class="d-flex justify-content-between align-items-start mb-3" style="cursor:pointer;">
                                        <div class="font-medium" style="color:#008CFF" @click="addChild"><i class="bi bi-plus-lg me-1"></i>Add Child</div>
                                    </div>
                                    <div v-if="errorMessage.length && errorMessage.includes('Please add the required number of child') && childData.length !== child">
                                      <div class="text-danger">Please add the required number of Child</div>
                                    </div>
                                </div>
                                <!-- child details section -->
                                <!-- <div class="d-flex align-items-between my-3">
                                    <div class="flex-grow-1">
                                        <span class="fw-bold text-uppercase">Infant</span>
                                    </div>
                                </div> -->
                                <!-- repetable element -->
                                <div class="col-12 border-bottom">
                                    
                                    <div class="theme-box-shadow theme-border-radius bg-light mb-3"  
                                    v-for="(data, index) of infrantData" :key="data">
                                        <div class="row border-bottom py-2 m-auto">
                                            <div class="col-8">
                                                <div class="fw-bold font-medium">Infant {{index + 1}}</div>
                                            </div>
                                            <div class="col-4 text-end align-self-center">
                                                <a class="font-small" data-bs-toggle="collapse" :href="'#collapseInfant-' + index"
                                                    role="button" aria-expanded="false" :aria-controls="'collapseInfant-' + index" :class="!dropdown1 ? 'not-collapsed' : 'collapsed'"
                                                        @click="dropdown1 == !dropdown1"><i
                                                        class="bi bi-chevron-down ps-1"
                                                       ></i></a>

                                            </div>
                                        </div>
                                        <div class="collapse" :id="'collapseInfant-' + index" :class="!dropdown1 ? 'collapse' : 'collapse show'">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <div class="px-3">
                                                        <ul class="row pt-3 m-0 ps-0">
                                                            <li class="col-12 col-md-2 title">
                                                                <label class="visually-hidden"
                                                                    for="inlineFormSelectTitle1">Preference</label>
                                                               <v-select
                                                                :items="['Mr', 'Miss']"
                                                                v-model="data.salutation"
                                                                variant="underlined"
                                                              ></v-select>
                                                            </li>
                                                            <li class="col-12 col-md-3 pb-3">
                                                                <v-text-field
                                                                  label="First Name"
                                                                  variant="underlined"
                                                                  v-model="data.fname"
                                                                  :rules="firstName"
                                                                  @keydown="filterkey($event)"
                                                                ></v-text-field>
                                                            </li>
                                                            <li class="col-12 col-md-3 pb-3">
                                                                <v-text-field
                                                                  label="Middle Name"
                                                                  variant="underlined"
                                                                  v-model="data.middle"
                                                                  @keydown="filterkey($event)"
                                                                ></v-text-field>
                                                            </li>
                                                            <li class="col-12 col-md-3 pb-3">
                                                                <v-text-field
                                                                  label="Last Name"
                                                                  variant="underlined"
                                                                  v-model="data.last"
                                                                  @keydown="filterkey($event)"
                                                                  :rules="lastName"
                                                                ></v-text-field>
                                                            </li>
                                                            <li class="col-12 col-lg-3 pb-3 gender">
                                                                <label for="inlineFormSelectTitle3" style="font-size:14px;">Gender</label>
                                                                <v-select
                                                                  v-model="data.gender"
                                                                  :items="['Male', 'Female']"
                                                                  variant="underlined"
                                                                  :rules="[(v) => !!v || 'this field is required']"
                                                                ></v-select>
                                                            </li>
                                                            <li class="col-12 col-lg-9 pb-3 datePickerSection" style="font-size:14px;">
                                                                <label for="inlineFormSelectTitle3">Date of
                                                                    Birth</label>
                                                                <div class="row gx-0">
                                                                     <div class="col-12 col-md-8 d-flex g-0 border-0">
                                                                    <div class="col-4 col-lg-6 p-0">
                                                                        <v-text-field
                                                                            v-model="data.dateDob.year"
                                                                            class="yearPick " 
                                                                            id="inlineFormSelectTitle3"
                                                                            label="Year"
                                                                            placeholder="Year"
                                                                            :rules="yearRules(data.Type, index)"
                                                                            maxlength="4"
                                                                            variant="underlined"
                                                                            @keypress="filter(event)"
                                                                            @input="inputHandle(data, index)"
                                                                            ref="yearInput"
                                                                            ></v-text-field>
                                                                    </div>
                                                                    <div class="col-4 col-lg-6">
                                                                        <v-select
                                                                            class="monthPick border-0" id="inlineFormSelectTitle4"
                                                                            label="Month"
                                                                            v-model="data.dateDob.month"
                                                                            :items="data.isYearValid ? monthList : []"
                                                                            variant="underlined"
                                                                            :rules="monthRules(data.Type, index)"
                                                                            @click="inputChange(data, index)"
                                                                            ></v-select>
                                                                    </div>
                                                                    <div class="col-4 col-lg-6">
                                                                        
                                                                        <v-text-field
                                                                            v-model="data.dateDob.date"
                                                                            id="inlineFormSelectTitle15"
                                                                            class="datePick border-0"
                                                                            label="Date"
                                                                            placeholder="Date"
                                                                            :rules="dateRules(data, index)"
                                                                            maxlength="2"
                                                                            variant="underlined"
                                                                            @keypress="filter(event)"
                                                                            ></v-text-field>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="infrantData.length < inf" class="d-flex justify-content-between align-items-start mb-3" style="cursor:pointer;">
                                        <div class="font-medium" style="color:#008CFF" @click="addInf"><i class="bi bi-plus-lg me-1"></i>Add Infant</div>
                                    </div>
                                    <div v-if="errorMessage.length && errorMessage.includes('Please add the required number of infants') && infrantData.length !== inf">
                                      <div class="text-danger">PLease add the required number of Infrant</div>
                                    </div>
                                </div>
                            </div>
                            <!-- contact information details section -->
                            <div class="d-flex justify-content-between align-items-start mb-3">
                                <div class="fw-bold">Contact information</div>
                            </div>
                            <!-- add contact details -->
                            <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3 pb-0" v-for="data of paxContacts"
                          :key="data">
                                <div class="row">
                                    <div class="col-12">
                                        <span class=" font-medium">Note: All communication related to booking
                                            will be sent to this email address and mobile.</span>
                                    </div>
                                    <div class="col-12">
                                        <ul class="row pt-3 mb-0 ps-0">
                                            <li class="col-12 col-md-4 pb-3 country">
                                                <!-- <label class="font-medium" for="inlineFormSelectCountryCode">Country
                                                    Code</label> -->
                                                <v-autocomplete
                                                    :items="countrycode"
                                                    item-title="name"
                                                    variant="underlined"
                                                    width="auto"
                                                    :rules="[
                                                      (v) => !!v || 'Country Code is required',
                                                    ]"
                                                    label="Country Code"
                                                    v-model="data.code"
                                                    class="countryCode"
                                                  >
                                                  </v-autocomplete>
                                            </li>
                                            <li class="col-12 col-md-4 pb-3">
                                                <!-- <label class="font-medium" for="specificSizeInputMobileNo">Mobile
                                                    No</label> -->
                                                <!-- <input type="number" class="form-control" id="specificSizeInputMobileNo"
                                                    placeholder="Mobile No"> -->
                                                    <v-text-field
                                                      v-model="data.number"
                                                      label="Phone Number"
                                                      minlength="5"
                                                      maxlength="15"
                                                      :rules="phoneNumberValid"
                                                      variant="underlined"
                                                      class="Name"
                                                    ></v-text-field>
                                            </li>
                                            <li class="col-12 col-md-4 pb-3">
                                                <!-- <label class="font-medium" for="specificSizeInputEmail">Email</label> -->
                                                <!-- <input type="text" class="form-control" id="specificSizeInputEmail"
                                                    placeholder="Email"> -->
                                                     <v-text-field
                                                      label="Email ID"
                                                      :rules="emailRequire"
                                                      variant="underlined"
                                                      v-model="data.email"
                                                      class="Name"
                                                    ></v-text-field>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!-- GST details section -->
                            <!-- <div class="d-flex justify-content-between align-items-start mb-3">
                                <div class="fw-bold">GST Details Confirmation (Optional)</div>
                            </div> -->
                            <!-- add GST details -->
                            <!-- <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3 pb-0">
                                <div class="row mb-3">
                                    <div class="col-12">
                                        <span class=" font-medium">Note: All communication related to booking
                                            will be sent to this email address and mobile.</span>
                                    </div>
                                    <div class="col-12">
                                        <ul class="row pt-3">
                                            <li class="col-12 col-md-4 pb-3">
                                                <label class="font-medium" for="specificSizeInputGSTINNumber">GSTIN
                                                    Number</label>
                                                <input type="number" class="form-control"
                                                    id="specificSizeInputGSTINNumber" placeholder="Enter GSTIN Number">
                                            </li>
                                            <li class="col-12 col-md-4 pb-3">
                                                <label class="font-medium" for="specificSizeInputCompanyName">Company
                                                    Name*</label>
                                                <input type="text" class="form-control"
                                                    id="specificSizeInputCompanyName" placeholder="Enter Name">
                                            </li>
                                            <li class="col-12 col-md-4 pb-3">
                                                <label class="font-medium" for="specificSizeInputCompanyEmail">Company
                                                    Email*</label>
                                                <input type="text" class="form-control"
                                                    id="specificSizeInputCompanyEmail"
                                                    placeholder="Enter Company Email">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                            <!-- Add-ons details section -->
                            <!-- <div class="d-flex justify-content-between align-items-start mb-3">
                                <div class="fw-bold">Add-ons</div>
                            </div> -->
                            <!-- add Add-ons details -->
                            <!-- <div class="theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                                <div class="row">
                                    <div class="col-12">
                                        <ul class="d-flex flex-row">
                                            <li class="theme-box-shadow theme-border-radius p-3 border me-3 d-flex flex-row">
                                                <v-img src="../assets/meal.png" width="30px" class="img-fluid me-2"
                                                        title="meal" alt="meal"></v-img>
                                                       <p class="my-0 text-primary text-center">Add Meals</p> 
                                            </li>
                                            <li class="theme-box-shadow theme-border-radius p-3 border me-3 d-flex flex-row">
                                                    <v-img src="../assets/baggage.png" width="30px" class="img-fluid me-2"></v-img>
                                                    <p class="my-0 text-primary">Add Baggage</p>
                                            </li>
                                            <li class="theme-box-shadow theme-border-radius p-3 border d-flex flex-row">
                                             
                                                        <v-img src="../assets/seat.png" width="30px" class="img-fluid me-2"></v-img>
                                                        <p class="my-0 text-primary">Seat</p>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div> -->
                          <div class="row">
                            <div class="col-12 col-md-12">
                                <!-- Acknowledgement details section -->
                                <div class="d-lg-flex d-none justify-content-between align-items-start mb-3">
                                    <div class="fw-bold">Acknowledgement</div>
                                </div>
                                <!-- Acknowledgement details -->
                                <div class="d-lg-flex d-none theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-check mb-3">
                                                <input v-model="formCheckBox" class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckChecked" checked>
                                                <label class="form-check-label font-small" for="flexCheckChecked">
                                                    I understand and agree with the <a href="#">Privacy Policy</a>, the <a
                                                        href="#">Terms & Conditions</a>
                                                    of Zetsfly.
                                                </label>
                                                <p v-if="!formCheckBox && checkForm" class="w-50 m-0 f-size-12 fw-400" 
                                                style="color: #b00020">You must agree to continue! </p>
                                            </div>
                                            <button type="submit" class="btn btn-effect btn-book px-5">Continue</button>
                                                
                                                <!-- <v-btn
                                                    width="25%"
                                                    height="40px"
                                                    class="continue-btn"
                                                    type="submit"
                                                    style="
                                                        color: white;
                                                        text-transform: capitalize;
                                                        background-color: #212529;
                                                    " @click="submitform"
                                                    >Continue</v-btn> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-12 col-md-3">
                          <div class="fw-bold mb-3">Booking Details</div>
                            <div class="row mx-lg-0 mx-2">
                                <!-- flight info strip repetable -->
                                <div class="col-12 px-0" v-for="(data, index) of roundresult" :key="index">
                                    <div class="theme-box-shadow theme-border-radius theme-bg-white bg-light p-3 mb-3">
                                        <!-- repetable -->
                                        <div class="row g-0">
                                            <div class="col-12 pb-3 mb-3 border-bottom">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 mb-2">
                                                        <div class="d-flex">
                                                            <!-- <div v-if="areLogosequal(data.Depature)">
                                                              <div v-for="(logo,index) in sameLogoData" :key="index" :style="sameLogoData.length >= 2 ? 'width:50%' : ''">
                                                                <v-img :src="logo" width="40px" class="gx-1"></v-img>
                                                              </div>
                                                            </div> -->

                                                            <div v-if="areLogosequal(data.Depature)" class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                                    <div v-for="(logo,index) in sameLogoData" :key="index" :style="{ width: sameLogoData.length >= 2 ? '50%' : '100%' }">
                                                      <v-img :src="logo" :width="sameLogoData.length >= 2 ? '40px' : 'auto'" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                            <div class="d-flex flex-column ms-2">
                                                                <span class="font-medium fw-bold mb-0">Departure - {{ depdate(data.Depature) }}</span>
                                                                <span class="font-medium mb-0 theme-text-accent-one">
                                                                  {{ getdepName(data.fromTrip) }}<i class="bi bi-arrow-right"></i> {{ getarrName(data.toTrip) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-start text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Depature[0].Departure.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Depature[0].Departure.Time }}
                                                        </div>
                                                    </div>
                                                    <div class=" col-6 col-md-6 text-center align-self-center">
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Depaturejourney.Time }}
                                                        </div>
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Depaturejourney.Stops }} Stop{{ data.Depaturejourney.Stops > 1 ? 's' : '' }}
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-end text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Depature[data.Depature.length-1].Arrival.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Depature[data.Depature.length-1].Arrival.Time }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <!-- repetable -->
                                        <div class="row g-0" v-if="data.Return">
                                            <div class="col-12 pb-3 mb-3 border-bottom">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 mb-2">
                                                        <div class="d-flex">
                                                              <!-- <div v-if="areLogosequal1(data.Return)">
                                                                <div v-for="(logo,index) in sameLogoData1" :key="index" :style="sameLogoData1.length >= 2 ? 'width:50%' : ''">
                                                                  <v-img :src="logo" width="40px" class="gx-1"></v-img>
                                                                </div>
                                                            </div> -->
                                                            <div v-if="areLogosequal1(data.Return)" class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                                                <div v-for="(logo,index) in sameLogoData1" :key="index" :style="{ width: sameLogoData1.length >= 2 ? '50%' : '100%' }">
                                                                  <v-img :src="logo" :width="sameLogoData1.length >= 2 ? '40px' : 'auto'" class="gx-1"></v-img>
                                                                </div>
                                                            </div>
                                                            <div class="d-flex flex-column ms-2">
                                                                <span class="font-medium fw-bold mb-0">Return - {{ depdate(data.Return) }}</span>
                                                                <span
                                                                    class="font-medium mb-0 theme-text-accent-one">
                                                                    {{ getdepName(data.toTrip) }}<i class="bi bi-arrow-right"></i> {{ getarrName(data.fromTrip) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-start text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Return[0].Departure.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Return[0].Departure.Time }}
                                                        </div>
                                                    </div>
                                                    <div class=" col-6 col-md-6 text-center align-self-center">
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Returnjourney.Time }}
                                                        </div>
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Returnjourney.Stops }} Stop{{ data.Returnjourney.Stops > 1 ? 's' : '' }}
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-end text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Return[data.Return.length-1].Arrival.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Return[data.Return.length-1].Arrival.Time }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-center">
                                         <v-btn style="width: 140px;height:37px;min-width: 0;min-height: 0;text-transform:none;letter-spacing:0;margin:0 auto;border-radius:5px;" 
                                            class="travel-btn btn-book" data-bs-toggle="offcanvas"
                                            data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                                          Travel Details</v-btn></div>
                                    </div>
                                    <div class="drawer-section">
                                      <div class="offcanvas offcanvas-end"
                                          data-bs-backdrop="static" tabindex="-1" 
                                          id="staticBackdrop" aria-labelledby="staticBackdropLabel">
                                      <div class="offcanvas-header d-flex justify-content-between">
                                        <h5 class="offcanvas-title" id="staticBackdrop">Flight Details</h5>
                                        <button type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close" color="white">
                                          <v-icon>mdi-close</v-icon>
                                        </button>
                                      </div>
                                      
                                      <div class="offcanvas-body pt-0">
                                        <div v-for="(flightdatas,index) of roundresult" :key="index">
                                          {{ console.log(flightdatas,'datasssss') }}
                                        <v-card style="border:1px solid grey;" class="my-4">
                                          <div class="d-flex flex-row align-items-center">
                                          <h6 class="p-2 mb-0" style="font-size:14px;">Depature</h6>
                                          <span class="fw-bold ms-2" style="font-size:16px;"> {{ getdepName(flightdatas.fromTrip) }} - {{ getarrName(flightdatas.toTrip) }}</span>
                                          </div>
                                          <v-divider class="my-0"></v-divider>
                                      <div v-for="(data, index) of flightdatas.Depature" 
                                            :key="index">
                                        <v-timeline truncate-line="both" class="ms-5 my-4">
                                          <v-timeline-item class="" size="x-small" justify="left">
                                            <template v-slot:opposite>
                                              <div>
                                                <span class="f-size-14 airline-date">{{
                                                getdatedFlight(data.Departure.Date)
                                              }}</span>
                                              <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightdep(data)
                                                }}</span>
                                                </div></template>
                                            <div>
                                              <span class="fw-600" style="font-size:16px;">{{ data.Departure.AirportCode }} </span><br/>
                                              <p class="m-0" style="font-size:12px;">{{data.Departure.AirportName }}</p>
                                            </div>
                                          </v-timeline-item>

                                          <v-timeline-item class="my-2" justify="left">
                                            <template v-slot:icon>
                                              <v-avatar :image="data.MarketingCarrier.logo" style="background:white;"></v-avatar>
                                            </template>
                                            <template v-slot:opposite>
                                              <div>
                                                <p class="f-size-14 airline-name m-0">
                                                {{ data.MarketingCarrier.Name }}

                                                <br />
                                                <span class="f-size-12 airline-id">
                                                  {{ data.MarketingCarrier.AirlineID }}
                                                  -
                                                  {{ data.MarketingCarrier.FlightNumber }}
                                                </span>
                                              </p>
                                              </div>
                                                </template>
                                            <div>
                                              <p class="m-0" style="font-size:14px;">{{data.FlightDetail.FlightDuration.Value}}</p>
                                            </div>
                                          </v-timeline-item>

                                          <v-timeline-item class="my-2" size="x-small">
                                            <template v-slot:opposite>
                                              <span class="f-size-14 airline-date">{{
                                                getdatedFlight1(data.Arrival.Date)
                                              }}</span>
                                              <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightarr(data)
                                                }}</span>
                                            </template>
                                            <div>
                                              <span class="fw-600" style="font-size:16px;">{{ data.Arrival.AirportCode }} </span><br/>
                                              <p class="m-0" style="font-size:12px;">{{data.Arrival.AirportName}}</p> 
                                            </div>
                                          </v-timeline-item>

                                        </v-timeline>

                                          <v-card v-if="flightdatas.Depature.length-1 !== index" style="width:52%;position:relative;left:15%;background-color:#63AB45;color:white;" class="p-1">
                                            <div class="d-flex justify-center">
                                              <div class="">
                                              <v-img :src="require('../assets/sandClock.png')" width="15px" style="padding-top:8px;"></v-img>
                                              </div>
                                              <div v-if="flightdatas.Return">
                                                <p class="m-0 ms-2" style="font-size:14px;">{{flightdatas.layoverTimes1[index]}} - LayoverTime</p>
                                              </div>
                                              <div v-if="!flightdatas.Return">
                                                <p class="m-0 ms-2" style="font-size:14px;">{{flightdatas.layoverTimes[index]}} - LayoverTime</p>
                                              </div>
                                            </div>
                                          </v-card>
                                        </div>
                                        </v-card>
                                        
                                        <v-card v-if="flightdatas.Return" style="border:1px solid grey;" class="my-4">
                                          <div class="d-flex flex-row align-items-center">
                                          <h6 class="p-2 mb-0" style="font-size:14px;">Arrival</h6>
                                          <span class="fw-bold ms-2" style="font-size:16px;"> {{ getdepName(flightdatas.toTrip) }} - {{ getarrName(flightdatas.fromTrip) }}</span>
                                          </div>
                                          <v-divider class="my-0"></v-divider>
                                        <div v-for="(data, index) of flightdatas.Return" 
                                            :key="index">
                                        <v-timeline truncate-line="both" class="ms-5 my-4">
                                          <v-timeline-item class="my-2" size="x-small" justify="left">
                                            <template v-slot:opposite>
                                              <div>
                                                <span class="f-size-14 airline-date">{{
                                                getdatedFlight(data.Departure.Date)
                                              }}</span>
                                              <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightdep(data)
                                                }}</span></div></template>
                                            <div>
                                              <span class="fw-500" style="font-size:16px;">{{ data.Departure.AirportCode }} </span><br/>
                                              <p class="m-0" style="font-size:12px;">{{data.Departure.AirportName }}</p>
                                            </div>
                                          </v-timeline-item>

                                          <v-timeline-item class="my-2" justify="left">
                                            <template v-slot:icon>
                                              <v-avatar :image="data.MarketingCarrier.logo" style="background:white;"></v-avatar>
                                            </template>
                                            <template v-slot:opposite>
                                              <div>
                                                <p class="f-size-14 airline-name m-0">
                                                {{ data.MarketingCarrier.Name }}

                                                <br />
                                                <span class="f-size-12 airline-id">
                                                  {{ data.MarketingCarrier.AirlineID }}
                                                  -
                                                  {{ data.MarketingCarrier.FlightNumber }}
                                                </span>
                                              </p>
                                              </div>
                                                </template>
                                            <div>
                                              <p class="m-0" style="font-size:14px;">{{data.FlightDetail.FlightDuration.Value}}</p>
                                            </div>
                                          </v-timeline-item>

                                          <v-timeline-item class="my-2" size="x-small">
                                            <template v-slot:opposite>
                                              <span class="f-size-14 airline-date">{{
                                                getdatedFlight1(data.Arrival.Date)
                                              }}</span>
                                              <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightarr(data)
                                                }}</span>
                                            </template>
                                            <div>
                                              <span class="fw-500" style="font-size:16px;">{{ data.Arrival.AirportCode }} </span><br/>
                                              <p class="m-0" style="font-size:12px;">{{data.Arrival.AirportName}}</p> 
                                            </div>
                                          </v-timeline-item>

                                        </v-timeline>

                                <v-card  v-if="flightdatas.Return.length-1 !== index" style="width:52%;position:relative;left:15%;background-color:#63AB45;color:white;" class="p-1">
                                  <div class="d-flex justify-center">
                                    <div class="">
                                    <v-img :src="require('../assets/sandClock.png')" width="15px" style="padding-top:8px;"></v-img>
                                    </div>
                                    <div>
                                    <p class="m-0 ms-2" style="font-size:14px;">{{flightdatas.layoverTimes2[index]}} - LayoverTime</p>
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                              </v-card>
                             
                              
                               </div>
                               </div>
                          </div>
                          </div>
                                </div>
                                 <div class="col-12 px-0" v-for="(data, index) of multiresult" :key="index">
                                    <div class="theme-box-shadow theme-border-radius theme-bg-white bg-light p-3 mb-3">
                                        <!-- repetable -->
                                        <div class="row g-0">
                                            <div class="col-12 pb-3 mb-3 border-bottom">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 mb-2">
                                                        <div class="d-flex">
                                                            <div v-if="areLogosequalMulti(data.Flight1)" class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                                    <div v-for="(logo,index) in sameLogoDataMulti" :key="index" :style="{ width: sameLogoDataMulti.length >= 2 ? '50%' : '100%' }">
                                                      <v-img :src="logo" :width="sameLogoDataMulti.length >= 2 ? '40px' : 'auto'" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                            <div class="d-flex flex-column ms-2">
                                                                <span class="font-medium fw-bold mb-0">Departure - {{ depdate(data.Flight1) }}</span>
                                                                <span class="font-medium mb-0 theme-text-accent-one">
                                                                  {{ getdepName1(data.muticityData[0]) }}<i class="bi bi-arrow-right"></i> {{ getarrName1(data.muticityData[0]) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-start text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Flight1[0].Departure.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Flight1[0].Departure.Time }}
                                                        </div>
                                                    </div>
                                                    <div class=" col-6 col-md-6 text-center align-self-center">
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Flight1JourneyTime.Time }}
                                                        </div>
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Flight1JourneyTime.Stops }} Stop{{ data.Flight1JourneyTime.Stops > 1 ? 's' : '' }}
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-end text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Flight1[data.Flight1.length-1].Arrival.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Flight1[data.Flight1.length-1].Arrival.Time }}
                                                        </div>
                                                    </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row g-0">
                                            <div class="col-12 pb-3 mb-3 border-bottom">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 mb-2">
                                                        <div class="d-flex">
                                                            <div v-if="areLogosequalMulti1(data.Flight2)" class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                                    <div v-for="(logo,index) in sameLogoDataMulti1" :key="index" :style="{ width: sameLogoDataMulti1.length >= 2 ? '50%' : '100%' }">
                                                      <v-img :src="logo" :width="sameLogoDataMulti1.length >= 2 ? '40px' : 'auto'" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                            <div class="d-flex flex-column ms-2">
                                                                <span class="font-medium fw-bold mb-0">Departure - {{ depdate(data.Flight2) }}</span>
                                                                <span class="font-medium mb-0 theme-text-accent-one">
                                                                  {{ getdepName1(data.muticityData[1]) }}<i class="bi bi-arrow-right"></i> {{ getarrName1(data.muticityData[1]) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-start text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Flight2[0].Departure.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Flight2[0].Departure.Time }}
                                                        </div>
                                                    </div>
                                                    <div class=" col-6 col-md-6 text-center align-self-center">
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Flight2JourneyTime.Time }}
                                                        </div>
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Flight2JourneyTime.Stops }} Stop{{ data.Flight2JourneyTime.Stops > 1 ? 's' : '' }}
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-end text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Flight2[data.Flight2.length-1].Arrival.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Flight2[data.Flight2.length-1].Arrival.Time }}
                                                        </div>
                                                    </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="data.Flight3" class="row g-0">
                                            <div class="col-12 pb-3 mb-3 border-bottom">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 mb-2">
                                                        <div class="d-flex">
                                                            <div v-if="areLogosequalMulti2(data.Flight3)" class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                                    <div v-for="(logo,index) in sameLogoDataMulti2" :key="index" :style="{ width: sameLogoDataMulti2.length >= 2 ? '50%' : '100%' }">
                                                      <v-img :src="logo" :width="sameLogoDataMulti2.length >= 2 ? '40px' : 'auto'" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                            <div class="d-flex flex-column ms-2">
                                                                <span class="font-medium fw-bold mb-0">Departure - {{ depdate(data.Flight3) }}</span>
                                                                <span class="font-medium mb-0 theme-text-accent-one">
                                                                  {{ getdepName1(data.muticityData[2]) }}<i class="bi bi-arrow-right"></i> {{ getarrName1(data.muticityData[2]) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-start text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Flight3[0].Departure.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Flight3[0].Departure.Time }}
                                                        </div>
                                                    </div>
                                                    <div class=" col-6 col-md-6 text-center align-self-center">
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Flight3JourneyTime.Time }}
                                                        </div>
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Flight3JourneyTime.Stops }} Stop{{ data.Flight3JourneyTime.Stops > 1 ? 's' : '' }}
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-end text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Flight3[data.Flight3.length-1].Arrival.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Flight3[data.Flight3.length-1].Arrival.Time }}
                                                        </div>
                                                    </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="data.Flight4" class="row g-0">
                                            <div class="col-12 pb-3 mb-3 border-bottom">
                                                <div class="row">
                                                    <div class="col-12 col-md-12 mb-2">
                                                        <div class="d-flex">
                                                            <div v-if="areLogosequalMulti3(data.Flight4)" class=" d-flex align-items-center flex-wrap pe-0 col-2">
                                                    <div v-for="(logo,index) in sameLogoDataMulti3" :key="index" :style="{ width: sameLogoDataMulti3.length >= 2 ? '50%' : '100%' }">
                                                      <v-img :src="logo" :width="sameLogoDataMulti3.length >= 2 ? '40px' : 'auto'" class="gx-1"></v-img>
                                                    </div>
                                                </div>
                                                            <div class="d-flex flex-column ms-2">
                                                                <span class="font-medium fw-bold mb-0">Departure - {{ depdate(data.Flight4) }}</span>
                                                                <span class="font-medium mb-0 theme-text-accent-one">
                                                                  {{ getdepName1(data.muticityData[3]) }}<i class="bi bi-arrow-right"></i> {{ getarrName1(data.muticityData[3]) }}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-start text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Flight4[0].Departure.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Flight4[0].Departure.Time }}
                                                        </div>
                                                    </div>
                                                    <div class=" col-6 col-md-6 text-center align-self-center">
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Flight4JourneyTime.Time }}
                                                        </div>
                                                        <div class="font-extra-small theme-text-accent-one">{{ data.Flight4JourneyTime.Stops }} Stop{{ data.Flight4JourneyTime.Stops > 1 ? 's' : '' }}
                                                        </div>
                                                    </div>
                                                    <div class="col-3 col-md-3 text-lg-end text-center">
                                                        <div class="font-medium fw-bold text-uppercase">{{ data.Flight4[data.Flight4.length-1].Arrival.AirportCode }}</div>
                                                        <div class="font-medium theme-text-accent-one">{{ data.Flight4[data.Flight4.length-1].Arrival.Time }}
                                                        </div>
                                                    </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div class="d-flex justify-center">
                                                  <v-btn style="width: 140px;height:37px;min-width: 0;min-height: 0;text-transform:none;letter-spacing:0;margin:0 auto;border-radius:5px;" 
                                                  class=" travel-btn btn-book" data-bs-toggle="offcanvas"
                                                  data-bs-target="#staticBackdrop" aria-controls="staticBackdrop">
                                                    Travel Details</v-btn></div>
                                            </div>
                                           
                                          <div class="drawer-section">
                                      <div class="offcanvas offcanvas-end"
                                        data-bs-backdrop="static" tabindex="-1" 
                                        id="staticBackdrop" aria-labelledby="staticBackdropLabel">
                                      <div class="offcanvas-header d-flex justify-content-between">
                                        <h5 class="offcanvas-title" id="offcanvasWithBackdropLabel">Flight Details</h5>
                                        <button type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close" color="white">
                                          <v-icon>mdi-close</v-icon>
                                        </button>
                                      </div>
                                      
                                      <div class="offcanvas-body pt-0">
                                        <v-card style="border:1px solid grey;" class="my-4">
                                <div class="d-flex flex-row align-items-center">
                                <h6 class="p-2 mb-0" style="font-size:14px;">Flight1</h6>
                                <span class="fw-bold"> {{ getdepNameMulti(data.muticityData[0]) }} - {{ getarrNameMulti(data.muticityData[0]) }}</span>
                                </div>
                               
                                <v-divider class="my-0"></v-divider>
                             <div v-for="(data1, index) of data.Flight1" 
                                  :key="index">
                              <v-timeline truncate-line="both" class="ms-5 my-4">
                                <v-timeline-item class="my-2" size="x-small" justify="left">
                                  <template v-slot:opposite>
                                    <div>
                                      <span class="f-size-14 airline-date">{{
                                      getdatedFlight(data1.Departure.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightdep(data1)
                                      }}</span></div></template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Departure.AirportCode }} </span><br/>
                                    <p class="m-0" style="font-size:12px;">{{data1.Departure.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" justify="left">
                                  <template v-slot:icon>
                                    <v-avatar :image="data1.MarketingCarrier.logo" style="background:white;"></v-avatar>
                                  </template>
                                  <template v-slot:opposite>
                                    <div>
                                      <p class="f-size-14 airline-name m-0">
                                      {{ data1.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data1.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data1.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                    </div>
                                      </template>
                                  <div>
                                    <p class="m-0" style="font-size:14px;">{{data1.FlightDetail.FlightDuration.Value}}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" size="x-small">
                                  <template v-slot:opposite>
                                    <span class="f-size-14 airline-date">{{
                                      getdatedFlight1(data1.Arrival.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightarr(data1)
                                      }}</span>
                                  </template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Arrival.AirportCode }} </span><br/>
                                    <p class="m-0" style="font-size:12px;">{{data1.Arrival.AirportName}}</p> 
                                  </div>
                                </v-timeline-item>

                              </v-timeline>

                                <v-card v-if="data.Flight1.length-1 !== index" style="width:55%;position:relative;left:15%;background-color:#63AB45;color:white;" class="p-1">
                                  <div class="d-flex justify-center">
                                    <div class="">
                                    <v-img :src="require('../assets/sandClock.png')" width="15px" style="padding-top:8px;"></v-img>
                                    </div>
                                    <div>
                                    <p class="m-0 ms-2" style="font-size:14px;">{{data.layoverTimes1[index]}} - LayoverTime</p>
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                              </v-card>
                              <v-card style="border:1px solid grey;" class="my-4">
                                <div class="d-flex flex-row align-items-center">
                                <h6 class="p-2 mb-0" style="font-size:14px;">Flight2</h6>
                                <span class="fw-bold"> {{ getdepNameMulti(data.muticityData[1]) }} - {{ getarrNameMulti(data.muticityData[1]) }}</span>
                                </div>
                                <v-divider class="my-0"></v-divider>
                              <div v-for="(data1, index) of data.Flight2" 
                                  :key="index">
                              <v-timeline truncate-line="both" class="ms-5 my-4">
                                <v-timeline-item class="my-2" size="x-small" justify="left">
                                  <template v-slot:opposite>
                                    <div>
                                      <span class="f-size-14 airline-date">{{
                                      getdatedFlight(data1.Departure.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightdep(data1)
                                      }}</span></div></template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Departure.AirportCode }} </span><br/>
                                    <p class="m-0" style="font-size:12px;">{{data1.Departure.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" justify="left">
                                  <template v-slot:icon>
                                    <v-avatar :image="data1.MarketingCarrier.logo" style="background:white;"></v-avatar>
                                  </template>
                                  <template v-slot:opposite>
                                    <div>
                                      <p class="f-size-14 airline-name m-0">
                                      {{ data1.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data1.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data1.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                    </div>
                                      </template>
                                  <div>
                                    <p class="m-0" style="font-size:14px;">{{data1.FlightDetail.FlightDuration.Value}}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" size="x-small">
                                  <template v-slot:opposite>
                                    <span class="f-size-14 airline-date">{{
                                      getdatedFlight1(data1.Arrival.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightarr(data1)
                                      }}</span>
                                  </template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Arrival.AirportCode }} </span><br/>
                                    <p class="m-0" style="font-size:12px;">{{data1.Arrival.AirportName}}</p> 
                                  </div>
                                </v-timeline-item>

                              </v-timeline>

                                <v-card v-if="data.Flight2.length-1 !== index" style="width:55%;position:relative;left:21%;background-color:#63AB45;color:white;" class="p-1">
                                  <div class="d-flex justify-center">
                                    <div class="">
                                    <v-img :src="require('../assets/sandClock.png')" width="15px" style="padding-top:8px;"></v-img>
                                    </div>
                                    <div>
                                    <p class="m-0 ms-2" style="font-size:14px;">{{data.layoverTimes2[index]}} - LayoverTime</p>
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                              </v-card>
                              <v-card v-if="data.Flight3" style="border:1px solid grey;" class="my-4">
                              <div class="d-flex flex-row align-items-center">
                                <h6 class="p-2 mb-0" style="font-size:14px;">Flight3</h6>
                                <span class="fw-bold"> {{ getdepNameMulti(data.muticityData[2]) }} - {{ getarrNameMulti(data.muticityData[2]) }}</span>
                                </div>
                                <v-divider class="my-0"></v-divider>
                              <div v-for="(data1, index) of data.Flight3" 
                                  :key="index">
                              <v-timeline truncate-line="both" class="ms-5 my-4">
                                <v-timeline-item class="my-2" size="x-small" justify="left">
                                  <template v-slot:opposite>
                                    <div>
                                      <span class="f-size-14 airline-date">{{
                                      getdatedFlight(data1.Departure.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightdep(data1)
                                      }}</span></div></template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Departure.AirportCode }} </span><br/>
                                    <p class="m-0" style="font-size:12px;">{{data1.Departure.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" justify="left">
                                  <template v-slot:icon>
                                    <v-avatar :image="data1.MarketingCarrier.logo" style="background:white;"></v-avatar>
                                  </template>
                                  <template v-slot:opposite>
                                    <div>
                                      <p class="f-size-14 airline-name m-0">
                                      {{ data1.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data1.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data1.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                    </div>
                                      </template>
                                  <div>
                                    <p class="m-0" style="font-size:14px;">{{data1.FlightDetail.FlightDuration.Value}}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" size="x-small">
                                  <template v-slot:opposite>
                                    <span class="f-size-14 airline-date">{{
                                      getdatedFlight1(data1.Arrival.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightarr(data1)
                                      }}</span>
                                  </template>
                                  <div>
                                    <span class="fw-600" style="font-size:16px;">{{ data1.Arrival.AirportCode }} </span><br/>
                                    <p class="m-0" style="font-size:12px;">{{data1.Arrival.AirportName}}</p> 
                                  </div>
                                </v-timeline-item>

                              </v-timeline>

                                <v-card  v-if="data.Flight3.length-1 !== index" style="width:55%;position:relative;left:15%;background-color:#63AB45;color:white;" class="p-1">
                                  <div class="d-flex justify-center">
                                    <div class="">
                                    <v-img :src="require('../assets/sandClock.png')" width="15px" style="padding-top:8px;"></v-img></div>
                                    <div>
                                    <p class="m-0 ms-2" style="font-size:14px;">{{data.layoverTimes3[index]}} - LayoverTime</p>
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                              </v-card>
                              <v-card v-if="data.Flight4" style="border:1px solid grey;" class="my-4">
                                <div class="d-flex flex-row align-items-center">
                                <h6 class="p-2 mb-0" style="font-size:14px;">Flight4</h6>
                                <span class="fw-bold"> {{ getdepNameMulti(data.muticityData[3]) }} - {{ getarrNameMulti(data.muticityData[3]) }}</span>
                                </div>
                                <v-divider class="my-0"></v-divider>
                               <div v-for="(data1, index) of data.Flight4" 
                                  :key="index">
                              <v-timeline truncate-line="both" class="ms-5 my-4">
                                <v-timeline-item class="my-2" size="x-small" justify="left">
                                  <template v-slot:opposite>
                                    <div>
                                      <span class="f-size-14 airline-date">{{
                                      getdatedFlight(data1.Departure.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightdep(data1)
                                      }}</span></div></template>
                                  <div>
                                    <span class="fw-600 f-size-16">{{ data1.Departure.AirportCode }} </span><br/>
                                    <p class="m-0" style="font-size:12px;">{{data1.Departure.AirportName }}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" justify="left">
                                  <template v-slot:icon>
                                    <v-avatar :image="data1.MarketingCarrier.logo" style="background:white;"></v-avatar>
                                  </template>
                                  <template v-slot:opposite>
                                    <div>
                                      <p class="f-size-16 airline-name m-0">
                                      {{ data1.MarketingCarrier.Name }}

                                      <br />
                                      <span class="f-size-12 airline-id">
                                        {{ data1.MarketingCarrier.AirlineID }}
                                        -
                                        {{ data1.MarketingCarrier.FlightNumber }}
                                      </span>
                                    </p>
                                    </div>
                                      </template>
                                  <div>
                                    <p class="m-0" style="font-size:14px;">{{data1.FlightDetail.FlightDuration.Value}}</p>
                                  </div>
                                </v-timeline-item>

                                <v-timeline-item class="my-2" size="x-small">
                                  <template v-slot:opposite>
                                    <span class="f-size-14 airline-date">{{
                                      getdatedFlight1(data1.Arrival.Date)
                                    }}</span>
                                    <span class="f-size-16 airline-time fw-bold" style="margin-left: 5px">{{ getflightarr(data1)
                                      }}</span>
                                  </template>
                                  <div>
                                    <span class="fw-600 f-size-16">{{ data1.Arrival.AirportCode }} </span><br/>
                                    <p class="m-0" style="font-size:12px;">{{data1.Arrival.AirportName}}</p> 
                                  </div>
                                </v-timeline-item>

                              </v-timeline>

                                <v-card v-if="data.Flight4.length-1 !== index" style="width:55%;position:relative;left:15%;background-color:#63AB45;color:white;" class="p-1">
                                  <div  class="d-flex justify-center">
                                    <div class="">
                                    <v-img :src="require('../assets/sandClock.png')" width="15px" style="padding-top:8px;"></v-img></div>
                                    <div>
                                    <p class="m-0 ms-2" style="font-size:14px;">{{data.layoverTimes4[index]}} - LayoverTime </p>
                                    </div>
                                  </div>
                                </v-card>
                              </div>
                              </v-card>
                               
                               </div>
                          </div>
                          </div>
                                        </div>
                                    </div>
                            

                            <div class="fw-bold mb-3">Fare Details</div>
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <div class="theme-box-shadow theme-border-radius bg-light">
                                        <ul class="p-0">
                                            <li class="border-bottom p-3 font-medium">
                                                <div class="d-flex justify-content-between">
                                                    <span class="font-medium " style="font-weight:500;">
                                                        Passenger</span>
                                                </div>
                                            </li>
                                            <!-- gst charge fare -->
                                            <li class="border-bottom p-3 font-medium">
                                                <div class="d-flex flex-column">
                                                    <span v-if="adultLength > 0"
                                                            >{{ adultLength }} x Adult</span
                                                        ><br v-if="childLength > 0"/>
                                                        <span v-if="childLength > 0"
                                                            >{{ childLength }} x Child</span
                                                        ><br v-if="infLength > 0"/>
                                                        <span v-if="infLength > 0"
                                                            >{{ infLength }} x Lap Infant</span
                                                        >
                                                    <!-- </span> -->
                                                </div>
                                            </li>
                                             <!-- grand total charge fare -->
                                            <li class="border-bottom p-3 font-medium">
                                                <div class="d-flex justify-content-between">
                                                    <span class="" style="font-weight:600">
                                                        Grand Total</span>
                                                    <span class="">
                                                        <span class="fw-bold"> {{ currency }} {{ totalAmountpassenger }}</span>
                                                    </span>
                                                </div>
                                            </li>
                                            <li class="border-bottom p-3 font-medium">
                                              <a class="d-flex align-center justify-content-between theme-text-accent-one"
                                                data-bs-toggle="collapse" href="#faresummary" role="button" aria-expanded="false"
                                                aria-controls="faresummary">
                                                <span class="font-medium fw-bold">Fare Summary</span>
                                                <span>
                                                  <i
                                                    class="bi bi-plus-circle-fill theme-text-accent-two"
                                                  ></i>
                                                </span>
                                              </a>
                                              
                                                <div class="collapse" id="faresummary">
                                                    <div class="d-flex justify-content-between pt-3">
                                                        <span class="font-medium">Base Price<br />
                                                         Taxes & Fees</span>
                                                        <!-- <span class="font-medium d-flex justify-content-between"> -->
                                                            <!-- <i class="bi bi-currency-dollar"></i>
                                                            <span class="fw-normal">11,545</span> -->
                                                            <!-- <span class="text-start">{{ currency }}</span> -->
                                                            <!-- <span class="text-end">{{ totalbasePassanger }}</span><br /> -->
                                                            <!-- <span class="text-start">{{ currency }}</span>
                                                                <span class="text-end">{{ totalTaxPassanger }}</span> -->
                                                        <!-- </span> -->
                                                        <div class="d-flex flex-column">
                                                        <div class="row">
                                                            <div class="col-4 pe-0">{{ currency }}</div>
                                                            <div class="col-8 text-end ps-2">{{ totalbasePassanger }}</div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-4 pe-0">{{ currency }}</div>
                                                            <div class="col-8 text-end">{{ totalTaxPassanger }}</div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li class="border-bottom font-medium">
                                              <div class="d-flex text-start">
                                                <a class="baggage border-none p-3" @click="getFareRules()"
                                                style="font-size:13px;font-weight:600;color: rgb(13, 110, 253); text-decoration: underline;">
                                                <span data-bs-toggle="offcanvas" data-bs-target="#staticBackdrop4" aria-controls="staticBackdrop">Baggage and Cancellation Rules</span>
                                                </a>
                                              </div>
                                              <div class="drawer-section">
                                                <div class="offcanvas offcanvas-end"
                                                    data-bs-backdrop="static" tabindex="-1" 
                                                    id="staticBackdrop4" aria-labelledby="staticBackdropLabel">
                                                <div class="offcanvas-header d-flex justify-content-between" style="background: #5293cb !important;color: #fff !important;">
                                                  <h6 class="offcanvas-title" id="staticBackdrop4">Baggage and Cancellation Rules</h6>
                                                  <button @click="closeBaggageRules" type="button" class="text-reset" data-bs-dismiss="offcanvas" aria-label="Close" color="white">
                                                    <v-icon>mdi-close</v-icon>
                                                  </button>
                                                </div>
                                                <div class="offcanvas-body pt-0" style="overflow-x:hidden;">
                                 <v-card class="mt-4">
                                  <v-tabs v-model="tab" align-tabs="center" stacked>
                                    <v-tab value="tab-1" class="m-0">
                                      <v-icon
                                        >mdi-bag-checked</v-icon
                                      >
                                      Baggage
                                    </v-tab>
                                      <v-tab value="tab-2">
                                      <v-icon>mdi-book-cancel-outline</v-icon>
                                      Cancellation Rules
                                    </v-tab>
                                  </v-tabs>

                                  <v-card-text class="pt-0">
                                    <keep-alive>
                                      <v-window v-model="tab">
                                <div v-if="tab === 'tab-1'">
                                <h6 class="mt-1">Baggage Rules:</h6>
                                <div class="p-2 rounded" v-for="(data, index) of roundresult" :key="index">
                                  <div>
                                  <div style="font-size: 15px; font-weight: 500">
                                {{ data.fromTrip }} - {{ data.toTrip }}
                              </div>
                              <div class="d-flex ms-2 mt-2">
                                <!-- <div>
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >

                                  <span> Checked Bag:</span>
                                  <span v-if="data.depBaggage" class="ms-1">
                                    {{ data.depBaggage.PieceAllowance.TotalQuantity || 0 }}

                                    {{ data.depBaggage.PieceAllowance.TotalQuantity > 1 ? "Pieces" : "Piece"}}
                                  </span>
                                  <span v-if="data.BaggageData" class="ms-1">
                                    {{ data.BaggageData.PieceAllowance.TotalQuantity || 0 }}

                                    {{ data.BaggageData.PieceAllowance.TotalQuantity > 1 ? "Pieces" : "Piece"}}
                                  </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>Carry-On:</span>
                                   <span v-if="data.depBaggage" class="ms-1">
                                    {{
                                      data.depBaggage.PieceAllowance
                                        .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                    }}
                                  </span>
                                  <span v-if="data.BaggageData" class="ms-1">
                                    {{
                                      data.BaggageData.PieceAllowance
                                        .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                    }}
                                  </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                              <hr v-if="data.Return" />
                              <div v-if="data.Return" class="mt-2">
                                <div
                                class="mt-1"
                                style="font-size: 15px; font-weight: 500"
                              >
                                {{ data.toTrip }} - {{ data.fromTrip }}
                              </div>
                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                              <div class="d-flex ms-2 mt-1">
                                <!-- <div style="color: gray !important">
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span> Checked Bag:</span>
                                  <!-- <span class="ms-1"> {{res.arrBaggage.PieceAllowance.TotalQuantity}}Piece</span> -->
                                  <span class="ms-1">
                                    {{
                                      data.arrBaggage.PieceAllowance
                                        .TotalQuantity || 0
                                    }}
                                    {{
                                      data.arrBaggage.PieceAllowance
                                        .TotalQuantity > 1
                                        ? "Pieces"
                                        : "Piece"
                                    }}
                                  </span>
                                  <v-icon color="gray" style="color:gray !important;">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>Carry-On:</span>
                                  <span class="ms-1">
                                    {{
                                      data.arrBaggage.PieceAllowance
                                        .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                    }}
                                  </span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                                </div>
                                <div v-if="multiresult">
                                <div class="p-2 rounded" v-for="(data, index) of multiresult" :key="index">
                                  <div>
                                  <div style="font-size: 15px; font-weight: 500">
                               {{ getdepNameMulti(data.muticityData[0]) }} -
                            {{ getarrNameMulti(data.muticityData[0]) }}
                              </div>
                              <div class="d-flex ms-2 mt-2">
                                <!-- <div>
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >

                                  <span> Checked Bag: </span>
                                   <span class="mt-1">
                                {{
                                  data.BaggageData1.PieceAllowance
                                    .TotalQuantity || 0
                                }}{{data.BaggageData1.PieceAllowance
                                    .TotalQuantity > 1
                                    ? " Pieces"
                                    : " Piece"}}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>Carry-On: </span>
                                   <span class=""
                                >{{data.BaggageData1.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                }}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                              <hr v-if="data.Flight2" />
                              <div v-if="data.Flight2" class="mt-2">
                                <div
                                class="mt-1"
                                style="font-size: 15px; font-weight: 500"
                              >
                                {{ getdepNameMulti(data.muticityData[1]) }} -
                            {{ getarrNameMulti(data.muticityData[1]) }}
                              </div>
                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                              <div class="d-flex ms-2 mt-2">
                                <!-- <div>
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >

                                  <span> Checked Bag: </span>
                                   <span class="mt-1">
                                {{
                                  data.BaggageData2.PieceAllowance
                                    .TotalQuantity || 0
                                }}{{data.BaggageData2.PieceAllowance
                                    .TotalQuantity > 1
                                    ? " Pieces"
                                    : " Piece"}}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>Carry-On: </span>
                                   <span class=""
                                >{{data.BaggageData2.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                }}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                              <hr v-if="data.Flight3" />
                              <div v-if="data.Flight3" class="mt-2">
                                <div
                                class="mt-1"
                                style="font-size: 15px; font-weight: 500"
                              >
                                {{ getdepNameMulti(data.muticityData[2]) }} -
                            {{ getarrNameMulti(data.muticityData[2]) }}
                              </div>
                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                              <div class="d-flex ms-2 mt-2">
                                <!-- <div>
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >

                                  <span> Checked Bag: </span>
                                   <span class="mt-1">
                                {{
                                  data.BaggageData3.PieceAllowance
                                    .TotalQuantity || 0
                                }}{{data.BaggageData3.PieceAllowance
                                    .TotalQuantity > 1
                                    ? " Pieces"
                                    : " Piece"}}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>Carry-On: </span>
                                   <span class=""
                                >{{data.BaggageData3.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                }}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                              <hr v-if="data.Flight4" />
                              <div v-if="data.Flight4" class="mt-2">
                                <div
                                class="mt-1"
                                style="font-size: 15px; font-weight: 500"
                              >
                                {{ getdepNameMulti(data.muticityData[3]) }} -
                            {{ getarrNameMulti(data.muticityData[3]) }}
                              </div>
                              <!-- <div v-if="flightdatas.arrBaggage.PieceAllowance.TotalQuantity != 0 "></div> -->
                              <div class="d-flex ms-2 mt-2">
                                <!-- <div>
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >

                                  <span> Checked Bag: </span>
                                   <span class="mt-1">
                                {{
                                  data.BaggageData4.PieceAllowance
                                    .TotalQuantity || 0
                                }}{{data.BaggageData4.PieceAllowance
                                    .TotalQuantity > 1
                                    ? " Pieces"
                                    : " Piece"}}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-suitcase
                                  </v-icon>
                                </div>
                              </div>

                              <div class="d-flex ms-2 mt-2">
                                <!-- <div >
                                  
                                </div> -->
                                <div
                                  style="
                                    font-size: 14px;
                                    font-weight: 400;
                                    margin-left: 5px;
                                  "
                                >
                                  <span>Carry-On: </span>
                                   <span class=""
                                >{{data.BaggageData4.PieceAllowance
                                    .TotalQuantity > 0 ? 1 + ' Piece' : 1 + ' Piece'
                                }}</span>
                                  <v-icon color="gray" style="color: gray !important">
                                    mdi-bag-checked
                                  </v-icon>
                                </div>
                              </div>
                              </div>
                                </div>
                                </div>
                                </div>
                                <div v-if="tab === 'tab-2'">
                                  <h6 class="mt-2">Cancellation Rules:</h6>
                                  <div class="p-2 cancellationRules">
                                    <p class="my-1" v-if="!fareRulesContent">Please check the Airline websites</p>
                                    <div v-else v-html="fareRulesContent"></div>
                                  </div>
                                </div>
                                </v-window>
                                </keep-alive>
                              </v-card-text>
                            </v-card>
                              </div>
                                                </div>
                                              </div>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            </div>
                            <div class="row" style="display:none !important;">
                                <div class="col-12">
                                    <div class="theme-box-shadow theme-border-radius bg-light p-3 font-small">
                                        Cancellation &amp;
                                        Date change
                                        charges <a href="#" class="">More</a></div>
                                </div>
                            </div>
                            <div class="row pe-0">
                            <div class="col-12 col-md-12 pe-0">
                                <!-- Acknowledgement details section -->
                                <div class="d-flex d-lg-none justify-content-between align-items-start mb-3">
                                    <div class="fw-bold">Acknowledgement</div>
                                </div>
                                <!-- Acknowledgement details -->
                                <div class="d-lg-none theme-box-shadow theme-border-radius bg-light mb-3 p-3">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-check mb-3">
                                                <input v-model="formCheckBox" class="form-check-input" type="checkbox" value=""
                                                    id="flexCheckChecked" checked>
                                                <label class="form-check-label font-small" for="flexCheckChecked">
                                                    I understand and agree with the <a href="#">Privacy Policy</a>, the <a
                                                        href="#">Terms & Conditions</a>
                                                    of Zetsfly.
                                                </label>
                                                <p v-if="!formCheckBox && checkForm" class="w-50 m-0 f-size-12 fw-400" 
                                                style="color: #b00020">You must agree to continue! </p>
                                            </div>
                                            <button type="submit" class="btn btn-effect btn-book px-5">Continue</button>
                                                
                                                <!-- <v-btn
                                                    width="25%"
                                                    height="40px"
                                                    class="continue-btn"
                                                    type="submit"
                                                    style="
                                                        color: white;
                                                        text-transform: capitalize;
                                                        background-color: #212529;
                                                    " @click="submitform"
                                                    >Continue</v-btn> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                      </div>
                    
                    <!-- button section -->
                    
                    
                </div>
            </div>
        
    </v-form>
  </div>
  <!-- <div v-if="pageloader">
      <pageloader></pageloader>
  </div> -->

  <div v-if="!pageloader">
    <pageLoader></pageLoader>
  </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import country1 from "@/coundtrycode.json";
// import pageloader from "@/components/dataLoder.vue";

import pageLoader from "@/components/loaderComponent.vue";

import { getConfigDataFromLocalStorage } from "@/localStorageData/localStorageUtil.js";

export default {
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  components: {
    pageLoader,
  },
  data() {
    const monthListAll = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(2000, i, 1); // Use any year, here I used 2000
      const monthName = date
        .toLocaleString("default", { month: "short" })
        .substring(0, 3);
      return monthName;
    });

    return {
        shoppingid:"",
        selectId:"",
        tab:null,
        fareRulesContent:"",
        sameLogoData: [],
        sameLogoData1: [],
        sameLogoDataMulti: [],
        sameLogoDataMulti1: [],
        sameLogoDataMulti2: [],
        sameLogoDataMulti3: [],
        errorMessage:[],
        diffLogo:null,
        thirdLogo:null,
        sameLogo:null,
        sameLogo1:null,
        dropdown1:true,
        collapseData:null,
      checkOutPage: true,
      pageloader: true,
      // pageloader: false,
      dialog1: false,
      dialog2: false,
      airlogodata: [],
      portal: false,
      countrycode: [],
      portal_shopId: "",
      portal_offId: "",
      portal_Id:"",
      limitPosition: 500,
      scrolled: false,
      lastPosition: 0,
      topSticky: "23%",
      booking_Id: "",
      formCheckBox: false,
      checkForm: false,
      datenumber: false,
      contactCheck: true,
      couponsCheck: true,
      totalAmountpassenger: [],
      currency: "",
      totalTaxPassanger: [],
      totalbasePassanger: [],
      valid1: false,
      ffb: "",
      air: "Aeroplan-Air canada",
      Redress: "",
      Traveler: "",
      Redressnumber: true,
      result: "name",
      value: "1",
      dateOfBirth: "",
      dateFormat: "dd MMM yyyy",
      apivalue: true,
      dateData: [],
      yearData: [],
      portalData: [],
      mode: true,

      passengersDatas: {
        adultDatas: [],
        childDatas: [],
        infrantDatas: [],
      },

      adultData: [],
      childData: [],
      infrantData: [],
      adult: "",
      child: "",
      inf: "",

      paxContacts: [
        {
          number: "",
          email: "",
        },
      ],

      emailRequire: [
        (v) => !!v || "This field is required",
        (v) => /^[a-zA-Z0-9._]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(v) || "Please enter a valid email address",
      ],

      phoneNumberValid: [
        (v) => !!v || "Phone Number is required",
        (v) => /^\+?\d{5,15}$/.test(v) || "Invalid Phone Number",
      ],

      //     emailRequire: {
      //   required: (v) => !!v || 'Email is required',
      //   validEmail: (v) => /.+@.+\..+/.test(v) || 'Please enter a valid email address'
      // }

      itineraryInfo: false,
      itineraryInfo1: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      flightInfo: false,
      flightDetail: false,
      bagInfo: false,
      baggageDetails: false,
      overlaySelector: false,
      valid: true,
      titleBox: true,
      roundresult: [],
      onewayresult: [],
      multiresult: [],
      isIcon: false,
      fromData: "",
      toData: "",
      adultLength: "",
      childLength: "",
      infLength: "",
      classed: "",
      fareItems: [],
      adultFare: [],
      childFare: [],
      infrantFare: [],
      adultprice: [],
      childprice: [],
      infprice: [],
      adulttax: [],
      childtax: [],
      inftax: [],
      totaltax: [],
      totalprice: [],
      totalamount: [],
      layoverTimes: [],
      portalResData: [],
      fareshow: false,
      // dobError:false,
      // clicked1: false,
      offerData1: [],
      offerData2: [],
      offerData3: [],
      offerData4: [],
      Flight2: [],
      Flight3: [],
      Flight4: [],
      Flight1: [],
      dobAdultError: false,

      submitClicked:false,
      // dobChildError: false,
      // dobInfrantError: false,

      dataLink: "",

      bookingviewApi: "",

      adultpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },
      chd: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      infpax: {
        firstname: [],
        lastname: [],
        middlename: [],
        salutation: [],
        gender: [],
        dob: [],
      },

      local_id: "",
      Localoneway_id: null,

      titles: ["Flight", "Passenger", "Request", "Free Text"],
      specialBox: [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ],
      selectFlight: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],
      selectPassanger: ["Adult 1"],
      selectRequest: [
        "California",
        "Colorado",
        "Florida",
        "Georgia",
        "Texas",
        "Wyoming",
      ],

      flightValid: [(v) => !!v || "Field is required"],
      passengerValid: [(v) => !!v || "Field is required"],
      requestValid: [(v) => !!v || "Field is required"],
      // textValid:[v => !!v || 'Field is required'],

      firstName: [
        (v) => !!v || "Please enter your First Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      lastName: [
        (v) => !!v || "Please enter your Last Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      middleName: [
        (v) => !!v || "Please enter your Middle Name",
        (v) =>
          /^[a-zA-Z -]+$/.test(v) ||
          "Only alphabets, hyphens, and spaces are allowed",
      ],

      segmentList: [],

      monthListOriginal: [...monthListAll],
      monthList: [...monthListAll],
      storeMonthList: [],
      airlogoapi:"",
      airline_logoData:[],
    };
  },
  methods: {
    closeBaggageRules(){
      this.tab = 'tab-1'
    },

    filterkey(evt) {
      let key = evt.key;
      if (/^[0-9]*$/.test(key)) {
        evt.preventDefault();
      }
    },
     getdepNameMulti(data) {
      return data.from;
    },
    getarrNameMulti(data) {
      return data.to;
    },
      getdatedFlight($event) {
      return moment($event).format("MMM DD");
    },
     getdatedFlightMulti($event) {
      return moment($event[0].Date).format("MMM DD");
    },
     getdatedFlight1($event) {
      return moment($event).format("MMM DD");
    },
    getdated2($event) {
      return moment($event).format("MMM DD, YYYY");
    },
    getdated3($event) {
      return moment($event).format("MMM DD, YYYY");
    },
   validateData() {
      this.errorMessage = []; 
      let hasErrors = false;

      if (this.adultData.length !== this.adult) {
        this.errorMessage.push("Please add the required number of adults");
        hasErrors = true;
      }
      if (this.childData.length !== this.child) {
        this.errorMessage.push("Please add the required number of child");
        hasErrors = true;
      }
      if (this.infrantData.length !== this.inf) {
        this.errorMessage.push("Please add the required number of infants");
        hasErrors = true;
      }

    return hasErrors;
  },
    
    getsegment() {
      if (this.roundresult) {
        this.roundresult.forEach((v) => {
          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
          if(v.Return){
          v.Return.forEach((e) => {
            this.segmentList.push(e);
          });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...1.");
      }

      if (this.onewayresult) {
        this.onewayresult.forEach((v) => {
          v.Depature.forEach((s) => {
            this.segmentList.push(s);
          });
        });
        console.log(this.onewayresult, "this.segmentListthis.segmentList...2.");
      }

      if (this.multiresult) {
        this.multiresult.forEach((v) => {
          if (v.Flight1) {
            v.Flight1.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight2) {
            v.Flight2.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight3) {
            v.Flight3.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight4) {
            v.Flight4.forEach((s) => {
              this.segmentList.push(s);
            });
          }
          if (v.Flight5) {
            v.Flight5.forEach((s) => {
              this.segmentList.push(s);
            });
          }
        });
        console.log(this.segmentList, "this.segmentListthis.segmentList...3.");
      }
    },

    inputHandle(data, index) {
      if (data.Type == "Adult") {
        if (data.dateDob.year.length < 4) {
          this.adultData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      } else if (data.Type == "Child") {
        if (data.dateDob.year.length < 4) {
          this.childData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      } else if (data.Type == "Infrant") {
        if (data.dateDob.year.length < 4) {
          this.infrantData.map((v, i) => {
            if (index == i) {
              v.dateDob.month = null;
              v.dateDob.date = null;
              v.isYearValid = false;
            }
          });
        }
      }
    },

    inputChange(data, index) {
      // console.log(data,index,'data,indexdata,indexdata,index')

      const enteredYear = data.dateDob.year;

      if (data.Type == "Adult") {
        this.adultData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        this.monthList = [...this.monthListOriginal];
        console.log(this.monthList, "this.monthListthis.monthList....1");
      } else if (data.Type == "Child") {
        this.childData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        this.monthList = [...this.monthListOriginal];
        console.log(this.monthList, "this.monthListthis.monthList....2");
      } else if (data.Type == "Infrant") {
        this.infrantData.map((v, i) => {
          if (index == i) {
            v.dateDob.date = null;
          }
        });

        let currentDate = moment(
          this.segmentList[this.segmentList.length - 1].Departure.Date
        ).format("YYYY-MMM-DD");
        let maximumDate = new Date(
          new Date(currentDate).getFullYear(),
          new Date(currentDate).getMonth(),
          new Date(currentDate).getDate() - 15
        );
        let maxMonth = moment(maximumDate, "YYYY-MMM-DD").format("MMM");

        console.log(currentDate, "currentDate...", maxMonth, "maxMonth...");

        if (enteredYear == new Date(maximumDate).getFullYear()) {
          const maxMonthIndex = this.monthListOriginal.indexOf(maxMonth);
          this.monthList = [...this.monthListOriginal];
          this.monthList.splice(maxMonthIndex + 1);
          console.log(this.monthList, "this.monthListthis.monthList....3");
        } else {
          this.monthList = [...this.monthListOriginal];
          console.log(this.monthList, "this.monthListthis.monthList.....4");
        }
      }
    },

    yearRules(Type, index) {
      console.log(Type,index,this.segmentList[0],'yearRulessss')
      return [
        (v) => !!v || "Please enter the Year",
        (v) => /^\d+$/.test(v) || "Please enter a valid Year",
        (v) => {
          const currentYear = new Date(
            this.segmentList[0].Departure.Date
          ).getFullYear();
          const enteredYear = parseInt(v, 10);

          let minYear, maxYear;

          if (Type === "Adult") {
            minYear = currentYear - 100;
            maxYear = currentYear - 12;

            if (v.length === 4) {
              console.log(enteredYear, "enteredYearenteredYear");
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.adultData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          } else if (Type === "Child") {
            this.segmentList.forEach((v) => {
              if (this.segmentList.length - 1) {
                maxYear = new Date(v.Departure.Date).getFullYear();
              }
            });

            maxYear = currentYear - 2;
            minYear = maxYear - 10;

            console.log(minYear,'minYearminYearminYear')


            if (v.length === 4) {
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {

                this.childData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          } else if (Type === "Infrant") {
            let currentDate = moment(
              this.segmentList[this.segmentList.length - 1].Departure.Date
            ).format("YYYY-MMM-DD");
            let maximumDate = new Date(
              new Date(currentDate).getFullYear(),
              new Date(currentDate).getMonth() - 1,
              new Date(currentDate).getDate()
            );
            let maxDate = moment(maximumDate, "YYYY-MMM-DD").format(
              "YYYY-MMM-DD"
            );

            maxYear = new Date(maxDate).getFullYear();
            minYear = maxYear - 2;

            if (v.length === 4) {
              const isValid = enteredYear >= minYear && enteredYear <= maxYear;
              if (isValid) {
                this.infrantData.map((v, i) => {
                  if (index == i) {
                    v.isYearValid = true;
                  }
                });
              }
              return (
                isValid || `Enter a year between ${minYear} and ${maxYear}`
              );
            }
          }
        },
      ];
    },

    monthRules(Type, index) {
      return [
        // () => (!!this.adultData[index].dateDob.year || !!this.childData[index].dateDob.year || !!this.infrantData[index].dateDob.year) || `Please enter the Year first`,
        () => {
          if (Type === "Adult") {
            if (!this.adultData[index].dateDob.year) {
              return (
                !!this.adultData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          } else if (Type === "Child") {
            if (!this.childData[index].dateDob.year) {
              return (
                !!this.childData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          } else if (Type === "Infrant") {
            if (!this.infrantData[index].dateDob.year) {
              return (
                !!this.infrantData[index].dateDob.year ||
                `Please enter the Year first`
              );
            }
          }
        },

        (v) => !!v || `Please enter the Month`,
      ];
    },

    dateRules(data, index) {
      return [
        () => {
          if (data.Type === "Adult") {
            if (
              !this.adultData[index].dateDob.year &&
              !this.adultData[index].dateDob.month
            ) {
              return (
                (!!this.adultData[index].dateDob.year &&
                  !!this.adultData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          } else if (data.Type === "Child") {
            if (
              !this.childData[index].dateDob.year &&
              !this.childData[index].dateDob.month
            ) {
              return (
                (!!this.childData[index].dateDob.year &&
                  !!this.childData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          } else if (data.Type === "Infrant") {
            if (
              !this.infrantData[index].dateDob.year &&
              !this.infrantData[index].dateDob.month
            ) {
              return (
                (!!this.infrantData[index].dateDob.year &&
                  !!this.infrantData[index].dateDob.month) ||
                `Please enter the Year and Month first`
              );
            }
          }
        },

        (v) => /^\d+$/.test(v) || "Please enter the date",
        (v) => (v >= 1 && v <= 31) || "Please enter a valid date",
        (v) => /^\d{2}$/.test(v) || 'format like "05"',

        (v) => {
          const dataDob =
            data.Type === "Adult"
              ? this.adultData[index].dateDob
              : data.Type === "Child"
              ? this.childData[index].dateDob
              : this.infrantData[index].dateDob;

          const year = dataDob.year;
          const month = dataDob.month;

          if (year && month) {
            console.log(year, month, "yearyearyearyear");
            const monthIndex = this.monthList.indexOf(month);
            console.log(monthIndex, "monthIndexmonthIndexmonthIndex");
            console.log(this.monthList, "monthListmonthListmonthList");
            const lastDayOfMonth = new Date(year, monthIndex + 1, 0).getDate();
            console.log(lastDayOfMonth, "lastDayOfMonthlastDayOfMonth");
            return (
              (v >= 1 && v <= lastDayOfMonth) || `Invalid date for ${month}`
            );
          }

          return true;
        },

        (v) => {
          console.log(this.segmentList,'segmentsdadad')
          let currentDate = moment(
            this.segmentList[this.segmentList.length - 1].Departure.Date
          ).format("YYYY-MMM-DD");
          let maximumDate = new Date(
            new Date(currentDate).getFullYear(),
            new Date(currentDate).getMonth(),
            new Date(currentDate).getDate() - 15
          );
          let formatDate = moment(maximumDate, "YYYY-MMM-DD").format(
            "YYYY-MMM-DD"
          );
          let maxYear = new Date(formatDate).getFullYear();
          let maxMonth = moment(maximumDate, "YYYY-MMM-DD").format("MMM");
          let maxDate = new Date(formatDate).getDate();
          maxDate = maxDate.toString().padStart(2, "0");
          console.log(maxDate, "maxDatemaxDatemaxDate..111.");
          console.log(
            currentDate,
            "currentDate....",
            maximumDate,
            "maximumDatemaximumDatemaximumDate..."
          );

          if (data.Type === "Infrant") {
            let normalDate = v >= 1 && v <= 31;
            let validDate = v >= 1 && v <= maxDate;

            if (
              maxYear == data.dateDob.year &&
              maxMonth == data.dateDob.month
            ) {
              return (
                validDate ||
                `Allow travel before ${maxMonth},${maxDate} for infrants`
              );
            } else {
              return normalDate;
            }
          }
        },
      ];
    },

    tryAgain1() {
      console.log(this.dataLink, "this.dataLinkthis.dataLink....1");
      this.dialog1 = false;
      this.portelDataConvert();
    },
    tryAgain2() {
      console.log(this.dataLink, "this.dataLinkthis.dataLink....2");
      this.dialog2 = false;

      if (this.local_id) {
        this.$router.push({
          path: "/confirmBook",
          query: { pass_uid: `${this.local_id}` },
        });

        setTimeout(() => {
          this.pageloader = false;
          this.dialog2 = true;
        }, 5000);
      } else {
        this.$router.push({
          path: "/confirmBook",
          query: { pass_uid1: `${this.Localoneway_id}` },
        });

        setTimeout(() => {
          this.pageloader = false;
          this.dialog2 = true;
        }, 5000);
      }
    },

    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    flight1(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightdepature = true;
    },
    flight2(data) {
      console.log(data, "pppppppppppppppppp");
      data.Flightreturn = true;
    },
    closeDep(data) {
      data.Flightdepature = false;
    },
    closearr(data) {
      data.Flightreturn = false;
    },

    flight() {
      this.overlaySelector = true;
      this.baggageDetails = false;
      this.flightInfo = true;
      this.flightDetail = true;
      this.bagInfo = false;
    },
    overlay() {
      this.overlaySelector = false;
    },
    itinerary() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = true;
      this.baggageDetails = false;
      this.bagInfo = true;
    },
    baggage() {
      this.dialog = true;
      this.overlaySelector = true;
      this.flightInfo = true;
      this.flightDetail = false;
      this.baggageDetails = true;
      this.bagInfo = true;
    },
    closedialog() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    closedialog1() {
      this.dialog = false;
      this.overlaySelector = false;
    },
    addBox() {
      if (this.valid && this.specialBox.length < 5) {
        this.specialBox.push({
          // fBox: null,
          pBox: "Adult 1",
          // rBox: null,
          // freeBox: null,
          ...this.specialBox,
        });
      }
      console.log(this.valid, "rest");
    },
    resetValues() {
      this.valid = false;
      this.specialBox = [
        {
          fBox: null,
          pBox: "Adult 1",
          rBox: null,
          freeBox: "",
        },
      ];
    },
     getDateDeparture($event) {
      return moment($event[0].Departure.Date, "YYYY-MM-DD").format("MMM DD");
    },
     getArrivaldate($event) {
      return moment(
        $event[$event.length - 1].Arrival.Date,
        "YYYY-MM-DD"
      ).format("MMM DD");
    },
    getTime($event) {
      return moment($event[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getArrivalTime($event) {
      return moment($event[$event.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    
    areLogosequal(data){
      for(let i=0;i < data.length; i++){
        if(!this.sameLogoData.includes(data[i].MarketingCarrier.logo)){
          this.sameLogoData.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoData,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoData
    },
    areLogosequal1(data){
      for(let i=0;i < data.length; i++){
        if(!this.sameLogoData1.includes(data[i].MarketingCarrier.logo)){
          this.sameLogoData1.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoData1,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoData1
    },
    areLogosequalMulti(data){
      // this.sameLogoDataMulti = []
      // console.log(this.sameLogoDataMulti,'sameLogoDataMultisameLogoDataMulti')

      for(let i=0;i < data.length; i++){
        if(!this.sameLogoDataMulti.includes(data[i].MarketingCarrier.logo)){
          this.sameLogoDataMulti.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti
    },
    areLogosequalMulti1(data){
      for(let i=0;i < data.length; i++){
        if(!this.sameLogoDataMulti1.includes(data[i].MarketingCarrier.logo)){
          this.sameLogoDataMulti1.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti1,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti1
    },
     areLogosequalMulti2(data){
      for(let i=0;i < data.length; i++){
        if(!this.sameLogoDataMulti2.includes(data[i].MarketingCarrier.logo)){
          this.sameLogoDataMulti2.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti2,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti2
    },
     areLogosequalMulti3(data){
      for(let i=0;i < data.length; i++){
        if(!this.sameLogoDataMulti3.includes(data[i].MarketingCarrier.logo)){
          this.sameLogoDataMulti3.push(data[i].MarketingCarrier.logo)
        }
        // console.log(this.sameLogoDataMulti3,'sameLogoDatasameLogoDatasameLogoData')
      }
      return this.sameLogoDataMulti3
    },


    getdepcode(data) {
      console.log(data, "getdepcode");
      return data[0].Departure.AirportCode;
    },
    getdepName(data) {
      console.log(data, "getdepcode1");
      return data.split("(")[0];
    },
    // getdepNameCode(){
    //   return data.split("(")[1].split(")")[0]
    // },
    getdepName1(data) {
      console.log(data, "getdepcode1");
      return data.from;
    },
    getarrcode(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
     getarrName(data) {
      return data.split("(")[0];
    },
    getarrName1(data) {
      return data.to;
    },
    depdate(data) {
      return moment(data[0].Departure.Date).format("ddd, DD MMM");
    },
    getStop(data) {
      return data.length - 1;
    },
    getflightname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getlogo($event) {
      return $event[0].MarketingCarrier.logo;
    },
    getlogo1($event) {
      return $event[0].MarketingCarrier.logo;
    },

    getflightnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getflightid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
    depairname(data) {
      return data[0].Departure.AirportName;
    },
    depairtime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    arrAirname(data) {
      return data[data.length - 1].Arrival.AirportName;
    },
    arrAirtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    // ----return --------------

    getstart(data) {
      return data[0].Departure.AirportCode;
      // console.log(data[0].Departure.AirportCode, 'kkkkkkk...q.e.we.e.e.')
    },
    getend(data) {
      return data[data.length - 1].Arrival.AirportCode;
    },
    getstartdate(data) {
        console.log(data,'dataaaaaa')
      return moment(data[data.length-1].Arrival.Date).format("ddd, MMM DD, YYYY");
    },
    getstop1(data) {
      return data.length - 1;
    },

    getstartname(data) {
      return data[0].MarketingCarrier.Name;
    },

    getstartnumber(data) {
      return data[0].MarketingCarrier.FlightNumber;
    },

    getstartid(data) {
      return data[0].MarketingCarrier.AirlineID;
    },
 
    getdeptime(data) {
      return moment(data[0].Departure.Time, "HH:mm:ss").format("HH:mm");
    },
   

    getarrtime(data) {
      return moment(data[data.length - 1].Arrival.Time, "HH:mm:ss").format(
        "HH:mm"
      );
    },

    getdeparDate($event) {
      console.log($event, "$event$event$event$event");
      return moment($event[0].Departure.Date).format("ddd MMM DD");
    },

    getdeparAirportCode($event) {
      return $event[0].Departure.AirportCode;
    },

    getdeparArrivalcode($event) {
      return $event[$event.length - 1].Arrival.AirportCode;
    },

    getdated($event) {
      return moment($event).format("MMM DD");
    },

    getdated1($event) {
      return moment($event).format("MMM DD");
    },
    getflightdep(data) {
      return moment(data.Departure.Time, "HH:mm:ss").format("HH:mm");
    },
    getflightarr(data) {
      return moment(data.Arrival.Time, "HH:mm:ss").format("HH:mm");
    },

    reset() {
      (this.ffb = ""), (this.air = "");
    },
    reset1() {
      (this.Redress = ""), (this.Traveler = "");
    },
    added() {
      let result =
        this.adultData.length + this.childData.length + this.infrantData.length;
      if (this.paxContacts.length < result) {
        this.paxContacts.push({
          number: "",
          email: "",
        });
      }
    },
    delet(index) {
      this.paxContacts.splice(index, 1);
    },
    ffbdata(data, index) {
      console.log(index, "index");
      console.log(data, "12345");
      this.adultData.map((item, i) => {
        if (i == index) {
          item.ffbnumber = !item.ffbnumber;
        }

        return item;
      });
    },
    tsadata(index) {
      this.adultData.map((item, i) => {
        if (i == index) {
          item.TSAnumber = !item.TSAnumber;
        }

        return item;
      });
    },
    apidata(index) {
      this.apivalue = true;
      this.adultData.map((item, i) => {
        if (i == index) {
          item.Apinumber = !item.Apinumber;
        }

        return item;
      });
    },

    day() {
      for (let i = 1; i <= 31; i++) {
        this.dateData.push(i);
        // console.log(this.date, '9999999')
      }
    },
    year() {
      for (let i = 2023; i <= 2035; i++) {
        this.yearData.push(i);
        // console.log(this.yearData, '9999999')
      }
    },

    adultDob() {
      this.adultData.map((v) => {
        if (v.dateOfBirth) {
          console.log(v.dateOfBirth, "dobdob");

          v.clicked1 = true;
          v.dobAdultError = false;
          console.log(v.dobAdultError, "dobdob");
        } else {
          v.clicked1 = false;
          v.dobAdultError = true;
        }
      });
    },
    childDob() {
      this.childData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          this.dobChildError = false;
        } else {
          v.clicked1 = false;
          this.dobChildError = true;
        }
      });
    },
    infrantDob() {
      this.infrantData.map((v) => {
        if (v.dateOfBirth) {
          v.clicked1 = true;
          this.dobInfrantError = false;
        } else {
          v.clicked1 = false;
          this.dobInfrantError = true;
        }
      });
    },

    // ClickOutDepat() {
    //     this.$refs.dpRef1.closeMenu();

    //     if (this.oneway.dedate) {
    //         this.clicked1 = true;
    //     }
    //     else {
    //         this.clicked1 = false;
    //     }
    // },

    getData() {
      console.log(this.adultData,'adultDataaaa')
      console.log(this.adultData.length,'adultDataaaa......1')
      for (let i = 0; i <= this.adultData.length; i++) {
        if (this.adultData.slice(0,1) < this.adult ) {
          this.adultData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Adult",
            clicked1: false,
            dobAdultError: false,
            isYearValid: false,
          });
        } 
        
        else {
          return this.adultData;
        }
      }
      console.log(this.adultData, "ssss");
    },
    addAdult(){
      console.log(this.adultData.length,'adultData......2')
        if(this.adultData.length < this.adult){
            this.adultData.push({
                fname: "",
                last: "",
                middle: "",
                gender: "Male",
                salutation: "Mr",
                dateDob: {
                    year: null,
                    month: null,
                    date: null,
                },
                dateOfBirth: null,
                ffbnumber: false,
                TSAnumber: false,
                Apinumber: false,
                Type: "Adult",
                clicked1: false,
                dobAdultError: false,
                isYearValid: false,
            
            });
            this.errorMessage = ""
        }
        
    },

    getChild() {
      for (let i = 0; i <= this.childData.length; i++) {
        if (this.childData.slice(0,1) < this.child) {
          this.childData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Child",
            clicked1: false,
            isYearValid: false,
            // dobChildError: false,
          });
        } else {
          return this.childData;
        }
      }
    },
      addChild(){
        if (this.childData.length < this.child) {
          this.childData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Child",
            clicked1: false,
            isYearValid: false,
            // dobChildError: false,
          });
        }
    },

    getinfrant() {
      for (let i = 0; i <= this.infrantData.length; i++) {
        if (this.infrantData.slice(0,1) < this.inf) {
          this.infrantData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Infrant",
            clicked1: false,
            isYearValid: false,
            // dobInfrantError: false,
          });
        } else {
          return this.infrantData;
        }
      }
    },
     
    addInf(){
        if (this.infrantData.length < this.inf) {
          this.infrantData.push({
            fname: "",
            last: "",
            middle: "",
            gender: "Male",
            salutation: "Mr",
            dateDob: {
              year: null,
              month: null,
              date: null,
            },
            dateOfBirth: null,
            ffbnumber: false,
            TSAnumber: false,
            Apinumber: false,
            Type: "Infrant",
            clicked1: false,
            isYearValid: false,
            // dobInfrantError: false,
          });
        }
    },

    getadult() {
      this.pageloader = true;
      this.roundresult.forEach((v) => {
        console.log(v, "tttooooo....");
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    onewayData() {
      this.pageloader = true;
      this.onewayresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength = v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    multiData() {
      this.pageloader = true;
    //   let res = this.local_id.split("-");
    //   let res1 = res[res.length - 1];
    //   let data_pass = [];
    //   data_pass = JSON.parse(localStorage.getItem(`${"pass"}-${res1}`));

      

      let totalpass = [];
      totalpass = this.adultLength + this.childLength + this.infLength;
      console.log(totalpass, "totalpass");

      this.multiresult.forEach((v) => {
        this.currency = v.currency;
        this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
        this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
        this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
        this.adultLength = v.Adult;
        this.childLength = v.Child;
        this.infLength =v.Inf;
        this.adult = v.Adult;
        this.child = v.Child;
        this.inf = v.Inf;
      });
    },

    handleScroll() {
      if (
        this.lastPosition < window.scrollY &&
        this.limitPosition < window.scrollY
      ) {
        this.scrolled = true;
        this.topSticky = "5%";
      } else {
        this.scrolled = false;
        this.topSticky = "23%";
      }

      this.lastPosition = window.scrollY;
    },
    // fareinsert() {
    //   this.insertFare.hit_id = this.booking_Id

    // },
pushData(){
         this.$router.push({
  path: "/confirmBook",
  query: { pass_id1: this.Localoneway_id }
})
},

pushData1(){
   this.$router.push({
              path: "/confirmBook",
              query: { pass_id: this.local_id }
            });
},
pushData2(){
     this.$router.push({
              path: "/confirmBook",
              query: { pass_id: this.local_id }
            });
},
getTravelerData() {
      let travelerData = localStorage.getItem('TravelerDetails');
      let passengerData = localStorage.getItem('passengerContact');
      if (travelerData) {
        travelerData = JSON.parse(travelerData);
      }

      if (travelerData.adultDatas.length > 0) {
        this.adultData = travelerData.adultDatas;
      } 
      if (travelerData.childDatas.length > 0) {
        this.childData = travelerData.childDatas;
      } 
      if (travelerData.infrantDatas.length > 0) {
        this.infrantData = travelerData.infrantDatas;
      } 
      // paxContacts
      if(passengerData){
        passengerData = JSON.parse(passengerData)
        console.log(passengerData,'passengerDataaa')
      }
      if(passengerData.length > 0){
        this.paxContacts = passengerData
      }
    },

    async submitform() {

      // this.pageloader = true;

      if (this.$refs.form) {
      const { valid } = await this.$refs.form.validate()
      // this.fareinsert();
      let contact = {
          contactCheck: this.contactCheck,
          couponsCheck: this.couponsCheck,
      };
      localStorage.setItem("Travelcontact", JSON.stringify(contact));
      this.passengersDatas.adultDatas = this.adultData;
      this.passengersDatas.childDatas = this.childData;
      this.passengersDatas.infrantDatas = this.infrantData;
console.log(this.adultData,'this.adultData')



console.log(this.valid1,'valid')

      if (valid && this.paxContacts[0].number && this.formCheckBox && !this.validateData()) {
         console.log(this.validateData(),!this.validateData(),'this.validateData())')


        this.datenumber = true;

        this.submitClicked = true;

        if (this.portal) {

       console.log(this.pageloader,this.paxContacts[0].number,this.formCheckBox,!this.validateData(), 
       "pageloaderpageloader...pageloaderpageloader" );


          this.pageloader = false;
          console.log(this.portal,'portal')
          
          let uniDataId = `${"env"}-${this.local_id}`;
           localStorage.setItem(`${uniDataId}`, JSON.stringify(this.portalResData));
          if (this.roundresult.length > 0) {         
             localStorage.setItem(`${"env"}-${this.local_id}`, JSON.stringify(this.portalResData));

            this.$router.push({
              path: "/confirmBook",
              query: { pass_id: uniDataId },
            });
          } else if (this.onewayresult.length > 0) {
           localStorage.setItem(`${"env"}-${this.local_id}`, JSON.stringify(this.portalResData));
            this.$router.push({
              path: "/confirmBook",
              query: { pass_id: uniDataId },
            });
          } else if (this.multiresult.length > 0) {
               localStorage.setItem(`${"env"}-${this.local_id}`, JSON.stringify(this.portalResData));
             localStorage.setItem("env-local_id", JSON.stringify(this.portalResData))
            this.$router.push({
              path: "/confirmBook",
              query: { pass_id: uniDataId },
            });
          }
        }
        else {
          this.pageloader = false;


          setTimeout(()=>{

          if (this.Localoneway_id) {
      console.log(valid, this.paxContacts[0].number, this.formCheckBox, !this.validateData(), "ggghghghghg.....2");

            // console.log('this.Localoneway_id ',this.Localoneway_id );
            this.pushData()
   
          } else if (this.local_id.includes("multi")) {
            // console.log('this.local_id ',this.local_id );
             this.pushData1()
           
          } else if (this.local_id) {
            // console.log('work....work....3', this.local_id);
             this.pushData2()
         
          } else {
            console("Not....working....");
          }
          }, 3000);
        }


        
        localStorage.setItem("TravelerDetails",JSON.stringify(this.passengersDatas));
        localStorage.setItem("passengerContact",JSON.stringify(this.paxContacts));
      } 
      
      else {
        console.log('ppppp')
        console.log(valid, this.formCheckBox, "ggghghghghg.....4");
        this.dobAdultError = true;
        this.dobChildError = true;
        this.dobInfrantError = true;
        this.datenumber = true;

        if (!this.formCheckBox) {
          console.log(this.formCheckBox, "ggghghghghg.....6");
          this.checkForm = true;
        }

        // return this.valid1;
      }

    }

    },
    async getFareRules(){
      let fareRequest = {
      request_type: "farerules",
      shopping_response_id: this.shoppingid,
      offer_id: this.selectId ,
      portal_id: this.portal_Id
    }
    console.log(fareRequest,'fareRequestssssss')
    await axios.get("https://booking.traveloplex.com/api/farerules", {
				// headers: {
				// 	"Content-Type": "application/JSON",
				// },
        params:fareRequest 
			})
				.then((response) => {

					this.fareRulesContent = response.data
					// console.log(response.data, "fareRulesContentfareRulesContent.....")

				})

				.catch((error) => {
					console.log(error, 'erroroor.....')
				})
    },

    async portelDataConvert() {
      let userBooking = {
        offer_id: this.portal_offId,
        shopping_response_id: this.portal_shopId,
        pos: "US",
        api_env: "CERT",
        request_type: "user_booking_view",
      };
    console.log(userBooking,this.bookingviewApi,'userBookinguserBooking');
      await axios
        .post(this.bookingviewApi, userBooking, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((responce) => {
          console.log(responce.data, "responce.dataresponce.data5555555555");
          if (responce.data.view_response == "fail") {
            setTimeout(() => {
              this.dialog1 = true;
            }, 3000);
            console.log("dialog1111");
          } else {
            let result = responce.data;
            this.portalData.push(result);
            console.log(this.portalData, "this.portalDatathis.portalData");
            this.checkOutPage = true;
            this.pageloader = false;
            // this.pageloader = true;
            console.log("dialog11114444444444444");
          }
        })
        .catch((err) => {
          console.log(err, "ppppp5555555");
          setTimeout(() => {
            this.dialog1 = true;
          }, 3000);
        });

      if (this.portalData.length > 0) {
        this.pageloader = false;
        for (let i = 0; i < this.portalData.length; i++) {
          console.log(this.portalData[i], "power");

          let Journey = [];
          let flightsegment = [];
          Journey = this.portalData[i].DataLists.FlightList.Flight;
          console.log(Journey, "Journey");
          flightsegment =
            this.portalData[i].DataLists.FlightSegmentList.FlightSegment;
          let shoppingId = this.portalData[i].ShoppingResponseId;
          // console.log(flightsegment, "flightsegment");
          let $data = [];
          $data.push(this.portalData[i].OffersGroup.AirlineOffers.Offer);

          let source1 = [];
          let source2 = [];
          let source3 = [];
          let source4 = [];
          let adult = 0;
          let child = 0;
          let inf = 0;
          $data.forEach((v) => {
            v.OfferItem[0].forEach((t) => {
              if (t.PassengerType == "ADT") {
                adult = t.PassengerQuantity;
              }
              if (t.PassengerType == "CNN") {
                child = t.PassengerQuantity;
              }
              if (t.PassengerType == "INF") {
                inf = t.PassengerQuantity;
              }
            });

            v.OfferItem[0][0].FareComponent.forEach((s, index) => {
              if (index == 0) {
                this.offerData1.push(s.SegmentRefs.split(" "));

                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source1.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 1) {
                this.offerData2.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source2.push(Journey[i].Journey);
                  }
                }
              }
              if (index == 2) {
                this.offerData3.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source3.push(Journey[i].Journey);
                  }
                }
              }

              if (index == 3) {
                this.offerData4.push(s.SegmentRefs.split(" "));
                for (let i = 0; i <= Journey.length - 1; i++) {
                  if (s.SegmentRefs == Journey[i].SegmentReferences) {
                    source4.push(Journey[i].Journey);
                  }
                }
              }
            });
          });
          console.log(flightsegment, "this.flightsegment");

          if (this.offerData1.length > 0) {
            for (let i = 0; i < this.offerData1.length; i++) {
              let seg = [];
              this.offerData1[i].forEach((f, index) => {
                let items = [];
                items = flightsegment.filter((v) => v.SegmentKey == f);
                if (items.length > 0) {
                  seg.push(items[0]);
                }
                if (index == this.offerData1[i].length - 1) {
                  this.portalResData.push({ Depature: seg });
                  this.Flight1.push(seg);
                }
              });
            }
          }

          if (this.offerData2.length > 0) {
            for (let i = 0; i < this.offerData2.length; i++) {
              let seg = [];
              this.offerData2[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData2[i].length - 1) {
                  this.Flight2.push(seg);
                }
              });
            }
          }
          // console.log(this.offerData3, "this.offerData3this.offerData3");
          if (this.offerData3.length > 0) {
            for (let i = 0; i < this.offerData3.length; i++) {
              let seg = [];
              this.offerData3[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData3[i].length - 1) {
                  this.Flight3.push(seg);
                }
              });
            }
          }

          if (this.offerData4.length > 0) {
            for (let i = 0; i < this.offerData4.length; i++) {
              let seg = [];
              this.offerData4[i].forEach((s, index) => {
                let item = [];
                item = flightsegment.filter((v) => v.SegmentKey == s);
                if (item.length > 0) {
                  seg.push(item[0]);
                }
                if (index == this.offerData4[i].length - 1) {
                  this.Flight4.push(seg);
                }
              });
            }
          }

          for (let i = 0; i < this.airlogodata.length - 1; i++) {
            for (let j = 0; j < flightsegment.length - 1; j++) {
              if (
                flightsegment[j].MarketingCarrier.AirlineID ==
                this.airlogodata[i].id
              ) {
                flightsegment[j].MarketingCarrier.logo =
                  this.airlogodata[i].logo;
              }
            }
          }

          let currency = [];
          let Baseprice = [];
          let TotalPrice = [];
          let Tax = [];
          let perperson = [];
          let ownername = [];
          let ownerlogo = [];
          let offerId = [];
          let cabinType = [];

          console.log($data, "ppppppppppkeerthi");

          $data.forEach((v, i) => {
            console.log(v, i, "offeriddatataa");
            offerId.push(v.OfferID);
          });

          $data.forEach((s) => {
            if (s.ReqCurrency == "USD") {
              currency.push("US$");
            } else if (s.ReqCurrency == "CAD") {
              currency.push("CA$");
            } else {
              currency.push("US$");
            }
            ownername.push(s.OwnerName);
            for (let i = 0; i < this.airlogodata.length - 1; i++) {
              if (s.Owner == this.airlogodata[i].id) {
                ownerlogo.push(this.airlogodata[i].logo);
              }
            }

            if (s.CabinType) {
              cabinType =
                s.CabinType.charAt(0).toUpperCase() +
                s.CabinType.slice(1).toLowerCase();
            } else {
              cabinType =
                this.portalData[i].SearchClass.charAt(0).toUpperCase() +
                this.portalData[i].SearchClass.slice(1).toLowerCase();
            }
            Baseprice.push(s.BasePrice.BookingCurrencyPrice);
            Tax.push(s.TaxPrice.BookingCurrencyPrice);
            perperson.push(s.PerPerson.BookingCurrencyPrice);
            TotalPrice.push(s.TotalPrice.BookingCurrencyPrice);
          });

          console.log(cabinType, "cabinTypecabinTypecabinType");

          for (let i = 0; i < this.portalResData.length; i++) {
            this.portalResData[i].selectId = offerId[i];
            this.portalResData[i].currency = currency[i];
            this.portalResData[i].Baseprice = Baseprice[i];
            this.portalResData[i].TotalPrice = TotalPrice[i];
            this.portalResData[i].Taxprice = Tax[i];
            this.portalResData[i].perperson = perperson[i];
            this.portalResData[i].Return = this.Flight2[i];
            this.portalResData[i].Flight1 = this.Flight1[i];
            this.portalResData[i].Flight2 = this.Flight2[i];
            this.portalResData[i].Flight3 = this.Flight3[i];
            this.portalResData[i].Flight4 = this.Flight4[i];
            this.portalResData[i].ownerlogo = ownerlogo[i];
            this.portalResData[i].ownername = ownername[i];
            this.portalResData[i].Depaturejourney = source1[i];
            this.portalResData[i].Returnjourney = source2[i];
            this.portalResData[i].Flight1JourneyTime = source1[i];
            this.portalResData[i].Flight2JourneyTime = source2[i];
            this.portalResData[i].Flight3JourneyTime = source1[i];
            this.portalResData[i].Flight4JourneyTime = source2[i];
            this.portalResData[i].cabinClass = cabinType;
            this.portalResData[i].adult = adult;
            this.portalResData[i].child = child;
            this.portalResData[i].inf = inf;
            this.portalResData[i].ShoppingResponseId = shoppingId;
          }

          console.log(
            this.portalResData,
            "this.portalResDatathis.portalResData..."
          );
          if (this.offerData3.length > 0) {
            this.multiresult = this.portalResData;
            // this.itineraryInfo = true;
          } else if (this.offerData2.length > 0) {
            this.roundresult = this.portalResData;
            this.itineraryInfo = true;
          } else if (this.offerData1.length > 0) {
            this.onewayresult = this.portalResData;
            this.itineraryInfo1 = true;
          }

          this.portalResData.forEach((v) => {
            console.log(this.portalResData, "ppppp");
            this.local_id = v.selectId;
            this.currency = v.currency;
            this.adult = parseFloat(v.adult);
            console.log(this.adult, "pppp");
            this.adultLength = parseFloat(v.adult);
            this.childLength = parseFloat(v.child);
            this.child = parseFloat(v.child);
            this.inf = parseFloat(v.inf);
            this.infLength = parseFloat(v.inf);
            this.totalTaxPassanger = parseFloat(v.Taxprice).toFixed(2);
            this.totalbasePassanger = parseFloat(v.Baseprice).toFixed(2);
            this.totalAmountpassenger = parseFloat(v.TotalPrice).toFixed(2);
          });
          this.getData();
          this.getChild();
          this.getinfrant();
          console.log(
            this.portalResData,
            "this.portalResDatathis.portalResData"
          );
        }
      } else {
        console.log("flightdata");
      }
    },
     async airlineLogo_func() {

      await axios
        .get(this.airlogoapi.url, {
          headers: {
            "Content-Type": this.airlogoapi.type,
          },
        })
        .then((response) => {
          if (response.data) {
            this.airline_logoData = response.data;
            // console.log(this.airline_logoData, "ressloglooglooglooglool")
          }
        })
        .catch((error) => {
          console.log(error, "ressloerrrororooool");
        });
    },

    getConfig() {
      const getConfigData1 = getConfigDataFromLocalStorage();
      if (getConfigData1) {
        this.bookingviewApi = getConfigData1.payload.portal_configuration.API_endpoints.meta_get_itinerary;
        // console.log(this.bookingviewApi,'bookingviewApibookingviewApibookingviewApi')
        this.portal_Id = getConfigData1.payload.portal_configuration.portal_id;
        this.airlogoapi = getConfigData1.payload.portal_configuration.content_data.airlines_logo;
      }
    },
  },


  watch: {
    adultData: {
      deep: true,
      handler(newData) {
        if (newData) {
          // console.log(newData, 'newDatanewDatanewDatanewDatanewDatanewData')
          newData.forEach((data) => {
            if (data.salutation !== "Mr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }

            if (data.dateDob.date) {
              // console.log(data.dateOfBirth.date,'newDatanewData')
              if (
                data.dateDob.year &&
                data.dateDob.month &&
                data.dateDob.date
              ) {
                const monthIndex = this.monthList.indexOf(data.dateDob.month);
                const convertDob = new Date(
                  parseInt(data.dateDob.year),
                  monthIndex,
                  parseInt(data.dateDob.date)
                );

                const formatDate = moment(convertDob).format("YYYY-MMM-DD");

                data.dateOfBirth = formatDate;

                // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
              }
            }
          });
        }
      },
    },

    childData: {
      deep: true,
      handler(newData) {
        if (newData) {
          newData.forEach((data) => {
            if (data.salutation !== "Mr") {
              data.gender = "Female";
            } else {
              data.gender = "Male";
            }

            if (data.dateDob.date) {
              // console.log(data.dateOfBirth.date,'newDatanewData')
              if (
                data.dateDob.year &&
                data.dateDob.month &&
                data.dateDob.date
              ) {
                const monthIndex = this.monthList.indexOf(data.dateDob.month);
                const convertDob = new Date(
                  parseInt(data.dateDob.year),
                  monthIndex,
                  parseInt(data.dateDob.date)
                );

                const formatDate = moment(convertDob).format("YYYY-MMM-DD");

                data.dateOfBirth = formatDate;

                // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
              }
            }
          });
        }
      },
    },

    infrantData: {
      deep: true,
      handler(newData) {
        newData.forEach((data) => {
          if (data.salutation !== "Mr") {
            data.gender = "Female";
          } else {
            data.gender = "Male";
          }

          if (data.dateDob.date) {
            // console.log(data.dateOfBirth.date,'newDatanewData')
            if (data.dateDob.year && data.dateDob.month && data.dateDob.date) {
              const monthIndex = this.monthList.indexOf(data.dateDob.month);
              const convertDob = new Date(
                parseInt(data.dateDob.year),
                monthIndex,
                parseInt(data.dateDob.date)
              );

              const formatDate = moment(convertDob).format("YYYY-MMM-DD");

              data.dateOfBirth = formatDate;

              // console.log(data.dateOfBirth, 'data.dateOfBirthdata.dateOfBirth')
            }
          }
        });
      },
    },

    "data.number"(newValue) {
      console.log(newValue, "ndnjdnjdnjnd");

      if (newValue.length <= 12 && newValue.length > 5) {
        this.data.number = newValue.slice(0, 12);
      }
    },

    // "data.number"(newValue) {

    //   if (newValue.length <= 12 && newValue.length >5) {
    //     this.data.number = newValue.slice(0, 12);
    //   }
    // },
  },
  

  mounted() {
     window.scrollTo(0, 0)
    country1.forEach((v) => {
      let obj = {
        name: `${v.name}(${v.dial_code})`,
        code: v.code,
      };
      this.countrycode.push(obj);
    });
    

    let result1 = localStorage.getItem("value1");
    console.log(result1, "123245");

    // console.log(this.dateOfBirth, "dobdob.....");

    this.getData();
    this.getChild();
    this.getinfrant();
    if(localStorage.getItem("TravelerDetails")){

      setTimeout(()=>{
        this.getTravelerData();
      },500)
    }

  },


  beforeRouteLeave(to, from, next) {
    if (to.fullPath.startsWith('/flightDetailsCom')) {
      // console.log('Case...1');
      next();
    } 
    else if(!this.validateData() && this.submitClicked) {
      // console.log('Case...2');
      next(); 
    } 
    else if (!this.validateData() && !this.submitClicked) {
      // console.log('Case...3');
      alert('Please Fill the Details and Click the "Continue" button');
      this.submitform(); 
      next(false);
      this.submitClicked = false; 
    } 
    else if (this.validateData() && !this.submitClicked) {
      // console.log('Case...4');
      alert('Please complete the form before leaving');
      this.submitform(); 
      next(false);
      this.submitClicked = false; 
    }
},

   created() {

    // this.$router.beforeEach((to, from, next) => {
    //   this.pageloader = true;

    //   console.log(this.pageloader,'pageloaderpageloaderpagel...1 ');
    //   next();
    // });

    // this.$router.afterEach(() => {
    //   console.log(this.pageloader,'pageloaderpageloaderpagel...2 ')
    //   this.pageloader = false;
    // });

    this.getConfig();
    this.airlineLogo_func();

    let $data = localStorage.getItem("loader");
    if ($data == "true") {
      console.log("working....11111");
      this.$router.push("/");
    }

    console.log(this.bookingviewApi,'userBookinguserBooking');

    this.local_id = this.$route.query.pass_id;
    // this.local_id1 = this.$route.query.pass_id;
    this.dataLink = location.href;
    this.Localoneway_id = this.$route.query.pass_id1;
    console.log(this.local_id,'uid')
    console.log(this.Localoneway_id, "this.local_idthis.local_id888888888888");
    // console.log(this.dataLink, "data_idthisdata8");

    //     if (!this.$route.query.pass_uid) {

    // }

    if (this.dataLink.includes("env")) {
      if (this.dataLink.includes("env")) {
        this.portal = true;
        this.portal_offId = this.$route.query.pass_uid;
        this.portal_shopId = this.$route.query.response_id;
        this.airlogodata = this.airline_logoData;
        this.portelDataConvert();
        this.checkOutPage = false;
        setTimeout(() => {
          this.getsegment();
        }, 500);
      }
    } else if (this.Localoneway_id) {
      let onewaydata_uid = [];
      onewaydata_uid = JSON.parse(
        localStorage.getItem(`${this.Localoneway_id}`)
      );
      console.log(onewaydata_uid,'uid')

      if (onewaydata_uid) {
        this.roundresult.push(onewaydata_uid);
        // this.pageloader = this.roundresult ? false : true;
        this.getadult();
        this.itineraryInfo1 = true;
        this.pageloader = true;

        console.log(this.roundresult, "onewayresult.....onewayresult");

        setTimeout(() => {
          this.getsegment();
        }, 500);
      } else if (this.Localoneway_id !== onewaydata_uid) {
        console.log('onewayData')
        setTimeout(() => {
          this.pageloader = false;
          this.dialog2 = true;
        }, 5000);
      }
    } else if (this.local_id) {
      if (this.local_id.includes("multi")) {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.local_id}`));
        if (data_uid) {
          this.multiresult.push(data_uid);
          // this.pageloader = this.multiresult ? false : true;
          this.multiData();
          this.pageloader = true;
          console.log(data_uid, "multicitymulticitymulticity");
          setTimeout(() => {
            this.getsegment();
          }, 500);
        }
      } else {
        let data_uid = [];
        data_uid = JSON.parse(localStorage.getItem(`${this.Localoneway_id}`));

        if (data_uid) {
          this.roundresult.push(data_uid);
          // this.pageloader = this.roundresult ? false : true;
          this.getadult();
          this.itineraryInfo = true;
          this.pageloader = true;

          console.log(this.roundresult, "roundresult.....roundresult");

          setTimeout(() => {
            this.getsegment();
          }, 500);
        } else if (this.local_id !== data_uid) {
          console.log("dialog1dialog1dialog2");

          setTimeout(() => {
            this.pageloader = false;
            this.dialog2 = true;
          }, 5000);
        }
      }
    } else {
      // console.log('redirectingggg...')
    //   this.$router.push("/");
      // setTimeout(() => {
      //   console.log('dialog1dialog1dialog2')
      //         this.pageloader = false;
      //         this.dialog1 = true;
      //       }, 5000);
    }
    if(this.roundresult.length > 0){
      this.shoppingid = this.roundresult[0].ShoppingResponseId
      this.selectId = this.roundresult[0].selectId
    }
    else if(this.multiresult.length > 0){
      this.shoppingid = this.multiresult[0].ShoppingResponseId
      this.selectId = this.multiresult[0].selectId
    }
  },
};
</script>

<style scoped>

@import "@/assets/main.css";


/* extra css */
a{
    color:black;
}
>>>.bi-clock::before{
  font-size:17px;
}
>>>.bi-info-circle-fill::before{
  font-size:17px;
}

.datePickerSection .yearPick,
.monthPick,
.datePick {
  width: 175px;
}

>>>.v-field--active .v-label.v-field-label{
  background:#f8f9fa;
}

>>> .datePickerSection .yearPick .v-field {
  /* border-radius: 0 !important; */
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

>>> .datePickerSection .monthPick .v-field {
  border-radius: 0 !important;
  padding:0px !important;
}

>>> .datePickerSection .datePick .v-field {
  border-radius: 0 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}


/* >>>.v-field__input{
    padding-top:0px !important;
    padding-bottom:10px;
    padding-left:8px;
    padding-right:5px;
}
>>>.v-field__field{
    height:40px;
    background-color:white;
    border: var(--bs-border-width) solid var(--bs-border-color) !important;
} */
/* >>>.v-field__outline{
    --v-field-border-width: 0.5px !important;
    --v-field-border-opacity: 0.02 !important;
    border-radius:10px;
} */
/* >>>.v-field__append-inner{
    background-color:white !important;
    border:1px solid #dee2e6 !important;
} */



>>>.yearPick .v-field__field{
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
>>>.datePick .v-field__field{
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
}
>>>.monthPick .v-field__field{
    border-right:none !important;
}
>>>.monthPick .v-field__append-inner{
    border-left:none !important;
}

.form-check-input{
  border:1px solid black !important;
}
/* >>>.gender .v-select__selection-text{
    margin-bottom:11px;
    padding-left:5px;
}
>>>.gender .v-field__field{
    border-right:none !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
>>>.gender .v-field__append-inner{
    border-left:none !important;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px;
    padding-right:5px;
} */



/* >>>.title .v-select__selection-text{
    margin-bottom:11px;
    padding-left:5px;
}
>>>.title .v-field__field{
    border-right:none !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
>>>.title .v-field__append-inner{
    border-left:none !important;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px;
    padding-right:5px;
} */


/* >>>.country .v-field__field{
    border-right:none !important;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
}
>>>.country .v-field__append-inner{
    border-left:none !important;
    border-top-right-radius:6px;
    border-bottom-right-radius:6px;
    padding-right:5px;
}
>>>.v-autocomplete__selection{
    margin-bottom:16px;
} */


>>>.Name .v-field__field{
    border-radius:6px;
}
.logoData{
    display:grid;
    grid-template-columns: auto auto;
    gap:2px;
}
a{
    text-decoration: none;
}
/* >>>.v-field--active .v-label.v-field-label{
  background:#f8f9fa;
}
>>>.countryCode .v-field {
  padding-right:0px;
} */


>>> .review-flight .v-input {
  color:black !important;
  font-weight: 500 !important;
}
@media screen and (max-width:430px) {
  .datePickerSection .yearPick,
.monthPick,
.datePick {
  width: 84px;
}

@media screen and (max-width:480px) {
  .flight-search{
    padding:0.6rem 0 2.2rem !important;
  }
  .drawer-section .offcanvas {
    width: 100% !important;
  }
}
}
.drawer-section .offcanvas {
  width: 35%;
}

>>>.drawer-section .v-timeline--vertical.v-timeline{
  grid-row-gap:24px !important;
}
>>>.drawer-section .v-timeline{
  justify-content:left !important;
}
>>>.drawer-section .v-list-item__content{
  display:flex !important;
  width:100%;
  justify-content:space-between;
  align-items:center;
}
.offcanvas-header{
  background: #5293cb !important;
  color:white !important;
}
>>>.btn-icon .v-btn--size-default{
  --v-btn-height: 11px !important;
}
.drawer-section .text-reset:hover{
  color:red !important;
  background:white;
  border-radius:8px;
}

>>>.drawer-section .v-slide-group__content{
  display:flex;
  justify-content:space-evenly;
}
>>>.drawer-section .v-slide-group__content .v-btn{
  height:45px !important;
}
>>>.drawer-section .v-slide-group__content .v-btn__content{
  font-size:12px;
}
>>>.drawer-section .v-slide-group__content{
  background:#f3f1f1;
  height:50px;
}
>>>.drawer-section .v-slide-group__content .v-slide-group-item--active .v-btn__content{
  color:#002d5b !important;
}
>>>.drawer-section .v-timeline-divider__inner-dot{
  background:#63AB45 !important;
}>>>.drawer-section .v-timeline--vertical.v-timeline {
  grid-row-gap: 24px;
}

>>>.drawer-section .v-timeline--vertical.v-timeline {
  /* grid-row-gap: 24px; */
  height: auto !important;
}
.travel-btn {
  position: relative;
  /* background-color: #002d5b !important; */
  color: white !important;
  overflow: hidden;
  transition: background-color 0.3s, color 0.3s;
}

.travel-btn::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #ffc104 0%);
  transition: left 0.3s;
}

.travel-btn:hover::before {
  left: 0;
}

.travel-btn:hover {
  color: black !important;
}
.form-check .form-check-input:checked{
  background-color:#63AB45 !important;
}
>>>.cancellationRules .fareRules_content__1uPa7 .FareRule h2{
  font-size:17px !important;
  font-weight:700 !important;
}
>>>.cancellationRules .fareRules_content__1uPa7 .FareRule p{
  overflow-wrap: break-word !important;
  inline-size: 58%;
}
>>>.cancellationRules .fareRules_content__1uPa7 .FareRule ul li{
  overflow-wrap: break-word !important;
  inline-size: 60%;
}
>>> .drawer-section .v-slide-group__content {
  display: flex;
  justify-content: center !important;
}

>>> .v-slide-group__content .v-btn {
  height: 45px !important;
}

>>> .v-slide-group__content .v-btn__content {
  font-size: 12px;
}

>>> .drawer-section .v-slide-group__content {
  background: #f3f1f1;
  height: 50px;
}

>>> .drawer-section
  .v-slide-group__content
  .v-slide-group-item--active
  .v-btn__content {
  color: #002d5b;
}
>>>.v-tabs--density-default.v-tabs--stacked{
      --v-tabs-height: 58px;
}
>>>.v-tabs--fixed-tabs .v-slide-group__content > *:last-child, .v-tabs--align-tabs-center .v-slide-group__content > *:last-child{
  margin-inline-end: inherit;
}
.offcanvas-title{
  color:white;
}

</style>
