<template>
  <div class="bg-img">
    <div style="margin-left:100px; margin-top:100px;">
      <p class="err-text">Error 404</p>
      <p class="err-content">Sorry, the destination you are looking for is not found</p>
      <v-btn width="200px" height="50px" color="#FF5400" style="background-color:#63AB45;" @click="$router.push('/')">
        Back to Home
      </v-btn>
    </div>

    
  </div>
</template>
<script>

export default {

  data() {
    return {

    };
  },


}

</script>
<style scoped>
.bg-img {
  background-image: url('../assets/img/pagenot.jpg');
    background-repeat: no-repeat;
    background-position: bottom right;
    width: 100%;
    height: 276px;
    background-size: 75% 113%;

}
.err-text{
  font-size: 5rem;
    font-weight: 400;
    margin: 0;
}
.err-content{
  font-size: 22px;
    font-weight: 400;

}


@media only screen and (max-width: 480px) {

  .err-text{
  font-size: 3rem;
    font-weight: 400;
    margin: 0;
}
.err-content{
  font-size: 12px;
    font-weight: 400;

}

}
p{
  line-height:60px;
}


</style>